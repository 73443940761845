import * as React from 'react'

import { Layout } from 'antd'

interface Props {
  className?: string
  style?: React.CSSProperties
  children?: JSX.Element
}

export const ContentBox: React.FC<Props> = ({ className, children, style = {} }) => {
  return (
    <Layout.Content className={className} style={{ margin: 16, ...style }}>
      {children}
    </Layout.Content>
  )
}
