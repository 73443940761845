// source: booking/v1/nomination.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var booking_v1_supplier_pb = require('../../booking/v1/supplier_pb.js');
goog.object.extend(proto, booking_v1_supplier_pb);
var booking_v1_pricing_pb = require('../../booking/v1/pricing_pb.js');
goog.object.extend(proto, booking_v1_pricing_pb);
goog.exportSymbol('proto.swift.booking.v1.BringBookingData', null, global);
goog.exportSymbol('proto.swift.booking.v1.DHLBookingData', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteNominationsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteNominationsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditNominationRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditNominationResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.FrejaBookingData', null, global);
goog.exportSymbol('proto.swift.booking.v1.IntereastBookingData', null, global);
goog.exportSymbol('proto.swift.booking.v1.K2BookingData', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListNominationsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListNominationsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.Nomination', null, global);
goog.exportSymbol('proto.swift.booking.v1.Nomination.BookingDataCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.RevalidateNominationsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.RevalidateNominationsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetNominationPriceRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetNominationPriceResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.SupplierConfiguration', null, global);
goog.exportSymbol('proto.swift.booking.v1.UPSBookingData', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateBookingDataRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateBookingDataResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Nomination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.Nomination.repeatedFields_, proto.swift.booking.v1.Nomination.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.Nomination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Nomination.displayName = 'proto.swift.booking.v1.Nomination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BringBookingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BringBookingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BringBookingData.displayName = 'proto.swift.booking.v1.BringBookingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DHLBookingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DHLBookingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DHLBookingData.displayName = 'proto.swift.booking.v1.DHLBookingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.FrejaBookingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.FrejaBookingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.FrejaBookingData.displayName = 'proto.swift.booking.v1.FrejaBookingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.IntereastBookingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.IntereastBookingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.IntereastBookingData.displayName = 'proto.swift.booking.v1.IntereastBookingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.K2BookingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.K2BookingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.K2BookingData.displayName = 'proto.swift.booking.v1.K2BookingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UPSBookingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UPSBookingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UPSBookingData.displayName = 'proto.swift.booking.v1.UPSBookingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SupplierConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SupplierConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SupplierConfiguration.displayName = 'proto.swift.booking.v1.SupplierConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListNominationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListNominationsRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListNominationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListNominationsRequest.displayName = 'proto.swift.booking.v1.ListNominationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListNominationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListNominationsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListNominationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListNominationsResponse.displayName = 'proto.swift.booking.v1.ListNominationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditNominationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditNominationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditNominationRequest.displayName = 'proto.swift.booking.v1.EditNominationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditNominationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditNominationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditNominationResponse.displayName = 'proto.swift.booking.v1.EditNominationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteNominationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteNominationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteNominationsRequest.displayName = 'proto.swift.booking.v1.DeleteNominationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteNominationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteNominationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteNominationsResponse.displayName = 'proto.swift.booking.v1.DeleteNominationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetNominationPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetNominationPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetNominationPriceRequest.displayName = 'proto.swift.booking.v1.SetNominationPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetNominationPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetNominationPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetNominationPriceResponse.displayName = 'proto.swift.booking.v1.SetNominationPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.RevalidateNominationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.RevalidateNominationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.RevalidateNominationsRequest.displayName = 'proto.swift.booking.v1.RevalidateNominationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.RevalidateNominationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.RevalidateNominationsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.RevalidateNominationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.RevalidateNominationsResponse.displayName = 'proto.swift.booking.v1.RevalidateNominationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateBookingDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateBookingDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateBookingDataRequest.displayName = 'proto.swift.booking.v1.UpdateBookingDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateBookingDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateBookingDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateBookingDataResponse.displayName = 'proto.swift.booking.v1.UpdateBookingDataResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.Nomination.repeatedFields_ = [31,32];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.Nomination.oneofGroups_ = [[24,25,26,27,28,29]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.Nomination.BookingDataCase = {
  BOOKING_DATA_NOT_SET: 0,
  BRING_BOOKING: 24,
  DHL_BOOKING: 25,
  FREJA_BOOKING: 26,
  INTEREAST_BOOKING: 27,
  K2_BOOKING: 28,
  UPS_BOOKING: 29
};

/**
 * @return {proto.swift.booking.v1.Nomination.BookingDataCase}
 */
proto.swift.booking.v1.Nomination.prototype.getBookingDataCase = function() {
  return /** @type {proto.swift.booking.v1.Nomination.BookingDataCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.Nomination.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Nomination.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Nomination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Nomination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Nomination.toObject = function(includeInstance, msg) {
  var f, obj = {
    nominationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    supplierId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    serviceId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    nominationRef: jspb.Message.getFieldWithDefault(msg, 6, ""),
    available: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    winner: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    priceRank: jspb.Message.getFieldWithDefault(msg, 9, 0),
    transitRank: jspb.Message.getFieldWithDefault(msg, 10, 0),
    combinedRank: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cost: jspb.Message.getFieldWithDefault(msg, 12, 0),
    costCurrency: jspb.Message.getFieldWithDefault(msg, 13, ""),
    price: jspb.Message.getFieldWithDefault(msg, 14, 0),
    priceCurrency: jspb.Message.getFieldWithDefault(msg, 15, ""),
    pricingConfig: (f = msg.getPricingConfig()) && booking_v1_pricing_pb.PricingConfig.toObject(includeInstance, f),
    supportsGoodsInvoice: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    note: jspb.Message.getFieldWithDefault(msg, 18, ""),
    etd: (f = msg.getEtd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    eta: (f = msg.getEta()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    earliestPickup: (f = msg.getEarliestPickup()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    latestPickup: (f = msg.getLatestPickup()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    supplierType: jspb.Message.getFieldWithDefault(msg, 23, 0),
    bringBooking: (f = msg.getBringBooking()) && proto.swift.booking.v1.BringBookingData.toObject(includeInstance, f),
    dhlBooking: (f = msg.getDhlBooking()) && proto.swift.booking.v1.DHLBookingData.toObject(includeInstance, f),
    frejaBooking: (f = msg.getFrejaBooking()) && proto.swift.booking.v1.FrejaBookingData.toObject(includeInstance, f),
    intereastBooking: (f = msg.getIntereastBooking()) && proto.swift.booking.v1.IntereastBookingData.toObject(includeInstance, f),
    k2Booking: (f = msg.getK2Booking()) && proto.swift.booking.v1.K2BookingData.toObject(includeInstance, f),
    upsBooking: (f = msg.getUpsBooking()) && proto.swift.booking.v1.UPSBookingData.toObject(includeInstance, f),
    transportMode: jspb.Message.getFieldWithDefault(msg, 30, 0),
    validationWarningsList: (f = jspb.Message.getRepeatedField(msg, 31)) == null ? undefined : f,
    validationErrorsList: (f = jspb.Message.getRepeatedField(msg, 32)) == null ? undefined : f,
    supplierConfiguration: (f = msg.getSupplierConfiguration()) && proto.swift.booking.v1.SupplierConfiguration.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.Nomination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Nomination;
  return proto.swift.booking.v1.Nomination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Nomination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.Nomination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNominationId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServiceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNominationRef(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvailable(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWinner(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceRank(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTransitRank(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCombinedRank(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCost(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostCurrency(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceCurrency(value);
      break;
    case 16:
      var value = new booking_v1_pricing_pb.PricingConfig;
      reader.readMessage(value,booking_v1_pricing_pb.PricingConfig.deserializeBinaryFromReader);
      msg.setPricingConfig(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportsGoodsInvoice(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEtd(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEta(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEarliestPickup(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLatestPickup(value);
      break;
    case 23:
      var value = /** @type {!proto.swift.booking.v1.SupplierType} */ (reader.readEnum());
      msg.setSupplierType(value);
      break;
    case 24:
      var value = new proto.swift.booking.v1.BringBookingData;
      reader.readMessage(value,proto.swift.booking.v1.BringBookingData.deserializeBinaryFromReader);
      msg.setBringBooking(value);
      break;
    case 25:
      var value = new proto.swift.booking.v1.DHLBookingData;
      reader.readMessage(value,proto.swift.booking.v1.DHLBookingData.deserializeBinaryFromReader);
      msg.setDhlBooking(value);
      break;
    case 26:
      var value = new proto.swift.booking.v1.FrejaBookingData;
      reader.readMessage(value,proto.swift.booking.v1.FrejaBookingData.deserializeBinaryFromReader);
      msg.setFrejaBooking(value);
      break;
    case 27:
      var value = new proto.swift.booking.v1.IntereastBookingData;
      reader.readMessage(value,proto.swift.booking.v1.IntereastBookingData.deserializeBinaryFromReader);
      msg.setIntereastBooking(value);
      break;
    case 28:
      var value = new proto.swift.booking.v1.K2BookingData;
      reader.readMessage(value,proto.swift.booking.v1.K2BookingData.deserializeBinaryFromReader);
      msg.setK2Booking(value);
      break;
    case 29:
      var value = new proto.swift.booking.v1.UPSBookingData;
      reader.readMessage(value,proto.swift.booking.v1.UPSBookingData.deserializeBinaryFromReader);
      msg.setUpsBooking(value);
      break;
    case 30:
      var value = /** @type {!proto.swift.common.TransportMode} */ (reader.readEnum());
      msg.setTransportMode(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.addValidationWarnings(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.addValidationErrors(value);
      break;
    case 33:
      var value = new proto.swift.booking.v1.SupplierConfiguration;
      reader.readMessage(value,proto.swift.booking.v1.SupplierConfiguration.deserializeBinaryFromReader);
      msg.setSupplierConfiguration(value);
      break;
    case 34:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 35:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Nomination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Nomination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Nomination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Nomination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNominationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNominationRef();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAvailable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getWinner();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPriceRank();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTransitRank();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCombinedRank();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCost();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCostCurrency();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getPriceCurrency();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPricingConfig();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      booking_v1_pricing_pb.PricingConfig.serializeBinaryToWriter
    );
  }
  f = message.getSupportsGoodsInvoice();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getEtd();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEta();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEarliestPickup();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLatestPickup();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSupplierType();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getBringBooking();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.swift.booking.v1.BringBookingData.serializeBinaryToWriter
    );
  }
  f = message.getDhlBooking();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.swift.booking.v1.DHLBookingData.serializeBinaryToWriter
    );
  }
  f = message.getFrejaBooking();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.swift.booking.v1.FrejaBookingData.serializeBinaryToWriter
    );
  }
  f = message.getIntereastBooking();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.swift.booking.v1.IntereastBookingData.serializeBinaryToWriter
    );
  }
  f = message.getK2Booking();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.swift.booking.v1.K2BookingData.serializeBinaryToWriter
    );
  }
  f = message.getUpsBooking();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.swift.booking.v1.UPSBookingData.serializeBinaryToWriter
    );
  }
  f = message.getTransportMode();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getValidationWarningsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      31,
      f
    );
  }
  f = message.getValidationErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      32,
      f
    );
  }
  f = message.getSupplierConfiguration();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.swift.booking.v1.SupplierConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 nomination_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getNominationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setNominationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.Nomination.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 booking_id = 3;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 supplier_id = 4;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 service_id = 5;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string nomination_ref = 6;
 * @return {string}
 */
proto.swift.booking.v1.Nomination.prototype.getNominationRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setNominationRef = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool available = 7;
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.getAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool winner = 8;
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.getWinner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setWinner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 price_rank = 9;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getPriceRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setPriceRank = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 transit_rank = 10;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getTransitRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setTransitRank = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 combined_rank = 11;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getCombinedRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setCombinedRank = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 cost = 12;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setCost = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string cost_currency = 13;
 * @return {string}
 */
proto.swift.booking.v1.Nomination.prototype.getCostCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setCostCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 price = 14;
 * @return {number}
 */
proto.swift.booking.v1.Nomination.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string price_currency = 15;
 * @return {string}
 */
proto.swift.booking.v1.Nomination.prototype.getPriceCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setPriceCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional PricingConfig pricing_config = 16;
 * @return {?proto.swift.booking.v1.PricingConfig}
 */
proto.swift.booking.v1.Nomination.prototype.getPricingConfig = function() {
  return /** @type{?proto.swift.booking.v1.PricingConfig} */ (
    jspb.Message.getWrapperField(this, booking_v1_pricing_pb.PricingConfig, 16));
};


/**
 * @param {?proto.swift.booking.v1.PricingConfig|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setPricingConfig = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearPricingConfig = function() {
  return this.setPricingConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasPricingConfig = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool supports_goods_invoice = 17;
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.getSupportsGoodsInvoice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setSupportsGoodsInvoice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional string note = 18;
 * @return {string}
 */
proto.swift.booking.v1.Nomination.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional google.protobuf.Timestamp etd = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Nomination.prototype.getEtd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setEtd = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearEtd = function() {
  return this.setEtd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasEtd = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp eta = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Nomination.prototype.getEta = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setEta = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearEta = function() {
  return this.setEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasEta = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp earliest_pickup = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Nomination.prototype.getEarliestPickup = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setEarliestPickup = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearEarliestPickup = function() {
  return this.setEarliestPickup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasEarliestPickup = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.Timestamp latest_pickup = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Nomination.prototype.getLatestPickup = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setLatestPickup = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearLatestPickup = function() {
  return this.setLatestPickup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasLatestPickup = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional SupplierType supplier_type = 23;
 * @return {!proto.swift.booking.v1.SupplierType}
 */
proto.swift.booking.v1.Nomination.prototype.getSupplierType = function() {
  return /** @type {!proto.swift.booking.v1.SupplierType} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.swift.booking.v1.SupplierType} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setSupplierType = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional BringBookingData bring_booking = 24;
 * @return {?proto.swift.booking.v1.BringBookingData}
 */
proto.swift.booking.v1.Nomination.prototype.getBringBooking = function() {
  return /** @type{?proto.swift.booking.v1.BringBookingData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BringBookingData, 24));
};


/**
 * @param {?proto.swift.booking.v1.BringBookingData|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setBringBooking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.swift.booking.v1.Nomination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearBringBooking = function() {
  return this.setBringBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasBringBooking = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional DHLBookingData dhl_booking = 25;
 * @return {?proto.swift.booking.v1.DHLBookingData}
 */
proto.swift.booking.v1.Nomination.prototype.getDhlBooking = function() {
  return /** @type{?proto.swift.booking.v1.DHLBookingData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.DHLBookingData, 25));
};


/**
 * @param {?proto.swift.booking.v1.DHLBookingData|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setDhlBooking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.swift.booking.v1.Nomination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearDhlBooking = function() {
  return this.setDhlBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasDhlBooking = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional FrejaBookingData freja_booking = 26;
 * @return {?proto.swift.booking.v1.FrejaBookingData}
 */
proto.swift.booking.v1.Nomination.prototype.getFrejaBooking = function() {
  return /** @type{?proto.swift.booking.v1.FrejaBookingData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.FrejaBookingData, 26));
};


/**
 * @param {?proto.swift.booking.v1.FrejaBookingData|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setFrejaBooking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.swift.booking.v1.Nomination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearFrejaBooking = function() {
  return this.setFrejaBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasFrejaBooking = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional IntereastBookingData intereast_booking = 27;
 * @return {?proto.swift.booking.v1.IntereastBookingData}
 */
proto.swift.booking.v1.Nomination.prototype.getIntereastBooking = function() {
  return /** @type{?proto.swift.booking.v1.IntereastBookingData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.IntereastBookingData, 27));
};


/**
 * @param {?proto.swift.booking.v1.IntereastBookingData|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setIntereastBooking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.swift.booking.v1.Nomination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearIntereastBooking = function() {
  return this.setIntereastBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasIntereastBooking = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional K2BookingData k2_booking = 28;
 * @return {?proto.swift.booking.v1.K2BookingData}
 */
proto.swift.booking.v1.Nomination.prototype.getK2Booking = function() {
  return /** @type{?proto.swift.booking.v1.K2BookingData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.K2BookingData, 28));
};


/**
 * @param {?proto.swift.booking.v1.K2BookingData|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setK2Booking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.swift.booking.v1.Nomination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearK2Booking = function() {
  return this.setK2Booking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasK2Booking = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional UPSBookingData ups_booking = 29;
 * @return {?proto.swift.booking.v1.UPSBookingData}
 */
proto.swift.booking.v1.Nomination.prototype.getUpsBooking = function() {
  return /** @type{?proto.swift.booking.v1.UPSBookingData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.UPSBookingData, 29));
};


/**
 * @param {?proto.swift.booking.v1.UPSBookingData|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setUpsBooking = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.swift.booking.v1.Nomination.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearUpsBooking = function() {
  return this.setUpsBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasUpsBooking = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional swift.common.TransportMode transport_mode = 30;
 * @return {!proto.swift.common.TransportMode}
 */
proto.swift.booking.v1.Nomination.prototype.getTransportMode = function() {
  return /** @type {!proto.swift.common.TransportMode} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setTransportMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * repeated string validation_warnings = 31;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.Nomination.prototype.getValidationWarningsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 31));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setValidationWarningsList = function(value) {
  return jspb.Message.setField(this, 31, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.addValidationWarnings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 31, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearValidationWarningsList = function() {
  return this.setValidationWarningsList([]);
};


/**
 * repeated string validation_errors = 32;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.Nomination.prototype.getValidationErrorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 32));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.setValidationErrorsList = function(value) {
  return jspb.Message.setField(this, 32, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.addValidationErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 32, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearValidationErrorsList = function() {
  return this.setValidationErrorsList([]);
};


/**
 * optional SupplierConfiguration supplier_configuration = 33;
 * @return {?proto.swift.booking.v1.SupplierConfiguration}
 */
proto.swift.booking.v1.Nomination.prototype.getSupplierConfiguration = function() {
  return /** @type{?proto.swift.booking.v1.SupplierConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierConfiguration, 33));
};


/**
 * @param {?proto.swift.booking.v1.SupplierConfiguration|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setSupplierConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearSupplierConfiguration = function() {
  return this.setSupplierConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasSupplierConfiguration = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 34;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Nomination.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 34));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 35;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Nomination.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 35));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Nomination} returns this
*/
proto.swift.booking.v1.Nomination.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Nomination} returns this
 */
proto.swift.booking.v1.Nomination.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Nomination.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 35) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BringBookingData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BringBookingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BringBookingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BringBookingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    consignmentNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pickupOrderNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BringBookingData}
 */
proto.swift.booking.v1.BringBookingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BringBookingData;
  return proto.swift.booking.v1.BringBookingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BringBookingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BringBookingData}
 */
proto.swift.booking.v1.BringBookingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsignmentNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickupOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BringBookingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BringBookingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BringBookingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BringBookingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsignmentNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPickupOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string consignment_number = 1;
 * @return {string}
 */
proto.swift.booking.v1.BringBookingData.prototype.getConsignmentNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BringBookingData} returns this
 */
proto.swift.booking.v1.BringBookingData.prototype.setConsignmentNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pickup_order_number = 2;
 * @return {string}
 */
proto.swift.booking.v1.BringBookingData.prototype.getPickupOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BringBookingData} returns this
 */
proto.swift.booking.v1.BringBookingData.prototype.setPickupOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DHLBookingData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DHLBookingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DHLBookingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DHLBookingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentTrackingNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dispachConfirmationNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DHLBookingData}
 */
proto.swift.booking.v1.DHLBookingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DHLBookingData;
  return proto.swift.booking.v1.DHLBookingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DHLBookingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DHLBookingData}
 */
proto.swift.booking.v1.DHLBookingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentTrackingNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDispachConfirmationNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DHLBookingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DHLBookingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DHLBookingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DHLBookingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentTrackingNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDispachConfirmationNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string shipment_tracking_number = 1;
 * @return {string}
 */
proto.swift.booking.v1.DHLBookingData.prototype.getShipmentTrackingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLBookingData} returns this
 */
proto.swift.booking.v1.DHLBookingData.prototype.setShipmentTrackingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dispach_confirmation_number = 2;
 * @return {string}
 */
proto.swift.booking.v1.DHLBookingData.prototype.getDispachConfirmationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLBookingData} returns this
 */
proto.swift.booking.v1.DHLBookingData.prototype.setDispachConfirmationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string service_name = 3;
 * @return {string}
 */
proto.swift.booking.v1.DHLBookingData.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLBookingData} returns this
 */
proto.swift.booking.v1.DHLBookingData.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_code = 4;
 * @return {string}
 */
proto.swift.booking.v1.DHLBookingData.prototype.getServiceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLBookingData} returns this
 */
proto.swift.booking.v1.DHLBookingData.prototype.setServiceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.FrejaBookingData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.FrejaBookingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.FrejaBookingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.FrejaBookingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderRef: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.FrejaBookingData}
 */
proto.swift.booking.v1.FrejaBookingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.FrejaBookingData;
  return proto.swift.booking.v1.FrejaBookingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.FrejaBookingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.FrejaBookingData}
 */
proto.swift.booking.v1.FrejaBookingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.FrejaBookingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.FrejaBookingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.FrejaBookingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.FrejaBookingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_ref = 1;
 * @return {string}
 */
proto.swift.booking.v1.FrejaBookingData.prototype.getOrderRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.FrejaBookingData} returns this
 */
proto.swift.booking.v1.FrejaBookingData.prototype.setOrderRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.IntereastBookingData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.IntereastBookingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.IntereastBookingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.IntereastBookingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shipmentId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.IntereastBookingData}
 */
proto.swift.booking.v1.IntereastBookingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.IntereastBookingData;
  return proto.swift.booking.v1.IntereastBookingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.IntereastBookingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.IntereastBookingData}
 */
proto.swift.booking.v1.IntereastBookingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.IntereastBookingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.IntereastBookingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.IntereastBookingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.IntereastBookingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.IntereastBookingData.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.IntereastBookingData} returns this
 */
proto.swift.booking.v1.IntereastBookingData.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shipment_id = 2;
 * @return {string}
 */
proto.swift.booking.v1.IntereastBookingData.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.IntereastBookingData} returns this
 */
proto.swift.booking.v1.IntereastBookingData.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.K2BookingData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.K2BookingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.K2BookingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.K2BookingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalOrderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.K2BookingData}
 */
proto.swift.booking.v1.K2BookingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.K2BookingData;
  return proto.swift.booking.v1.K2BookingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.K2BookingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.K2BookingData}
 */
proto.swift.booking.v1.K2BookingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.K2BookingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.K2BookingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.K2BookingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.K2BookingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string internal_order_id = 1;
 * @return {string}
 */
proto.swift.booking.v1.K2BookingData.prototype.getInternalOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.K2BookingData} returns this
 */
proto.swift.booking.v1.K2BookingData.prototype.setInternalOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UPSBookingData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UPSBookingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UPSBookingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UPSBookingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pickupReqNumb: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UPSBookingData}
 */
proto.swift.booking.v1.UPSBookingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UPSBookingData;
  return proto.swift.booking.v1.UPSBookingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UPSBookingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UPSBookingData}
 */
proto.swift.booking.v1.UPSBookingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickupReqNumb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UPSBookingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UPSBookingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UPSBookingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UPSBookingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPickupReqNumb();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string shipment_id = 1;
 * @return {string}
 */
proto.swift.booking.v1.UPSBookingData.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSBookingData} returns this
 */
proto.swift.booking.v1.UPSBookingData.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pickup_req_numb = 2;
 * @return {string}
 */
proto.swift.booking.v1.UPSBookingData.prototype.getPickupReqNumb = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSBookingData} returns this
 */
proto.swift.booking.v1.UPSBookingData.prototype.setPickupReqNumb = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SupplierConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SupplierConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SupplierConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    disableCost: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SupplierConfiguration}
 */
proto.swift.booking.v1.SupplierConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SupplierConfiguration;
  return proto.swift.booking.v1.SupplierConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SupplierConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SupplierConfiguration}
 */
proto.swift.booking.v1.SupplierConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SupplierConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SupplierConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SupplierConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisableCost();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool disable_cost = 1;
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierConfiguration.prototype.getDisableCost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.SupplierConfiguration} returns this
 */
proto.swift.booking.v1.SupplierConfiguration.prototype.setDisableCost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListNominationsRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListNominationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListNominationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListNominationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nominationIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    bookingIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListNominationsRequest}
 */
proto.swift.booking.v1.ListNominationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListNominationsRequest;
  return proto.swift.booking.v1.ListNominationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListNominationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListNominationsRequest}
 */
proto.swift.booking.v1.ListNominationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNominationIds(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBookingIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListNominationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListNominationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListNominationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNominationIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getBookingIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 nomination_ids = 1;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.getNominationIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.ListNominationsRequest} returns this
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.setNominationIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.ListNominationsRequest} returns this
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.addNominationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListNominationsRequest} returns this
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.clearNominationIdsList = function() {
  return this.setNominationIdsList([]);
};


/**
 * repeated int64 booking_ids = 2;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.getBookingIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.ListNominationsRequest} returns this
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.setBookingIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.ListNominationsRequest} returns this
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.addBookingIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListNominationsRequest} returns this
 */
proto.swift.booking.v1.ListNominationsRequest.prototype.clearBookingIdsList = function() {
  return this.setBookingIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListNominationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListNominationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListNominationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListNominationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListNominationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nominationsList: jspb.Message.toObjectList(msg.getNominationsList(),
    proto.swift.booking.v1.Nomination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListNominationsResponse}
 */
proto.swift.booking.v1.ListNominationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListNominationsResponse;
  return proto.swift.booking.v1.ListNominationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListNominationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListNominationsResponse}
 */
proto.swift.booking.v1.ListNominationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Nomination;
      reader.readMessage(value,proto.swift.booking.v1.Nomination.deserializeBinaryFromReader);
      msg.addNominations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListNominationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListNominationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListNominationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListNominationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNominationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.booking.v1.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Nomination nominations = 1;
 * @return {!Array<!proto.swift.booking.v1.Nomination>}
 */
proto.swift.booking.v1.ListNominationsResponse.prototype.getNominationsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Nomination>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Nomination, 1));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Nomination>} value
 * @return {!proto.swift.booking.v1.ListNominationsResponse} returns this
*/
proto.swift.booking.v1.ListNominationsResponse.prototype.setNominationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.booking.v1.Nomination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.ListNominationsResponse.prototype.addNominations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.booking.v1.Nomination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListNominationsResponse} returns this
 */
proto.swift.booking.v1.ListNominationsResponse.prototype.clearNominationsList = function() {
  return this.setNominationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditNominationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditNominationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditNominationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditNominationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nomination: (f = msg.getNomination()) && proto.swift.booking.v1.Nomination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditNominationRequest}
 */
proto.swift.booking.v1.EditNominationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditNominationRequest;
  return proto.swift.booking.v1.EditNominationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditNominationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditNominationRequest}
 */
proto.swift.booking.v1.EditNominationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Nomination;
      reader.readMessage(value,proto.swift.booking.v1.Nomination.deserializeBinaryFromReader);
      msg.setNomination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditNominationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditNominationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditNominationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditNominationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNomination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Nomination nomination = 1;
 * @return {?proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.EditNominationRequest.prototype.getNomination = function() {
  return /** @type{?proto.swift.booking.v1.Nomination} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Nomination, 1));
};


/**
 * @param {?proto.swift.booking.v1.Nomination|undefined} value
 * @return {!proto.swift.booking.v1.EditNominationRequest} returns this
*/
proto.swift.booking.v1.EditNominationRequest.prototype.setNomination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditNominationRequest} returns this
 */
proto.swift.booking.v1.EditNominationRequest.prototype.clearNomination = function() {
  return this.setNomination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditNominationRequest.prototype.hasNomination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditNominationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditNominationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditNominationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditNominationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nomination: (f = msg.getNomination()) && proto.swift.booking.v1.Nomination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditNominationResponse}
 */
proto.swift.booking.v1.EditNominationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditNominationResponse;
  return proto.swift.booking.v1.EditNominationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditNominationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditNominationResponse}
 */
proto.swift.booking.v1.EditNominationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Nomination;
      reader.readMessage(value,proto.swift.booking.v1.Nomination.deserializeBinaryFromReader);
      msg.setNomination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditNominationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditNominationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditNominationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditNominationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNomination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Nomination nomination = 1;
 * @return {?proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.EditNominationResponse.prototype.getNomination = function() {
  return /** @type{?proto.swift.booking.v1.Nomination} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Nomination, 1));
};


/**
 * @param {?proto.swift.booking.v1.Nomination|undefined} value
 * @return {!proto.swift.booking.v1.EditNominationResponse} returns this
*/
proto.swift.booking.v1.EditNominationResponse.prototype.setNomination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditNominationResponse} returns this
 */
proto.swift.booking.v1.EditNominationResponse.prototype.clearNomination = function() {
  return this.setNomination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditNominationResponse.prototype.hasNomination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteNominationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteNominationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteNominationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteNominationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nominationId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteNominationsRequest}
 */
proto.swift.booking.v1.DeleteNominationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteNominationsRequest;
  return proto.swift.booking.v1.DeleteNominationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteNominationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteNominationsRequest}
 */
proto.swift.booking.v1.DeleteNominationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNominationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteNominationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteNominationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteNominationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteNominationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNominationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteNominationsRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteNominationsRequest} returns this
 */
proto.swift.booking.v1.DeleteNominationsRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 nomination_id = 2;
 * @return {number}
 */
proto.swift.booking.v1.DeleteNominationsRequest.prototype.getNominationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteNominationsRequest} returns this
 */
proto.swift.booking.v1.DeleteNominationsRequest.prototype.setNominationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteNominationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteNominationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteNominationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteNominationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteNominationsResponse}
 */
proto.swift.booking.v1.DeleteNominationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteNominationsResponse;
  return proto.swift.booking.v1.DeleteNominationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteNominationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteNominationsResponse}
 */
proto.swift.booking.v1.DeleteNominationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteNominationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteNominationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteNominationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteNominationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetNominationPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetNominationPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetNominationPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetNominationPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nominationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pricingConfig: (f = msg.getPricingConfig()) && booking_v1_pricing_pb.PricingConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetNominationPriceRequest}
 */
proto.swift.booking.v1.SetNominationPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetNominationPriceRequest;
  return proto.swift.booking.v1.SetNominationPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetNominationPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetNominationPriceRequest}
 */
proto.swift.booking.v1.SetNominationPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNominationId(value);
      break;
    case 2:
      var value = new booking_v1_pricing_pb.PricingConfig;
      reader.readMessage(value,booking_v1_pricing_pb.PricingConfig.deserializeBinaryFromReader);
      msg.setPricingConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetNominationPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetNominationPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetNominationPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetNominationPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNominationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPricingConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      booking_v1_pricing_pb.PricingConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 nomination_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.SetNominationPriceRequest.prototype.getNominationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SetNominationPriceRequest} returns this
 */
proto.swift.booking.v1.SetNominationPriceRequest.prototype.setNominationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PricingConfig pricing_config = 2;
 * @return {?proto.swift.booking.v1.PricingConfig}
 */
proto.swift.booking.v1.SetNominationPriceRequest.prototype.getPricingConfig = function() {
  return /** @type{?proto.swift.booking.v1.PricingConfig} */ (
    jspb.Message.getWrapperField(this, booking_v1_pricing_pb.PricingConfig, 2));
};


/**
 * @param {?proto.swift.booking.v1.PricingConfig|undefined} value
 * @return {!proto.swift.booking.v1.SetNominationPriceRequest} returns this
*/
proto.swift.booking.v1.SetNominationPriceRequest.prototype.setPricingConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetNominationPriceRequest} returns this
 */
proto.swift.booking.v1.SetNominationPriceRequest.prototype.clearPricingConfig = function() {
  return this.setPricingConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetNominationPriceRequest.prototype.hasPricingConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetNominationPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetNominationPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetNominationPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetNominationPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nomination: (f = msg.getNomination()) && proto.swift.booking.v1.Nomination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetNominationPriceResponse}
 */
proto.swift.booking.v1.SetNominationPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetNominationPriceResponse;
  return proto.swift.booking.v1.SetNominationPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetNominationPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetNominationPriceResponse}
 */
proto.swift.booking.v1.SetNominationPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Nomination;
      reader.readMessage(value,proto.swift.booking.v1.Nomination.deserializeBinaryFromReader);
      msg.setNomination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetNominationPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetNominationPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetNominationPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetNominationPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNomination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Nomination nomination = 1;
 * @return {?proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.SetNominationPriceResponse.prototype.getNomination = function() {
  return /** @type{?proto.swift.booking.v1.Nomination} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Nomination, 1));
};


/**
 * @param {?proto.swift.booking.v1.Nomination|undefined} value
 * @return {!proto.swift.booking.v1.SetNominationPriceResponse} returns this
*/
proto.swift.booking.v1.SetNominationPriceResponse.prototype.setNomination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetNominationPriceResponse} returns this
 */
proto.swift.booking.v1.SetNominationPriceResponse.prototype.clearNomination = function() {
  return this.setNomination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetNominationPriceResponse.prototype.hasNomination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.RevalidateNominationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.RevalidateNominationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.RevalidateNominationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.RevalidateNominationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.RevalidateNominationsRequest}
 */
proto.swift.booking.v1.RevalidateNominationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.RevalidateNominationsRequest;
  return proto.swift.booking.v1.RevalidateNominationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.RevalidateNominationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.RevalidateNominationsRequest}
 */
proto.swift.booking.v1.RevalidateNominationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.RevalidateNominationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.RevalidateNominationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.RevalidateNominationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.RevalidateNominationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 BookingId = 1;
 * @return {number}
 */
proto.swift.booking.v1.RevalidateNominationsRequest.prototype.getBookingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.RevalidateNominationsRequest} returns this
 */
proto.swift.booking.v1.RevalidateNominationsRequest.prototype.setBookingid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.RevalidateNominationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.RevalidateNominationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.RevalidateNominationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.RevalidateNominationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.RevalidateNominationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nominationsList: jspb.Message.toObjectList(msg.getNominationsList(),
    proto.swift.booking.v1.Nomination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.RevalidateNominationsResponse}
 */
proto.swift.booking.v1.RevalidateNominationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.RevalidateNominationsResponse;
  return proto.swift.booking.v1.RevalidateNominationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.RevalidateNominationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.RevalidateNominationsResponse}
 */
proto.swift.booking.v1.RevalidateNominationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Nomination;
      reader.readMessage(value,proto.swift.booking.v1.Nomination.deserializeBinaryFromReader);
      msg.addNominations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.RevalidateNominationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.RevalidateNominationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.RevalidateNominationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.RevalidateNominationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNominationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.booking.v1.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Nomination nominations = 1;
 * @return {!Array<!proto.swift.booking.v1.Nomination>}
 */
proto.swift.booking.v1.RevalidateNominationsResponse.prototype.getNominationsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Nomination>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Nomination, 1));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Nomination>} value
 * @return {!proto.swift.booking.v1.RevalidateNominationsResponse} returns this
*/
proto.swift.booking.v1.RevalidateNominationsResponse.prototype.setNominationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.booking.v1.Nomination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.RevalidateNominationsResponse.prototype.addNominations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.booking.v1.Nomination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.RevalidateNominationsResponse} returns this
 */
proto.swift.booking.v1.RevalidateNominationsResponse.prototype.clearNominationsList = function() {
  return this.setNominationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateBookingDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateBookingDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateBookingDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nominationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transportRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateBookingDataRequest}
 */
proto.swift.booking.v1.UpdateBookingDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateBookingDataRequest;
  return proto.swift.booking.v1.UpdateBookingDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateBookingDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateBookingDataRequest}
 */
proto.swift.booking.v1.UpdateBookingDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNominationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateBookingDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateBookingDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateBookingDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNominationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTransportRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 nomination_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.UpdateBookingDataRequest.prototype.getNominationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.UpdateBookingDataRequest} returns this
 */
proto.swift.booking.v1.UpdateBookingDataRequest.prototype.setNominationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string transport_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.UpdateBookingDataRequest.prototype.getTransportRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateBookingDataRequest} returns this
 */
proto.swift.booking.v1.UpdateBookingDataRequest.prototype.setTransportRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateBookingDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateBookingDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateBookingDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nomination: (f = msg.getNomination()) && proto.swift.booking.v1.Nomination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateBookingDataResponse}
 */
proto.swift.booking.v1.UpdateBookingDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateBookingDataResponse;
  return proto.swift.booking.v1.UpdateBookingDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateBookingDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateBookingDataResponse}
 */
proto.swift.booking.v1.UpdateBookingDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Nomination;
      reader.readMessage(value,proto.swift.booking.v1.Nomination.deserializeBinaryFromReader);
      msg.setNomination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateBookingDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateBookingDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateBookingDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNomination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Nomination nomination = 1;
 * @return {?proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.UpdateBookingDataResponse.prototype.getNomination = function() {
  return /** @type{?proto.swift.booking.v1.Nomination} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Nomination, 1));
};


/**
 * @param {?proto.swift.booking.v1.Nomination|undefined} value
 * @return {!proto.swift.booking.v1.UpdateBookingDataResponse} returns this
*/
proto.swift.booking.v1.UpdateBookingDataResponse.prototype.setNomination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateBookingDataResponse} returns this
 */
proto.swift.booking.v1.UpdateBookingDataResponse.prototype.clearNomination = function() {
  return this.setNomination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateBookingDataResponse.prototype.hasNomination = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.swift.booking.v1);
