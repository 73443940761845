import { useState } from 'react'

const useExpandedRow = (multipleExpandableRows?: boolean) => {
  const [expandedRows, setExpandedRows] = useState<string[]>()

  const addOrRemoveKeyFromExpandedRow = (expandRow: boolean, key: string): void => {
    multipleExpandableRows
      ? setExpandedRows(getMultipleExpandedRows(expandedRows, expandRow, key))
      : setExpandedRows(getSingleExpandedRow(expandRow, key))
  }

  const getSingleExpandedRow = (expandRow: boolean, key: string) => {
    return expandRow ? [key] : []
  }

  const getMultipleExpandedRows = (
    expandedRows: string[] | undefined,
    expandRow: boolean,
    key: string,
  ) => {
    if (!expandedRows) {
      return [key]
    }
    return expandRow
      ? [...expandedRows, key]
      : expandedRows.filter((existingKey) => existingKey !== key)
  }

  return { addOrRemoveKeyFromExpandedRow, expandedRows, setExpandedRows }
}

export { useExpandedRow }
