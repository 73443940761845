import { Owner } from 'proto/common/owner_pb'
import { Document, Usage } from 'proto/document/v1/document_pb'
import { User } from 'proto/iam/v1/user_pb'

import { canDeleteDocument as roleCanDeleteDocument } from './rolePermissions'

export const documentLabels: { [key in Usage]: string } = {
  [Usage.NONE]: 'None',
  [Usage.GOODSINVOICE]: 'Goods Invoice',
  [Usage.FREIGHTBILL]: 'Freight Bill',
  [Usage.CMR]: 'CMR',
  [Usage.TRANSPORTORDER]: 'Transport Order',
  [Usage.INVOICE]: 'Invoice',
  [Usage.DEVIATION]: 'Deviation',
  [Usage.BOOKINGCONFIRMATION]: 'Booking Confirmation',
  [Usage.SHIPMENTADVICE]: 'Shipment Advice',
  [Usage.LOADINGLIST]: 'Loading List',
  [Usage.UNLOADINGLIST]: 'Unloading List',
  [Usage.ADMINISTRATIVE]: 'Administrative',
  [Usage.CONTRACT]: 'Contract',
  [Usage.SOP]: 'SOP',
}

export const canDeleteDocument = (doc: Document, user: User) => {
  const metaData = doc.getMetaData()
  if (!metaData || metaData.getUsage() === Usage.CMR) {
    return false
  }

  // Allow users to delete their own documents
  const owner = metaData?.getOwner()
  if (owner && owner.getUserId() === user.getUserId()) {
    return true
  }

  const roles = user.getRolesList()
  return roleCanDeleteDocument(roles)
}

export const filterRepeatedDocuments = (docs: Document[], usages: Usage[]) => {
  const highestDocIds = usages.map((u) =>
    docs.reduce((prev, curr) => {
      const md = curr.getMetaData()
      if (!!md && md.getUsage() === u && !md.getCustom() && md.getDocumentId() > prev) {
        return md.getDocumentId()
      }
      return prev
    }, -1),
  )
  return docs.filter((doc) => {
    const md = doc.getMetaData()
    return (
      !!md &&
      (md.getCustom() ||
        !usages.some((u) => u === md.getUsage()) ||
        highestDocIds.some((h) => h === md.getDocumentId()))
    )
  })
}

export const isPrivateDocument = (owner?: Owner) => {
  if (!owner) {
    return false
  }

  if (owner.getOrganizationId() === 0 && owner.getUserGroupId() === 0) {
    return true
  }
  return false
}
