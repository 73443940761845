import { TransportOperatorUiType } from '../../../types/transportOperator'
import UiConfig, { UserConfig } from '../../../types/userui'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly item: UiConfig
  readonly transportOperators: { [key: string]: TransportOperatorUiType }
  readonly isFetching: boolean
  readonly transportOperatorErr?: Error
}

const initialState: State = {
  item: new UiConfig(),
  transportOperators: {},
  isFetching: false,
  transportOperatorErr: undefined,
}

export const getItem = (state: RootState) => state.ui.config.item

export const getTransportOperators = (state: RootState) =>
  Object.keys(state.ui.config.transportOperators).map(
    (id) => state.ui.config.transportOperators[id],
  )

export const getTransportOperatorErr = (state: RootState) => state.ui.config.transportOperatorErr

export const getIsFetching = (state: RootState) => state.ui.config.isFetching

export const getUserConfig = (state: RootState) => state.ui.config.item.getUserConfig()

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.SET_USER_CONFIG: {
      const { config } = action.payload
      const changedUserConfig = new UserConfig()
      if (config) {
        changedUserConfig.configFromJSON(config.configToJSON())
      }
      const { item } = s
      item.setUserConfig(changedUserConfig)
      return { ...s, item: item }
    }
    case actions.SET_USER_DEFAULT_ADDRESSES: {
      return { ...s }
    }
    case actions.EDIT_USER_DEFAULT_ADDRESS: {
      return { ...s }
    }
    case actions.LIST_TRANSPORT_OPERATOR_RESP: {
      const { transportOperators } = action.payload
      const newItems = transportOperators.reduce((obj, sup) => {
        return { ...obj, [sup.ID]: sup }
      }, {})
      const items = { ...s.transportOperators, ...newItems }
      return { ...s, transportOperators: items, isFetching: false }
    }
    case actions.CREATE_TRANSPORT_OPERATOR_REQ: {
      return { ...s, transportOperatorErr: undefined, isFetching: true }
    }
    case actions.CREATE_TRANSPORT_OPERATOR_RESP: {
      const { transportOperator } = action.payload
      const transportOperators = {
        ...s.transportOperators,
        [transportOperator.ID]: transportOperator,
      }
      return { ...s, transportOperators, isFetching: false }
    }
    case actions.CREATE_TRANSPORT_OPERATOR_ERR: {
      const { err } = action.payload
      return { ...s, transportOperatorErr: err, isFetching: false }
    }

    case actions.EDIT_TRANSPORT_OPERATOR_REQ: {
      return { ...s, transportOperatorErr: undefined, isFetching: true }
    }

    case actions.EDIT_TRANSPORT_OPERATOR_RESP: {
      const { transportOperator } = action.payload
      const transportOperators = {
        ...s.transportOperators,
        [transportOperator.ID]: transportOperator,
      }
      return { ...s, transportOperators, isFetching: false }
    }

    case actions.EDIT_TRANSPORT_OPERATOR_ERR: {
      const { err } = action.payload
      return { ...s, transportOperatorErr: err, isFetching: false }
    }

    case actions.DELETE_TRANSPORT_OPERATOR_REQ: {
      return { ...s, transportOperatorErr: undefined, isFetching: true }
    }

    case actions.DELETE_TRANSPORT_OPERATOR_RESP: {
      const { id } = action.payload

      const newItems = Object.keys(s.transportOperators).reduce<{
        [key: string]: TransportOperatorUiType
      }>((map, i) => {
        if (i !== id) {
          map[i] = s.transportOperators[i]
        }
        return map
      }, {})

      return { ...s, transportOperators: newItems, isFetching: false }
    }

    case actions.DELETE_TRANSPORT_OPERATOR_ERR: {
      const { err } = action.payload
      return { ...s, transportOperatorErr: err, isFetching: false }
    }

    default:
      return s
  }
}
