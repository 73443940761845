import { Address, AddressSorting } from 'proto/crm/v1/address_pb'

import { Pagination, defaultPageSize } from './query'

export type AddressPrimitiveUsage = Extract<
  Address.Usage,
  Address.Usage.SENDER | Address.Usage.RECEIVER
>

export type AddressUsageLabel =
  | 'Sender'
  | 'Receiver'
  | 'Purchase Order'
  | 'Return Order'
  | 'Organization'
  | 'Invoice'

export interface AddressFilter {
  usage?: Array<Address.Usage>
  organizationID?: Array<number>
  addressID?: Array<number>
  search?: string
}

export type AddressPage = {
  pagination: Pagination
  filter: AddressFilter
  sorting: AddressSorting
}

export const getDefaultAddressPage = (): AddressPage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new AddressSorting(),
})

// A very permissive type so we can convert some indexes to a JSON string in
// address to allow conversion to a query parameter string.
export type ParsingAddressPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  search?: string
  sorting?: string
}
