import { combineReducers } from 'redux'

import { State as ArticleTypeState, default as articleType } from './articleTypes/reducer'
import { State as CostState, default as cost } from './cost/reducer'
import { State as InvoiceState, default as invoice } from './invoice/reducer'
import { State as ProviderState, default as provider } from './provider/reducer'

export interface State {
  cost: CostState
  invoice: InvoiceState
  articleType: ArticleTypeState
  provider: ProviderState
}

export default combineReducers<State>({
  cost,
  invoice,
  articleType,
  provider,
} as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.
