import * as React from 'react'

import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  MarkerClustererProps,
  useLoadScript,
} from '@react-google-maps/api'
import { Cluster, Clusterer } from '@react-google-maps/marker-clusterer'
import { Card, Col, Row } from 'antd'
import { CardProps } from 'antd/lib/card'

import * as commonGeolocation from '../../proto/common/geolocation_pb'

import { getCircleIcon } from './icons'
import mapStyle from './mapStyle'
import './style.css'

interface MapCardProps extends CardProps {
  title?: string
}

export const MapCard: React.FC<MapCardProps> = ({ title, children, ...props }) => {
  return (
    <Card
      title={
        title ? (
          <Row>
            <Col xs={12} style={{ textAlign: 'left' }}>
              <span style={{ lineHeight: '32px' }}>{title}</span>
            </Col>
          </Row>
        ) : null
      }
      style={{ height: '100%' }}
      bodyStyle={{ padding: 0, height: '100%' }}
      {...props}
    >
      {children}
    </Card>
  )
}

interface MapWrapperProps {
  children: React.ReactNode
}

export const MapWrapper: React.FC<MapWrapperProps> = ({ children }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: window.env.GOOGLE_MAPS_API_KEY,
    /*
     * Set fixed version here to avoid keyboard navigation outline on markers
     * TODO: Look for a long-term solution, follow the related github issue:
     * https://github.com/JustFly1984/react-google-maps-api/issues/2181
     */
    /*
     * Stay with 3.47 for now as this the last one to support IE11. End of support is November 2022.
     * See:
     * https://developers.google.com/maps/documentation/javascript/releases#3.48.1
     * https://developers.google.com/maps/documentation/javascript/releases#3.47
     */
    version: '3.47',
  })

  return <>{isLoaded && children}</>
}

export const CustomMarkerClusterer: React.FC<MarkerClustererProps> = ({ children, ...props }) => {
  const clusterStyle = {
    url: getCircleIcon(),
    height: 30,
    width: 30,
    textColor: '#fff',
    textSize: 15,
    fontFamily: 'TTNorms',
  }

  const handleMouseOver = (c: Cluster) => {
    if (c.clusterIcon.div) {
      const img = c.clusterIcon.div.firstChild as HTMLImageElement
      img.src = getCircleIcon(true)
    }
  }

  const handleMouseOut = (c: Cluster) => {
    if (c.clusterIcon.div) {
      const img = c.clusterIcon.div.firstChild as HTMLImageElement
      img.src = getCircleIcon()
    }
  }

  return (
    <MarkerClusterer
      options={{
        maxZoom: 6,
      }}
      styles={[clusterStyle]}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      clusterClass={'marker-highlighted'}
      averageCenter={true}
      {...props}
    >
      {children}
    </MarkerClusterer>
  )
}

interface CustomMarkerProps {
  readonly geolocation: commonGeolocation.Geolocation.AsObject
  readonly icon: string
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  zIndex?: number
  clusterer?: Clusterer
  size?: number
}

export const CustomMarker: React.FC<CustomMarkerProps> = ({
  geolocation,
  icon,
  onClick,
  zIndex,
  clusterer,
  onMouseOver,
  onMouseOut,
  size = 36,
}) => {
  return (
    <Marker
      position={geolocation}
      options={{
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(size, size),
          anchor: new google.maps.Point(20, 20),
        },
      }}
      onClick={onClick}
      zIndex={zIndex}
      clusterer={clusterer}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      shape={{ type: 'circle', coords: [14.4, 21.6, 14.4] }}
    />
  )
}

interface MapProps {
  readonly centerGeolocation?: commonGeolocation.Geolocation.AsObject
  readonly onClick?: (geolocation: commonGeolocation.Geolocation.AsObject) => void
  readonly zoomable?: boolean
  readonly cursor?: string
  readonly zoom?: number
  readonly children?: React.ReactNode
  readonly containerStyle?: React.CSSProperties
}

const defaultContainerStyle = {
  width: '100%',
  height: '100%',
}

const defaultMapCenter = {
  lat: 50.073658,
  lng: 14.41854,
}

// Needs to be wrapped inside MapWrapper when used to ensure google script is loaded via useLoadScript
export const Map: React.FC<MapProps> = ({
  centerGeolocation = defaultMapCenter,
  onClick,
  zoomable = true,
  cursor,
  zoom = 2.8,
  children,
  containerStyle,
}) => {
  const mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: zoomable,
    styles: mapStyle,
    draggableCursor: cursor ? cursor : 'auto',
    minZoom: 1.5,
    maxZoom: 12,
    restriction: {
      latLngBounds: {
        north: 85,
        south: -85,
        west: -180,
        east: 180,
      },
      strictBounds: false,
    },
  }

  let handleClick
  if (!!onClick) {
    handleClick = (e: google.maps.MapMouseEvent) => {
      onClick({
        lat: e.latLng?.lat() ?? 0,
        lng: e.latLng?.lng() ?? 0,
      })
    }
  }

  return (
    <GoogleMap
      mapContainerStyle={{ ...defaultContainerStyle, ...containerStyle }}
      center={centerGeolocation}
      zoom={zoom}
      options={mapOptions}
      onClick={handleClick}
    >
      {children}
    </GoogleMap>
  )
}
