import * as grpcWeb from 'grpc-web'
import { call, put, takeLatest } from 'redux-saga/effects'

import * as commonQuery from '../../proto/common/query_pb'
import { OrganizationServicePromiseClient } from '../../proto/iam/v1/organization_grpc_web_pb'
import * as organizationv1 from '../../proto/iam/v1/organization_pb'

import {
  Actions,
  CREATE_REQ,
  EDIT_INSTRUCTIONS_REQ,
  EDIT_INV_REQ,
  EDIT_REQ,
  GET_REQ,
  LIST_REQ,
} from '../../store/iam/organization/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

export function* list(
  client: OrganizationServicePromiseClient,
  action: ReturnType<typeof Actions.listOrganizationsReq>,
) {
  try {
    const { skip, limit } = action.payload
    const req = new organizationv1.ListOrganizationsRequest()

    if (limit !== 0 || skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(limit)
      pagination.setSkip(skip)
      req.setPagination(pagination)
    }

    const resp: organizationv1.ListOrganizationsResponse = yield call(
      [client, client.listOrganizations],
      req,
      authMetadata(),
    )
    yield put(Actions.listOrganizationsResp(resp.getOrganizationsList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listOrganizationsErr(err))
  }
}

export function* get(
  client: OrganizationServicePromiseClient,
  action: ReturnType<typeof Actions.getOrganizationReq>,
) {
  try {
    const { id } = action.payload
    const req = new organizationv1.GetOrganizationRequest()

    if (id) {
      req.setOrganizationId(id)
    }

    const resp: organizationv1.GetOrganizationResponse = yield call(
      [client, client.getOrganization],
      req,
      authMetadata(),
    )
    yield put(Actions.getOrganizationResp(resp.getOrganization()))
  } catch (err) {
    if ((err as any).code && (err as any).code === grpcWeb.StatusCode.NOT_FOUND) {
      yield put(Actions.getOrganizationResp(undefined))
    } else {
      if (err instanceof Error) yield put(Actions.getOrganizationErr(err))
    }
  }
}

export function* create(
  client: OrganizationServicePromiseClient,
  action: ReturnType<typeof Actions.createOrganizationReq>,
) {
  try {
    const { organization } = action.payload
    const req = new organizationv1.CreateOrganizationRequest()
    req.setOrganization(organization)
    const resp: organizationv1.CreateOrganizationResponse = yield call(
      [client, client.createOrganization],
      req,
      authMetadata(),
    )
    const newOrg = resp.getOrganization()
    if (!newOrg) {
      throw new Error('missing org')
    }
    yield put(Actions.createOrganizationResp(newOrg))
    yield put(
      NotificationActions.send({
        key: `organization-${newOrg.getOrganizationId()}`,
        kind: 'success',
        message: 'Organization Created',
        description: 'Organization ' + newOrg.getDescription() + ' was created successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createOrganizationErr(err))
  }
}

export function* edit(
  client: OrganizationServicePromiseClient,
  action: ReturnType<typeof Actions.editOrganizationReq>,
) {
  try {
    const { organization } = action.payload
    const req = new organizationv1.EditOrganizationRequest()
    req.setOrganization(organization)
    const resp: organizationv1.EditOrganizationResponse = yield call(
      [client, client.editOrganization],
      req,
      authMetadata(),
    )
    const editOrg = resp.getOrganization()
    if (!editOrg) {
      throw new Error('missing org')
    }
    yield put(Actions.editOrganizationResp(editOrg))
    yield put(
      NotificationActions.send({
        key: `organization-${editOrg.getOrganizationId()}`,
        kind: 'success',
        message: 'Organization Updated',
        description: 'Organization ' + editOrg.getDescription() + ' was updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editOrganizationErr(err))
  }
}

export function* editInvoicing(
  client: OrganizationServicePromiseClient,
  action: ReturnType<typeof Actions.editInvoicingReq>,
) {
  try {
    const { id, invoiceConfig, invoiceAddress } = action.payload
    const req = new organizationv1.EditInvoicingRequest()
    req.setOrganizationId(id)
    req.setInvoiceConfig(invoiceConfig)
    req.setInvoiceAddress(invoiceAddress)
    const resp: organizationv1.EditInvoicingResponse = yield call(
      [client, client.editInvoicing],
      req,
      authMetadata(),
    )
    const respOrg = resp.getOrganization()
    if (!respOrg) {
      throw new Error('missing org')
    }
    yield put(Actions.editInvoicingResp(respOrg))
    yield put(
      NotificationActions.send({
        key: `organization-${respOrg.getOrganizationId()}`,
        kind: 'success',
        message: 'Organization Invoicing Updated',
        description: 'Organization ' + respOrg.getDescription() + ' was updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editInvoicingErr(err))
  }
}

export function* editInstructions(
  client: OrganizationServicePromiseClient,
  action: ReturnType<typeof Actions.editInstructionsReq>,
) {
  try {
    const {
      id,
      externalInstructions,
      internalOperationalInstructions,
      internalFinancialInstructions,
    } = action.payload
    const req = new organizationv1.EditInstructionsRequest()
    req.setOrganizationId(id)
    req.setExternalInstructions(externalInstructions)
    req.setInternalOperationalInstructions(internalOperationalInstructions)
    req.setInternalFinancialInstructions(internalFinancialInstructions)
    const resp: organizationv1.EditInstructionsResponse = yield call(
      [client, client.editInstructions],
      req,
      authMetadata(),
    )
    const respOrg = resp.getOrganization()
    if (!respOrg) {
      throw new Error('missing org')
    }
    yield put(Actions.editInstructionsResp(respOrg))
    yield put(
      NotificationActions.send({
        key: `organization-${respOrg.getOrganizationId()}`,
        kind: 'success',
        message: 'Organization Instructions Updated',
        description: 'Organization ' + respOrg.getDescription() + ' was updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editInstructionsErr(err))
  }
}

export default function* sagas() {
  const client = new OrganizationServicePromiseClient('')

  yield takeLatest(LIST_REQ, list, client)
  yield takeLatest(GET_REQ, get, client)
  yield takeLatest(CREATE_REQ, create, client)
  yield takeLatest(EDIT_REQ, edit, client)
  yield takeLatest(EDIT_INV_REQ, editInvoicing, client)
  yield takeLatest(EDIT_INSTRUCTIONS_REQ, editInstructions, client)
}
