import { Location, NavigateFunction, useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

const useRouter = (): [NavigateFunction, Location] => {
  const navigate = useNavigate()
  const location: Location = useLocation()
  return [navigate, location]
}

export { useRouter }
