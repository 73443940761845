import * as React from 'react'

interface Props {
  title: string
  required?: boolean
  style?: React.CSSProperties
  defaultMargin?: boolean
}

export const TableTitle: React.FC<Props> = ({
  title,
  required,
  style = {},
  defaultMargin = false,
}) => {
  return (
    <div style={style}>
      <span
        style={{
          color: '#ff4d4f',
          width: '9px',
          marginLeft: defaultMargin ? 0 : '-9px',
          opacity: required ? 1 : 0,
        }}
      >
        *{' '}
      </span>
      <span>{title}</span>
    </div>
  )
}
