import * as nominationv1 from '../../../proto/booking/v1/nomination_pb'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: Array<nominationv1.Nomination> }
  readonly err?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  items: {},
  err: undefined,
  isFetching: false,
}

export const getItems = (state: RootState, bookingID: number) =>
  state.booking.nomination.items[bookingID] || []
export const getErr = (state: RootState) => state.booking.nomination.err
export const getIsFetching = (state: RootState) => state.booking.nomination.isFetching

export const getWinnerNomination = (state: RootState, bookingID: number) => {
  return getItems(state, bookingID).find((nom) => nom.getWinner())
}

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { nominations, bookingIDs } = action.payload
      const items = s.items
      bookingIDs.forEach((id) => {
        items[id] = nominations.filter((n) => n.getBookingId() === id)
      })
      return { ...s, items, isFetching: false }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { nomination } = action.payload
      const bookingNominations = s.items[nomination.getBookingId()]
      const editedBookingNominations = bookingNominations.map((n) => {
        if (n.getNominationId() === nomination.getNominationId()) {
          return nomination
        }
        return n
      })
      const items = {
        ...s.items,
        [nomination.getBookingId()]: editedBookingNominations,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_PRICING_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_PRICING_RESP: {
      const { nomination } = action.payload
      const bookingNominations = s.items[nomination.getBookingId()]
      const editedBookingNominations = bookingNominations.map((n) => {
        if (n.getNominationId() === nomination.getNominationId()) {
          return nomination
        }
        return n
      })

      const items = {
        ...s.items,
        [nomination.getBookingId()]: editedBookingNominations,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.SET_PRICING_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.REVALIDATE_NOMINATIONS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.REVALIDATE_NOMINATIONS_RESP: {
      const { nominations } = action.payload
      if (nominations.length <= 0) {
        return { ...s, isFetching: false }
      }

      const items = {
        ...s.items,
        [nominations[0].getBookingId()]: nominations,
      }

      return { ...s, items, isFetching: false }
    }

    case actions.REVALIDATE_NOMINATIONS_ERR: {
      const { err } = action.payload
      return { ...s, err }
    }

    case actions.UPDATE_BOOKING_DATA_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_BOOKING_DATA_RESP: {
      const { nomination } = action.payload
      const items = {
        ...s.items,
        [nomination.getBookingId()]: [nomination],
      }

      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_BOOKING_DATA_ERR: {
      const { err } = action.payload
      return { ...s, err }
    }

    default:
      return s
  }
}
