import React from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import * as Sentry from '@sentry/browser'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/en_GB'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import moment from 'moment'
import 'moment/locale/en-gb'
import { getRoutes } from 'route/routes'

import { getStore } from 'store'

import { ErrorBoundaryComponent } from 'components/Error/ErrorBoundary'

import '../antd-styles.less'
import '../styles.css'

// App config, loaded by index.html as env.js form the API.
interface Env {
  AUTH0_DOMAIN: string
  AUTH0_CLIENT_ID: string
  AUTH0_AUDIENCE: string
  AUTH0_CONNECTION: string
  GOOGLE_MAPS_API_KEY: string
  LAUNCH_DARKLY_CLIENT_KEY: string
  ENVIRONMENT: string
  VERSION: string
}

declare global {
  interface Window {
    env: Env
  }
}

// eslint-disable-next-line react/function-component-definition
function App() {
  // Default env values.
  window.env = window.env || {}
  window.env.AUTH0_DOMAIN = window.env.AUTH0_DOMAIN || ''
  window.env.AUTH0_CLIENT_ID = window.env.AUTH0_CLIENT_ID || ''
  window.env.AUTH0_AUDIENCE = window.env.AUTH0_AUDIENCE || ''
  window.env.AUTH0_CONNECTION = window.env.AUTH0_CONNECTION || ''
  window.env.GOOGLE_MAPS_API_KEY = window.env.GOOGLE_MAPS_API_KEY || ''
  window.env.LAUNCH_DARKLY_CLIENT_KEY = window.env.LAUNCH_DARKLY_CLIENT_KEY || ''
  window.env.ENVIRONMENT = window.env.ENVIRONMENT || 'unknown'
  window.env.VERSION = window.env.VERSION || 'unknown'

  // Locale and i18n.
  moment.updateLocale('en-gb', {
    week: {
      dow: 1,
    },
  })

  const devEnv = process.env.NODE_ENV === 'development'

  !devEnv &&
    TagManager.initialize({
      gtmId: 'GTM-KC54GWH',
    })

  // Sentry.io error reporting.
  !devEnv &&
    Sentry.init({
      dsn: 'https://b01a42ff1ea44bd19c1fd13c8d2b1025@sentry.io/1215566',
      environment: window.env.ENVIRONMENT,
      release: window.env.VERSION,
    })

  return (
    <ConfigProvider locale={locale}>
      <ErrorBoundaryComponent>
        <Provider store={getStore()}>
          <RouterProvider router={getRoutes.routes} />
        </Provider>
      </ErrorBoundaryComponent>
    </ConfigProvider>
  )
}

// Update the export default to use your environment-specific client ID and a sample context:
export default withLDProvider({
  clientSideID: window.env.LAUNCH_DARKLY_CLIENT_KEY as string,
  context: {
    kind: 'user',
    key: 'Elise system',
  },
})(App)
