import { Rule } from 'antd/lib/form'

const runCustomvalidators = (
  customValidators: Array<() => string | undefined>,
): string | undefined => {
  const addCustomValidationMessages = customValidators
    ? customValidators?.map((validatorFunc) => validatorFunc())
    : ['']
  return [...addCustomValidationMessages]
    .filter((value) => {
      return value !== undefined
    })
    .join(' \n')
}

const getFormItemValidator = (validateFunc: () => string | undefined) => {
  return {
    validator() {
      const errMessage = validateFunc ? validateFunc() : false
      return errMessage ? Promise.reject(errMessage) : Promise.resolve()
    },
  }
}

const runRules = (rules?: Rule[]) => {
  const errMessage = rules?.filter((ev: any) => {
    return !!ev.message ? true : false
  })
  return errMessage ? Promise.reject(errMessage) : Promise.resolve()
}

export { runCustomvalidators, getFormItemValidator, runRules }
