import * as distancev1 from '../proto/distance/v1/distance_pb'

export type DistanceUnit = 'unknown' | 'm'

export const unitEnumToDistanceUnit: {
  [key in distancev1.DistanceUnit]: DistanceUnit
} = {
  [distancev1.DistanceUnit.UNKNOWN]: 'unknown',
  [distancev1.DistanceUnit.M]: 'm',
}

export const distanceString = (distance: number, distanceUnit: distancev1.DistanceUnit): string => {
  const unit = unitEnumToDistanceUnit[distanceUnit]
  switch (unit) {
    case 'm':
      return Math.round((distance / 1000) * 10) / 10 + ' km'
  }
  return distance + ' ' + String(unit)
}
