/**
 * @fileoverview gRPC-Web generated client stub for swift.filtering.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: filtering/v1/filter.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var booking_v1_booking_pb = require('../../booking/v1/booking_pb.js')
const proto = {};
proto.swift = {};
proto.swift.filtering = {};
proto.swift.filtering.v1 = require('./filter_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.filtering.v1.FilterServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.filtering.v1.FilterServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.filtering.v1.ListFiltersRequest,
 *   !proto.swift.filtering.v1.ListFiltersResponse>}
 */
const methodDescriptor_FilterService_ListFilters = new grpc.web.MethodDescriptor(
  '/swift.filtering.v1.FilterService/ListFilters',
  grpc.web.MethodType.UNARY,
  proto.swift.filtering.v1.ListFiltersRequest,
  proto.swift.filtering.v1.ListFiltersResponse,
  /**
   * @param {!proto.swift.filtering.v1.ListFiltersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.filtering.v1.ListFiltersResponse.deserializeBinary
);


/**
 * @param {!proto.swift.filtering.v1.ListFiltersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.filtering.v1.ListFiltersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.filtering.v1.ListFiltersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.filtering.v1.FilterServiceClient.prototype.listFilters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/ListFilters',
      request,
      metadata || {},
      methodDescriptor_FilterService_ListFilters,
      callback);
};


/**
 * @param {!proto.swift.filtering.v1.ListFiltersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.filtering.v1.ListFiltersResponse>}
 *     Promise that resolves to the response
 */
proto.swift.filtering.v1.FilterServicePromiseClient.prototype.listFilters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/ListFilters',
      request,
      metadata || {},
      methodDescriptor_FilterService_ListFilters);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.filtering.v1.GetFilterRequest,
 *   !proto.swift.filtering.v1.GetFilterResponse>}
 */
const methodDescriptor_FilterService_GetFilter = new grpc.web.MethodDescriptor(
  '/swift.filtering.v1.FilterService/GetFilter',
  grpc.web.MethodType.UNARY,
  proto.swift.filtering.v1.GetFilterRequest,
  proto.swift.filtering.v1.GetFilterResponse,
  /**
   * @param {!proto.swift.filtering.v1.GetFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.filtering.v1.GetFilterResponse.deserializeBinary
);


/**
 * @param {!proto.swift.filtering.v1.GetFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.filtering.v1.GetFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.filtering.v1.GetFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.filtering.v1.FilterServiceClient.prototype.getFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/GetFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_GetFilter,
      callback);
};


/**
 * @param {!proto.swift.filtering.v1.GetFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.filtering.v1.GetFilterResponse>}
 *     Promise that resolves to the response
 */
proto.swift.filtering.v1.FilterServicePromiseClient.prototype.getFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/GetFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_GetFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.filtering.v1.CreateFilterRequest,
 *   !proto.swift.filtering.v1.CreateFilterResponse>}
 */
const methodDescriptor_FilterService_CreateFilter = new grpc.web.MethodDescriptor(
  '/swift.filtering.v1.FilterService/CreateFilter',
  grpc.web.MethodType.UNARY,
  proto.swift.filtering.v1.CreateFilterRequest,
  proto.swift.filtering.v1.CreateFilterResponse,
  /**
   * @param {!proto.swift.filtering.v1.CreateFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.filtering.v1.CreateFilterResponse.deserializeBinary
);


/**
 * @param {!proto.swift.filtering.v1.CreateFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.filtering.v1.CreateFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.filtering.v1.CreateFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.filtering.v1.FilterServiceClient.prototype.createFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/CreateFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_CreateFilter,
      callback);
};


/**
 * @param {!proto.swift.filtering.v1.CreateFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.filtering.v1.CreateFilterResponse>}
 *     Promise that resolves to the response
 */
proto.swift.filtering.v1.FilterServicePromiseClient.prototype.createFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/CreateFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_CreateFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.filtering.v1.EditFilterRequest,
 *   !proto.swift.filtering.v1.EditFilterResponse>}
 */
const methodDescriptor_FilterService_EditFilter = new grpc.web.MethodDescriptor(
  '/swift.filtering.v1.FilterService/EditFilter',
  grpc.web.MethodType.UNARY,
  proto.swift.filtering.v1.EditFilterRequest,
  proto.swift.filtering.v1.EditFilterResponse,
  /**
   * @param {!proto.swift.filtering.v1.EditFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.filtering.v1.EditFilterResponse.deserializeBinary
);


/**
 * @param {!proto.swift.filtering.v1.EditFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.filtering.v1.EditFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.filtering.v1.EditFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.filtering.v1.FilterServiceClient.prototype.editFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/EditFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_EditFilter,
      callback);
};


/**
 * @param {!proto.swift.filtering.v1.EditFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.filtering.v1.EditFilterResponse>}
 *     Promise that resolves to the response
 */
proto.swift.filtering.v1.FilterServicePromiseClient.prototype.editFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/EditFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_EditFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.filtering.v1.DeleteFilterRequest,
 *   !proto.swift.filtering.v1.DeleteFilterResponse>}
 */
const methodDescriptor_FilterService_DeleteFilter = new grpc.web.MethodDescriptor(
  '/swift.filtering.v1.FilterService/DeleteFilter',
  grpc.web.MethodType.UNARY,
  proto.swift.filtering.v1.DeleteFilterRequest,
  proto.swift.filtering.v1.DeleteFilterResponse,
  /**
   * @param {!proto.swift.filtering.v1.DeleteFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.filtering.v1.DeleteFilterResponse.deserializeBinary
);


/**
 * @param {!proto.swift.filtering.v1.DeleteFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.filtering.v1.DeleteFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.filtering.v1.DeleteFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.filtering.v1.FilterServiceClient.prototype.deleteFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/DeleteFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_DeleteFilter,
      callback);
};


/**
 * @param {!proto.swift.filtering.v1.DeleteFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.filtering.v1.DeleteFilterResponse>}
 *     Promise that resolves to the response
 */
proto.swift.filtering.v1.FilterServicePromiseClient.prototype.deleteFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.filtering.v1.FilterService/DeleteFilter',
      request,
      metadata || {},
      methodDescriptor_FilterService_DeleteFilter);
};


module.exports = proto.swift.filtering.v1;

