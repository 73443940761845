import { IContract } from 'shared/interface/contract.interface'
import { INominatedContract } from 'shared/interface/nominated-contract.interface'

import { ActionsUnion, createAction } from '../helpers'

import * as contractTypes from 'types/contract'

export const LIST_CONTRACTS_REQ = 'swift2/contracts/LIST_CONTRACTS_REQ'
export const LIST_CONTRACTS_RESP = 'swift2/contracts/LIST_CONTRACTS_RESP'
export const LIST_CONTRACTS_ERR = 'swift2/contracts/LIST_CONTRACTS_ERR'

export const LIST_NOMINATED_CONTRACT_RESP = 'swift2/contracts/LIST_NOMINATED_CONTRACT_RESP'

export const GET_CONTRACT_REQ = 'swift2/contracts/GET_CONTRACT_REQ'
export const GET_CONTRACT_RESP = 'swift2/contracts/GET_CONTRACT_RESP'
export const GET_CONTRACT_ERR = 'swift2/contracts/GET_CONTRACT_ERR'

export const CREATE_CONTRACT_REQ = 'swift2/contracts/CREATE_CONTRACT_REQ'
export const CREATE_CONTRACT_RESP = 'swift2/contracts/CREATE_CONTRACT_RESP'
export const CREATE_CONTRACT_ERR = 'swift2/contracts/CREATE_CONTRACT_ERR'

export const UPDATE_CONTRACT_REQ = 'swift2/contracts/UPDATE_CONTRACT_REQ'
export const UPDATE_CONTRACT_RESP = 'swift2/contracts/UPDATE_CONTRACT_RESP'
export const UPDATE_CONTRACT_ERR = 'swift2/contracts/UPDATE_CONTRACT_ERR'

export const ContractActions = {
  listContractsReq: (page: contractTypes.ContractPage) =>
    createAction(LIST_CONTRACTS_REQ, { page }),
  listContractsResp: (count: number, contracts: IContract[], page: contractTypes.ContractPage) =>
    createAction(LIST_CONTRACTS_RESP, { count, contracts, page }),
  listContractsErr: (err: Error) => createAction(LIST_CONTRACTS_ERR, { err }),
  listNominatedContractResp: (nominatedContract: INominatedContract) =>
    createAction(LIST_NOMINATED_CONTRACT_RESP, { nominatedContract }),

  getContractReq: (contractRef: string) => createAction(GET_CONTRACT_REQ, { contractRef }),
  getContractResp: (contract: IContract) => createAction(GET_CONTRACT_RESP, { contract }),
  getContractErr: (err: Error) => createAction(GET_CONTRACT_ERR, { err }),

  createContractReq: (contract: IContract, callback: (contract: IContract) => void) =>
    createAction(CREATE_CONTRACT_REQ, { contract, callback }),
  createContractResp: (contract: IContract) => createAction(CREATE_CONTRACT_RESP, { contract }),
  createContractErr: (err: Error) => createAction(CREATE_CONTRACT_ERR, { err }),

  updateContractReq: (contract: IContract) => createAction(UPDATE_CONTRACT_REQ, { contract }),
  updateContractResp: (contract: IContract) => createAction(UPDATE_CONTRACT_RESP, { contract }),
  updateContractErr: (err: Error) => createAction(UPDATE_CONTRACT_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof ContractActions>
