import { createSelector } from 'reselect'

import { Address } from 'proto/crm/v1/address_pb'

import { RootState } from '../../../store/reducer'

import * as addressTypes from '../../../types/address'

import * as actions from './actions'

export interface State {
  readonly pages: Map<addressTypes.AddressPage, Array<number>>
  readonly items: { [key: string]: Address }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly latestCreatedAddressId: number | undefined
}

const initialState: State = {
  pages: new Map(),
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
  latestCreatedAddressId: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.crm.address.items).map((id) => state.crm.address.items[id])
}
export const getItemsById = (state: RootState) => {
  return state.crm.address.items
}
export const getItemById = (state: RootState, addressID: number) => {
  return getItems(state).find((a) => a.getAddressId() === addressID)
}
export const getCount = (state: RootState) => state.crm.address.count
export const getPageItems = (state: RootState, page: addressTypes.AddressPage) => {
  const items = state.crm.address.items
  const pageItems = state.crm.address.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<Address>)
}
export const getNameSortedPageItems = (state: RootState, page: addressTypes.AddressPage) => {
  const items = state.crm.address.items
  const pageItems = state.crm.address.pages.get(page) || []
  const pageAddresses = pageItems.map((pi) => items[pi])

  return pageAddresses.sort((a, b) => {
    return a.getName().trim().localeCompare(b.getName().trim())
  })
}
export const getSenders = (state: RootState) => {
  return getItems(state).filter((a) => a.getUsageList().indexOf(Address.Usage.SENDER) >= 0)
}
export const getFirstSender = createSelector([getSenders], (senders) => senders[0])
export const getErr = (state: RootState) => state.crm.address.err
export const getIsFetching = (state: RootState) => state.crm.address.isFetching
export const getLatestCreatedAddressId = (state: RootState) =>
  state.crm.address.latestCreatedAddressId

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, addresses, page } = action.payload
      const newItems = addresses.reduce<{ [key: string]: Address }>((map, r) => {
        map[r.getAddressId()] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      const pageItems = addresses.map((s) => s.getAddressId())
      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })
      pages.set(page, pageItems)
      return { ...s, items, count, isFetching: false, latestCreatedAddressId: undefined }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { address, page } = action.payload
      const latestCreatedAddressId = address.getAddressId()
      const items = {
        ...s.items,
        [latestCreatedAddressId]: address,
      }

      // Update page items
      if (page) {
        const pageItems = s.pages.get(page)
        if (pageItems) {
          s.pages.set(page, [latestCreatedAddressId, ...pageItems])
        }
      }
      return { ...s, items, latestCreatedAddressId, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_RESP: {
      const { address } = action.payload
      const items = {
        ...s.items,
        [address.getAddressId()]: address,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { id } = action.payload
      const newItems = Object.keys(s.items).reduce<{ [key: string]: Address }>((map, i) => {
        if (i !== String(id)) {
          map[String(i)] = s.items[String(i)]
        }
        return map
      }, {})

      const pages = s.pages
      s.pages.forEach((page, key) => {
        pages.set(
          key,
          page.filter((p) => p !== id),
        )
      })

      return { ...s, items: newItems, pages, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
