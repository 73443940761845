// source: booking/v1/deviation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
goog.exportSymbol('proto.swift.booking.v1.CreateDeviationRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateDeviationResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteDeviationRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteDeviationResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.Deviation', null, global);
goog.exportSymbol('proto.swift.booking.v1.Deviation.DeviationType', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeviationSource', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeviationSource.Source', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditDeviationRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditDeviationResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListDeviationsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListDeviationsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Deviation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.Deviation.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.Deviation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Deviation.displayName = 'proto.swift.booking.v1.Deviation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeviationSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeviationSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeviationSource.displayName = 'proto.swift.booking.v1.DeviationSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListDeviationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ListDeviationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListDeviationsRequest.displayName = 'proto.swift.booking.v1.ListDeviationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListDeviationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListDeviationsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListDeviationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListDeviationsResponse.displayName = 'proto.swift.booking.v1.ListDeviationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateDeviationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateDeviationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateDeviationRequest.displayName = 'proto.swift.booking.v1.CreateDeviationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateDeviationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateDeviationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateDeviationResponse.displayName = 'proto.swift.booking.v1.CreateDeviationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditDeviationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditDeviationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditDeviationRequest.displayName = 'proto.swift.booking.v1.EditDeviationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditDeviationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditDeviationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditDeviationResponse.displayName = 'proto.swift.booking.v1.EditDeviationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteDeviationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteDeviationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteDeviationRequest.displayName = 'proto.swift.booking.v1.DeleteDeviationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteDeviationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteDeviationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteDeviationResponse.displayName = 'proto.swift.booking.v1.DeleteDeviationResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.Deviation.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Deviation.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Deviation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Deviation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Deviation.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
    bookingId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bookingRef: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviationDate: (f = msg.getDeviationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviationTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    deviationSourceList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    remarks: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Deviation}
 */
proto.swift.booking.v1.Deviation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Deviation;
  return proto.swift.booking.v1.Deviation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Deviation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Deviation}
 */
proto.swift.booking.v1.Deviation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviationId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeviationDate(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.swift.booking.v1.Deviation.DeviationType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviationType(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<!proto.swift.booking.v1.DeviationSource.Source>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviationSource(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Deviation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Deviation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Deviation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Deviation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviationDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviationTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getDeviationSourceList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.Deviation.DeviationType = {
  DAMAGE: 0,
  DELAY: 1,
  INTERNAL: 2,
  CONTRACT: 3,
  DEAD_FREIGHT: 4,
  INCORRECT_REFERENCE: 5,
  CUSTOMS: 6,
  WAITING_TIME: 7,
  COST: 8,
  CLAIM: 9,
  GOODWILL: 10,
  FORCE_MAJURE: 11,
  OTHER: 12
};

/**
 * optional int64 deviation_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.Deviation.prototype.getDeviationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.setDeviationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.Deviation.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
*/
proto.swift.booking.v1.Deviation.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Deviation.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 booking_id = 3;
 * @return {number}
 */
proto.swift.booking.v1.Deviation.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string booking_ref = 4;
 * @return {string}
 */
proto.swift.booking.v1.Deviation.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp deviation_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Deviation.prototype.getDeviationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
*/
proto.swift.booking.v1.Deviation.prototype.setDeviationDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.clearDeviationDate = function() {
  return this.setDeviationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Deviation.prototype.hasDeviationDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated DeviationType deviation_type = 6;
 * @return {!Array<!proto.swift.booking.v1.Deviation.DeviationType>}
 */
proto.swift.booking.v1.Deviation.prototype.getDeviationTypeList = function() {
  return /** @type {!Array<!proto.swift.booking.v1.Deviation.DeviationType>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Deviation.DeviationType>} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.setDeviationTypeList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.swift.booking.v1.Deviation.DeviationType} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.addDeviationType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.clearDeviationTypeList = function() {
  return this.setDeviationTypeList([]);
};


/**
 * repeated DeviationSource.Source deviation_source = 7;
 * @return {!Array<!proto.swift.booking.v1.DeviationSource.Source>}
 */
proto.swift.booking.v1.Deviation.prototype.getDeviationSourceList = function() {
  return /** @type {!Array<!proto.swift.booking.v1.DeviationSource.Source>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.swift.booking.v1.DeviationSource.Source>} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.setDeviationSourceList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.swift.booking.v1.DeviationSource.Source} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.addDeviationSource = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.clearDeviationSourceList = function() {
  return this.setDeviationSourceList([]);
};


/**
 * optional string remarks = 8;
 * @return {string}
 */
proto.swift.booking.v1.Deviation.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Deviation.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
*/
proto.swift.booking.v1.Deviation.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Deviation.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Deviation.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Deviation} returns this
*/
proto.swift.booking.v1.Deviation.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Deviation} returns this
 */
proto.swift.booking.v1.Deviation.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Deviation.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeviationSource.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeviationSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeviationSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeviationSource.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeviationSource}
 */
proto.swift.booking.v1.DeviationSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeviationSource;
  return proto.swift.booking.v1.DeviationSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeviationSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeviationSource}
 */
proto.swift.booking.v1.DeviationSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeviationSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeviationSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeviationSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeviationSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.DeviationSource.Source = {
  INTEREAST: 0,
  SUPPLIER: 1,
  CUSTOMER: 2,
  OTHER: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListDeviationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListDeviationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListDeviationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListDeviationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bookingRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListDeviationsRequest}
 */
proto.swift.booking.v1.ListDeviationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListDeviationsRequest;
  return proto.swift.booking.v1.ListDeviationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListDeviationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListDeviationsRequest}
 */
proto.swift.booking.v1.ListDeviationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListDeviationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListDeviationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListDeviationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListDeviationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.ListDeviationsRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.ListDeviationsRequest} returns this
 */
proto.swift.booking.v1.ListDeviationsRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.ListDeviationsRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.ListDeviationsRequest} returns this
 */
proto.swift.booking.v1.ListDeviationsRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListDeviationsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListDeviationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListDeviationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListDeviationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListDeviationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviationsList: jspb.Message.toObjectList(msg.getDeviationsList(),
    proto.swift.booking.v1.Deviation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListDeviationsResponse}
 */
proto.swift.booking.v1.ListDeviationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListDeviationsResponse;
  return proto.swift.booking.v1.ListDeviationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListDeviationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListDeviationsResponse}
 */
proto.swift.booking.v1.ListDeviationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.swift.booking.v1.Deviation;
      reader.readMessage(value,proto.swift.booking.v1.Deviation.deserializeBinaryFromReader);
      msg.addDeviations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListDeviationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListDeviationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListDeviationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListDeviationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.Deviation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Deviation deviations = 2;
 * @return {!Array<!proto.swift.booking.v1.Deviation>}
 */
proto.swift.booking.v1.ListDeviationsResponse.prototype.getDeviationsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Deviation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Deviation, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Deviation>} value
 * @return {!proto.swift.booking.v1.ListDeviationsResponse} returns this
*/
proto.swift.booking.v1.ListDeviationsResponse.prototype.setDeviationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.Deviation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Deviation}
 */
proto.swift.booking.v1.ListDeviationsResponse.prototype.addDeviations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.Deviation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListDeviationsResponse} returns this
 */
proto.swift.booking.v1.ListDeviationsResponse.prototype.clearDeviationsList = function() {
  return this.setDeviationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateDeviationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateDeviationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateDeviationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateDeviationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviation: (f = msg.getDeviation()) && proto.swift.booking.v1.Deviation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateDeviationRequest}
 */
proto.swift.booking.v1.CreateDeviationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateDeviationRequest;
  return proto.swift.booking.v1.CreateDeviationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateDeviationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateDeviationRequest}
 */
proto.swift.booking.v1.CreateDeviationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Deviation;
      reader.readMessage(value,proto.swift.booking.v1.Deviation.deserializeBinaryFromReader);
      msg.setDeviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateDeviationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateDeviationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateDeviationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateDeviationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Deviation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Deviation deviation = 1;
 * @return {?proto.swift.booking.v1.Deviation}
 */
proto.swift.booking.v1.CreateDeviationRequest.prototype.getDeviation = function() {
  return /** @type{?proto.swift.booking.v1.Deviation} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Deviation, 1));
};


/**
 * @param {?proto.swift.booking.v1.Deviation|undefined} value
 * @return {!proto.swift.booking.v1.CreateDeviationRequest} returns this
*/
proto.swift.booking.v1.CreateDeviationRequest.prototype.setDeviation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateDeviationRequest} returns this
 */
proto.swift.booking.v1.CreateDeviationRequest.prototype.clearDeviation = function() {
  return this.setDeviation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateDeviationRequest.prototype.hasDeviation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateDeviationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateDeviationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateDeviationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateDeviationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviation: (f = msg.getDeviation()) && proto.swift.booking.v1.Deviation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateDeviationResponse}
 */
proto.swift.booking.v1.CreateDeviationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateDeviationResponse;
  return proto.swift.booking.v1.CreateDeviationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateDeviationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateDeviationResponse}
 */
proto.swift.booking.v1.CreateDeviationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Deviation;
      reader.readMessage(value,proto.swift.booking.v1.Deviation.deserializeBinaryFromReader);
      msg.setDeviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateDeviationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateDeviationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateDeviationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateDeviationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Deviation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Deviation deviation = 1;
 * @return {?proto.swift.booking.v1.Deviation}
 */
proto.swift.booking.v1.CreateDeviationResponse.prototype.getDeviation = function() {
  return /** @type{?proto.swift.booking.v1.Deviation} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Deviation, 1));
};


/**
 * @param {?proto.swift.booking.v1.Deviation|undefined} value
 * @return {!proto.swift.booking.v1.CreateDeviationResponse} returns this
*/
proto.swift.booking.v1.CreateDeviationResponse.prototype.setDeviation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateDeviationResponse} returns this
 */
proto.swift.booking.v1.CreateDeviationResponse.prototype.clearDeviation = function() {
  return this.setDeviation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateDeviationResponse.prototype.hasDeviation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditDeviationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditDeviationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditDeviationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditDeviationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviation: (f = msg.getDeviation()) && proto.swift.booking.v1.Deviation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditDeviationRequest}
 */
proto.swift.booking.v1.EditDeviationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditDeviationRequest;
  return proto.swift.booking.v1.EditDeviationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditDeviationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditDeviationRequest}
 */
proto.swift.booking.v1.EditDeviationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Deviation;
      reader.readMessage(value,proto.swift.booking.v1.Deviation.deserializeBinaryFromReader);
      msg.setDeviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditDeviationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditDeviationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditDeviationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditDeviationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Deviation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Deviation deviation = 1;
 * @return {?proto.swift.booking.v1.Deviation}
 */
proto.swift.booking.v1.EditDeviationRequest.prototype.getDeviation = function() {
  return /** @type{?proto.swift.booking.v1.Deviation} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Deviation, 1));
};


/**
 * @param {?proto.swift.booking.v1.Deviation|undefined} value
 * @return {!proto.swift.booking.v1.EditDeviationRequest} returns this
*/
proto.swift.booking.v1.EditDeviationRequest.prototype.setDeviation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditDeviationRequest} returns this
 */
proto.swift.booking.v1.EditDeviationRequest.prototype.clearDeviation = function() {
  return this.setDeviation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditDeviationRequest.prototype.hasDeviation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditDeviationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditDeviationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditDeviationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditDeviationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviation: (f = msg.getDeviation()) && proto.swift.booking.v1.Deviation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditDeviationResponse}
 */
proto.swift.booking.v1.EditDeviationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditDeviationResponse;
  return proto.swift.booking.v1.EditDeviationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditDeviationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditDeviationResponse}
 */
proto.swift.booking.v1.EditDeviationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Deviation;
      reader.readMessage(value,proto.swift.booking.v1.Deviation.deserializeBinaryFromReader);
      msg.setDeviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditDeviationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditDeviationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditDeviationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditDeviationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Deviation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Deviation deviation = 1;
 * @return {?proto.swift.booking.v1.Deviation}
 */
proto.swift.booking.v1.EditDeviationResponse.prototype.getDeviation = function() {
  return /** @type{?proto.swift.booking.v1.Deviation} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Deviation, 1));
};


/**
 * @param {?proto.swift.booking.v1.Deviation|undefined} value
 * @return {!proto.swift.booking.v1.EditDeviationResponse} returns this
*/
proto.swift.booking.v1.EditDeviationResponse.prototype.setDeviation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditDeviationResponse} returns this
 */
proto.swift.booking.v1.EditDeviationResponse.prototype.clearDeviation = function() {
  return this.setDeviation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditDeviationResponse.prototype.hasDeviation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteDeviationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteDeviationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteDeviationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteDeviationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviationId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteDeviationRequest}
 */
proto.swift.booking.v1.DeleteDeviationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteDeviationRequest;
  return proto.swift.booking.v1.DeleteDeviationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteDeviationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteDeviationRequest}
 */
proto.swift.booking.v1.DeleteDeviationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteDeviationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteDeviationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteDeviationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteDeviationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 deviation_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteDeviationRequest.prototype.getDeviationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteDeviationRequest} returns this
 */
proto.swift.booking.v1.DeleteDeviationRequest.prototype.setDeviationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteDeviationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteDeviationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteDeviationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteDeviationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviationId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteDeviationResponse}
 */
proto.swift.booking.v1.DeleteDeviationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteDeviationResponse;
  return proto.swift.booking.v1.DeleteDeviationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteDeviationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteDeviationResponse}
 */
proto.swift.booking.v1.DeleteDeviationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteDeviationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteDeviationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteDeviationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteDeviationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 deviation_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteDeviationResponse.prototype.getDeviationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteDeviationResponse} returns this
 */
proto.swift.booking.v1.DeleteDeviationResponse.prototype.setDeviationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.swift.booking.v1);
