interface SagasContext {
  navigate: {
    (to: string): Promise<string>
  }
}
interface CustomContext {
  context: SagasContext
}

const context: SagasContext = {
  navigate: (path: string) => {
    window.location.href = path
    return Promise.resolve('')
  },
}

const customContext: CustomContext = {
  context,
}

const ContextItems = {
  customContext: 'customContext',
} as const

type ContextItemKeys = (typeof ContextItems)[keyof typeof ContextItems]
export { ContextItems, customContext }
export type { ContextItemKeys, SagasContext, CustomContext }
