import { combineReducers } from 'redux'

import { State as ConfigState, default as config } from './config/reducer'
import { State as PageState, default as page } from './page/reducer'

export interface State {
  page: PageState
  config: ConfigState
}

export default combineReducers<State>({
  page,
  config,
} as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.
