import * as providerv1 from '../../../proto/invoicing/v1/provider_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const LIST_PROVIDERS_REQ = 'swift2/provider/LIST_PROVIDERS_REQ'
export const LIST_PROVIDERS_RESP = 'swift2/provider/LIST_PROVIDERS_RESP'
export const LIST_PROVIDERS_ERR = 'swift2/provider/LIST_PROVIDERS_ERR'
export const GET_REQ = 'swift2/provider/GET_REQ'
export const GET_RESP = 'swift2/provider/GET_RESP'
export const GET_ERR = 'swift2/provider/GET_ERR'
export const CREATE_REQ = 'swift2/provider/CREATE_REQ'
export const CREATE_RESP = 'swift2/provider/CREATE_RESP'
export const CREATE_ERR = 'swift2/provider/CREATE_ERR'
export const EDIT_REQ = 'swift2/provider/EDIT_REQ'
export const EDIT_RESP = 'swift2/provider/EDIT_RESP'
export const EDIT_ERR = 'swift2/provider/EDIT_ERR'
export const DELETE_REQ = 'swift2/provider/DELETE_REQ'
export const DELETE_RESP = 'swift2/provider/DELETE_RESP'
export const DELETE_ERR = 'swift2/provider/DELETE_ERR'

export const Actions = {
  listProvidersReq: (limit: number, skip: number) =>
    createAction(LIST_PROVIDERS_REQ, { limit, skip }),
  listProvidersResp: (count: number, providers: providerv1.Provider[]) =>
    createAction(LIST_PROVIDERS_RESP, { count, providers }),
  listProvidersErr: (err: Error) => createAction(LIST_PROVIDERS_ERR, { err }),

  getProviderReq: (id: number) => createAction(GET_REQ, { id }),
  getProviderResp: (provider?: providerv1.Provider) => createAction(GET_RESP, { provider }),
  getProviderErr: (err: Error) => createAction(GET_ERR, { err }),

  createProviderReq: (provider: providerv1.Provider) => createAction(CREATE_REQ, { provider }),
  createProviderResp: (provider: providerv1.Provider) => createAction(CREATE_RESP, { provider }),
  createProviderErr: (err: Error) => createAction(CREATE_ERR, { err }),

  editProviderReq: (provider: providerv1.Provider) => createAction(EDIT_REQ, { provider }),
  editProviderResp: (provider: providerv1.Provider) => createAction(EDIT_RESP, { provider }),
  editProviderErr: (err: Error) => createAction(EDIT_ERR, { err }),

  deleteProviderReq: (id: number) => createAction(DELETE_REQ, { id }),
  deleteProviderResp: (id: number) => createAction(DELETE_RESP, { id }),
  deleteProviderErr: (err: Error) => createAction(DELETE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
