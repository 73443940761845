import React from 'react'
import { Link } from 'react-router-dom'

import Icon, { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Empty, Spin, Typography } from 'antd'

import { ErrorAlert } from '../Alerts/Alerts'
import './style.css'

const { Text } = Typography

interface DashboardCardProps {
  readonly header?: {
    title: string
    total?: number
    link?: string
    buttonLabel?: string
    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  }
  readonly children: React.ReactNode
  readonly fetching: boolean
  readonly error?: Error
  readonly defaultErrorMessage: string
}

interface CardHeaderProps {
  readonly title: string
  readonly total?: number
  readonly createButton?: React.ReactNode
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, total, createButton }) => {
  return (
    <div className="card-heading">
      <div className="card-heading-label">
        <Text className="card-heading-label-text">{title}</Text>
        {!!total && (
          <>
            <Text className="card-heading-label-divider">|</Text>
            <Text className="card-heading-label-total" strong>
              {total}
            </Text>
          </>
        )}
      </div>

      {createButton}
    </div>
  )
}

interface NavigationButtonProps {
  readonly link: string
  readonly label: string
  readonly icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ link, label, icon }) => {
  return (
    <Link to={link}>
      <Button ghost size="large">
        {icon && <Icon component={icon} />}
        {label}
      </Button>
    </Link>
  )
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  header,
  children,
  fetching,
  error,
  defaultErrorMessage,
}) => {
  const { title = '', total, link, buttonLabel, icon } = header ?? {}
  const hasTotal = total && total > 0
  const cardHeader = header && (
    <CardHeader
      title={title}
      total={total}
      createButton={
        link && buttonLabel && <NavigationButton link={link} label={buttonLabel} icon={icon} />
      }
    />
  )

  return (
    <Card
      title={cardHeader}
      style={{ padding: 16, flex: '1 1 auto', overflow: 'auto' }}
      headStyle={{ padding: 0, borderBottom: '2px solid #00000040', minHeight: 'auto' }}
      bodyStyle={{ padding: 0 }}
      className={`dashboard-card ${!hasTotal ? 'empty-data' : ''}`}
    >
      {fetching ? (
        <div style={{ textAlign: 'center', margin: 48 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true} />} />
        </div>
      ) : error ? (
        <ErrorAlert message={defaultErrorMessage} description={error.message} />
      ) : !hasTotal ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        children
      )}
    </Card>
  )
}
