// source: invoicing/v1/provider.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var common_provider_pb = require('../../common/provider_pb.js');
goog.object.extend(proto, common_provider_pb);
goog.exportSymbol('proto.swift.invoicing.v1.CreateProviderRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateProviderResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteAccountingRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteAccountingResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteProviderRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteProviderResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.EditProviderRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.EditProviderResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.GetBranchProviderRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.GetBranchProviderResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.GetProviderRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.GetProviderResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListAccountingRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListAccountingResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListProvidersRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListProvidersResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Provider', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Provider.ProviderDataCase', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetAccountingRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetAccountingResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.VismaProviderData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.Provider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.invoicing.v1.Provider.oneofGroups_);
};
goog.inherits(proto.swift.invoicing.v1.Provider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.Provider.displayName = 'proto.swift.invoicing.v1.Provider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.VismaProviderData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.VismaProviderData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.VismaProviderData.displayName = 'proto.swift.invoicing.v1.VismaProviderData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListProvidersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.ListProvidersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListProvidersRequest.displayName = 'proto.swift.invoicing.v1.ListProvidersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListProvidersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.ListProvidersResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.ListProvidersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListProvidersResponse.displayName = 'proto.swift.invoicing.v1.ListProvidersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.GetProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.GetProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.GetProviderRequest.displayName = 'proto.swift.invoicing.v1.GetProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.GetProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.GetProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.GetProviderResponse.displayName = 'proto.swift.invoicing.v1.GetProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.GetBranchProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.GetBranchProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.GetBranchProviderRequest.displayName = 'proto.swift.invoicing.v1.GetBranchProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.GetBranchProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.GetBranchProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.GetBranchProviderResponse.displayName = 'proto.swift.invoicing.v1.GetBranchProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateProviderRequest.displayName = 'proto.swift.invoicing.v1.CreateProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateProviderResponse.displayName = 'proto.swift.invoicing.v1.CreateProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.EditProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.EditProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.EditProviderRequest.displayName = 'proto.swift.invoicing.v1.EditProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.EditProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.EditProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.EditProviderResponse.displayName = 'proto.swift.invoicing.v1.EditProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteProviderRequest.displayName = 'proto.swift.invoicing.v1.DeleteProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteProviderResponse.displayName = 'proto.swift.invoicing.v1.DeleteProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetAccountingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.SetAccountingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetAccountingRequest.displayName = 'proto.swift.invoicing.v1.SetAccountingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetAccountingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.SetAccountingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetAccountingResponse.displayName = 'proto.swift.invoicing.v1.SetAccountingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListAccountingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.ListAccountingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListAccountingRequest.displayName = 'proto.swift.invoicing.v1.ListAccountingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListAccountingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.ListAccountingResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.ListAccountingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListAccountingResponse.displayName = 'proto.swift.invoicing.v1.ListAccountingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteAccountingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteAccountingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteAccountingRequest.displayName = 'proto.swift.invoicing.v1.DeleteAccountingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteAccountingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteAccountingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteAccountingResponse.displayName = 'proto.swift.invoicing.v1.DeleteAccountingResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.invoicing.v1.Provider.oneofGroups_ = [[4]];

/**
 * @enum {number}
 */
proto.swift.invoicing.v1.Provider.ProviderDataCase = {
  PROVIDER_DATA_NOT_SET: 0,
  VISMA_DATA: 4
};

/**
 * @return {proto.swift.invoicing.v1.Provider.ProviderDataCase}
 */
proto.swift.invoicing.v1.Provider.prototype.getProviderDataCase = function() {
  return /** @type {proto.swift.invoicing.v1.Provider.ProviderDataCase} */(jspb.Message.computeOneofCase(this, proto.swift.invoicing.v1.Provider.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.Provider.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.Provider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.Provider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Provider.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    providerType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vismaData: (f = msg.getVismaData()) && proto.swift.invoicing.v1.VismaProviderData.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.Provider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.Provider;
  return proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.Provider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProviderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.common.ProviderType} */ (reader.readEnum());
      msg.setProviderType(value);
      break;
    case 4:
      var value = new proto.swift.invoicing.v1.VismaProviderData;
      reader.readMessage(value,proto.swift.invoicing.v1.VismaProviderData.deserializeBinaryFromReader);
      msg.setVismaData(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.Provider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.Provider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.Provider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Provider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProviderType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVismaData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.swift.invoicing.v1.VismaProviderData.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 provider_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.Provider.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Provider} returns this
 */
proto.swift.invoicing.v1.Provider.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.swift.invoicing.v1.Provider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Provider} returns this
 */
proto.swift.invoicing.v1.Provider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional swift.common.ProviderType provider_type = 3;
 * @return {!proto.swift.common.ProviderType}
 */
proto.swift.invoicing.v1.Provider.prototype.getProviderType = function() {
  return /** @type {!proto.swift.common.ProviderType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.common.ProviderType} value
 * @return {!proto.swift.invoicing.v1.Provider} returns this
 */
proto.swift.invoicing.v1.Provider.prototype.setProviderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional VismaProviderData visma_data = 4;
 * @return {?proto.swift.invoicing.v1.VismaProviderData}
 */
proto.swift.invoicing.v1.Provider.prototype.getVismaData = function() {
  return /** @type{?proto.swift.invoicing.v1.VismaProviderData} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.VismaProviderData, 4));
};


/**
 * @param {?proto.swift.invoicing.v1.VismaProviderData|undefined} value
 * @return {!proto.swift.invoicing.v1.Provider} returns this
*/
proto.swift.invoicing.v1.Provider.prototype.setVismaData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.swift.invoicing.v1.Provider.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Provider} returns this
 */
proto.swift.invoicing.v1.Provider.prototype.clearVismaData = function() {
  return this.setVismaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Provider.prototype.hasVismaData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Provider.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Provider} returns this
*/
proto.swift.invoicing.v1.Provider.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Provider} returns this
 */
proto.swift.invoicing.v1.Provider.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Provider.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Provider.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Provider} returns this
*/
proto.swift.invoicing.v1.Provider.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Provider} returns this
 */
proto.swift.invoicing.v1.Provider.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Provider.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.VismaProviderData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.VismaProviderData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.VismaProviderData.toObject = function(includeInstance, msg) {
  var f, obj = {
    host: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, ""),
    database: jspb.Message.getFieldWithDefault(msg, 3, ""),
    login: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.VismaProviderData}
 */
proto.swift.invoicing.v1.VismaProviderData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.VismaProviderData;
  return proto.swift.invoicing.v1.VismaProviderData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.VismaProviderData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.VismaProviderData}
 */
proto.swift.invoicing.v1.VismaProviderData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabase(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.VismaProviderData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.VismaProviderData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.VismaProviderData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatabase();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.VismaProviderData} returns this
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string port = 2;
 * @return {string}
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.VismaProviderData} returns this
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string database = 3;
 * @return {string}
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.getDatabase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.VismaProviderData} returns this
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.setDatabase = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string login = 4;
 * @return {string}
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.VismaProviderData} returns this
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.VismaProviderData} returns this
 */
proto.swift.invoicing.v1.VismaProviderData.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListProvidersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListProvidersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListProvidersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListProvidersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListProvidersRequest}
 */
proto.swift.invoicing.v1.ListProvidersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListProvidersRequest;
  return proto.swift.invoicing.v1.ListProvidersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListProvidersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListProvidersRequest}
 */
proto.swift.invoicing.v1.ListProvidersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListProvidersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListProvidersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListProvidersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListProvidersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.invoicing.v1.ListProvidersRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.invoicing.v1.ListProvidersRequest} returns this
*/
proto.swift.invoicing.v1.ListProvidersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListProvidersRequest} returns this
 */
proto.swift.invoicing.v1.ListProvidersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListProvidersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.ListProvidersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListProvidersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListProvidersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListProvidersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListProvidersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    proto.swift.invoicing.v1.Provider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListProvidersResponse}
 */
proto.swift.invoicing.v1.ListProvidersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListProvidersResponse;
  return proto.swift.invoicing.v1.ListProvidersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListProvidersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListProvidersResponse}
 */
proto.swift.invoicing.v1.ListProvidersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.invoicing.v1.Provider;
      reader.readMessage(value,proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListProvidersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListProvidersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListProvidersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListProvidersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.invoicing.v1.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.ListProvidersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.ListProvidersResponse} returns this
 */
proto.swift.invoicing.v1.ListProvidersResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Provider providers = 2;
 * @return {!Array<!proto.swift.invoicing.v1.Provider>}
 */
proto.swift.invoicing.v1.ListProvidersResponse.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Provider, 2));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Provider>} value
 * @return {!proto.swift.invoicing.v1.ListProvidersResponse} returns this
*/
proto.swift.invoicing.v1.ListProvidersResponse.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.ListProvidersResponse.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.invoicing.v1.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ListProvidersResponse} returns this
 */
proto.swift.invoicing.v1.ListProvidersResponse.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.GetProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.GetProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.GetProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.GetProviderRequest}
 */
proto.swift.invoicing.v1.GetProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.GetProviderRequest;
  return proto.swift.invoicing.v1.GetProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.GetProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.GetProviderRequest}
 */
proto.swift.invoicing.v1.GetProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.GetProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.GetProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.GetProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 provider_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.GetProviderRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.GetProviderRequest} returns this
 */
proto.swift.invoicing.v1.GetProviderRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.GetProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.GetProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.GetProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: (f = msg.getProvider()) && proto.swift.invoicing.v1.Provider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.GetProviderResponse}
 */
proto.swift.invoicing.v1.GetProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.GetProviderResponse;
  return proto.swift.invoicing.v1.GetProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.GetProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.GetProviderResponse}
 */
proto.swift.invoicing.v1.GetProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Provider;
      reader.readMessage(value,proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.GetProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.GetProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.GetProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional Provider provider = 1;
 * @return {?proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.GetProviderResponse.prototype.getProvider = function() {
  return /** @type{?proto.swift.invoicing.v1.Provider} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Provider, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Provider|undefined} value
 * @return {!proto.swift.invoicing.v1.GetProviderResponse} returns this
*/
proto.swift.invoicing.v1.GetProviderResponse.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.GetProviderResponse} returns this
 */
proto.swift.invoicing.v1.GetProviderResponse.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.GetProviderResponse.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.GetBranchProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.GetBranchProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    branchId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.GetBranchProviderRequest}
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.GetBranchProviderRequest;
  return proto.swift.invoicing.v1.GetBranchProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.GetBranchProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.GetBranchProviderRequest}
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.GetBranchProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.GetBranchProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 branch_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.GetBranchProviderRequest} returns this
 */
proto.swift.invoicing.v1.GetBranchProviderRequest.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.GetBranchProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.GetBranchProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: (f = msg.getProvider()) && proto.swift.invoicing.v1.Provider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.GetBranchProviderResponse}
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.GetBranchProviderResponse;
  return proto.swift.invoicing.v1.GetBranchProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.GetBranchProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.GetBranchProviderResponse}
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Provider;
      reader.readMessage(value,proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.GetBranchProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.GetBranchProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional Provider provider = 1;
 * @return {?proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.prototype.getProvider = function() {
  return /** @type{?proto.swift.invoicing.v1.Provider} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Provider, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Provider|undefined} value
 * @return {!proto.swift.invoicing.v1.GetBranchProviderResponse} returns this
*/
proto.swift.invoicing.v1.GetBranchProviderResponse.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.GetBranchProviderResponse} returns this
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.GetBranchProviderResponse.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: (f = msg.getProvider()) && proto.swift.invoicing.v1.Provider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateProviderRequest}
 */
proto.swift.invoicing.v1.CreateProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateProviderRequest;
  return proto.swift.invoicing.v1.CreateProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateProviderRequest}
 */
proto.swift.invoicing.v1.CreateProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Provider;
      reader.readMessage(value,proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional Provider provider = 1;
 * @return {?proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.CreateProviderRequest.prototype.getProvider = function() {
  return /** @type{?proto.swift.invoicing.v1.Provider} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Provider, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Provider|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateProviderRequest} returns this
*/
proto.swift.invoicing.v1.CreateProviderRequest.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateProviderRequest} returns this
 */
proto.swift.invoicing.v1.CreateProviderRequest.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateProviderRequest.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: (f = msg.getProvider()) && proto.swift.invoicing.v1.Provider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateProviderResponse}
 */
proto.swift.invoicing.v1.CreateProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateProviderResponse;
  return proto.swift.invoicing.v1.CreateProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateProviderResponse}
 */
proto.swift.invoicing.v1.CreateProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Provider;
      reader.readMessage(value,proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional Provider provider = 1;
 * @return {?proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.CreateProviderResponse.prototype.getProvider = function() {
  return /** @type{?proto.swift.invoicing.v1.Provider} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Provider, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Provider|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateProviderResponse} returns this
*/
proto.swift.invoicing.v1.CreateProviderResponse.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateProviderResponse} returns this
 */
proto.swift.invoicing.v1.CreateProviderResponse.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateProviderResponse.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.EditProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.EditProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.EditProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.EditProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: (f = msg.getProvider()) && proto.swift.invoicing.v1.Provider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.EditProviderRequest}
 */
proto.swift.invoicing.v1.EditProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.EditProviderRequest;
  return proto.swift.invoicing.v1.EditProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.EditProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.EditProviderRequest}
 */
proto.swift.invoicing.v1.EditProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Provider;
      reader.readMessage(value,proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.EditProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.EditProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.EditProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.EditProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional Provider provider = 1;
 * @return {?proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.EditProviderRequest.prototype.getProvider = function() {
  return /** @type{?proto.swift.invoicing.v1.Provider} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Provider, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Provider|undefined} value
 * @return {!proto.swift.invoicing.v1.EditProviderRequest} returns this
*/
proto.swift.invoicing.v1.EditProviderRequest.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.EditProviderRequest} returns this
 */
proto.swift.invoicing.v1.EditProviderRequest.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.EditProviderRequest.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.EditProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.EditProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.EditProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.EditProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    provider: (f = msg.getProvider()) && proto.swift.invoicing.v1.Provider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.EditProviderResponse}
 */
proto.swift.invoicing.v1.EditProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.EditProviderResponse;
  return proto.swift.invoicing.v1.EditProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.EditProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.EditProviderResponse}
 */
proto.swift.invoicing.v1.EditProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Provider;
      reader.readMessage(value,proto.swift.invoicing.v1.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.EditProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.EditProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.EditProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.EditProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional Provider provider = 1;
 * @return {?proto.swift.invoicing.v1.Provider}
 */
proto.swift.invoicing.v1.EditProviderResponse.prototype.getProvider = function() {
  return /** @type{?proto.swift.invoicing.v1.Provider} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Provider, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Provider|undefined} value
 * @return {!proto.swift.invoicing.v1.EditProviderResponse} returns this
*/
proto.swift.invoicing.v1.EditProviderResponse.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.EditProviderResponse} returns this
 */
proto.swift.invoicing.v1.EditProviderResponse.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.EditProviderResponse.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteProviderRequest}
 */
proto.swift.invoicing.v1.DeleteProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteProviderRequest;
  return proto.swift.invoicing.v1.DeleteProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteProviderRequest}
 */
proto.swift.invoicing.v1.DeleteProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 provider_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.DeleteProviderRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.DeleteProviderRequest} returns this
 */
proto.swift.invoicing.v1.DeleteProviderRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteProviderResponse}
 */
proto.swift.invoicing.v1.DeleteProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteProviderResponse;
  return proto.swift.invoicing.v1.DeleteProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteProviderResponse}
 */
proto.swift.invoicing.v1.DeleteProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetAccountingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetAccountingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetAccountingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetAccountingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountingNumber: (f = msg.getAccountingNumber()) && common_provider_pb.AccountingNumber.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetAccountingRequest}
 */
proto.swift.invoicing.v1.SetAccountingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetAccountingRequest;
  return proto.swift.invoicing.v1.SetAccountingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetAccountingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetAccountingRequest}
 */
proto.swift.invoicing.v1.SetAccountingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.common.ProviderType} */ (reader.readEnum());
      msg.setProviderType(value);
      break;
    case 2:
      var value = new common_provider_pb.AccountingNumber;
      reader.readMessage(value,common_provider_pb.AccountingNumber.deserializeBinaryFromReader);
      msg.setAccountingNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetAccountingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetAccountingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetAccountingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetAccountingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAccountingNumber();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_provider_pb.AccountingNumber.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.ProviderType provider_type = 1;
 * @return {!proto.swift.common.ProviderType}
 */
proto.swift.invoicing.v1.SetAccountingRequest.prototype.getProviderType = function() {
  return /** @type {!proto.swift.common.ProviderType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.common.ProviderType} value
 * @return {!proto.swift.invoicing.v1.SetAccountingRequest} returns this
 */
proto.swift.invoicing.v1.SetAccountingRequest.prototype.setProviderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional swift.common.AccountingNumber accounting_number = 2;
 * @return {?proto.swift.common.AccountingNumber}
 */
proto.swift.invoicing.v1.SetAccountingRequest.prototype.getAccountingNumber = function() {
  return /** @type{?proto.swift.common.AccountingNumber} */ (
    jspb.Message.getWrapperField(this, common_provider_pb.AccountingNumber, 2));
};


/**
 * @param {?proto.swift.common.AccountingNumber|undefined} value
 * @return {!proto.swift.invoicing.v1.SetAccountingRequest} returns this
*/
proto.swift.invoicing.v1.SetAccountingRequest.prototype.setAccountingNumber = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.SetAccountingRequest} returns this
 */
proto.swift.invoicing.v1.SetAccountingRequest.prototype.clearAccountingNumber = function() {
  return this.setAccountingNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.SetAccountingRequest.prototype.hasAccountingNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetAccountingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetAccountingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetAccountingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetAccountingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountingNumber: (f = msg.getAccountingNumber()) && common_provider_pb.AccountingNumber.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetAccountingResponse}
 */
proto.swift.invoicing.v1.SetAccountingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetAccountingResponse;
  return proto.swift.invoicing.v1.SetAccountingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetAccountingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetAccountingResponse}
 */
proto.swift.invoicing.v1.SetAccountingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_provider_pb.AccountingNumber;
      reader.readMessage(value,common_provider_pb.AccountingNumber.deserializeBinaryFromReader);
      msg.setAccountingNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetAccountingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetAccountingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetAccountingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetAccountingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountingNumber();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_provider_pb.AccountingNumber.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.AccountingNumber accounting_number = 1;
 * @return {?proto.swift.common.AccountingNumber}
 */
proto.swift.invoicing.v1.SetAccountingResponse.prototype.getAccountingNumber = function() {
  return /** @type{?proto.swift.common.AccountingNumber} */ (
    jspb.Message.getWrapperField(this, common_provider_pb.AccountingNumber, 1));
};


/**
 * @param {?proto.swift.common.AccountingNumber|undefined} value
 * @return {!proto.swift.invoicing.v1.SetAccountingResponse} returns this
*/
proto.swift.invoicing.v1.SetAccountingResponse.prototype.setAccountingNumber = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.SetAccountingResponse} returns this
 */
proto.swift.invoicing.v1.SetAccountingResponse.prototype.clearAccountingNumber = function() {
  return this.setAccountingNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.SetAccountingResponse.prototype.hasAccountingNumber = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListAccountingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListAccountingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListAccountingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListAccountingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListAccountingRequest}
 */
proto.swift.invoicing.v1.ListAccountingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListAccountingRequest;
  return proto.swift.invoicing.v1.ListAccountingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListAccountingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListAccountingRequest}
 */
proto.swift.invoicing.v1.ListAccountingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.common.ProviderType} */ (reader.readEnum());
      msg.setProviderType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListAccountingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListAccountingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListAccountingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListAccountingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional swift.common.ProviderType provider_type = 1;
 * @return {!proto.swift.common.ProviderType}
 */
proto.swift.invoicing.v1.ListAccountingRequest.prototype.getProviderType = function() {
  return /** @type {!proto.swift.common.ProviderType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.common.ProviderType} value
 * @return {!proto.swift.invoicing.v1.ListAccountingRequest} returns this
 */
proto.swift.invoicing.v1.ListAccountingRequest.prototype.setProviderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.ListAccountingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListAccountingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListAccountingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListAccountingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListAccountingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountingNumbersList: jspb.Message.toObjectList(msg.getAccountingNumbersList(),
    common_provider_pb.AccountingNumber.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListAccountingResponse}
 */
proto.swift.invoicing.v1.ListAccountingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListAccountingResponse;
  return proto.swift.invoicing.v1.ListAccountingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListAccountingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListAccountingResponse}
 */
proto.swift.invoicing.v1.ListAccountingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_provider_pb.AccountingNumber;
      reader.readMessage(value,common_provider_pb.AccountingNumber.deserializeBinaryFromReader);
      msg.addAccountingNumbers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListAccountingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListAccountingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListAccountingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListAccountingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountingNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_provider_pb.AccountingNumber.serializeBinaryToWriter
    );
  }
};


/**
 * repeated swift.common.AccountingNumber accounting_numbers = 1;
 * @return {!Array<!proto.swift.common.AccountingNumber>}
 */
proto.swift.invoicing.v1.ListAccountingResponse.prototype.getAccountingNumbersList = function() {
  return /** @type{!Array<!proto.swift.common.AccountingNumber>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_provider_pb.AccountingNumber, 1));
};


/**
 * @param {!Array<!proto.swift.common.AccountingNumber>} value
 * @return {!proto.swift.invoicing.v1.ListAccountingResponse} returns this
*/
proto.swift.invoicing.v1.ListAccountingResponse.prototype.setAccountingNumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.common.AccountingNumber=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.common.AccountingNumber}
 */
proto.swift.invoicing.v1.ListAccountingResponse.prototype.addAccountingNumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.common.AccountingNumber, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ListAccountingResponse} returns this
 */
proto.swift.invoicing.v1.ListAccountingResponse.prototype.clearAccountingNumbersList = function() {
  return this.setAccountingNumbersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteAccountingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteAccountingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    articleNo: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteAccountingRequest}
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteAccountingRequest;
  return proto.swift.invoicing.v1.DeleteAccountingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteAccountingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteAccountingRequest}
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.common.ProviderType} */ (reader.readEnum());
      msg.setProviderType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteAccountingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteAccountingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getArticleNo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional swift.common.ProviderType provider_type = 1;
 * @return {!proto.swift.common.ProviderType}
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.prototype.getProviderType = function() {
  return /** @type {!proto.swift.common.ProviderType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.common.ProviderType} value
 * @return {!proto.swift.invoicing.v1.DeleteAccountingRequest} returns this
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.prototype.setProviderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 article_no = 2;
 * @return {number}
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.prototype.getArticleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.DeleteAccountingRequest} returns this
 */
proto.swift.invoicing.v1.DeleteAccountingRequest.prototype.setArticleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteAccountingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteAccountingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    articleNo: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteAccountingResponse}
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteAccountingResponse;
  return proto.swift.invoicing.v1.DeleteAccountingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteAccountingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteAccountingResponse}
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteAccountingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteAccountingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleNo();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 article_no = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.prototype.getArticleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.DeleteAccountingResponse} returns this
 */
proto.swift.invoicing.v1.DeleteAccountingResponse.prototype.setArticleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.swift.invoicing.v1);
