import * as grpcWeb from 'grpc-web'
import { all, call, getContext, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { RouteNames } from 'route/routes-map'
import { ContextItems, CustomContext } from 'sagas/context'
import { fromNominatedContractProto } from 'shared/mappers/nominated-contract.mapper'

import { BookingServicePromiseClient } from 'proto/booking/v1/booking_grpc_web_pb'
import * as bookingv1 from 'proto/booking/v1/booking_pb'
import * as commonQuery from 'proto/common/query_pb'
import * as documentv1 from 'proto/document/v1/document_pb'
import * as invoicev1 from 'proto/invoicing/v1/invoice_pb'

import {
  ACCEPT_BOOKING_REQ,
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  EDIT_BOOKING_REQ,
  GET_EVENTS_REQ,
  GET_QUOTES_REQ,
  GET_REQ,
  GET_STATS_REQ,
  LIST_REQ,
  QUOTE_BOOKING_REQ,
  SET_ACTION_REQ,
  SET_BOOKING_PRICE_REQ,
  SET_FINANCIAL_MONTH_REQ,
  UPDATE_BOOKING_ITEMS_REQ,
  UPDATE_ESTIMATES_REQ,
  UPDATE_INTERNAL_NOTE_REQ,
  UPDATE_REQUIREMENTS_REQ,
  UPDATE_STATUS_REQ,
  UPDATE_TRANSPORT_OPERATOR_REQ,
  UPDATE_TRANSPORT_REF_REQ,
} from 'store/booking/booking/actions'
import { Actions as NominationActions } from 'store/booking/nomination/actions'
import { ContractActions } from 'store/contract/actions'
import { Actions as DocumentActions } from 'store/document/actions'
import { getItemsByFilter } from 'store/document/reducer'
import { Actions as InvoiceActions } from 'store/invoicing/invoice/actions'
import { Actions as NotificationActions } from 'store/notification/actions'
import { Notif } from 'store/notification/types'
import { Actions as OrderActions } from 'store/order/order/actions'
import { Actions as ProductActions } from 'store/order/product/actions'
import { RootState } from 'store/reducer'
import { Actions as ConfigActions } from 'store/ui/config/actions'

import { authMetadata } from 'helpers/auth'
import { filterRepeatedDocuments } from 'helpers/documents'
import { capitalizeFirstLetter } from 'helpers/strings'

export function* list(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.listBookingsReq>,
) {
  try {
    const { page, fetchInvoices, skipSubmodes } = action.payload

    const req = new bookingv1.ListBookingsRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }

    if (page.filter) {
      const filter = new bookingv1.BookingFilter()
      if (page.filter.userID && page.filter.userID.length > 0) {
        filter.setUserIdList(page.filter.userID)
      }
      if (page.filter.transportOperator && page.filter.transportOperator.length > 0) {
        filter.setTransportOperatorUserIdList(page.filter.transportOperator)
      }
      if (page.filter.organizationID && page.filter.organizationID.length > 0) {
        filter.setOrganizationIdList(page.filter.organizationID)
      }
      if (page.filter.userGroupID && page.filter.userGroupID.length > 0) {
        filter.setUserGroupIdList(page.filter.userGroupID)
      }
      if (page.filter.branchID && page.filter.branchID.length > 0) {
        filter.setBranchIdList(page.filter.branchID)
      }
      if (page.filter.transportMode && page.filter.transportMode.length > 0) {
        filter.setTransportModeList(page.filter.transportMode)
      }
      if (page.filter.status && page.filter.status.length > 0) {
        filter.setStatusList(page.filter.status)
      }
      if (page.filter.serviceSelection && page.filter.serviceSelection.length > 0) {
        filter.setServiceSelectionList(page.filter.serviceSelection)
      }
      if (page.filter.sender && page.filter.sender.length > 0) {
        filter.setSenderAddressIdList(page.filter.sender)
      }
      if (page.filter.senderCountry && page.filter.senderCountry.length > 0) {
        filter.setSenderCountryIdList(page.filter.senderCountry)
      }
      if (page.filter.receiver && page.filter.receiver.length > 0) {
        filter.setReceiverAddressIdList(page.filter.receiver)
      }
      if (page.filter.receiverCountry && page.filter.receiverCountry.length > 0) {
        filter.setReceiverCountryIdList(page.filter.receiverCountry)
      }
      if (page.filter.search && page.filter.search !== '') {
        filter.setSearch(page.filter.search)
      }
      if (page.filter.bookingRef && page.filter.bookingRef.length > 0) {
        filter.setBookingRefList(page.filter.bookingRef)
      }
      if (page.filter.shippable) {
        filter.setShippable(page.filter.shippable)
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sorting = new bookingv1.BookingSorting()
      sorting.setField(page.sorting.getField())
      sorting.setOrdering(page.sorting.getOrdering())
      req.setSorting(sorting)
    }

    if (page.filterID) {
      req.setFilterId(page.filterID)
    }

    if (skipSubmodes) {
      req.setSkipSubmodes(skipSubmodes)
    }

    const resp: bookingv1.ListBookingsResponse = yield call(
      [client, client.listBookings],
      req,
      authMetadata(),
    )

    yield put(Actions.listBookingsResp(resp.getTotalCount(), resp.getBookingsList(), page))

    if (fetchInvoices) {
      const bookingRefs = resp.getBookingsList().map((b) => b.getBookingRef())
      yield put(
        InvoiceActions.listInvoicesReq(
          {
            pagination: { skip: 0, limit: 0 },
            filter: {
              bookingRef: bookingRefs,
            },
            sorting: new invoicev1.InvoiceSorting(),
          },
          false,
          true,
        ),
      )
    }
  } catch (err: any) {
    yield put(Actions.listBookingsErr(err))
  }
}

export function* get(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.getBookingReq>,
) {
  try {
    const { ref, fetchLinkedItems, fetchLinkedEvents } = action.payload
    const req = new bookingv1.GetBookingRequest()
    req.setBookingRef(ref)
    const resp: bookingv1.GetBookingResponse = yield call(
      [client, client.getBooking],
      req,
      authMetadata(),
    )
    const booking = resp.getBooking()
    if (!booking) {
      throw new Error('missing booking')
    }

    const orderRefs = booking.getOrderRefsList()

    if (fetchLinkedItems) {
      // Fetch the linked orders
      yield all(orderRefs.map((ref) => put(OrderActions.getOrderReq(ref, false, false))))

      // List the products, which are needed for a first pass render
      yield put(ProductActions.listProductsReq({ pagination: { skip: 0, limit: 0 } }))
    }

    if (fetchLinkedEvents) {
      yield put(Actions.getEventsReq(ref))
      yield all(orderRefs.map((ref) => put(OrderActions.getOrderEventsReq(ref))))
    }

    yield put(Actions.getBookingResp(booking))
  } catch (err: any) {
    if (err.code && err.code === grpcWeb.StatusCode.NOT_FOUND) {
      yield put(Actions.getBookingResp(undefined))
    } else {
      yield put(Actions.getBookingErr(err))
    }
  }
}

export function* getEvents(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.getEventsReq>,
) {
  try {
    const { bookingRef } = action.payload
    const req = new bookingv1.GetBookingEventsRequest()
    req.setBookingRef(bookingRef)
    const resp: bookingv1.GetBookingEventsResponse = yield call(
      [client, client.getBookingEvents],
      req,
      authMetadata(),
    )
    const updatedEvents = resp.getEventsList()
    yield put(Actions.getEventsResp(updatedEvents, bookingRef))
  } catch (err: any) {
    yield put(Actions.getEventsErr(err))
  }
}

export function* getStats(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.getBookingStatsReq>,
) {
  try {
    const { query } = action.payload
    const req = new bookingv1.GetBookingStatsRequest()
    req.setQuery(query)
    const resp: bookingv1.GetBookingStatsResponse = yield call(
      [client, client.getBookingStats],
      req,
      authMetadata(),
    )
    const stats = resp.getStats()
    if (!stats) {
      throw new Error('missing stats')
    }

    yield put(Actions.getBookingStatsResp(stats))
  } catch (err: any) {
    yield put(Actions.getBookingStatsErr(err))
  }
}

export function* create(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.createBookingReq>,
) {
  try {
    const { booking, asQuote } = action.payload
    const req = new bookingv1.CreateBookingRequest()
    req.setBooking(booking)
    req.setAsQuote(asQuote)
    const resp: bookingv1.CreateBookingResponse = yield call(
      [client, client.createBooking],
      req,
      authMetadata(),
    )
    const newBooking = resp.getBooking()
    if (!newBooking) {
      throw new Error('missing booking')
    }
    yield put(Actions.createBookingResp(newBooking))
    yield put(NominationActions.listResp(resp.getNominationsList(), [newBooking.getBookingId()]))
    action.payload.navFunc(newBooking.getBookingRef())
    // Show notification and send user to the newly created booking.
    const notification: Notif = {
      key: `booking-${newBooking.getBookingRef()}`,
      kind: 'success',
      message: asQuote ? 'Quote Created' : 'Draft Created',
      description: asQuote
        ? 'Your quote has been created, a response will soon be available.'
        : 'Your draft has been created, please select a service to book a transport.',
      dismissAfter: 4500,
    }
    yield put(NotificationActions.send(notification))
  } catch (err: any) {
    yield put(Actions.createBookingErr(err))
  }
}

export function* editBooking(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.editBookingReq>,
) {
  try {
    const { booking } = action.payload
    const req = new bookingv1.EditBookingRequest()
    req.setBooking(booking)
    const resp: bookingv1.EditBookingResponse = yield call(
      [client, client.editBooking],
      req,
      authMetadata(),
    )
    const editedBooking = resp.getBooking()
    if (!editedBooking) {
      throw new Error('missing draft')
    }
    yield put(Actions.getEventsReq(booking.getBookingRef()))
    yield put(Actions.editBookingResp(editedBooking))
    const { context }: CustomContext = yield getContext(ContextItems.customContext)
    if (editedBooking.getStatus() === bookingv1.Booking.Status.DRAFT) {
      context.navigate(`/drafts/${booking.getBookingRef()}`)
      yield put(
        NotificationActions.send({
          key: `booking-${editedBooking.getBookingRef()}`,
          kind: 'success',
          message: 'Draft Saved',
          description: 'Draft was updated successfully',
          dismissAfter: 4500,
        }),
      )
    } else if (editedBooking.getStatus() === bookingv1.Booking.Status.QUOTE) {
      context.navigate(`/quotes/${booking.getBookingRef()}`)
      yield put(
        NotificationActions.send({
          key: `booking-${editedBooking.getBookingRef()}`,
          kind: 'success',
          message: 'Quote Saved',
          description: 'Quote was updated successfully',
          dismissAfter: 4500,
        }),
      )
    } else if (editedBooking.getStatus() === bookingv1.Booking.Status.BOOKED) {
      context.navigate(`/bookings/${booking.getBookingRef()}`)
      yield put(
        NotificationActions.send({
          key: `booking-${editedBooking.getBookingRef()}`,
          kind: 'success',
          message: 'Booking Saved',
          description: 'Booking was updated successfully',
          dismissAfter: 4500,
        }),
      )
    }
  } catch (err: any) {
    yield put(Actions.editBookingErr(err))
  }
}

export function* quoteBooking(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.quoteBookingReq>,
) {
  try {
    const { bookingRef, bookingID } = action.payload

    // Report a fetch of nominations, without making the saga actually fetch.
    // The nominations will be fetched from the quote booking request.
    yield put(NominationActions.listReq([bookingID], false))

    const req = new bookingv1.QuoteBookingRequest()
    req.setBookingRef(bookingRef)
    const resp: bookingv1.QuoteBookingResponse = yield call(
      [client, client.quoteBooking],
      req,
      authMetadata(),
    )
    const quotedBooking = resp.getBooking()
    if (!quotedBooking) {
      throw new Error('missing quote')
    }
    yield put(Actions.quoteBookingResp(quotedBooking))
    yield put(NominationActions.listResp(resp.getNominationsList(), [bookingID]))
  } catch (err: any) {
    yield put(Actions.quoteBookingErr(err))
  }
}
export function* getQuotes(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.getQuotesReq>,
) {
  try {
    const { bookingRef, bookingID } = action.payload

    // Report a fetch of nominations, without making the saga actually fetch.
    // The nominations will be fetched from the quote booking request.
    yield put(NominationActions.listReq([bookingID], false))

    const req = new bookingv1.GetQuotesRequest()
    req.setBookingRef(bookingRef)
    const resp: bookingv1.GetQuotesResponse = yield call(
      [client, client.getQuotes],
      req,
      authMetadata(),
    )
    const nominatedContract = resp.getNominatedContract()
    if (nominatedContract) {
      const iNominatedContract = fromNominatedContractProto(nominatedContract)
      yield put(ContractActions.listNominatedContractResp(iNominatedContract))
    }
    yield put(NominationActions.listResp(resp.getNominationsList(), [bookingID]))
    yield put(Actions.getQuotesResp())
  } catch (err: any) {
    yield put(Actions.getQuotesErr(err))
  }
}

export function* acceptBooking(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.acceptBookingReq>,
) {
  try {
    const { bookingRef, dataCase, acceptDirectSupplier, acceptManagedSupplier, acceptContract } =
      action.payload
    const req = new bookingv1.AcceptBookingRequest()
    req.setBookingRef(bookingRef)
    switch (dataCase) {
      case bookingv1.AcceptBookingRequest.DataCase.ACCEPT_DIRECT_SUPPLIER:
        if (!acceptDirectSupplier) {
          throw new Error('missing supplier direct data')
        }
        req.setAcceptDirectSupplier(acceptDirectSupplier)
        break
      case bookingv1.AcceptBookingRequest.DataCase.ACCEPT_MANAGED_SUPPLIER:
        if (!acceptManagedSupplier) {
          throw new Error('missing supplier managed data')
        }
        req.setAcceptManagedSupplier(acceptManagedSupplier)
        break
      case bookingv1.AcceptBookingRequest.DataCase.ACCEPT_CONTRACT:
        if (!acceptContract) {
          throw new Error('missing contract data')
        }
        req.setAcceptContract(acceptContract)
        break
      default:
        throw new Error('service selection not enabled')
    }
    const resp: bookingv1.AcceptBookingResponse = yield call(
      [client, client.acceptBooking],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(Actions.getEventsReq(bookingRef))
    yield put(Actions.acceptBookingResp(updatedBooking))

    yield put(
      NotificationActions.send({
        key: `booking-${updatedBooking.getBookingRef()}`,
        kind: 'success',
        message: 'Booking accepted',
        description:
          'Your booking has been accepted, you will be contacted by the transporter shortly.',
        dismissAfter: 4500,
      }),
    )
    action.payload.navFunc(`/bookings/${updatedBooking.getBookingRef()}`, updatedBooking)
  } catch (err: any) {
    yield put(Actions.acceptBookingErr(err))
  }
}

export function* updateEstimates(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.updateEstimatesReq>,
) {
  try {
    const { booking, uiConfig } = action.payload
    const req = new bookingv1.UpdateEstimatesRequest()
    req.setBookingId(booking.getBookingId())
    req.setEstimatedTimeDeparture(booking.getEstimatedTimeDeparture())
    req.setEstimatedTimeArrival(booking.getEstimatedTimeArrival())
    const resp: bookingv1.UpdateEstimatesResponse = yield call(
      [client, client.updateEstimates],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(Actions.getEventsReq(updatedBooking.getBookingRef()))
    yield put(Actions.updateEstimatesResp(updatedBooking))
    yield put(ConfigActions.setBookingUiConfig(booking, uiConfig))
  } catch (err: any) {
    yield put(Actions.updateEstimatesErr(err))
  }
}

export function* updateStatus(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.updateStatusReq>,
) {
  try {
    const { newStatus } = action.payload
    const req = new bookingv1.UpdateStatusRequest()
    req.setEventsList([newStatus])
    const resp: bookingv1.UpdateStatusResponse = yield call(
      [client, client.updateStatus],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(Actions.getEventsReq(newStatus.getBookingRef()))
    yield put(Actions.updateStatusResp(updatedBooking))
  } catch (err: any) {
    yield put(Actions.updateStatusErr(err))
  }
}

export function* updateTransportRef(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.updateTransportRefReq>,
) {
  try {
    const { bookingID, transportRef } = action.payload
    if (bookingID === 0) {
      throw new Error('missing booking credential')
    }
    if (transportRef?.trim() === '') {
      throw new Error('missing new transport reference')
    }
    const req = new bookingv1.UpdateTransportRefRequest()
    req.setBookingId(bookingID)
    req.setTransportRef(transportRef)
    const resp: bookingv1.UpdateTransportRefResponse = yield call(
      [client, client.updateTransportRef],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    updatedBooking.setTransportRef(transportRef)
    yield put(Actions.updateTransportRefResp(updatedBooking))
    yield put(
      NotificationActions.send({
        key: `booking-${updatedBooking.getBookingRef()}`,
        kind: 'success',
        message: 'Transport Reference Updated',
        description: 'The Transport reference has been updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.updateTransportRefErr(err))
  }
}

export function* updateInternalNote(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.updateInternalNoteReq>,
) {
  try {
    const { booking } = action.payload
    const req = new bookingv1.UpdateInternalNoteRequest()

    req.setBookingRef(booking.getBookingRef())
    req.setInternalNote(booking.getInternalNote())

    const resp: bookingv1.UpdateInternalNoteResponse = yield call(
      [client, client.updateInternalNote],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(Actions.updateInternalNoteResp(updatedBooking))
    yield put(
      NotificationActions.send({
        key: `booking-${updatedBooking.getBookingRef()}`,
        kind: 'success',
        message: 'Booking Note Updated',
        description: 'The booking note has been Updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.updateInternalNoteErr(err))
  }
}

export function* deleteBooking(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.deleteBookingReq>,
) {
  try {
    const { bookingID, status } = action.payload
    const req = new bookingv1.DeleteBookingRequest()
    req.setBookingId(bookingID)
    const resp: bookingv1.DeleteBookingResponse = yield call(
      [client, client.deleteBooking],
      req,
      authMetadata(),
    )
    if (!resp || !resp.getBookingId()) {
      throw new Error('missing ID')
    }

    yield put(Actions.deleteBookingResp(resp.getBookingId()))
    yield put(
      NotificationActions.send({
        key: `booking-deleted-${bookingID}`,
        kind: 'success',
        message: `Booking deleted`,
        description: `The ${capitalizeFirstLetter(
          status.toString().toLowerCase(),
        )} booking has been deleted.`,
        dismissAfter: 4500,
      }),
    )
    const { context }: CustomContext = yield getContext(ContextItems.customContext)
    if (status === bookingv1.Booking.Status.QUOTE || status === bookingv1.Booking.Status.QUOTED) {
      context.navigate(RouteNames.Quotes)
    } else if (status === bookingv1.Booking.Status.BOOKED) {
      context.navigate(RouteNames.Bookings)
    } else {
      context.navigate(RouteNames.Drafts)
    }
  } catch (err: any) {
    yield put(Actions.deleteBookingErr(err))
  }
}

export function* updateBookingItems(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.updateBookingItemsReq>,
) {
  try {
    const { booking, reason } = action.payload
    const req = new bookingv1.UpdateBookingItemsRequest()
    req.setBooking(booking)
    req.setReason(reason)
    const resp: bookingv1.UpdateBookingItemsResponse = yield call(
      [client, client.updateBookingItems],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(
      Actions.updateBookingItemsResp(
        bookingv1.Booking.deserializeBinary(updatedBooking.serializeBinary()),
      ),
    )
    yield put(
      NotificationActions.send({
        key: `booking-${updatedBooking.getBookingRef()}`,
        kind: 'success',
        message: 'Booking Collis Updated',
        description: 'The booking Colli has been updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.updateBookingItemsErr(err))
  }
}

export function* setBookingPrice(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.setBookingPriceReq>,
) {
  try {
    const { bookingID, bookingPrice, bookingPriceCurrency } = action.payload
    const req = new bookingv1.SetBookingPriceRequest()
    req.setBookingId(bookingID)
    req.setBookingPrice(bookingPrice)
    req.setBookingPriceCurrency(bookingPriceCurrency)
    const resp: bookingv1.SetBookingPriceResponse = yield call(
      [client, client.setBookingPrice],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(
      Actions.setBookingPriceResp(
        bookingv1.Booking.deserializeBinary(updatedBooking.serializeBinary()),
      ),
    )
    yield put(
      NotificationActions.send({
        key: `booking-${updatedBooking.getBookingRef()}`,
        kind: 'success',
        message: 'Booking Price Updated',
        description: 'The booking price has been updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.setBookingPriceErr(err))
  }
}

export function* updateRequirements(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.updateRequirementsReq>,
) {
  try {
    const { booking } = action.payload

    const documents: documentv1.Document[] = filterRepeatedDocuments(
      yield select((state: RootState) =>
        getItemsByFilter(state, { bookingRef: [booking.getBookingRef()] }),
      ),
      [
        documentv1.Usage.CMR,
        documentv1.Usage.TRANSPORTORDER,
        documentv1.Usage.BOOKINGCONFIRMATION,
        documentv1.Usage.SHIPMENTADVICE,
      ],
    )

    const req = new bookingv1.UpdateRequirementsRequest()
    req.setBookingId(booking.getBookingId())
    req.setRequirementsList(booking.getRequirementsList())
    req.setInvoiceRef(booking.getInvoiceRef())
    req.setLoadingRef(booking.getLoadingRef())
    req.setUnloadingRef(booking.getUnloadingRef())

    const resp: bookingv1.UpdateRequirementsResponse = yield call(
      [client, client.updateRequirements],
      req,
      authMetadata(),
    )
    const editedBooking = resp.getBooking()
    if (!editedBooking) {
      throw new Error('missing booking')
    }
    yield put(
      Actions.updateRequirementsResp(
        bookingv1.Booking.deserializeBinary(editedBooking.serializeBinary()),
      ),
    )
    yield all(
      documents.map((d) => {
        const u = d.getMetaData()?.getUsage()
        const cd = new documentv1.CustomData()
        cd.setCustom(false)
        if (u) {
          return put(DocumentActions.generateBookingDocument(booking, u, cd))
        }
      }),
    )
  } catch (err: any) {
    yield put(Actions.updateRequirementsErr(err))
  }
}

export function* updateTransportOperator(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.updateTransportOperatorReq>,
) {
  try {
    const { bookingID, owner } = action.payload

    const req = new bookingv1.UpdateTransportOperatorRequest()
    req.setBookingId(bookingID)
    req.setOwner(owner)

    const resp: bookingv1.UpdateTransportOperatorResponse = yield call(
      [client, client.updateTransportOperator],
      req,
      authMetadata(),
    )
    const editedBooking = resp.getBooking()
    if (!editedBooking) {
      throw new Error('missing booking')
    }
    yield put(
      Actions.updateRequirementsResp(
        bookingv1.Booking.deserializeBinary(editedBooking.serializeBinary()),
      ),
    )
  } catch (err: any) {
    yield put(Actions.updateTransportOperatorErr(err))
  }
}

export function* setAction(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.setActionReq>,
) {
  try {
    const { booking } = action.payload

    const req = new bookingv1.SetActionRequest()
    req.setBookingId(booking.getBookingId())
    req.setAction(booking.getAction())

    const resp: bookingv1.SetActionResponse = yield call(
      [client, client.setAction],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(
      Actions.setActionResp(bookingv1.Booking.deserializeBinary(updatedBooking.serializeBinary())),
    )
  } catch (err: any) {
    yield put(Actions.setActionErr(err))
  }
}

export function* setFinancialMonth(
  client: BookingServicePromiseClient,
  action: ReturnType<typeof Actions.setFinancialMonthReq>,
) {
  try {
    const { booking } = action.payload
    const req = new bookingv1.SetFinancialMonthRequest()
    req.setBookingId(booking.getBookingId())
    req.setBookingRef(booking.getBookingRef())
    req.setFinancialMonth(booking.getFinancialMonth())

    const resp: bookingv1.SetFinancialMonthResponse = yield call(
      [client, client.setFinancialMonth],
      req,
      authMetadata(),
    )
    const updatedBooking = resp.getBooking()
    if (!updatedBooking) {
      throw new Error('missing booking')
    }
    yield put(
      Actions.setFinancialMonthResp(
        bookingv1.Booking.deserializeBinary(updatedBooking.serializeBinary()),
      ),
    )
  } catch (err: any) {
    yield put(Actions.setFinancialMonthErr(err))
  }
}

export default function* sagas() {
  const client = new BookingServicePromiseClient('')

  yield takeLatest(LIST_REQ, list, client)
  yield takeLatest(GET_REQ, get, client)
  yield takeLatest(GET_STATS_REQ, getStats, client)

  yield takeEvery(CREATE_REQ, create, client)
  yield takeEvery(EDIT_BOOKING_REQ, editBooking, client)
  yield takeEvery(QUOTE_BOOKING_REQ, quoteBooking, client)
  yield takeEvery(GET_QUOTES_REQ, getQuotes, client)
  yield takeEvery(ACCEPT_BOOKING_REQ, acceptBooking, client)
  yield takeEvery(UPDATE_ESTIMATES_REQ, updateEstimates, client)
  yield takeEvery(UPDATE_TRANSPORT_REF_REQ, updateTransportRef, client)
  yield takeEvery(UPDATE_STATUS_REQ, updateStatus, client)
  yield takeEvery(UPDATE_INTERNAL_NOTE_REQ, updateInternalNote, client)
  yield takeEvery(DELETE_REQ, deleteBooking, client)
  yield takeEvery(GET_EVENTS_REQ, getEvents, client)
  yield takeEvery(UPDATE_BOOKING_ITEMS_REQ, updateBookingItems, client)
  yield takeEvery(UPDATE_REQUIREMENTS_REQ, updateRequirements, client)
  yield takeEvery(SET_BOOKING_PRICE_REQ, setBookingPrice, client)
  yield takeEvery(UPDATE_TRANSPORT_OPERATOR_REQ, updateTransportOperator, client)
  yield takeEvery(SET_ACTION_REQ, setAction, client)
  yield takeEvery(SET_FINANCIAL_MONTH_REQ, setFinancialMonth, client)
}
