import React from 'react'

import { Checkbox, Form } from 'antd'

interface CheckboxProps {
  checked: boolean
  label?: string
  disabled?: boolean
  onChange: (value: boolean) => void
}

/**
 * Usage: select or deselect items
 */
export const CheckboxComponent = ({ checked, label, disabled, onChange }: CheckboxProps) => {
  return (
    <Form.Item>
      <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={disabled}>
        {label}
      </Checkbox>
    </Form.Item>
  )
}
