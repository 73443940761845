import * as nominationv1 from '../../../proto/booking/v1/nomination_pb'
import * as pricingv1 from '../../../proto/booking/v1/pricing_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const LIST_REQ = 'swift2/nomination/LIST_REQ'
export const LIST_RESP = 'swift2/nomination/LIST_RESP'
export const LIST_ERR = 'swift2/nomination/LIST_ERR'

export const EDIT_REQ = 'swift2/nomination/EDIT_REQ'
export const EDIT_RESP = 'swift2/nomination/EDIT_RESP'
export const EDIT_ERR = 'swift2/nomination/EDIT_ERR'

export const SET_PRICING_REQ = 'swift2/nomination/SET_PRICING_REQ'
export const SET_PRICING_RESP = 'swift2/nomination/SET_PRICING_RESP'
export const SET_PRICING_ERR = 'swift2/nomination/SET_PRICING_ERR'

export const REVALIDATE_NOMINATIONS_REQ = 'swift2/nomination/REVALIDATE_REQ'
export const REVALIDATE_NOMINATIONS_RESP = 'swift2/nomination/REVALIDATE_RESP'
export const REVALIDATE_NOMINATIONS_ERR = 'swift2/nomination/REVALIDATE_ERR'

export const UPDATE_BOOKING_DATA_REQ = 'swift2/nomination/UPDATE_BOOKING_DATA_REQ'
export const UPDATE_BOOKING_DATA_RESP = 'swift2/nomination/UPDATE_BOOKING_DATA_RESP'
export const UPDATE_BOOKING_DATA_ERR = 'swift2/nomination/UPDATE_BOOKING_DATA_ERR'

export const Actions = {
  listReq: (bookingIDs: number[], fetch: boolean) => createAction(LIST_REQ, { bookingIDs, fetch }),
  listResp: (nominations: nominationv1.Nomination[], bookingIDs: number[]) =>
    createAction(LIST_RESP, { nominations, bookingIDs }),
  listErr: (err: Error) => createAction(LIST_ERR, { err }),
  editReq: (nomination: nominationv1.Nomination) => createAction(EDIT_REQ, { nomination }),
  editResp: (nomination: nominationv1.Nomination) => createAction(EDIT_RESP, { nomination }),
  editErr: (err: Error) => createAction(EDIT_ERR, { err }),
  setNominationPriceReq: (nominationId: number, pricingConfig: pricingv1.PricingConfig) =>
    createAction(SET_PRICING_REQ, { nominationId, pricingConfig }),
  setNominationPriceResp: (nomination: nominationv1.Nomination) =>
    createAction(SET_PRICING_RESP, { nomination }),
  setNominationPriceErr: (err: Error) => createAction(SET_PRICING_ERR, { err }),
  revalidateBookingReq: (bookingId: number) =>
    createAction(REVALIDATE_NOMINATIONS_REQ, { bookingId }),
  revalidateBookingResp: (nominations: nominationv1.Nomination[]) =>
    createAction(REVALIDATE_NOMINATIONS_RESP, {
      nominations,
    }),
  revalidateBookingErr: (err: Error) => createAction(REVALIDATE_NOMINATIONS_ERR, { err }),
  updateBookingDataReq: (nominationId: number, transportRef: string) =>
    createAction(UPDATE_BOOKING_DATA_REQ, { nominationId, transportRef }),
  updateBookingDataResp: (nomination: nominationv1.Nomination) =>
    createAction(UPDATE_BOOKING_DATA_RESP, {
      nomination,
    }),
  updateBookingDataErr: (err: Error) => createAction(UPDATE_BOOKING_DATA_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
