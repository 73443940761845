import React from 'react'

import moment from 'moment'

interface DateTimeColumnProps {
  value: string | moment.Moment
}

export const DateTimeColumn = ({ value }: DateTimeColumnProps) => {
  return <span>{moment(value).format('L LT')}</span>
}
