import * as orderv1 from '../../../proto/order/v1/order_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as orderTypes from '../../../types/order'

export const LIST_REQ = 'swift2/order/LIST_REQ'
export const LIST_RESP = 'swift2/order/LIST_RESP'
export const LIST_ERR = 'swift2/order/LIST_ERR'
export const GET_REQ = 'swift2/order/GET_REQ'
export const GET_RESP = 'swift2/order/GET_RESP'
export const GET_ERR = 'swift2/order/GET_ERR'
export const GET_STATS_REQ = 'swift2/order/GET_STATS_REQ'
export const GET_STATS_RESP = 'swift2/order/GET_STATS_RESP'
export const GET_STATS_ERR = 'swift2/order/GET_STATS_ERR'

export const CREATE_REQ = 'swift2/order/CREATE_REQ'
export const CREATE_RESP = 'swift2/order/CREATE_RESP'
export const CREATE_ERR = 'swift2/order/CREATE_ERR'
export const UPDATE_REQ = 'swift2/order/UPDATE_REQ'
export const UPDATE_RESP = 'swift2/order/UPDATE_RESP'
export const UPDATE_ERR = 'swift2/order/UPDATE_ERR'
export const GET_EVENTS_REQ = 'swift2/order/GET_EVENTS_REQ'
export const GET_EVENTS_RESP = 'swift2/order/GET_EVENTS_RESP'
export const GET_EVENTS_ERR = 'swift2/order/GET_EVENTS_ERR'
export const APPLY_ORDER_EVENT_REQ = 'swift2/order/APPLY_ORDER_EVENT_REQ'
export const APPLY_ORDER_EVENT_RESP = 'swift2/order/APPLY_ORDER_EVENT_RESP'
export const APPLY_ORDER_EVENT_ERR = 'swift2/order/APPLY_ORDER_EVENT_ERR'

export const Actions = {
  listOrdersReq: (page: orderTypes.OrderPage) => createAction(LIST_REQ, { page }),
  listOrdersResp: (count: number, orders: orderv1.Order[], page: orderTypes.OrderPage) =>
    createAction(LIST_RESP, { count, orders, page }),
  listOrdersErr: (err: Error) => createAction(LIST_ERR, { err }),
  getOrderReq: (ref: string, fetchLinkedItems: boolean, fetchEvents: boolean) =>
    createAction(GET_REQ, { ref, fetchLinkedItems, fetchEvents }),
  getOrderResp: (order?: orderv1.Order) => createAction(GET_RESP, { order }),
  getOrderErr: (err: Error) => createAction(GET_ERR, { err }),
  getOrderStatsReq: (query: orderv1.OrderStatsQuery) => createAction(GET_STATS_REQ, { query }),
  getOrderStatsResp: (stats: orderv1.OrderStats) => createAction(GET_STATS_RESP, { stats }),
  getOrderStatsErr: (err: Error) => createAction(GET_STATS_ERR, { err }),

  createOrderReq: (order: orderv1.Order) => createAction(CREATE_REQ, { order }),
  createOrderResp: (order: orderv1.Order) => createAction(CREATE_RESP, { order }),
  createOrderErr: (err: Error) => createAction(CREATE_ERR, { err }),
  updateOrderReq: (order: orderv1.Order) => createAction(UPDATE_REQ, { order }),
  updateOrderResp: (order: orderv1.Order) => createAction(UPDATE_RESP, { order }),
  updateOrderErr: (err: Error) => createAction(UPDATE_ERR, { err }),
  getOrderEventsReq: (ref: string) => createAction(GET_EVENTS_REQ, { ref }),
  getOrderEventsResp: (events: orderv1.OrderEvent[], orderRef: string) =>
    createAction(GET_EVENTS_RESP, { events, orderRef }),
  getOrderEventsErr: (err: Error) => createAction(GET_EVENTS_ERR, { err }),
  applyOrderEventReq: (event: orderv1.OrderEvent) => createAction(APPLY_ORDER_EVENT_REQ, { event }),
  applyOrderEventResp: (order: orderv1.Order) => createAction(APPLY_ORDER_EVENT_RESP, { order }),
  applyOrderEventErr: (err: Error) => createAction(APPLY_ORDER_EVENT_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
