import * as signupv1 from '../../../proto/iam/v1/signup_pb'
import { Address } from 'proto/crm/v1/address_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const VALIDATE_JOIN_CODE_INPUT = 'swift2/signup/VALIDATE_JOIN_CODE_INPUT'
export const VALIDATE_JOIN_CODE_REQ = 'swift2/signup/VALIDATE_JOIN_CODE_REQ'
export const VALIDATE_JOIN_CODE_RESP = 'swift2/signup/VALIDATE_JOIN_CODE_RESP'
export const VALIDATE_JOIN_CODE_ERR = 'swift2/signup/VALIDATE_JOIN_CODE_ERR'
export const SIGNUP_USER_REQ = 'swift2/signup/SIGNUP_USER_REQ'
export const SIGNUP_USER_RESP = 'swift2/signup/SIGNUP_USER_RESP'
export const SIGNUP_USER_ERR = 'swift2/signup/SIGNUP_USER_ERR'
export const CLEAN_SIGNUP_USER_ERR = 'swift2/signup/CLEAN_SIGNUP_USER_ERR'

export const Actions = {
  validateJoinCodeInput: (joinCode: string) => createAction(VALIDATE_JOIN_CODE_INPUT, { joinCode }),
  validateJoinCodeReq: (joinCode: string) => createAction(VALIDATE_JOIN_CODE_REQ, { joinCode }),
  validateJoinCodeResp: (validation: signupv1.ValidateJoinCodeResponse) =>
    createAction(VALIDATE_JOIN_CODE_RESP, { validation }),
  validateJoinCodeErr: (err: Error) => createAction(VALIDATE_JOIN_CODE_ERR, { err }),
  signupUser: (userID: string, email: string, joinCode: string, address?: Address) =>
    createAction(SIGNUP_USER_REQ, {
      userID,
      email,
      joinCode,
      address,
    }),
  signupUserResp: () => createAction(SIGNUP_USER_RESP),
  signupUserErr: (err: Error) => createAction(SIGNUP_USER_ERR, { err }),
  cleanSignupUserErr: () => createAction(CLEAN_SIGNUP_USER_ERR, {}),
}

export type ActionTypes = ActionsUnion<typeof Actions>
