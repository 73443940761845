import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { LDFlagSet, useFlags } from 'launchdarkly-react-client-sdk'

import { User } from '../proto/iam/v1/user_pb'
import { Organization } from 'proto/iam/v1/organization_pb'

import { IconButton } from 'components'

import logo from '../assets/images/logo-intereast-red.png'
import './Sider.css'
import {
  AddressesMenuItem,
  AdminMenuItem,
  BookingsMenuItem,
  ContractsMenuItem,
  DraftsMenuItem,
  FinanceMenuItem,
  HomeMenuItem,
  InvoicesMenuItem,
  MenuItem,
  OrdersMenuItem,
  ProductsMenuItem,
  QuotesMenuItem,
  ReportsMenuItem,
  ShipmentsMenuItem,
  SignOutMenuItem,
  keyFromPath,
} from './SiderMenuItems'

export interface Props {
  collapsed: boolean
  onLogout: () => void
  readonly roles: Array<User.Role>
  onCollapse: () => void
  organization?: Organization
}

export const Sider = ({ collapsed, onLogout, roles, onCollapse }: Props) => {
  const flags = useFlags()

  const renderIconButtonContent = (collapsed: boolean) => {
    const IconComponent = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
    const text = collapsed ? 'Expand' : 'Close'

    return (
      <>
        <IconComponent />
        <div className="sider__expand-text">{text}</div>
      </>
    )
  }

  return (
    <div className="sider">
      <Layout.Sider
        className="sider__container"
        breakpoint="sm"
        collapsible
        defaultCollapsed={true}
        collapsed={collapsed}
        trigger={null}
      >
        <div className="sider__logo">
          <Link to="/">
            <img src={logo} style={{ height: 28 }} alt="logo" />
          </Link>
        </div>
        <Menu
          triggerSubMenuAction={'hover'}
          defaultSelectedKeys={['/']}
          selectedKeys={[keyFromPath(useLocation().pathname)]}
          mode="inline"
          items={menuItems(roles, onLogout, flags)}
          className="sider__menu"
          inlineCollapsed={collapsed}
        />
      </Layout.Sider>
      <div className="sider__expand-button">
        <IconButton icon={renderIconButtonContent(collapsed)} onClick={onCollapse} size="small" />
      </div>
    </div>
  )
}

const menuItems = (
  roles: User.Role[],
  onLogout: () => void,
  featureFlags?: LDFlagSet,
): MenuItem[] => {
  const items: MenuItem[] = [
    HomeMenuItem(),
    OrdersMenuItem(roles, featureFlags),
    BookingsMenuItem(),
    DraftsMenuItem(),
    QuotesMenuItem(featureFlags),
    ContractsMenuItem(roles, featureFlags),
    ShipmentsMenuItem(roles),
    InvoicesMenuItem(roles),
    FinanceMenuItem(roles),
    ProductsMenuItem(roles, featureFlags),
    AddressesMenuItem(),
    ReportsMenuItem(roles, featureFlags),
    AdminMenuItem(roles),
    { type: 'divider' },
    SignOutMenuItem(onLogout),
  ]

  return items
}
