import * as pricingv1 from '../../../proto/booking/v1/pricing_pb'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: pricingv1.Pricing }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.booking.pricing.items).map((id) => state.booking.pricing.items[id])
}
export const getItemsById = (state: RootState, pricingId: number) => {
  return getItems(state).find((pricing) => pricing.getPricingId() === pricingId)
}

export const getErr = (state: RootState) => state.booking.pricing.err
export const getIsFetching = (state: RootState) => state.booking.pricing.isFetching

export default function reducer(p: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...p, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, pricing } = action.payload
      const newItems = pricing.reduce((items, p) => {
        return { ...items, [p.getPricingId()]: p }
      }, {})
      const items = { ...p.items, ...newItems }
      return { ...p, items, count, isFetching: false }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...p, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...p, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { pricing } = action.payload
      if (!pricing) {
        return { ...p, isFetching: false }
      }
      const items = {
        ...p.items,
        [pricing.getPricingId()]: pricing,
      }
      return { ...p, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...p, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...p, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { pricing } = action.payload
      const items = {
        ...p.items,
        [pricing.getPricingId()]: pricing,
      }
      return { ...p, items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...p, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...p, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { pricing } = action.payload
      const items = {
        ...p.items,
        [pricing.getPricingId()]: pricing,
      }
      return { ...p, items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...p, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...p, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { pricingID } = action.payload
      const items = Object.keys(p.items).reduce<{ [key: string]: pricingv1.Pricing }>(
        (items, key) => {
          if (p.items[key].getPricingId() !== pricingID) {
            items[key] = p.items[key]
          }
          return items
        },
        {},
      )
      return { ...p, items, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...p, err: err, isFetching: false }
    }

    default:
      return p
  }
}
