import * as messagev1 from '../../proto/messaging/v1/messaging_pb'

import { RootState } from '../../store/reducer'

import * as actions from './actions'

export interface State {
  readonly items: ReadonlyArray<messagev1.Message>
  readonly isFetching: boolean
  readonly error?: Error
}

const initialState: State = {
  items: [],
  isFetching: false,
}

// Uses some explicit declarations that values cannot be null to simplify logic.
// Relies on insert checks always happening via this function
function mergeItems(
  existingItems: ReadonlyArray<messagev1.Message>,
  newItems: ReadonlyArray<messagev1.Message>,
): ReadonlyArray<messagev1.Message> {
  const existing: { [key: string]: messagev1.Message } = existingItems.reduce((acc, curr) => {
    return { ...acc, [curr.getMessageId()]: curr }
  }, {})

  const received: { [key: string]: messagev1.Message } = newItems.reduce((acc, curr) => {
    return { ...acc, [curr.getMessageId()]: curr }
  }, {})
  const result: { [key: string]: messagev1.Message } = { ...existing, ...received }
  return Object.values(result)
}

export const getItems = (state: RootState) => state.message.items
export const getItemsByRefs = (
  state: RootState,
  orderRef: string,
  bookingRef: string,
  shipmentRef: string,
) =>
  state.message.items.filter((message) => {
    return (
      (message.getOrderRef() === orderRef && orderRef !== '') ||
      (message.getBookingRef() === bookingRef && bookingRef !== '') ||
      (message.getShipmentRef() === shipmentRef && shipmentRef !== '')
    )
  })
export const getIsFetching = (state: RootState) => state.message.isFetching
export const getError = (state: RootState) => state.message.error

export default function reducer(state: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQUEST:
      return { ...state, error: undefined, isFetching: true }
    case actions.LIST_ERROR:
      return { ...state, error: action.payload.error, isFetching: false }
    case actions.LIST_RESPONSE: {
      const newItems = mergeItems(state.items, action.payload.messages)
      return {
        ...state,
        error: undefined,
        isFetching: false,
        items: newItems,
      }
    }

    case actions.SEND_MESSAGE:
      return { ...state, error: undefined, isFetching: true }
    case actions.SEND_MESSAGE_ERROR:
      return { ...state, error: action.payload.error, isFetching: false }
    case actions.SEND_MESSAGE_SUCCESS: {
      const newItems = mergeItems(state.items, [action.payload.message])
      return {
        ...state,
        error: undefined,
        isFetching: false,
        items: newItems,
      }
    }

    case actions.SET_PINNED_MESSAGE_REQUEST:
      return { ...state, error: undefined, isFetching: true }
    case actions.SET_PINNED_MESSAGE_ERROR:
      return { ...state, error: action.payload.error, isFetching: false }
    case actions.SET_PINNED_MESSAGE_RESPONSE: {
      const newItems = mergeItems(state.items, [action.payload.message])
      return {
        ...state,
        error: undefined,
        isFetching: false,
        items: newItems,
      }
    }
    default:
      return state
  }
}
