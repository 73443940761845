import { combineReducers } from 'redux'

import { State as AddressState, default as address } from './address/reducer'

export interface State {
  address: AddressState
}

export default combineReducers<State>({
  address,
} as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.
