import { UserInput } from '../types/TimerpickerInput.type'

export enum TimeConvention {
  STANDARD = 'STANDARD',
}

const delimiters = [':']
const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

export const isValidTimeInputAtDigitIndex = (input: string, digitIndex: number) => {
  if (standardTimeInputLengthRule(input) === false) {
    return false
  }
  const inputValidationFunction = getStandardTimeAllowedCharsAtDigits(digitIndex)
  return inputValidationFunction ? inputValidationFunction(input).includes(input[digitIndex]) : true
}

const digitsInSpan = (start: string, end: string) => {
  return digits.slice(digits.indexOf(start), digits.indexOf(end) + 1)
}

const isDelimiter = (char: string) => {
  return delimiters.includes(char)
}

const standardTimeAllowedCharsDigit0 = (input: string): string[] => {
  return input[1] && parseInt(input[1], 10) > 3 ? digitsInSpan('0', '1') : digitsInSpan('0', '2')
}

const standardTimeAllowedCharsDigit1 = (input: string): string[] => {
  return input[0] === '2' ? digitsInSpan('0', '3') : digitsInSpan('0', '9')
}

const standardTimeAllowedCharsDigit2 = (input: string): string[] => {
  const delimiterOrEmptyIfDelimiterPresentInInput =
    isDelimiter(input[3]) || isDelimiter(input[1]) ? [] : delimiters
  return delimiterOrEmptyIfDelimiterPresentInInput
}

const standardTimeAllowedCharsDigit3 = (): string[] => {
  return digitsInSpan('0', '5')
}

const standardTimeAllowedCharsDigit4 = (): string[] => {
  return digitsInSpan('0', '9')
}

/**
 * Retrieves the rule for the given parameter. e.g in the time "12:34"
 * 1 would be 0, 2 would 1, : would be 2 etc
 * @param digit the index for a char in a time-string.
 */
const getStandardTimeAllowedCharsDigitN = (digit: number): UserInput => {
  const digitIndexUserInputValidationRecord: Record<number, UserInput> = {
    0: standardTimeAllowedCharsDigit0,
    1: standardTimeAllowedCharsDigit1,
    2: standardTimeAllowedCharsDigit2,
    3: standardTimeAllowedCharsDigit3,
    4: standardTimeAllowedCharsDigit4,
  }

  return digitIndexUserInputValidationRecord[digit]
}

/* Standard time (24h clock) digit rules */
const standardTimeInputLengthRule = (input: string) => {
  return input.length <= 5
}

const getStandardTimeAllowedCharsAtDigits = (digit: number): UserInput => {
  return getStandardTimeAllowedCharsDigitN(digit)
}
