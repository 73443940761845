/**
 * @fileoverview gRPC-Web generated client stub for swift.invoicing.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: invoicing/v1/invoice.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var crm_v1_address_pb = require('../../crm/v1/address_pb.js')
const proto = {};
proto.swift = {};
proto.swift.invoicing = {};
proto.swift.invoicing.v1 = require('./invoice_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.invoicing.v1.InvoiceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.ListInvoicesRequest,
 *   !proto.swift.invoicing.v1.ListInvoicesResponse>}
 */
const methodDescriptor_InvoiceService_ListInvoices = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/ListInvoices',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.ListInvoicesRequest,
  proto.swift.invoicing.v1.ListInvoicesResponse,
  /**
   * @param {!proto.swift.invoicing.v1.ListInvoicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.ListInvoicesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.ListInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.ListInvoicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.ListInvoicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.listInvoices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_ListInvoices,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.ListInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.ListInvoicesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.listInvoices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_ListInvoices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.CreateInvoiceRequest,
 *   !proto.swift.invoicing.v1.CreateInvoiceResponse>}
 */
const methodDescriptor_InvoiceService_CreateInvoice = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/CreateInvoice',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.CreateInvoiceRequest,
  proto.swift.invoicing.v1.CreateInvoiceResponse,
  /**
   * @param {!proto.swift.invoicing.v1.CreateInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.CreateInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.CreateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.CreateInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.CreateInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.createInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/CreateInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_CreateInvoice,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.CreateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.CreateInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.createInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/CreateInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_CreateInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.UpdateInvoiceRequest,
 *   !proto.swift.invoicing.v1.UpdateInvoiceResponse>}
 */
const methodDescriptor_InvoiceService_UpdateInvoice = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/UpdateInvoice',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.UpdateInvoiceRequest,
  proto.swift.invoicing.v1.UpdateInvoiceResponse,
  /**
   * @param {!proto.swift.invoicing.v1.UpdateInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.UpdateInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.UpdateInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.UpdateInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.updateInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateInvoice,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.UpdateInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.updateInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.IssueInvoiceRequest,
 *   !proto.swift.invoicing.v1.IssueInvoiceResponse>}
 */
const methodDescriptor_InvoiceService_IssueInvoice = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/IssueInvoice',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.IssueInvoiceRequest,
  proto.swift.invoicing.v1.IssueInvoiceResponse,
  /**
   * @param {!proto.swift.invoicing.v1.IssueInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.IssueInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.IssueInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.IssueInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.IssueInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.issueInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/IssueInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_IssueInvoice,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.IssueInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.IssueInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.issueInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/IssueInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_IssueInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest,
 *   !proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse>}
 */
const methodDescriptor_InvoiceService_IssueCollectiveInvoice = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/IssueCollectiveInvoice',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest,
  proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse,
  /**
   * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.issueCollectiveInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/IssueCollectiveInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_IssueCollectiveInvoice,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.issueCollectiveInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/IssueCollectiveInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_IssueCollectiveInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.UpdateStatusRequest,
 *   !proto.swift.invoicing.v1.UpdateStatusResponse>}
 */
const methodDescriptor_InvoiceService_UpdateStatus = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/UpdateStatus',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.UpdateStatusRequest,
  proto.swift.invoicing.v1.UpdateStatusResponse,
  /**
   * @param {!proto.swift.invoicing.v1.UpdateStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.UpdateStatusResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.UpdateStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.UpdateStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.UpdateStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.updateStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateStatus',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateStatus,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.UpdateStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.UpdateStatusResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.updateStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateStatus',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.SendRemindersRequest,
 *   !proto.swift.invoicing.v1.SendRemindersResponse>}
 */
const methodDescriptor_InvoiceService_SendReminders = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/SendReminders',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.SendRemindersRequest,
  proto.swift.invoicing.v1.SendRemindersResponse,
  /**
   * @param {!proto.swift.invoicing.v1.SendRemindersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.SendRemindersResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.SendRemindersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.SendRemindersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.SendRemindersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.sendReminders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/SendReminders',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_SendReminders,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.SendRemindersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.SendRemindersResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.sendReminders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/SendReminders',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_SendReminders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.PreviewInvoiceRequest,
 *   !proto.swift.invoicing.v1.PreviewInvoiceResponse>}
 */
const methodDescriptor_InvoiceService_PreviewInvoice = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/PreviewInvoice',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.PreviewInvoiceRequest,
  proto.swift.invoicing.v1.PreviewInvoiceResponse,
  /**
   * @param {!proto.swift.invoicing.v1.PreviewInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.PreviewInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.PreviewInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.PreviewInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.previewInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/PreviewInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_PreviewInvoice,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.PreviewInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.previewInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/PreviewInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_PreviewInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest,
 *   !proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse>}
 */
const methodDescriptor_InvoiceService_PreviewCollectiveInvoice = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/PreviewCollectiveInvoice',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest,
  proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse,
  /**
   * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.previewCollectiveInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/PreviewCollectiveInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_PreviewCollectiveInvoice,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.previewCollectiveInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/PreviewCollectiveInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_PreviewCollectiveInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.DeleteInvoiceRequest,
 *   !proto.swift.invoicing.v1.DeleteInvoiceResponse>}
 */
const methodDescriptor_InvoiceService_DeleteInvoice = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/DeleteInvoice',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.DeleteInvoiceRequest,
  proto.swift.invoicing.v1.DeleteInvoiceResponse,
  /**
   * @param {!proto.swift.invoicing.v1.DeleteInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.DeleteInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.DeleteInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.DeleteInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.deleteInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/DeleteInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_DeleteInvoice,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.DeleteInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.deleteInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/DeleteInvoice',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_DeleteInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.CreateInvoiceSeriesRequest,
 *   !proto.swift.invoicing.v1.CreateInvoiceSeriesResponse>}
 */
const methodDescriptor_InvoiceService_CreateInvoiceSeries = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/CreateInvoiceSeries',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.CreateInvoiceSeriesRequest,
  proto.swift.invoicing.v1.CreateInvoiceSeriesResponse,
  /**
   * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.CreateInvoiceSeriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.createInvoiceSeries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/CreateInvoiceSeries',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_CreateInvoiceSeries,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.createInvoiceSeries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/CreateInvoiceSeries',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_CreateInvoiceSeries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.UpdateInvoiceNoteRequest,
 *   !proto.swift.invoicing.v1.UpdateInvoiceNoteResponse>}
 */
const methodDescriptor_InvoiceService_UpdateInvoiceNote = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/UpdateInvoiceNote',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.UpdateInvoiceNoteRequest,
  proto.swift.invoicing.v1.UpdateInvoiceNoteResponse,
  /**
   * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.UpdateInvoiceNoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.updateInvoiceNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateInvoiceNote',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateInvoiceNote,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.updateInvoiceNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateInvoiceNote',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateInvoiceNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.UpdateAutoInvoicingRequest,
 *   !proto.swift.invoicing.v1.UpdateAutoInvoicingResponse>}
 */
const methodDescriptor_InvoiceService_UpdateAutoInvoicing = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.InvoiceService/UpdateAutoInvoicing',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.UpdateAutoInvoicingRequest,
  proto.swift.invoicing.v1.UpdateAutoInvoicingResponse,
  /**
   * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.UpdateAutoInvoicingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.InvoiceServiceClient.prototype.updateAutoInvoicing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateAutoInvoicing',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateAutoInvoicing,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.InvoiceServicePromiseClient.prototype.updateAutoInvoicing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.InvoiceService/UpdateAutoInvoicing',
      request,
      metadata || {},
      methodDescriptor_InvoiceService_UpdateAutoInvoicing);
};


module.exports = proto.swift.invoicing.v1;

