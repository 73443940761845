import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonQuery from 'proto/common/query_pb'
import { InvoiceServicePromiseClient } from 'proto/invoicing/v1/invoice_grpc_web_pb'
import * as invoicev1 from 'proto/invoicing/v1/invoice_pb'

import { Actions as DocumentActions } from 'store/document/actions'
import {
  Actions,
  CREATE_INVOICE_REQ,
  DELETE_INVOICE_REQ,
  ISSUE_COLLECTIVE_INVOICE_REQ,
  ISSUE_INVOICE_REQ,
  LIST_REQ,
  PREVIEW_COLLECTIVE_INVOICE_REQ,
  PREVIEW_INVOICE_REQ,
  SEND_INVOICE_REMINDERS_REQ,
  UPDATE_AUTO_INVOCING_REQ,
  UPDATE_INVOICE_NOTE_REQ,
  UPDATE_INVOICE_REQ,
} from 'store/invoicing/invoice/actions'
import { Actions as NotificationActions } from 'store/notification/actions'
import { Actions as StoryActions } from 'store/story/actions'
import { Actions as UIActions } from 'store/ui/page/actions'

import { authMetadata } from 'helpers/auth'

export function* list(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.listInvoicesReq>,
) {
  try {
    const { page, fetchInvoiceDocuments, skipArticles } = action.payload
    const req = new invoicev1.ListInvoicesRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }
    if (page.filter) {
      const filter = new invoicev1.InvoiceFilter()
      if (page.filter.bookingRef && page.filter.bookingRef.length > 0) {
        filter.setBookingRefList(page.filter.bookingRef)
      }
      if (page.filter.status && page.filter.status.length > 0) {
        filter.setStatusList(page.filter.status)
      }
      if (page.filter.organizationId && page.filter.organizationId.length > 0) {
        filter.setOrganizationIdList(page.filter.organizationId)
      }
      if (page.filter.branchId && page.filter.branchId.length > 0) {
        filter.setBranchIdList(page.filter.branchId)
      }
      if (page.filter.category && page.filter.category.length > 0) {
        filter.setCategoryList(page.filter.category)
      }
      if (page.filter.partOf) {
        filter.setPartOf(page.filter.partOf)
      }
      if (page.filter.autoInvoicing) {
        filter.setAutoInvoicing(page.filter.autoInvoicing)
      }
      if (page.filter.search && page.filter.search !== '') {
        filter.setSearch(page.filter.search)
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sort = new invoicev1.InvoiceSorting()
      sort.setField(page.sorting.getField())
      sort.setOrdering(page.sorting.getOrdering())
      req.setSorting(sort)
    }
    if (skipArticles) {
      req.setSkipArticles(skipArticles)
    }

    const resp: invoicev1.ListInvoicesResponse = yield call(
      [client, client.listInvoices],
      req,
      authMetadata(),
    )

    yield put(Actions.listInvoicesResp(resp.getTotalCount(), resp.getInvoicesList(), page))
    const activeInvoices = resp
      .getInvoicesList()
      .filter((i) => i.getStatus() !== invoicev1.Invoice.Status.DRAFT)
    const unifiedInvoiceNo = [
      ...activeInvoices.map((i) => i.getInvoiceNo()),
      ...activeInvoices.map((i) => i.getCollectiveInvoiceNo()),
    ]
    const invoiceNos = [...new Set(unifiedInvoiceNo)]
    // If no invoice_no is sent to document list it will list all documents in the DB.
    if (fetchInvoiceDocuments && invoiceNos.length > 0) {
      yield put(DocumentActions.list({ filter: { invoiceNo: invoiceNos } }))
    }
  } catch (err: any) {
    yield put(Actions.listInvoicesErr(err))
  }
}

export function* updateInvoice(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.updateInvoiceReq>,
) {
  try {
    const { invoice } = action.payload
    const req = new invoicev1.UpdateInvoiceRequest()
    req.setInvoice(invoice)

    const resp: invoicev1.UpdateInvoiceResponse = yield call(
      [client, client.updateInvoice],
      req,
      authMetadata(),
    )
    const result = resp.getInvoice()
    if (result) {
      yield put(
        NotificationActions.send({
          key: `invoice-${result.getInvoiceId()}`,
          kind: 'success',
          message: 'Invoice Saved',
          description: 'Invoice was saved successfully',
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('Response contained no invoice')
    }
    yield put(Actions.updateInvoiceResp(result))
  } catch (err: any) {
    yield put(Actions.updateInvoiceErr(err))
  }
}

export function* issueInvoice(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.issueInvoiceReq>,
) {
  try {
    const { invoice } = action.payload

    const req = new invoicev1.IssueInvoiceRequest()
    req.setInvoiceId(invoice.getInvoiceId())

    const resp: invoicev1.IssueInvoiceResponse = yield call(
      [client, client.issueInvoice],
      req,
      authMetadata(),
    )

    const issuedInvoice = resp.getInvoice()
    if (issuedInvoice) {
      yield put(
        NotificationActions.send({
          key: `invoice-${invoice.getInvoiceId()}`,
          kind: 'success',
          message: 'Invoice Issued',
          description: 'Invoice was issued successfully',
          dismissAfter: 4500,
        }),
      )
      yield put(DocumentActions.list({ filter: { bookingRef: [invoice.getBookingRef()] } }))
    } else {
      throw new Error('Response contained no invoice')
    }

    yield put(Actions.issueInvoiceResp(issuedInvoice))
    yield put(StoryActions.updateStoryInvoices(issuedInvoice))
  } catch (err: any) {
    yield put(Actions.issueInvoiceErr(err))
  }
}

export function* createInvoice(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.createInvoiceReq>,
) {
  try {
    const { invoice } = action.payload
    const req = new invoicev1.CreateInvoiceRequest()
    req.setInvoice(invoice)
    const resp: invoicev1.CreateInvoiceResponse = yield call(
      [client, client.createInvoice],
      req,
      authMetadata(),
    )
    const newInvoice = resp.getInvoice()
    if (!newInvoice) {
      throw new Error('missing invoice')
    }
    yield put(Actions.createInvoiceResp(newInvoice))
    yield put(
      NotificationActions.send({
        key: `invoice-${newInvoice.getInvoiceId()}`,
        kind: 'success',
        message: 'Invoice Created',
        description: 'Your invoice has been created.',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.createInvoiceErr(err))
  }
}

export function* issueCollectiveInvoice(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.issueCollectiveInvoiceReq>,
) {
  try {
    const { invoiceIds, invoicingEmails, page } = action.payload

    const req = new invoicev1.IssueCollectiveInvoiceRequest()
    req.setInvoiceIdsList(invoiceIds)
    req.setInvoicingEmailsList(invoicingEmails)

    const resp: invoicev1.IssueCollectiveInvoiceResponse = yield call(
      [client, client.issueCollectiveInvoice],
      req,
      authMetadata(),
    )

    const result = resp.getInvoicesList()
    if (result) {
      yield put(
        NotificationActions.send({
          key: `collective-invoice-${invoiceIds.join('-')}`,
          kind: 'success',
          message: 'Collective Invoice Issued',
          description: 'Collective Invoice was issued successfully',
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('Response contained no invoices')
    }

    yield put(Actions.issueCollectiveInvoiceResp(result))
    yield put(Actions.listInvoicesReq(page, true))
    yield put(UIActions.setInvoiceListPage(page))
  } catch (err: any) {
    yield put(Actions.issueCollectiveInvoiceErr(err))
  }
}

export function* sendInvoiceReminders(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.sendInvoiceRemindersReq>,
) {
  try {
    const { reminders } = action.payload

    const reminderObject = Object.entries(reminders).map(([key, value]) => {
      const rem = new invoicev1.Reminder()
      rem.setInvoiceNo(Number(key))
      rem.setEmailsList(value)
      return rem
    })
    const remind = new invoicev1.Remind()
    remind.setRemindersList(reminderObject)
    const req = new invoicev1.SendRemindersRequest()
    req.setRemind(remind)

    const resp: invoicev1.SendRemindersResponse = yield call(
      [client, client.sendReminders],
      req,
      authMetadata(),
    )

    const invoices = resp.getInvoicesList()

    const missingInvoicesKey = Object.keys(reminders).filter((k) =>
      invoices.every((i) => i.getInvoiceNo() != Number(k)),
    )

    if (missingInvoicesKey.length == 0) {
      yield put(
        NotificationActions.send({
          key: `invoice-reminder`,
          kind: 'success',
          message: 'Invoice Reminder Sent',
          description: 'Invoice reminders were sent successfully',
          dismissAfter: 4500,
        }),
      )
    }
    yield put(Actions.sendInvoiceRemindersResp(invoices))
    if (missingInvoicesKey.length > 0) {
      yield put(
        NotificationActions.send({
          key: `invoice-reminder`,
          kind: 'warning',
          message: 'Partial Invoice Reminder Sent',
          description: 'Only some Invoice reminders were sent successfully',
          dismissAfter: 4500,
        }),
      )
      throw new Error('Invoice with remainders were not sent: ' + missingInvoicesKey)
    }
  } catch (err: any) {
    yield put(Actions.sendInvoiceRemindersErr(err))
  }
}

export function* previewInvoice(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.previewInvoiceReq>,
) {
  try {
    const { invoice } = action.payload

    const req = new invoicev1.PreviewInvoiceRequest()
    req.setInvoiceId(invoice.getInvoiceId())

    const resp: invoicev1.PreviewInvoiceResponse = yield call(
      [client, client.previewInvoice],
      req,
      authMetadata(),
    )

    const blob = new Blob([resp.getFile()], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    window.open(url)

    yield put(Actions.previewInvoiceResp())
  } catch (err: any) {
    yield put(Actions.previewInvoiceErr(err))
  }
}

export function* previewCollectiveInvoice(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.previewCollectiveInvoiceReq>,
) {
  try {
    const { invoiceIds } = action.payload

    const req = new invoicev1.PreviewCollectiveInvoiceRequest()
    req.setInvoiceIdsList(invoiceIds)

    const resp: invoicev1.PreviewCollectiveInvoiceResponse = yield call(
      [client, client.previewCollectiveInvoice],
      req,
      authMetadata(),
    )

    const blob = new Blob([resp.getFile()], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    window.open(url)

    yield put(Actions.previewCollectiveInvoiceResp())
  } catch (err: any) {
    yield put(Actions.previewCollectiveInvoiceErr(err))
  }
}

export function* deleteInvoice(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.deleteInvoiceReq>,
) {
  try {
    const { invoiceId } = action.payload
    const req = new invoicev1.DeleteInvoiceRequest()
    req.setInvoiceId(invoiceId)
    const resp: invoicev1.DeleteInvoiceResponse = yield call(
      [client, client.deleteInvoice],
      req,
      authMetadata(),
    )
    if (!resp || !resp.getInvoiceId()) {
      throw new Error('missing ID')
    }

    yield put(Actions.deleteInvoiceResp(resp.getInvoiceId()))
    yield put(
      NotificationActions.send({
        key: `invoice-deleted-${invoiceId}`,
        kind: 'success',
        message: `Invoice deleted`,
        description: `The invoice has been deleted.`,
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.deleteInvoiceErr(err))
  }
}

export function* updateInvoiceNote(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.updateInvoiceNoteReq>,
) {
  try {
    const { invoice } = action.payload
    const req = new invoicev1.UpdateInvoiceNoteRequest()

    req.setInvoiceId(invoice.getInvoiceId())
    req.setInvoiceNote(invoice.getInvoiceNote())
    req.setCreatedBy(invoice.getInvoiceNoteCreatedBy())

    const resp: invoicev1.UpdateInvoiceNoteResponse = yield call(
      [client, client.updateInvoiceNote],
      req,
      authMetadata(),
    )
    const updatedInvoice = resp.getInvoice()
    if (!updatedInvoice) {
      throw new Error('missing invoice')
    }
    yield put(Actions.updateInvoiceNoteResp(updatedInvoice))
    yield put(
      NotificationActions.send({
        key: `invoice-${updatedInvoice.getInvoiceId()}`,
        kind: 'success',
        message: 'Invoice Note Edited',
        description: 'The invoice note has been edited successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.updateInvoiceNoteErr(err))
  }
}

export function* updateAutoInvoicing(
  client: InvoiceServicePromiseClient,
  action: ReturnType<typeof Actions.updateAutoInvoicingReq>,
) {
  try {
    const { invoiceID, toggle, pending } = action.payload
    const req = new invoicev1.UpdateAutoInvoicingRequest()

    req.setInvoiceId(invoiceID)
    req.setPending(pending)
    req.setToggle(toggle)

    const resp: invoicev1.UpdateAutoInvoicingResponse = yield call(
      [client, client.updateAutoInvoicing],
      req,
      authMetadata(),
    )
    const updatedInvoice = resp.getInvoice()
    if (!updatedInvoice) {
      throw new Error('missing invoice')
    }
    yield put(Actions.updateAutoInvoicingResp(updatedInvoice))
    yield put(
      NotificationActions.send({
        key: `invoice-${updatedInvoice.getInvoiceId()}`,
        kind: 'success',
        message: 'Auto invocing has been updated',
        description: 'The invoice has been updated successfully',
        dismissAfter: 4500,
      }),
    )
  } catch (err: any) {
    yield put(Actions.updateAutoInvoicingErr(err))
  }
}

export default function* sagas() {
  const client = new InvoiceServicePromiseClient('')

  yield takeLatest(LIST_REQ, list, client)
  yield takeEvery(UPDATE_INVOICE_REQ, updateInvoice, client)
  yield takeEvery(ISSUE_INVOICE_REQ, issueInvoice, client)
  yield takeEvery(CREATE_INVOICE_REQ, createInvoice, client)
  yield takeEvery(ISSUE_COLLECTIVE_INVOICE_REQ, issueCollectiveInvoice, client)
  yield takeEvery(SEND_INVOICE_REMINDERS_REQ, sendInvoiceReminders, client)
  yield takeEvery(PREVIEW_INVOICE_REQ, previewInvoice, client)
  yield takeEvery(PREVIEW_COLLECTIVE_INVOICE_REQ, previewCollectiveInvoice, client)
  yield takeEvery(DELETE_INVOICE_REQ, deleteInvoice, client)
  yield takeLatest(UPDATE_INVOICE_NOTE_REQ, updateInvoiceNote, client)
  yield takeLatest(UPDATE_AUTO_INVOCING_REQ, updateAutoInvoicing, client)
}
