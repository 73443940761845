import * as reselect from 'reselect'

import * as addressTypes from '../../../types/address'
import * as articleTypes from '../../../types/articleType'
import * as bookingTypes from '../../../types/booking'
import * as contractTypes from '../../../types/contract'
import * as costTypes from '../../../types/cost'
import * as currencyTypes from '../../../types/currency'
import * as invoiceTypes from '../../../types/invoice'
import * as orderTypes from '../../../types/order'
import * as productTypes from '../../../types/product'
import * as shipmentTypes from '../../../types/shipment'
import * as templateTypes from '../../../types/templates'
import * as usersTypes from '../../../types/user'

import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly orders: OrdersUiState
  readonly home: HomeUiState
  readonly bookings: BookingUiState
  readonly shipments: ShipmentUiState
  readonly products: ProductUiState
  readonly address: AddressUiState
  readonly invoices: InvoiceUiState
  readonly articleTypes: ArticleTypeUiState
  readonly costs: CostUiState
  readonly templates: TemplateUiState
  readonly rates: RatesUiState
  readonly contracts: ContractUiState
  readonly users: UsersUiState
}

interface OrdersUiState {
  readonly currentPage: orderTypes.OrderPage
}

interface HomeUiState {
  readonly currentOrderPage: orderTypes.OrderPage
  readonly currentBookingPage: bookingTypes.BookingPage
}

interface BookingUiState {
  readonly currentPage: bookingTypes.BookingPage
}

interface ShipmentUiState {
  readonly currentPage: shipmentTypes.ShipmentPage
}

interface ProductUiState {
  readonly currentPage: productTypes.ProductPage
}

interface AddressUiState {
  readonly currentPage: addressTypes.AddressPage
}

interface InvoiceUiState {
  readonly currentPage: invoiceTypes.InvoicePage
}

interface ArticleTypeUiState {
  readonly currentPage: articleTypes.ArticleTypePage
}

interface CostUiState {
  readonly currentPage: costTypes.CostPage
}

interface TemplateUiState {
  readonly currentPage: templateTypes.TemplatePage
}

interface RatesUiState {
  readonly currentPage: currencyTypes.CurrencyPage
}

interface ContractUiState {
  readonly currentPage: contractTypes.ContractPage
}

interface UsersUiState {
  readonly currentPage: usersTypes.UserPage
}
const defaultOrdersState: OrdersUiState = {
  currentPage: orderTypes.getDefaultOrderPage(),
}

const defaultHomeState: HomeUiState = {
  currentOrderPage: orderTypes.getDefaultOrderPage(),
  currentBookingPage: bookingTypes.getDefaultBookingPage(),
}

const defaultBookingState: BookingUiState = {
  currentPage: bookingTypes.getDefaultBookingPage(),
}

const defaultShipmentState: ShipmentUiState = {
  currentPage: shipmentTypes.getDefaultShipmentPage(),
}

const defaultProductState: ProductUiState = {
  currentPage: productTypes.getDefaultProductPage(),
}

const defaultAddressState: AddressUiState = {
  currentPage: addressTypes.getDefaultAddressPage(),
}

const defaultInvoiceState: InvoiceUiState = {
  currentPage: invoiceTypes.getDefaultInvoicePage(),
}

const defaultArticleType: ArticleTypeUiState = {
  currentPage: articleTypes.getDefaultArticleTypePage(),
}

const defaultCostState: CostUiState = {
  currentPage: costTypes.getDefaultCostPage(),
}

const defaultTemplateState: TemplateUiState = {
  currentPage: templateTypes.getDefaultTemplatePage(),
}

const defaultRatesState: RatesUiState = {
  currentPage: currencyTypes.getDefaultCurrencyPage(),
}

const defaultContractState: ContractUiState = {
  currentPage: contractTypes.getDefaultContractPage(),
}

const defaultUsersState: UsersUiState = {
  currentPage: usersTypes.getDefaultUserPage(),
}

const initialState: State = {
  orders: defaultOrdersState,
  home: defaultHomeState,
  bookings: defaultBookingState,
  shipments: defaultShipmentState,
  products: defaultProductState,
  address: defaultAddressState,
  invoices: defaultInvoiceState,
  articleTypes: defaultArticleType,
  costs: defaultCostState,
  templates: defaultTemplateState,
  rates: defaultRatesState,
  contracts: defaultContractState,
  users: defaultUsersState,
}

const getOrderListPage = (state: RootState) => state.ui.page.orders.currentPage
export const GetOrderListPage = reselect.createSelector(
  getOrderListPage,
  (currentPage) => currentPage,
)

const getBookingListPage = (state: RootState) => state.ui.page.bookings.currentPage
export const GetBookingListPage = reselect.createSelector(
  getBookingListPage,
  (currentPage) => currentPage,
)

const getHomeOrderPage = (state: RootState) => state.ui.page.home.currentOrderPage
export const GetHomeOrderPage = reselect.createSelector(
  getHomeOrderPage,
  (currentOrderPage) => currentOrderPage,
)

const getHomeBookingPage = (state: RootState) => state.ui.page.home.currentBookingPage
export const GetHomeBookingPage = reselect.createSelector(
  getHomeBookingPage,
  (currentBookingPage) => currentBookingPage,
)

const getShipmentListPage = (state: RootState) => state.ui.page.shipments.currentPage
export const GetShipmentListPage = reselect.createSelector(
  getShipmentListPage,
  (currentPage) => currentPage,
)

const getProductListPage = (state: RootState) => state.ui.page.products.currentPage
export const GetProductListPage = reselect.createSelector(
  getProductListPage,
  (currentPage) => currentPage,
)

const getAddressListPage = (state: RootState) => state.ui.page.address.currentPage
export const GetAddressListPage = reselect.createSelector(
  getAddressListPage,
  (currentPage) => currentPage,
)

const getInvoiceListPage = (state: RootState) => state.ui.page.invoices.currentPage
export const GetInvoiceListPage = reselect.createSelector(
  getInvoiceListPage,
  (currentPage) => currentPage,
)

const getArticleTypeListPage = (state: RootState) => state.ui.page.articleTypes.currentPage
export const GetArticleTypeListPage = reselect.createSelector(
  getArticleTypeListPage,
  (currentPage) => currentPage,
)

const getCostListPage = (state: RootState) => state.ui.page.costs.currentPage
export const GetCostListPage = reselect.createSelector(
  getCostListPage,
  (currentPage) => currentPage,
)

const getTemplateListPage = (state: RootState) => state.ui.page.templates.currentPage
export const GetTemplateListPage = reselect.createSelector(
  getTemplateListPage,
  (currentPage) => currentPage,
)

const getRatesListPage = (state: RootState) => state.ui.page.rates.currentPage
export const GetRatesListPage = reselect.createSelector(
  getRatesListPage,
  (currentPage) => currentPage,
)

const getContractListPage = (state: RootState) => state.ui.page.contracts.currentPage
export const GetContractListPage = reselect.createSelector(
  getContractListPage,
  (currentPage) => currentPage,
)

const getUserListPage = (state: RootState) => state.ui.page.users.currentPage
export const GetUsersListPage = reselect.createSelector(
  getUserListPage,
  (currentPage) => currentPage,
)

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.SET_HOME_ORDER_PAGE: {
      const home: HomeUiState = {
        currentOrderPage: action.payload.orderPage,
        currentBookingPage: action.payload.bookingPage,
      }
      return { ...s, home }
    }

    case actions.SET_HOME_BOOKING_PAGE: {
      const home: HomeUiState = {
        currentOrderPage: s.home.currentOrderPage,
        currentBookingPage: action.payload.page,
      }
      return { ...s, home }
    }

    case actions.SET_ORDER_LIST_PAGE: {
      const orders: OrdersUiState = { currentPage: action.payload.page }
      return { ...s, orders }
    }

    case actions.SET_BOOKING_LIST_PAGE: {
      const bookings: BookingUiState = { currentPage: action.payload.page }
      return { ...s, bookings }
    }

    case actions.SET_SHIPMENT_LIST_PAGE: {
      const shipments: ShipmentUiState = { currentPage: action.payload.page }
      return { ...s, shipments }
    }

    case actions.SET_PRODUCT_LIST_PAGE: {
      const products: ProductUiState = { currentPage: action.payload.page }
      return { ...s, products }
    }

    case actions.SET_ADDRESS_LIST_PAGE: {
      const address: AddressUiState = { currentPage: action.payload.page }
      return { ...s, address }
    }

    case actions.SET_INVOICE_LIST_PAGE: {
      const invoices: InvoiceUiState = { currentPage: action.payload.page }
      return { ...s, invoices }
    }

    case actions.SET_ARTICLE_TYPE_LIST_PAGE: {
      const articleTypes: ArticleTypeUiState = { currentPage: action.payload.page }
      return { ...s, articleTypes }
    }

    case actions.SET_COST_LIST_PAGE: {
      const costs: CostUiState = { currentPage: action.payload.page }
      return { ...s, costs }
    }

    case actions.SET_TEMPLATE_LIST_PAGE: {
      const templates: TemplateUiState = { currentPage: action.payload.page }
      return { ...s, templates }
    }

    case actions.SET_RATES_LIST_PAGE: {
      const rates: RatesUiState = { currentPage: action.payload.page }
      return { ...s, rates }
    }

    case actions.SET_CONTRACT_LIST_PAGE: {
      const contracts: ContractUiState = { currentPage: action.payload.page }
      return { ...s, contracts }
    }

    case actions.SET_USERS_LIST_PAGE: {
      const users: UsersUiState = { currentPage: action.payload.page }
      return { ...s, users }
    }

    default:
      return s
  }
}
