import { Invoice } from 'proto/invoicing/v1/invoice_pb'

import { RootState } from 'store/reducer'

import * as invoiceTypes from 'types/invoice'

import * as actions from './actions'

export interface State {
  readonly pages: Map<invoiceTypes.InvoicePage, Array<number>>
  readonly items: { [key: string]: Invoice }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly isFetchingArticleTypes: boolean
}

const initialState: State = {
  pages: new Map(),
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
  isFetchingArticleTypes: false,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.invoicing.invoice.items).map((id) => state.invoicing.invoice.items[id])
}

export const getCount = (state: RootState) => state.invoicing.invoice.count
export const getPageItems = (state: RootState, page: invoiceTypes.InvoicePage) => {
  const items = state.invoicing.invoice.items
  const pageItems = state.invoicing.invoice.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<Invoice>)
}

export const getItemsByBookingRef = (state: RootState, bookingRef: string) => {
  return getItems(state).filter((i) => i.getBookingRef() === bookingRef)
}

export const getErr = (state: RootState) => state.invoicing.invoice.err
export const getIsFetching = (state: RootState) => state.invoicing.invoice.isFetching

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, invoices, page } = action.payload
      const newItems = invoices.reduce<{ [key: string]: Invoice }>((map, r) => {
        map[r.getInvoiceId()] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }

      const pageItems = invoices.map((i) => i.getInvoiceId())

      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })

      pages.set(page, pageItems)
      return { ...s, items, count, isFetching: false, pages }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_INVOICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_INVOICE_RESP: {
      const { invoice } = action.payload

      const items = {
        ...s.items,
        [invoice.getInvoiceId()]: invoice,
      }

      return { ...s, items: items, isFetching: false }
    }

    case actions.UPDATE_INVOICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.ISSUE_INVOICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.ISSUE_INVOICE_RESP: {
      const { invoice } = action.payload

      const items = {
        ...s.items,
        [invoice.getInvoiceId()]: invoice,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.ISSUE_INVOICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_INVOICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_INVOICE_RESP: {
      const { invoice } = action.payload

      const items = {
        ...s.items,
        [invoice.getInvoiceId()]: invoice,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.CREATE_INVOICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.ISSUE_COLLECTIVE_INVOICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.ISSUE_COLLECTIVE_INVOICE_RESP: {
      const { invoices } = action.payload

      const items = { ...s.items }

      invoices.forEach((i) => {
        items[i.getInvoiceId()] = i
      })

      return { ...s, items: items, isFetching: false }
    }

    case actions.ISSUE_COLLECTIVE_INVOICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SEND_INVOICE_REMINDERS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SEND_INVOICE_REMINDERS_RESP: {
      const { invoices } = action.payload
      const newItems = invoices.reduce<{ [key: string]: Invoice }>((map, r) => {
        map[r.getInvoiceId()] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }

      return { ...s, items, isFetching: false }
    }

    case actions.SEND_INVOICE_REMINDERS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.PREVIEW_INVOICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.PREVIEW_INVOICE_RESP: {
      return { ...s, isFetching: false }
    }

    case actions.PREVIEW_INVOICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.PREVIEW_COLLECTIVE_INVOICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.PREVIEW_COLLECTIVE_INVOICE_RESP: {
      return { ...s, isFetching: false }
    }
    case actions.PREVIEW_COLLECTIVE_INVOICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_INVOICE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_INVOICE_RESP: {
      const { invoiceId } = action.payload
      const newItems = Object.keys(s.items).reduce<{ [key: string]: Invoice }>((map, i) => {
        if (i !== String(invoiceId)) {
          map[String(i)] = s.items[String(i)]
        }
        return map
      }, {})

      const pages = s.pages
      s.pages.forEach((page, key) => {
        pages.set(
          key,
          page.filter((p) => p !== invoiceId),
        )
      })

      return { ...s, items: newItems, pages, isFetching: false }
    }

    case actions.DELETE_INVOICE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_INVOICE_NOTE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.UPDATE_INVOICE_NOTE_RESP: {
      const { invoice } = action.payload
      const items = {
        ...s.items,
        [invoice.getInvoiceId()]: invoice,
      }
      return { ...s, items, isFetching: false }
    }
    case actions.UPDATE_INVOICE_NOTE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_AUTO_INVOCING_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_AUTO_INVOCING_RESP: {
      const { invoice } = action.payload
      const items = {
        ...s.items,
        [invoice.getInvoiceId()]: invoice,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_AUTO_INVOCING_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
