import { Booking } from 'proto/booking/v1/booking_pb'
import { Owner } from 'proto/common/owner_pb'
import { CustomData, Document, Instruction, Usage } from 'proto/document/v1/document_pb'
import { Shipment } from 'proto/shipment/v1/shipment_pb'

import { ActionsUnion, createAction } from '../helpers'

import { DocumentPage } from 'types/documents'

export const LIST_REQUEST = 'swift2/document/LIST_REQUEST'
export const LIST_RESPONSE = 'swift2/document/LIST_RESPONSE'
export const LIST_ERROR = 'swift2/document/LIST_ERROR'

export const UPLOAD_FILE = 'swift2/document/UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'swift2/document/UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_ERROR = 'swift2/document/UPLOAD_FILE_ERROR'
export const DELETE_REQUEST = 'swift2/document/DELETE_REQUEST'
export const DELETE_RESPONSE = 'swift2/document/DELETE_RESPONSE'
export const DELETE_ERROR = 'swift2/document/DELETE_ERROR'

export const GENERATE_BOOKING_DOCUMENT_REQUEST = 'swift2/document/GENERATE_BOOKING_DOCUMENT_REQUEST'
export const GENERATE_BOOKING_DOCUMENT_RESPONSE =
  'swift2/document/GENERATE_BOOKING_DOCUMENT_RESPONSE'
export const GENERATE_BOOKING_DOCUMENT_ERROR = 'swift2/document/GENERATE_BOOKING_DOCUMENT_ERROR'

export const GENERATE_SHIPMENT_DOCUMENT_REQUEST =
  'swift2/document/GENERATE_SHIPMENT_DOCUMENT_REQUEST'
export const GENERATE_SHIPMENT_DOCUMENT_RESPONSE =
  'swift2/document/GENERATE_SHIPMENT_DOCUMENT_RESPONSE'
export const GENERATE_SHIPMENT_DOCUMENT_ERROR = 'swift2/document/GENERATE_SHIPMENT_DOCUMENT_ERROR'

export const Actions = {
  list: (page: DocumentPage) => createAction(LIST_REQUEST, { page }),
  listResp: (documents: ReadonlyArray<Document>, count: number) =>
    createAction(LIST_RESPONSE, { documents, count }),
  listErr: (error: Error) => createAction(LIST_ERROR, { error }),
  uploadFile: (
    file: File,
    namespace: string,
    note: string,
    orderRef: string,
    bookingRef: string,
    shipmentRef: string,
    organizationRef: string,
    usage: Usage,
    deviationId: number,
    relatedOwner: Owner,
    isPrivate: boolean,
  ) =>
    createAction(UPLOAD_FILE, {
      file,
      namespace,
      note,
      orderRef,
      bookingRef,
      shipmentRef,
      organizationRef,
      usage,
      deviationId,
      relatedOwner,
      isPrivate,
    }),
  uploadFileSuccess: (document: Document) => createAction(UPLOAD_FILE_SUCCESS, { document }),
  uploadFileError: (error: Error) => createAction(UPLOAD_FILE_ERROR, { error }),
  delete: (documentID: number) => createAction(DELETE_REQUEST, { documentID }),
  deleteResp: (documentID: number) => createAction(DELETE_RESPONSE, { documentID }),
  deleteErr: (error: Error) => createAction(DELETE_ERROR, { error }),
  generateBookingDocument: (booking: Booking, usage: Usage, customData: CustomData) =>
    createAction(GENERATE_BOOKING_DOCUMENT_REQUEST, { booking, usage, customData }),
  generateBookingDocumentResp: (document: Document) =>
    createAction(GENERATE_BOOKING_DOCUMENT_RESPONSE, { document }),
  generateBookingDocumentErr: (error: Error) =>
    createAction(GENERATE_BOOKING_DOCUMENT_ERROR, { error }),
  generateShipmentDocument: (shipment: Shipment, usage: Usage, instructions: Instruction[]) =>
    createAction(GENERATE_SHIPMENT_DOCUMENT_REQUEST, { shipment, usage, instructions }),
  generateShipmentDocumentResp: (document: Document) =>
    createAction(GENERATE_SHIPMENT_DOCUMENT_RESPONSE, { document }),
  generateShipmentDocumentErr: (error: Error) =>
    createAction(GENERATE_SHIPMENT_DOCUMENT_ERROR, { error }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
