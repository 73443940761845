import React, { useId } from 'react'

import { Form, FormInstance } from 'antd'
import { Rule } from 'antd/lib/form'

import { runRules } from '../helpers/CustomValidator.helper'

import { DateRange, StartOrEndTime, TimeRangeError } from '../types/TimeRange.type'

import './Input.css'
import { TimePickerComponent } from './TimePicker'
import './TimeRangePicker.css'

interface TimeRangePickerProps {
  startTime: string
  endTime: string
  startTimeLabel?: string
  endTimeLabel?: string
  placeholderStart?: string
  placeHolderEnd?: string
  errorMessage?: string
  disabled?: boolean
  vertical?: boolean
  required?: boolean
  formRef: FormInstance<any>
  onChange: (times: DateRange) => void
  rules?: Rule[]
}

/**
 * Usage: When a user wants to select a time range
 */
export const TimeRangePickerComponent = ({
  startTime,
  endTime,
  errorMessage,
  disabled,
  placeholderStart,
  placeHolderEnd,
  startTimeLabel = 'Start Time (24h)',
  endTimeLabel = 'End Time (24h)',
  vertical,
  required = false,
  formRef,
  onChange,
  rules,
}: TimeRangePickerProps) => {
  const id = useId()
  const onTimePicked = (time: string, startOrEnd: StartOrEndTime): void => {
    const timesToEmit: DateRange = startOrEnd === 'start' ? [time, endTime] : [startTime, time]
    onChange(timesToEmit)
  }

  // Decides based on the prop errorMessage if the form's validatiostatus should be set or not.
  const hasErrorMessage = (): TimeRangeError => {
    return errorMessage ? 'error' : ''
  }
  return (
    <Form.Item
      name={id + 'time-range-picker'}
      className="form-item--margin"
      required={rules ? true : false}
      rules={[
        {
          validator() {
            return runRules(rules)
          },
        },
      ]}
    >
      <div
        className={`inter-time-range-picker--pickers ${
          vertical ? 'inter-time-range-picker--vertical' : ''
        }`}
      >
        <TimePickerComponent
          formRef={formRef}
          errorMessage={errorMessage ? ' ' : ''}
          formItemName={id + 'start-time'}
          label={startTimeLabel}
          disabled={disabled}
          value={startTime}
          placeholder={placeholderStart}
          required={required}
          onChange={(ev) => onTimePicked(ev, 'start')}
        />
        <TimePickerComponent
          errorMessage={errorMessage ? ' ' : ''}
          formRef={formRef}
          formItemName={id + 'end-time'}
          label={endTimeLabel}
          disabled={disabled}
          value={endTime}
          placeholder={placeHolderEnd}
          required={required}
          onChange={(ev) => onTimePicked(ev, 'end')}
        />
      </div>
    </Form.Item>
  )
}
