export function capitalizeFirstLetter(s: string) {
  if (s === '') {
    return ''
  }
  return s.replace(/^./, s[0].toUpperCase())
}

export function hasJsonStructure(str: string): boolean {
  try {
    const result = JSON.parse(str)
    const type = Object.prototype.toString.call(result)
    return type === '[object Object]' || type === '[object Array]'
  } catch (err) {
    return false
  }
}
