import { ActionsUnion, createAction } from '../../helpers'

import * as addressTypes from '../../../types/address'
import * as articleTypes from '../../../types/articleType'
import * as bookingTypes from '../../../types/booking'
import * as contractTypes from '../../../types/contract'
import * as costTypes from '../../../types/cost'
import * as currencyTypes from '../../../types/currency'
import * as invoiceTypes from '../../../types/invoice'
import * as orderTypes from '../../../types/order'
import * as productTypes from '../../../types/product'
import * as shipmentTypes from '../../../types/shipment'
import * as templateTypes from '../../../types/templates'
import * as userTypes from '../../../types/user'

export const SET_HOME_ORDER_PAGE = 'swift2/ui/SET_HOME_ORDER_PAGE'
export const SET_HOME_BOOKING_PAGE = 'swift2/ui/SET_HOME_BOOKING_PAGE'
export const SET_ORDER_LIST_PAGE = 'swift2/ui/SET_ORDER_LIST_PAGE'
export const SET_BOOKING_LIST_PAGE = 'swift2/ui/SET_BOOKING_LIST_PAGE'
export const SET_SHIPMENT_LIST_PAGE = 'swift2/ui/SET_SHIPMENT_LIST_PAGE'
export const SET_PRODUCT_LIST_PAGE = 'swift2/ui/SET_PRODUCT_LIST_PAGE'
export const SET_ADDRESS_LIST_PAGE = 'swift2/ui/SET_ADDRESS_LIST_PAGE'
export const SET_INVOICE_LIST_PAGE = 'swift2/ui/SET_INVOICE_LIST_PAGE'
export const SET_ARTICLE_TYPE_LIST_PAGE = 'swift2/ui/SET_ARTICLE_TYPE_LIST_PAGE'
export const SET_COST_LIST_PAGE = 'swift2/ui/SET_COST_LIST_PAGE'
export const SET_TEMPLATE_LIST_PAGE = 'swift2/ui/SET_TEMPLATE_LIST_PAGE'
export const SET_RATES_LIST_PAGE = 'swift2/ui/SET_RATES_LIST_PAGE'
export const SET_CONTRACT_LIST_PAGE = 'swift2/ui/SET_CONTRACT_LIST_PAGE'
export const SET_USERS_LIST_PAGE = 'swift2/ui/SET_USERS_LIST_PAGE'

export const Actions = {
  setHomeOrderPage: (orderPage: orderTypes.OrderPage, bookingPage: bookingTypes.BookingPage) =>
    createAction(SET_HOME_ORDER_PAGE, { orderPage, bookingPage }),
  setHomeBookingPage: (page: bookingTypes.BookingPage) =>
    createAction(SET_HOME_BOOKING_PAGE, { page }),
  setOrderListPage: (page: orderTypes.OrderPage) => createAction(SET_ORDER_LIST_PAGE, { page }),
  setBookingListPage: (page: bookingTypes.BookingPage) =>
    createAction(SET_BOOKING_LIST_PAGE, { page }),
  setShipmentListPage: (page: shipmentTypes.ShipmentPage) =>
    createAction(SET_SHIPMENT_LIST_PAGE, { page }),
  setProductListPage: (page: productTypes.ProductPage) =>
    createAction(SET_PRODUCT_LIST_PAGE, { page }),
  setAddressListPage: (page: addressTypes.AddressPage) =>
    createAction(SET_ADDRESS_LIST_PAGE, { page }),
  setInvoiceListPage: (page: invoiceTypes.InvoicePage) =>
    createAction(SET_INVOICE_LIST_PAGE, { page }),
  setArticleTypeListPage: (page: articleTypes.ArticleTypePage) =>
    createAction(SET_ARTICLE_TYPE_LIST_PAGE, { page }),
  setCostListPage: (page: costTypes.CostPage) => createAction(SET_COST_LIST_PAGE, { page }),
  setTemplateListPage: (page: templateTypes.TemplatePage) =>
    createAction(SET_TEMPLATE_LIST_PAGE, { page }),
  setRatesListPage: (page: currencyTypes.CurrencyPage) =>
    createAction(SET_RATES_LIST_PAGE, { page }),
  setContractListPage: (page: contractTypes.ContractPage) =>
    createAction(SET_CONTRACT_LIST_PAGE, { page }),
  setUsersListPage: (page: userTypes.UserPage) => createAction(SET_USERS_LIST_PAGE, { page }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
