import { ActionsUnion, createAction } from '../../helpers'

import * as bookingTypes from '../../../types/booking'

export const GET_PROFITABILITY_LIST_DATA = 'swift2/ui/GET_PROFITABILITY_LIST_DATA'

export const Actions = {
  getProfitabilityList: (bookingPage: bookingTypes.BookingPage) =>
    createAction(GET_PROFITABILITY_LIST_DATA, { bookingPage }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
