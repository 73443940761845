import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import * as userv1 from '../proto/iam/v1/user_pb'

import * as authSelectors from '../store/auth/reducer'
import * as userSelectors from '../store/iam/user/reducer'
import { RootState } from '../store/reducer'

import { FullSizeSpinner } from '../components/Spinners'

import { useRouter } from '../helpers/route.helper'

import { RouteNames } from '../route/routes-map'

export const LoadingComponent = () => {
  const [searchParam] = useSearchParams()
  const [navigate] = useRouter()
  const userFetching = useSelector<RootState, boolean>((state) =>
    userSelectors.getIsFetching(state),
  )
  const currentUser = useSelector<RootState, userv1.User | undefined>((state) =>
    userSelectors.getCurrentUser(state),
  )

  const authErr = useSelector<RootState, Error | undefined>((state) => authSelectors.getErr(state))

  useEffect(() => {
    // means that currentUser exists and the user should be routed to its destination.
    if (userFetching === false && currentUser) {
      const targetPath: string = searchParam.get('to') ?? RouteNames.Main
      navigate(targetPath)
    }
    // Should route the user to the setup page after auth and call to retrieve user returned undefined.
    if (userFetching === false && !currentUser) {
      navigate(RouteNames.Setup)
    }
  }, [userFetching, currentUser])

  useEffect(() => {
    // As a backup to prevent the user from being stuck at the loading page.
    if (authErr) {
      navigate(RouteNames.Login)
    }
  }, [authErr])

  return <FullSizeSpinner />
}
