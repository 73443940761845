import React from 'react'

import { Modal } from 'antd'

import { Heading } from 'components/Typography'

import './modal.css'

interface ModalProps {
  visible: boolean
  title?: string
  onOk?: () => void
  onCancel: () => void
  okButtonText?: string
  cancelButtonText?: string
  children?: JSX.Element
  footer?: JSX.Element
  size: 'xs' | 'sm' | 'md' | 'lg'
  header?: JSX.Element
  disableOkButton?: boolean
}

export const ModalComponent = ({
  visible,
  title,
  okButtonText = 'Save',
  cancelButtonText = 'Cancel',
  children,
  footer,
  size,
  onOk,
  onCancel,
  header,
  disableOkButton,
}: ModalProps) => {
  const getModalClassNames = (): string => {
    const sizeMap = {
      xs: 'modal__container--xsmall',
      sm: 'modal__container--small ',
      md: 'modal__container',
      lg: 'modal__container--large',
    }
    return sizeMap[size ?? 'md']
  }
  return (
    <Modal
      open={visible}
      title={header ? header : <Heading text={title ?? ''} headingType="h2" />}
      okText={okButtonText}
      cancelText={cancelButtonText}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      className={'modal__dimensions ' + getModalClassNames()}
      okButtonProps={{ disabled: disableOkButton }} // Pass disabled property to control OK button state
    >
      {children}
    </Modal>
  )
}
