export const defaultPageSize = 25
export const defaultPageSizeForShipment = 8

export const pageSizes = ['10', '25', '50', '100']
export const pageSizesFinance = ['25', '50', '100', '300', '500']

export type Pagination = {
  skip: number
  limit: number
}
