import { combineReducers } from 'redux'

import { State as SignupState, default as signup } from '../iam/signup/reducer'
import { State as BranchState, default as branch } from './branch/reducer'
import { State as OrganizationState, default as organization } from './organization/reducer'
import { State as UserState, default as user } from './user/reducer'
import { State as UserGroupState, default as usergroup } from './usergroup/reducer'

export interface State {
  branch: BranchState
  organization: OrganizationState
  signup: SignupState
  user: UserState
  usergroup: UserGroupState
}

export default combineReducers<State>({
  branch,
  organization,
  signup,
  user,
  usergroup,
} as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.
