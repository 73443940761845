import * as orderv1 from '../../../proto/order/v1/order_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { RootState } from '../../../store/reducer'

import * as date from '../../../helpers/timestamp'
import { timestampToMoment } from '../../../helpers/timestamp'

import * as orderTypes from '../../../types/order'

import * as actions from './actions'

export interface State {
  readonly pages: Map<orderTypes.OrderPage, Array<number>>
  readonly items: { [key: string]: orderv1.Order }
  readonly orderEvents: { [key: string]: Array<orderv1.OrderEvent> }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly isFetchingEvents: boolean
  readonly stats?: orderv1.OrderStats
}

const initialState: State = {
  items: {},
  pages: new Map(),
  orderEvents: {},
  count: 0,
  err: undefined,
  isFetching: false,
  isFetchingEvents: false,
  stats: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.order.order.items).map((id) => state.order.order.items[id])
}

export const getPageItems = (state: RootState, page: orderTypes.OrderPage) => {
  const items = state.order.order.items
  const pageItems = state.order.order.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<orderv1.Order>)
}

export const getItemsById = (state: RootState) => {
  return state.order.order.items
}

export const getItemByRef = (state: RootState, ref: string) => {
  return Object.keys(state.order.order.items)
    .map((id) => state.order.order.items[id])
    .find((o) => (o ? o.getOrderRef() === ref : false))
}

export const getEventsByRef = (state: RootState, orderRef: string) => {
  const linkedEvents = state.order.order.orderEvents[orderRef] || []
  return linkedEvents.sort(
    date.sortFn((o) => timestampToMoment(o.getRelatedEventDate() as Timestamp)),
  )
}

export const getItemsByRefs = (state: RootState, orderRefs: string[] | string | undefined) => {
  const orders = Object.values(state.order.order.items)
  const refs = [orderRefs].flat() // Making sure we always get an array

  return refs
    .map((ref) => orders.find((o) => o.getOrderRef() === ref))
    .filter((o): o is orderv1.Order => o !== undefined)
}

export const getCount = (state: RootState) => {
  return state.order.order.count
}

export const getStats = (state: RootState) => {
  return state.order.order.stats
}

export const getErr = (state: RootState) => state.order.order.err
export const getIsFetching = (state: RootState) => state.order.order.isFetching

export const getIsFetchingEvents = (state: RootState) => state.order.order.isFetchingEvents
export const getEvents = (state: RootState) => state.order.order.orderEvents

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, orders, page } = action.payload

      const newItems = orders.reduce<{ [key: string]: orderv1.Order }>((map, r) => {
        map[r.getOrderId()] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }

      const PageItems = orders.map((o) => o.getOrderId())

      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })

      pages.set(page, PageItems)
      return { ...s, items, count, isFetching: false, pages }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { order } = action.payload
      if (!order) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [order.getOrderId()]: order,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_STATS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_STATS_RESP: {
      const { stats } = action.payload
      // NOTE: Replace all stats for now.
      return { ...s, stats, isFetching: false }
    }

    case actions.GET_STATS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { order } = action.payload
      const items = {
        ...s.items,
        [order.getOrderId()]: order,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_RESP: {
      const { order } = action.payload
      // TODO: Fix this mess...
      //   const bookingId = Object.keys(s.items).find((id) => {
      //     const b = s.items[id]
      //     if (!b) {
      //       return false
      //     }
      //     const r = b.getOrder()
      //     if (!r) {
      //       return false
      //     }
      //     return r.getOrderId() === order.getOrderId()
      //   })
      //   if (!bookingId) {
      //     return s
      //   }
      //   const updatedRet = s.items[order.getOrderId()]
      //   if (!updatedRet) {
      //     return s
      //   }
      //   booking.setOrder(order)
      // NOTE: new Booking([]) requires modification of the generated proto file.
      const items = {
        ...s.items,
        [order.getOrderId()]: orderv1.Order.deserializeBinary(order.serializeBinary()),
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_EVENTS_REQ: {
      return { ...s, err: undefined, isFetchingEvents: true }
    }

    case actions.GET_EVENTS_RESP: {
      const { events, orderRef } = action.payload
      const orderEvents = {
        ...s.orderEvents,
        [orderRef]: events,
      }
      return { ...s, orderEvents, isFetchingEvents: false }
    }

    case actions.GET_EVENTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetchingEvents: false }
    }

    case actions.APPLY_ORDER_EVENT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.APPLY_ORDER_EVENT_RESP: {
      const { order } = action.payload
      const items = {
        ...s.items,
        [order.getOrderId()]: order,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.APPLY_ORDER_EVENT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
