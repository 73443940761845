export interface GT {
  tag: 'GT'
  jsComparisonValue: 1
}
export interface LT {
  tag: 'LT'
  jsComparisonValue: -1
}
export interface EQ {
  tag: 'EQ'
  jsComparisonValue: 0
}
export const lt: Ordering = {
  tag: 'LT',
  jsComparisonValue: -1,
}
export const gt: Ordering = {
  tag: 'GT',
  jsComparisonValue: 1,
}
export const eq: Ordering = {
  tag: 'EQ',
  jsComparisonValue: 0,
}
export type Ordering = LT | EQ | GT

// This function provides Orders for various types
// Basically a means of taking two values of that type and returning an Ordering

export const jsDateOrder = (d1: Date, d2: Date): Ordering => {
  if (d1 < d2) {
    return lt
  }
  if (d1 > d2) {
    return gt
  }
  return eq
}

// Accepts undefined for the sake of interrop with pb objects and higher order functions
export function makeOrderingFn<T, C>(
  extractComparator: (t: T) => C | undefined,
  comparisonFn: (c1: C, c2: C) => Ordering,
): (t1: T, t2: T) => Ordering {
  return (t1, t2) => {
    const fst: C | undefined = extractComparator(t1)
    const snd: C | undefined = extractComparator(t2)
    if (fst === undefined && snd === undefined) {
      return eq
    }
    if (fst === undefined) {
      return lt
    }
    if (snd === undefined) {
      return gt
    }
    return comparisonFn(fst, snd)
  }
}

export function sort<T>(ts: ReadonlyArray<T>, orderFn: (t1: T, t2: T) => Ordering) {
  const copied = [...ts]
  return copied.sort((a, b) => orderFn(a, b).jsComparisonValue)
}
