import React, { useMemo } from 'react'

import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { Table } from 'antd'

import {
  ColumnType,
  getColumnWidthDependingOnColumnType,
  getSpecificColumnTypeTextAlignment,
} from './table.helpers'

import { DateTimeColumn } from './DateTimeColumn'
import { NumberColumn } from './NumberColumn'
import { TextColumn } from './TextColumn'
import { useExpandedRow } from './expanded-row.hook'

export type DataType<T extends {}> = T & {
  key: string
}
export interface TableColumns<T> {
  title: any
  dataIndex: string | string[]
  key: string | number
  width?: string | number
  fixed?: 'left' | 'right'
  columnType?: ColumnType
  render?: (text: string, record: T, index: number) => JSX.Element
}

interface TableProps<T extends Record<string | number, unknown>> {
  data: DataType<T>[]
  columns: TableColumns<T>[]
  multipleExpandableRows?: boolean
  footer?: JSX.Element
  noDataText?: JSX.Element | string
  loading?: boolean
  expandRowByClick?: boolean
  expandableChild?: (item: T) => JSX.Element
}

export const TableComponent = <T extends Record<string | number, any>>({
  data,
  columns,
  multipleExpandableRows = false,
  footer,
  noDataText,
  loading = false,
  expandRowByClick = true,
  expandableChild,
}: TableProps<T>) => {
  const { expandedRows, addOrRemoveKeyFromExpandedRow } = useExpandedRow(multipleExpandableRows)

  const getRenderer = (
    column: TableColumns<T>,
    text: string,
    record: T,
    index: number,
  ): JSX.Element => {
    const columnTypeMap = {
      numberColumn: <NumberColumn value={text} />,
      textColumn: <TextColumn value={text} />,
      dateTimeColumn: <DateTimeColumn value={text} />,
      customActions: column.render ? column.render(text, record, index) : <>{text}</>,
      actionRight: column.render ? column.render(text, record, index) : <>{text}</>,
      custom: column.render ? column.render(text, record, index) : <>{text}</>,
    }

    const columnTypeToRender = column.render ? 'custom' : column.columnType
    return columnTypeMap[columnTypeToRender || 'textColumn']
  }

  const memoizedColumns: any[] = useMemo(() => {
    return columns?.map((column) => {
      return {
        ...column,
        render: (text: string, record: T, index: number) =>
          getRenderer(column, text, record, index),
        width: getColumnWidthDependingOnColumnType(column),
        align: getSpecificColumnTypeTextAlignment(column),
      }
    })
  }, [columns])

  return (
    <Table<DataType<T>>
      pagination={false}
      dataSource={data}
      summary={() => footer}
      columns={memoizedColumns}
      expandedRowKeys={expandedRows}
      locale={{
        emptyText: noDataText,
      }}
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 48 }} spin={true} />,
      }}
      scroll={{ x: true }}
      expandable={
        expandableChild
          ? {
              expandRowByClick: expandRowByClick,
              expandedRowRender: (item) => (expandableChild ? expandableChild(item) : null),
              rowExpandable: () => !!expandableChild,
              onExpand: (expanded, record) => {
                addOrRemoveKeyFromExpandedRow(expanded, record.key)
              },
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ),
            }
          : undefined
      }
    />
  )
}
