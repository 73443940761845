import React from 'react'

import { Popover } from 'antd'

interface Props {
  children?: React.ReactNode
  errors: string[]
  title: string
}

const ErrorHandlingButton: React.FC<Props> = ({ children, errors, title }) => {
  return (
    <>
      {errors.length === 0 || errors.every((e) => e === '') ? (
        children
      ) : (
        <Popover
          content={
            <div>
              {errors.map((s, i) => (
                <p key={i}>{s}</p>
              ))}
            </div>
          }
          title={title}
          trigger="hover"
        >
          {children}
        </Popover>
      )}
    </>
  )
}

export default ErrorHandlingButton
