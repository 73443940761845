import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonQuery from '../../proto/common/query_pb'
import * as currencyv1 from '../../proto/currency/v1/currency_pb'
import { CostServicePromiseClient } from '../../proto/invoicing/v1/cost_grpc_web_pb'
import * as costv1 from '../../proto/invoicing/v1/cost_pb'

import { Actions as CurrencyActions } from '../../store/currency/actions'
import { getBranchDefaultCurrency } from '../../store/iam/branch/reducer'
import {
  Actions,
  LIST_REQ,
  SET_BOOKING_COSTS_REQ,
  SET_COST_STATUS_REQ,
} from '../../store/invoicing/cost/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'
import { RootState } from '../../store/reducer'

import { authMetadata } from '../../helpers/auth'

export function* list(
  client: CostServicePromiseClient,
  action: ReturnType<typeof Actions.listCostsReq>,
) {
  try {
    const { page } = action.payload
    const req = new costv1.ListCostsRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }

    if (page.filter) {
      const filter = new costv1.CostFilter()
      if (page.filter.bookingRef && page.filter.bookingRef.length > 0) {
        filter.setBookingRefList(page.filter.bookingRef)
      }
      if (page.filter.status && page.filter.status.length > 0) {
        filter.setStatusList(page.filter.status)
      }
      if (page.filter.type && page.filter.type.length > 0) {
        filter.setTypeList(page.filter.type)
      }
      if (page.filter.organizationId && page.filter.organizationId.length > 0) {
        filter.setOrganizationIdList(page.filter.organizationId)
      }
      if (page.filter.branchId && page.filter.branchId.length > 0) {
        filter.setBranchIdList(page.filter.branchId)
      }
      if (page.filter.search && page.filter.search !== '') {
        filter.setSearch(page.filter.search)
      }
      if (page.filter.shipmentRef && page.filter.shipmentRef != '') {
        filter.setShipmentRef(page.filter.shipmentRef)
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sort = new costv1.CostSorting()
      sort.setField(page.sorting.getField())
      sort.setOrdering(page.sorting.getOrdering())
      req.setSorting(sort)
    }
    const resp: costv1.ListCostsResponse = yield call(
      [client, client.listCosts],
      req,
      authMetadata(),
    )

    yield setCostCurrencies(resp.getCostsList())

    yield put(Actions.listCostsResp(resp.getTotalCount(), resp.getCostsList(), page))
  } catch (err: any) {
    yield put(Actions.listCostsErr(err))
  }
}

function* setCostCurrencies(costs: costv1.Cost[]) {
  if (costs.length > 0) {
    const defaultBranchCurrency: string = yield select((state: RootState) =>
      getBranchDefaultCurrency(state),
    )
    const convList: currencyv1.ConvertCurrency[] = []
    costs.forEach((c) => {
      if (
        c.getActualAmount() !== 0 &&
        c.getCurrency().length > 0 &&
        c.getCurrency() !== defaultBranchCurrency
      ) {
        const convObj = new currencyv1.ConvertCurrency()
        convObj.setBookingRef(c.getBookingRef())
        convObj.setContextId(c.getCostId())
        convObj.setCurrencyFrom(c.getCurrency())
        convObj.setKeyCurrency(defaultBranchCurrency)
        convObj.setCurrencyTo(defaultBranchCurrency)
        convObj.setAmount(c.getActualAmount())
        convObj.setType(currencyv1.ConvertCurrency.Type.COST)
        convObj.setLookupDate(c.getCurrencyDate())
        convList.push(convObj)
      }
    })

    if (convList.length > 0) {
      yield put(CurrencyActions.convertCurrencyReq(convList, currencyv1.ConvertCurrency.Type.COST))
    }
  }
}

export function* setBookingCosts(
  client: CostServicePromiseClient,
  action: ReturnType<typeof Actions.setBookingCostsReq>,
) {
  try {
    const { costs } = action.payload
    const req = new costv1.SetBookingCostsRequest()
    const bookingRef = costs[0].getBookingRef()
    req.setCostsList(costs)
    const resp: costv1.SetBookingCostsResponse = yield call(
      [client, client.setBookingCosts],
      req,
      authMetadata(),
    )

    if (resp.getCostsList().length > 0) {
      yield put(
        NotificationActions.send({
          key: `booking-${bookingRef}`,
          kind: 'success',
          message: 'Costs Saved',
          description: 'Costs were saved successfully',
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('Response contained no booking ref')
    }
    yield put(Actions.setBookingCostsResp(resp.getCostsList()))
  } catch (err: any) {
    yield put(Actions.setBookingCostsErr(err))
  }
}

export function* setCostStatus(
  client: CostServicePromiseClient,
  action: ReturnType<typeof Actions.setCostStatusReq>,
) {
  try {
    const { costId, status, comment } = action.payload

    const req = new costv1.SetCostStatusRequest()
    req.setCostId(costId)
    req.setStatus(status)
    req.setComment(comment)

    const resp: costv1.SetCostStatusResponse = yield call(
      [client, client.setCostStatus],
      req,
      authMetadata(),
    )
    const cost = resp.getCost()
    if (cost) {
      yield put(
        NotificationActions.send({
          key: `cost-${cost.getCostId()}`,
          kind: 'success',
          message: 'Cost Updated',
          description: `Cost status was changed successfully`,
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('Response contained no cost')
    }
    yield put(Actions.setCostStatusResp(cost))
  } catch (err: any) {
    yield put(Actions.setCostStatusErr(err))
  }
}

export default function* sagas() {
  const client = new CostServicePromiseClient('')

  yield takeLatest(LIST_REQ, list, client)
  yield takeEvery(SET_BOOKING_COSTS_REQ, setBookingCosts, client)
  yield takeEvery(SET_COST_STATUS_REQ, setCostStatus, client)
}
