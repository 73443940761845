import * as filteringv1 from '../../proto/filtering/v1/filter_pb'

import { RootState } from '../../store/reducer'

import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: filteringv1.Filter }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly latestCreatedFilterId: number | undefined
}

const initialState: State = {
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
  latestCreatedFilterId: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.filter.items).map((id) => state.filter.items[id])
}
export const getItemsById = (state: RootState) => {
  return state.filter.items
}
export const getCount = (state: RootState) => state.filter.count
export const getErr = (state: RootState) => state.filter.err
export const getIsFetching = (state: RootState) => state.filter.isFetching
export const getLatestCreatedFilterId = (state: RootState) => state.filter.latestCreatedFilterId

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, filters } = action.payload
      const items = filters.reduce<{
        [key: string]: filteringv1.Filter
      }>((map, filter) => {
        map[filter.getFilterId()] = filter
        return map
      }, {})
      return { ...s, items, count, isFetching: false, latestCreatedFilterId: undefined }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { filter } = action.payload
      if (!filter) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [filter.getFilterId()]: filter,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { filter } = action.payload
      const latestCreatedFilterId = filter.getFilterId()
      const items = {
        ...s.items,
        [latestCreatedFilterId]: filter,
      }
      return { ...s, items, latestCreatedFilterId, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { filter } = action.payload
      const items = {
        ...s.items,
        [filter.getFilterId()]: filter,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { id } = action.payload

      const newItems = Object.keys(s.items).reduce<{ [key: string]: filteringv1.Filter }>(
        (map, i) => {
          if (i !== String(id)) {
            map[String(i)] = s.items[String(i)]
          }
          return map
        },
        {},
      )

      return { ...s, items: newItems, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
