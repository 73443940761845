import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonQuery from '../../proto/common/query_pb'
import { AddressServicePromiseClient } from '../../proto/crm/v1/address_grpc_web_pb'
import {
  AddressFilter,
  AddressSorting,
  CreateAddressRequest,
  CreateAddressResponse,
  DeleteAddressRequest,
  ListAddressesRequest,
  ListAddressesResponse,
  UpdateAddressRequest,
  UpdateAddressResponse,
} from 'proto/crm/v1/address_pb'

import {
  Actions,
  CREATE_REQ,
  DELETE_REQ,
  LIST_REQ,
  UPDATE_REQ,
} from '../../store/crm/address/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'
import { Actions as ConfigActions } from '../../store/ui/config/actions'

import { authMetadata } from '../../helpers/auth'

export function* list(
  client: AddressServicePromiseClient,
  action: ReturnType<typeof Actions.listAddressesReq>,
) {
  try {
    const { page } = action.payload
    const req = new ListAddressesRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }

    if (page.filter) {
      const filter = new AddressFilter()
      if (page.filter.usage && page.filter.usage.length > 0) {
        filter.setUsageList(page.filter.usage)
      }
      if (page.filter.organizationID && page.filter.organizationID.length > 0) {
        filter.setOrganizationIdList(page.filter.organizationID)
      }
      if (page.filter.search && page.filter.search !== '') {
        filter.setSearch(page.filter.search)
      }
      if (page.filter.addressID && page.filter.addressID.length > 0) {
        filter.setAddressIdList(page.filter.addressID)
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sort = new AddressSorting()
      sort.setField(page.sorting.getField())
      sort.setOrdering(page.sorting.getOrdering())
      req.setSorting(sort)
    }

    const resp: ListAddressesResponse = yield call(
      [client, client.listAddresses],
      req,
      authMetadata(),
    )
    yield put(Actions.listAddressesResp(resp.getTotalCount(), resp.getAddressesList(), page))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listAddressesErr(err))
  }
}

export function* create(
  client: AddressServicePromiseClient,
  action: ReturnType<typeof Actions.createAddressReq>,
) {
  try {
    const { address, refetch, page, defaultAddress } = action.payload
    const req = new CreateAddressRequest()
    req.setAddress(address)
    const resp: CreateAddressResponse = yield call(
      [client, client.createAddress],
      req,
      authMetadata(),
    )
    const newAddress = resp.getAddress()
    if (!newAddress) {
      throw new Error('missing address')
    }
    yield put(
      NotificationActions.send({
        key: `address-${newAddress.getAddressId()}`,
        kind: 'success',
        message: 'Address Created',
        description: 'The address was created successfully.',
        dismissAfter: 4500,
      }),
    )

    // Edit the default address if required.
    if (defaultAddress.senderID === 0) {
      defaultAddress.senderID = newAddress.getAddressId()
      yield put(ConfigActions.editUserDefaultAddress(defaultAddress))
    } else if (defaultAddress.receiverID === 0) {
      defaultAddress.receiverID = newAddress.getAddressId()
      yield put(ConfigActions.editUserDefaultAddress(defaultAddress))
    }

    if (refetch) {
      yield put(Actions.createAddressResp(newAddress))
      yield put(Actions.listAddressesReq(page))
    } else {
      yield put(Actions.createAddressResp(newAddress, page))
    }
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createAddressErr(err))
  }
}

export function* update(
  client: AddressServicePromiseClient,
  action: ReturnType<typeof Actions.updateAddressReq>,
) {
  try {
    const { address, defaultAddress } = action.payload
    const req = new UpdateAddressRequest()
    req.setAddress(address)
    const resp: UpdateAddressResponse = yield call(
      [client, client.updateAddress],
      req,
      authMetadata(),
    )
    const updateAddress = resp.getAddress()
    if (!updateAddress) {
      throw new Error('missing address')
    }

    // Update the default address.
    yield put(ConfigActions.editUserDefaultAddress(defaultAddress))

    yield put(
      NotificationActions.send({
        key: `address-${updateAddress.getAddressId()}`,
        kind: 'success',
        message: 'Address Updated',
        description: 'The address was updated successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.updateAddressResp(updateAddress))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.updateAddressErr(err))
  }
}

export function* del(
  client: AddressServicePromiseClient,
  action: ReturnType<typeof Actions.deleteAddressReq>,
) {
  try {
    const { id } = action.payload
    const req = new DeleteAddressRequest()
    req.setAddressId(id)
    yield call([client, client.deleteAddress], req, authMetadata())
    yield put(
      NotificationActions.send({
        key: `address-${id}`,
        kind: 'success',
        message: 'Address Deleted',
        description: 'The address was deleted successfully.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.deleteAddressResp(id))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteAddressErr(err))
  }
}

export default function* sagas() {
  const client = new AddressServicePromiseClient('')

  yield takeLatest(LIST_REQ, list, client)

  yield takeEvery(CREATE_REQ, create, client)
  yield takeEvery(UPDATE_REQ, update, client)
  yield takeEvery(DELETE_REQ, del, client)
}
