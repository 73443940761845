import * as bookingtemplatev1 from '../../../proto/booking/v1/bookingtemplate_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as templateTypes from '../../../types/templates'

export const LIST_REQ = 'swift2/bookingtemplate/LIST_REQ'
export const LIST_RESP = 'swift2/bookingtemplate/LIST_RESP'
export const LIST_ERR = 'swift2/bookingtemplate/LIST_ERR'

export const GET_REQ = 'swift2/bookingtemplate/GET_REQ'
export const GET_RESP = 'swift2/bookingtemplate/GET_RESP'
export const GET_ERR = 'swift2/bookingtemplate/GET_ERR'

export const CREATE_REQ = 'swift2/bookingtemplate/CREATE_REQ'
export const CREATE_RESP = 'swift2/bookingtemplate/CREATE_RESP'
export const CREATE_ERR = 'swift2/bookingtemplate/CREATE_ERR'

export const DELETE_REQ = 'swift2/bookingtemplate/DELETE_REQ'
export const DELETE_RESP = 'swift2/bookingtemplate/DELETE_RESP'
export const DELETE_ERR = 'swift2/bookingtemplate/DELETE_ERR'

export const Actions = {
  listBookingTemplatesReq: (page: templateTypes.TemplatePage) => createAction(LIST_REQ, { page }),
  listBookingTemplatesResp: (
    count: number,
    bookingTemplates: bookingtemplatev1.BookingTemplate[],
    page: templateTypes.TemplatePage,
  ) => createAction(LIST_RESP, { count, bookingTemplates, page }),
  listBookingTemplatesErr: (err: Error) => createAction(LIST_ERR, { err }),

  getBookingTemplateReq: (id: number) => createAction(GET_REQ, { id }),
  getBookingTemplateResp: (bookingTemplate: bookingtemplatev1.BookingTemplate) =>
    createAction(GET_RESP, { bookingTemplate }),
  getBookingTemplateErr: (err: Error) => createAction(GET_ERR, { err }),

  createBookingTemplateReq: (
    bookingTemplate: bookingtemplatev1.BookingTemplate,
    page: templateTypes.TemplatePage,
  ) => createAction(CREATE_REQ, { bookingTemplate, page }),
  createBookingTemplateResp: (
    bookingTemplate: bookingtemplatev1.BookingTemplate,
    page?: templateTypes.TemplatePage,
  ) => createAction(CREATE_RESP, { bookingTemplate, page }),
  createBookingTemplateErr: (err: Error) => createAction(CREATE_ERR, { err }),

  deleteBookingTemplateReq: (id: number) => createAction(DELETE_REQ, { id }),
  deleteBookingTemplateResp: (id: number) => createAction(DELETE_RESP, { id }),
  deleteBookingTemplateErr: (err: Error) => createAction(DELETE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
