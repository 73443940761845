import React from 'react'

import { Dropdown, Form } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

import { SecondaryButton } from 'components/Buttons'

import './Dropdown.css'

interface DropdownProps {
  label?: string
  icon?: JSX.Element
  buttonLabel: string
  items: ItemType[]
  trigger?: 'click'
  noMargin?: boolean
}

export const DropdownComponent = ({
  label,
  icon,
  items,
  buttonLabel,
  noMargin,
  trigger = 'click',
}: DropdownProps) => {
  return (
    <Form.Item label={label} className={noMargin ? 'dropdown-component--no-margin' : ''}>
      <Dropdown menu={{ items }} trigger={[trigger]}>
        <SecondaryButton label={buttonLabel} iconBefore={icon} />
      </Dropdown>
    </Form.Item>
  )
}
