import { delay, put, takeEvery } from 'redux-saga/effects'

import { Actions, SEND } from '../store/notification/actions'

export function* send(action: ReturnType<typeof Actions.send>) {
  const { notif } = action.payload
  if (notif.dismissAfter) {
    yield delay(notif.dismissAfter)
    yield put(Actions.dismiss(notif.key))
  }
}

export default function* sagas() {
  yield takeEvery(SEND, send)
}
