export interface BookingUiType {
  useBookingTimestamp?: boolean
}

export const createDefaultBookingUiType = (): BookingUiType => {
  return { useBookingTimestamp: false }
}

export const jsonStringToBookingUiType = (bookingUiTypeJsonString: string): BookingUiType => {
  try {
    return JSON.parse(bookingUiTypeJsonString) as BookingUiType
  } catch (e) {
    // Fallback, return a default object literal
    return createDefaultBookingUiType()
  }
}

export const bookingUiTypeToJsonString = (bookingUiType: BookingUiType): string => {
  try {
    return JSON.stringify(bookingUiType)
  } catch (e) {
    // Fallback, return a default object literal which is rendered into a string
    return JSON.stringify(createDefaultBookingUiType())
  }
}
