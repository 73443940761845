import { combineReducers } from 'redux'

import { State as StoryState, default as story } from './story/reducer'

import { State as AuthState, default as auth } from './auth/reducer'
import { State as BookingState, default as booking } from './booking/reducer'
import { State as ContractState, default as contract } from './contract/reducer'
import { State as CRMState, default as crm } from './crm/reducer'
import { State as CurrencyState, default as currency } from './currency/reducer'
import { State as DocumentState, default as document } from './document/reducer'
import { State as FilterState, default as filter } from './filter/reducer'
import { State as IAMState, default as iam } from './iam/reducer'
import { State as InvoicingState, default as invoicing } from './invoicing/reducer'
import { State as MessageState, default as message } from './message/reducer'
import { State as NotificationState, default as notification } from './notification/reducer'
import { State as OrderState, default as order } from './order/reducer'
import { State as ShipmentState, default as shipment } from './shipment/reducer'
import { State as UIState, default as ui } from './ui/reducer'

export interface RootState {
  auth: AuthState
  booking: BookingState
  currency: CurrencyState
  crm: CRMState
  document: DocumentState
  filter: FilterState
  iam: IAMState
  message: MessageState
  notification: NotificationState
  order: OrderState
  shipment: ShipmentState
  ui: UIState
  invoicing: InvoicingState
  story: StoryState
  contract: ContractState
}

const reducer = () =>
  combineReducers<RootState>({
    auth,
    booking,
    currency,
    crm,
    document,
    filter,
    iam,
    message,
    notification,
    order,
    shipment,
    ui,
    invoicing,
    story,
    contract,
  } as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.

export default reducer
