import { Address } from 'proto/crm/v1/address_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as addressTypes from '../../../types/address'
import { DefaultAddress } from '../../../types/userui'

export const LIST_REQ = 'swift2/address/LIST_REQ'
export const LIST_RESP = 'swift2/address/LIST_RESP'
export const LIST_ERR = 'swift2/address/LIST_ERR'
export const CREATE_REQ = 'swift2/address/CREATE_REQ'
export const CREATE_RESP = 'swift2/address/CREATE_RESP'
export const CREATE_ERR = 'swift2/address/CREATE_ERR'
export const UPDATE_REQ = 'swift2/address/UPDATE_REQ'
export const UPDATE_RESP = 'swift2/address/UPDATE_REQ_RESP'
export const UPDATE_ERR = 'swift2/address/UPDATE_REQ_ERR'
export const DELETE_REQ = 'swift2/address/DELETE_REQ'
export const DELETE_RESP = 'swift2/address/DELETE_RESP'
export const DELETE_ERR = 'swift2/address/DELETE_ERR'

export const Actions = {
  listAddressesReq: (page: addressTypes.AddressPage) => createAction(LIST_REQ, { page }),
  listAddressesResp: (count: number, addresses: Address[], page: addressTypes.AddressPage) =>
    createAction(LIST_RESP, { count, addresses, page }),
  listAddressesErr: (err: Error) => createAction(LIST_ERR, { err }),
  createAddressReq: (
    address: Address,
    defaultAddress: DefaultAddress,
    page: addressTypes.AddressPage,
    refetch = false,
  ) => createAction(CREATE_REQ, { address, defaultAddress, page, refetch }),
  createAddressResp: (address: Address, page?: addressTypes.AddressPage) =>
    createAction(CREATE_RESP, { address, page }),
  createAddressErr: (err: Error) => createAction(CREATE_ERR, { err }),
  updateAddressReq: (address: Address, defaultAddress: DefaultAddress) =>
    createAction(UPDATE_REQ, { address, defaultAddress }),
  updateAddressResp: (address: Address) => createAction(UPDATE_RESP, { address }),
  updateAddressErr: (err: Error) => createAction(UPDATE_ERR, { err }),
  deleteAddressReq: (id: number) => createAction(DELETE_REQ, { id }),
  deleteAddressResp: (id: number) => createAction(DELETE_RESP, { id }),
  deleteAddressErr: (err: Error) => createAction(DELETE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
