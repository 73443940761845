import { ActionsUnion, createAction } from '../helpers'

import { Notif } from './types'

export const SEND = 'swift2/notification/SEND'
export const DISMISS = 'swift2/notification/DISMISS'
export const CLEAR = 'swift2/notification/CLEAR'

export const Actions = {
  send: (notif: Notif) => createAction(SEND, { notif }),
  dismiss: (key: string) => createAction(DISMISS, { key }),
  clear: () => createAction(CLEAR),
}

export type ActionTypes = ActionsUnion<typeof Actions>
