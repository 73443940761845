// source: invoicing/v1/invoice.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
goog.exportSymbol('proto.swift.invoicing.v1.Article', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateInvoiceRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateInvoiceResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateInvoiceSeriesRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateInvoiceSeriesResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteInvoiceRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteInvoiceResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Invoice', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Invoice.Category', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Invoice.Status', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Invoice.Type', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.InvoiceFilter', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.InvoiceFilter.PartOf', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.InvoiceSeries', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.InvoiceSorting', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.InvoiceSorting.Field', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.InvoiceSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.IssueInvoiceRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.IssueInvoiceResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListInvoicesRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListInvoicesResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.PreviewInvoiceRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.PreviewInvoiceResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Remind', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Reminder', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SendRemindersRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SendRemindersResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateAutoInvoicingRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateAutoInvoicingResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateInvoiceNoteRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateInvoiceNoteResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateInvoiceRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateInvoiceResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateStatusRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateStatusResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.Invoice.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.Invoice.displayName = 'proto.swift.invoicing.v1.Invoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.Article = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.Article, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.Article.displayName = 'proto.swift.invoicing.v1.Article';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.InvoiceFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.InvoiceFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.InvoiceFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.InvoiceFilter.displayName = 'proto.swift.invoicing.v1.InvoiceFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.InvoiceSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.InvoiceSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.InvoiceSorting.displayName = 'proto.swift.invoicing.v1.InvoiceSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.Remind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.Remind.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.Remind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.Remind.displayName = 'proto.swift.invoicing.v1.Remind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.Reminder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.Reminder.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.Reminder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.Reminder.displayName = 'proto.swift.invoicing.v1.Reminder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.InvoiceSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.InvoiceSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.InvoiceSeries.displayName = 'proto.swift.invoicing.v1.InvoiceSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListInvoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.ListInvoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListInvoicesRequest.displayName = 'proto.swift.invoicing.v1.ListInvoicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListInvoicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.ListInvoicesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.ListInvoicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListInvoicesResponse.displayName = 'proto.swift.invoicing.v1.ListInvoicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateInvoiceRequest.displayName = 'proto.swift.invoicing.v1.CreateInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateInvoiceResponse.displayName = 'proto.swift.invoicing.v1.CreateInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateInvoiceRequest.displayName = 'proto.swift.invoicing.v1.UpdateInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateInvoiceResponse.displayName = 'proto.swift.invoicing.v1.UpdateInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.IssueInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.IssueInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.IssueInvoiceRequest.displayName = 'proto.swift.invoicing.v1.IssueInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.IssueInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.IssueInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.IssueInvoiceResponse.displayName = 'proto.swift.invoicing.v1.IssueInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.displayName = 'proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.displayName = 'proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateStatusRequest.displayName = 'proto.swift.invoicing.v1.UpdateStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateStatusResponse.displayName = 'proto.swift.invoicing.v1.UpdateStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SendRemindersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.SendRemindersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SendRemindersRequest.displayName = 'proto.swift.invoicing.v1.SendRemindersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SendRemindersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.SendRemindersResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.SendRemindersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SendRemindersResponse.displayName = 'proto.swift.invoicing.v1.SendRemindersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.PreviewInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.PreviewInvoiceRequest.displayName = 'proto.swift.invoicing.v1.PreviewInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.PreviewInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.PreviewInvoiceResponse.displayName = 'proto.swift.invoicing.v1.PreviewInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.displayName = 'proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.displayName = 'proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteInvoiceRequest.displayName = 'proto.swift.invoicing.v1.DeleteInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteInvoiceResponse.displayName = 'proto.swift.invoicing.v1.DeleteInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateInvoiceSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.displayName = 'proto.swift.invoicing.v1.CreateInvoiceSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateInvoiceSeriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.displayName = 'proto.swift.invoicing.v1.CreateInvoiceSeriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateInvoiceNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.displayName = 'proto.swift.invoicing.v1.UpdateInvoiceNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateInvoiceNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.displayName = 'proto.swift.invoicing.v1.UpdateInvoiceNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateAutoInvoicingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.displayName = 'proto.swift.invoicing.v1.UpdateAutoInvoicingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateAutoInvoicingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.displayName = 'proto.swift.invoicing.v1.UpdateAutoInvoicingResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.Invoice.repeatedFields_ = [8,9,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
    bookingRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    category: jspb.Message.getFieldWithDefault(msg, 5, 0),
    invoiceNo: jspb.Message.getFieldWithDefault(msg, 6, 0),
    collectiveInvoiceNo: jspb.Message.getFieldWithDefault(msg, 7, 0),
    partialInvoiceNosList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    invoicingEmailsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    invoiceRef: jspb.Message.getFieldWithDefault(msg, 10, ""),
    intereastRef: jspb.Message.getFieldWithDefault(msg, 11, ""),
    customerRef: jspb.Message.getFieldWithDefault(msg, 12, ""),
    customerNo: jspb.Message.getFieldWithDefault(msg, 13, 0),
    customerVatNo: jspb.Message.getFieldWithDefault(msg, 14, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 15, ""),
    invoiceDate: (f = msg.getInvoiceDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    invoicingPeriodStartDate: (f = msg.getInvoicingPeriodStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    invoicingPeriodEndDate: (f = msg.getInvoicingPeriodEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateOfService: (f = msg.getDateOfService()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    customer: (f = msg.getCustomer()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
    articlesList: jspb.Message.toObjectList(msg.getArticlesList(),
    proto.swift.invoicing.v1.Article.toObject, includeInstance),
    currencyDate: (f = msg.getCurrencyDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    issuer: (f = msg.getIssuer()) && common_owner_pb.Owner.toObject(includeInstance, f),
    issuedAt: (f = msg.getIssuedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastRemindedAt: (f = msg.getLastRemindedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    invoiceType: jspb.Message.getFieldWithDefault(msg, 29, 0),
    invoiceNote: jspb.Message.getFieldWithDefault(msg, 30, ""),
    invoiceNoteUpdatedAt: (f = msg.getInvoiceNoteUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    invoiceNoteCreatedBy: jspb.Message.getFieldWithDefault(msg, 32, ""),
    autoInvoicing: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    autoReminder: jspb.Message.getBooleanFieldWithDefault(msg, 34, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.Invoice;
  return proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 4:
      var value = /** @type {!proto.swift.invoicing.v1.Invoice.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.swift.invoicing.v1.Invoice.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceNo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectiveInvoiceNo(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPartialInvoiceNos(values[i]);
      }
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addInvoicingEmails(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceRef(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntereastRef(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerRef(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerNo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerVatNo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvoiceDate(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvoicingPeriodStartDate(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvoicingPeriodEndDate(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateOfService(value);
      break;
    case 21:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 22:
      var value = new proto.swift.invoicing.v1.Article;
      reader.readMessage(value,proto.swift.invoicing.v1.Article.deserializeBinaryFromReader);
      msg.addArticles(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrencyDate(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 26:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setIssuer(value);
      break;
    case 27:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssuedAt(value);
      break;
    case 28:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastRemindedAt(value);
      break;
    case 29:
      var value = /** @type {!proto.swift.invoicing.v1.Invoice.Type} */ (reader.readEnum());
      msg.setInvoiceType(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNote(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInvoiceNoteUpdatedAt(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNoteCreatedBy(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoInvoicing(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoReminder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getInvoiceNo();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCollectiveInvoiceNo();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPartialInvoiceNosList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getInvoicingEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getInvoiceRef();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIntereastRef();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCustomerRef();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCustomerNo();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getCustomerVatNo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getInvoiceDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInvoicingPeriodStartDate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInvoicingPeriodEndDate();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateOfService();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getArticlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.swift.invoicing.v1.Article.serializeBinaryToWriter
    );
  }
  f = message.getCurrencyDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIssuer();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getIssuedAt();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastRemindedAt();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceType();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = message.getInvoiceNote();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getInvoiceNoteUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceNoteCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getAutoInvoicing();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getAutoReminder();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.Invoice.Status = {
  NEW: 0,
  DRAFT: 1,
  PENDING: 2,
  CLOSED: 3,
  ISSUED: 4,
  PARTIALLY_PAID: 5,
  OVERDUE: 6,
  PAID: 7,
  CANCELED: 8
};

/**
 * @enum {number}
 */
proto.swift.invoicing.v1.Invoice.Category = {
  SINGLE: 0,
  PARTIAL: 1,
  COLLECTIVE: 2
};

/**
 * @enum {number}
 */
proto.swift.invoicing.v1.Invoice.Type = {
  DEBIT: 0,
  CREDIT: 1
};

/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.invoicing.v1.Invoice.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string booking_ref = 3;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.swift.invoicing.v1.Invoice.Status}
 */
proto.swift.invoicing.v1.Invoice.prototype.getStatus = function() {
  return /** @type {!proto.swift.invoicing.v1.Invoice.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice.Status} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Category category = 5;
 * @return {!proto.swift.invoicing.v1.Invoice.Category}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCategory = function() {
  return /** @type {!proto.swift.invoicing.v1.Invoice.Category} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice.Category} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 invoice_no = 6;
 * @return {number}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceNo = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 collective_invoice_no = 7;
 * @return {number}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCollectiveInvoiceNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setCollectiveInvoiceNo = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated int64 partial_invoice_nos = 8;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.Invoice.prototype.getPartialInvoiceNosList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setPartialInvoiceNosList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.addPartialInvoiceNos = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearPartialInvoiceNosList = function() {
  return this.setPartialInvoiceNosList([]);
};


/**
 * repeated string invoicing_emails = 9;
 * @return {!Array<string>}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoicingEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setInvoicingEmailsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.addInvoicingEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearInvoicingEmailsList = function() {
  return this.setInvoicingEmailsList([]);
};


/**
 * optional string invoice_ref = 10;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceRef = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string intereast_ref = 11;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getIntereastRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setIntereastRef = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string customer_ref = 12;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCustomerRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setCustomerRef = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 customer_no = 13;
 * @return {number}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCustomerNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setCustomerNo = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string customer_vat_no = 14;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCustomerVatNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setCustomerVatNo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string currency = 15;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp invoice_date = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearInvoiceDate = function() {
  return this.setInvoiceDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasInvoiceDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp due_date = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Timestamp invoicing_period_start_date = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoicingPeriodStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setInvoicingPeriodStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearInvoicingPeriodStartDate = function() {
  return this.setInvoicingPeriodStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasInvoicingPeriodStartDate = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp invoicing_period_end_date = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoicingPeriodEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setInvoicingPeriodEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearInvoicingPeriodEndDate = function() {
  return this.setInvoicingPeriodEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasInvoicingPeriodEndDate = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp date_of_service = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getDateOfService = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setDateOfService = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearDateOfService = function() {
  return this.setDateOfService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasDateOfService = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional swift.crm.v1.Address customer = 21;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCustomer = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 21));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated Article articles = 22;
 * @return {!Array<!proto.swift.invoicing.v1.Article>}
 */
proto.swift.invoicing.v1.Invoice.prototype.getArticlesList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Article>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Article, 22));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Article>} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setArticlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Article=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Article}
 */
proto.swift.invoicing.v1.Invoice.prototype.addArticles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.swift.invoicing.v1.Article, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearArticlesList = function() {
  return this.setArticlesList([]);
};


/**
 * optional google.protobuf.Timestamp currency_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCurrencyDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setCurrencyDate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearCurrencyDate = function() {
  return this.setCurrencyDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasCurrencyDate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional swift.common.Owner issuer = 26;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.invoicing.v1.Invoice.prototype.getIssuer = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 26));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setIssuer = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearIssuer = function() {
  return this.setIssuer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasIssuer = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.Timestamp issued_at = 27;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getIssuedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 27));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setIssuedAt = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearIssuedAt = function() {
  return this.setIssuedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasIssuedAt = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.Timestamp last_reminded_at = 28;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getLastRemindedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 28));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setLastRemindedAt = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearLastRemindedAt = function() {
  return this.setLastRemindedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasLastRemindedAt = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional Type invoice_type = 29;
 * @return {!proto.swift.invoicing.v1.Invoice.Type}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceType = function() {
  return /** @type {!proto.swift.invoicing.v1.Invoice.Type} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice.Type} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional string invoice_note = 30;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceNote = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional google.protobuf.Timestamp invoice_note_updated_at = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceNoteUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
*/
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceNoteUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.clearInvoiceNoteUpdatedAt = function() {
  return this.setInvoiceNoteUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.hasInvoiceNoteUpdatedAt = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string invoice_note_created_by = 32;
 * @return {string}
 */
proto.swift.invoicing.v1.Invoice.prototype.getInvoiceNoteCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setInvoiceNoteCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional bool auto_invoicing = 33;
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.getAutoInvoicing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setAutoInvoicing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool auto_reminder = 34;
 * @return {boolean}
 */
proto.swift.invoicing.v1.Invoice.prototype.getAutoReminder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.Invoice} returns this
 */
proto.swift.invoicing.v1.Invoice.prototype.setAutoReminder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.Article.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.Article.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.Article} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Article.toObject = function(includeInstance, msg) {
  var f, obj = {
    articleId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    articleNo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    netAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vatAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 7, ""),
    invoiceCurrency: jspb.Message.getFieldWithDefault(msg, 8, ""),
    invoiceCurrencyRate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    invoiceCurrencyRateSource: jspb.Message.getFieldWithDefault(msg, 10, ""),
    invoiceConvertedNetAmount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    invoiceConvertedVatAmount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    invoiceConvertedTotalAmount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    branchCurrency: jspb.Message.getFieldWithDefault(msg, 14, ""),
    branchCurrencyRate: jspb.Message.getFieldWithDefault(msg, 15, 0),
    branchCurrencyRateSource: jspb.Message.getFieldWithDefault(msg, 16, ""),
    branchConvertedNetAmount: jspb.Message.getFieldWithDefault(msg, 17, 0),
    branchConvertedVatAmount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    branchConvertedTotalAmount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    domesticVat: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    euVat: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    costId: jspb.Message.getFieldWithDefault(msg, 22, 0),
    invoiceAddonConversionPercentage: jspb.Message.getFieldWithDefault(msg, 23, 0),
    branchAddonConversionPercentage: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.Article}
 */
proto.swift.invoicing.v1.Article.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.Article;
  return proto.swift.invoicing.v1.Article.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.Article} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.Article}
 */
proto.swift.invoicing.v1.Article.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNetAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVatAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceCurrency(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceCurrencyRate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceCurrencyRateSource(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceConvertedNetAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceConvertedVatAmount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceConvertedTotalAmount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchCurrency(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchCurrencyRate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchCurrencyRateSource(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchConvertedNetAmount(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchConvertedVatAmount(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchConvertedTotalAmount(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDomesticVat(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEuVat(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceAddonConversionPercentage(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchAddonConversionPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.Article.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.Article.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.Article} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Article.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getArticleNo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNetAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getVatAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInvoiceCurrency();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInvoiceCurrencyRate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getInvoiceCurrencyRateSource();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInvoiceConvertedNetAmount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getInvoiceConvertedVatAmount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getInvoiceConvertedTotalAmount();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getBranchCurrency();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBranchCurrencyRate();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getBranchCurrencyRateSource();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBranchConvertedNetAmount();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getBranchConvertedVatAmount();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getBranchConvertedTotalAmount();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getDomesticVat();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getEuVat();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getCostId();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getInvoiceAddonConversionPercentage();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getBranchAddonConversionPercentage();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
};


/**
 * optional int64 article_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getArticleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setArticleId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 article_no = 2;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getArticleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setArticleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.swift.invoicing.v1.Article.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 net_amount = 4;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getNetAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setNetAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 vat_amount = 5;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getVatAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setVatAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 total_amount = 6;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string currency = 7;
 * @return {string}
 */
proto.swift.invoicing.v1.Article.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string invoice_currency = 8;
 * @return {string}
 */
proto.swift.invoicing.v1.Article.prototype.getInvoiceCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setInvoiceCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 invoice_currency_rate = 9;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getInvoiceCurrencyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setInvoiceCurrencyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string invoice_currency_rate_source = 10;
 * @return {string}
 */
proto.swift.invoicing.v1.Article.prototype.getInvoiceCurrencyRateSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setInvoiceCurrencyRateSource = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 invoice_converted_net_amount = 11;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getInvoiceConvertedNetAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setInvoiceConvertedNetAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 invoice_converted_vat_amount = 12;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getInvoiceConvertedVatAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setInvoiceConvertedVatAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 invoice_converted_total_amount = 13;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getInvoiceConvertedTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setInvoiceConvertedTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string branch_currency = 14;
 * @return {string}
 */
proto.swift.invoicing.v1.Article.prototype.getBranchCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setBranchCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 branch_currency_rate = 15;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getBranchCurrencyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setBranchCurrencyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string branch_currency_rate_source = 16;
 * @return {string}
 */
proto.swift.invoicing.v1.Article.prototype.getBranchCurrencyRateSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setBranchCurrencyRateSource = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 branch_converted_net_amount = 17;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getBranchConvertedNetAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setBranchConvertedNetAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 branch_converted_vat_amount = 18;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getBranchConvertedVatAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setBranchConvertedVatAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 branch_converted_total_amount = 19;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getBranchConvertedTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setBranchConvertedTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional bool domestic_vat = 20;
 * @return {boolean}
 */
proto.swift.invoicing.v1.Article.prototype.getDomesticVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setDomesticVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool eu_vat = 21;
 * @return {boolean}
 */
proto.swift.invoicing.v1.Article.prototype.getEuVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setEuVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional int64 cost_id = 22;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getCostId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setCostId = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int64 invoice_addon_conversion_percentage = 23;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getInvoiceAddonConversionPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setInvoiceAddonConversionPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 branch_addon_conversion_percentage = 24;
 * @return {number}
 */
proto.swift.invoicing.v1.Article.prototype.getBranchAddonConversionPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Article} returns this
 */
proto.swift.invoicing.v1.Article.prototype.setBranchAddonConversionPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.InvoiceFilter.repeatedFields_ = [1,3,4,5,6,7,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.InvoiceFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.InvoiceFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.InvoiceFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingRefList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    organizationIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    branchIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    categoryList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    invoiceTypeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    partOf: jspb.Message.getFieldWithDefault(msg, 8, 0),
    autoInvoicing: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    issuingBranchIdList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    invoiceNoList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter}
 */
proto.swift.invoicing.v1.InvoiceFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.InvoiceFilter;
  return proto.swift.invoicing.v1.InvoiceFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.InvoiceFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter}
 */
proto.swift.invoicing.v1.InvoiceFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRef(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.swift.invoicing.v1.Invoice.Status>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBranchId(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<!proto.swift.invoicing.v1.Invoice.Category>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategory(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<!proto.swift.invoicing.v1.Invoice.Type>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInvoiceType(values[i]);
      }
      break;
    case 8:
      var value = /** @type {!proto.swift.invoicing.v1.InvoiceFilter.PartOf} */ (reader.readEnum());
      msg.setPartOf(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoInvoicing(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIssuingBranchId(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInvoiceNo(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.InvoiceFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.InvoiceFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.InvoiceFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getBranchIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getInvoiceTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getPartOf();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getAutoInvoicing();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIssuingBranchIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getInvoiceNoList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.InvoiceFilter.PartOf = {
  UNKNOWN: 0,
  NULL: 1,
  NOT_NULL: 2
};

/**
 * repeated string booking_ref = 1;
 * @return {!Array<string>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getBookingRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setBookingRefList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addBookingRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearBookingRefList = function() {
  return this.setBookingRefList([]);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Invoice.Status status = 3;
 * @return {!Array<!proto.swift.invoicing.v1.Invoice.Status>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.swift.invoicing.v1.Invoice.Status>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Invoice.Status>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice.Status} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated int64 organization_id = 4;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated int64 branch_id = 5;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getBranchIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setBranchIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addBranchId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearBranchIdList = function() {
  return this.setBranchIdList([]);
};


/**
 * repeated Invoice.Category category = 6;
 * @return {!Array<!proto.swift.invoicing.v1.Invoice.Category>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getCategoryList = function() {
  return /** @type {!Array<!proto.swift.invoicing.v1.Invoice.Category>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Invoice.Category>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setCategoryList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice.Category} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addCategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearCategoryList = function() {
  return this.setCategoryList([]);
};


/**
 * repeated Invoice.Type invoice_type = 7;
 * @return {!Array<!proto.swift.invoicing.v1.Invoice.Type>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getInvoiceTypeList = function() {
  return /** @type {!Array<!proto.swift.invoicing.v1.Invoice.Type>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Invoice.Type>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setInvoiceTypeList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice.Type} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addInvoiceType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearInvoiceTypeList = function() {
  return this.setInvoiceTypeList([]);
};


/**
 * optional PartOf part_of = 8;
 * @return {!proto.swift.invoicing.v1.InvoiceFilter.PartOf}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getPartOf = function() {
  return /** @type {!proto.swift.invoicing.v1.InvoiceFilter.PartOf} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.InvoiceFilter.PartOf} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setPartOf = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool auto_invoicing = 9;
 * @return {boolean}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getAutoInvoicing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setAutoInvoicing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated int64 issuing_branch_id = 10;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getIssuingBranchIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setIssuingBranchIdList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addIssuingBranchId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearIssuingBranchIdList = function() {
  return this.setIssuingBranchIdList([]);
};


/**
 * repeated int64 invoice_no = 11;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.getInvoiceNoList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.setInvoiceNoList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.addInvoiceNo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.InvoiceFilter} returns this
 */
proto.swift.invoicing.v1.InvoiceFilter.prototype.clearInvoiceNoList = function() {
  return this.setInvoiceNoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.InvoiceSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.InvoiceSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.InvoiceSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.InvoiceSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.InvoiceSorting}
 */
proto.swift.invoicing.v1.InvoiceSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.InvoiceSorting;
  return proto.swift.invoicing.v1.InvoiceSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.InvoiceSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.InvoiceSorting}
 */
proto.swift.invoicing.v1.InvoiceSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.invoicing.v1.InvoiceSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.invoicing.v1.InvoiceSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.InvoiceSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.InvoiceSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.InvoiceSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.InvoiceSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.InvoiceSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.invoicing.v1.InvoiceSorting.Field = {
  INVOICE_ID: 0,
  INVOICE_NO: 1,
  CATEGORY: 2,
  INVOICE_TYPE: 3,
  COLLECTIVE_INVOICE_NO: 4,
  BOOKING_REF: 5,
  INVOICE_REF: 6,
  STATUS: 7,
  CUSTOMER_REF: 8,
  INTEREAST_REF: 9,
  INVOICE_DATE: 10,
  DUE_DATE: 11,
  INVOICING_PERIOD_START_DATE: 12,
  INVOICING_PERIOD_END_DATE: 13,
  OWNER: 14,
  ORGANIZATION: 15
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.invoicing.v1.InvoiceSorting.Field}
 */
proto.swift.invoicing.v1.InvoiceSorting.prototype.getField = function() {
  return /** @type {!proto.swift.invoicing.v1.InvoiceSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.InvoiceSorting.Field} value
 * @return {!proto.swift.invoicing.v1.InvoiceSorting} returns this
 */
proto.swift.invoicing.v1.InvoiceSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.invoicing.v1.InvoiceSorting.Ordering}
 */
proto.swift.invoicing.v1.InvoiceSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.invoicing.v1.InvoiceSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.InvoiceSorting.Ordering} value
 * @return {!proto.swift.invoicing.v1.InvoiceSorting} returns this
 */
proto.swift.invoicing.v1.InvoiceSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.Remind.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.Remind.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.Remind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.Remind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Remind.toObject = function(includeInstance, msg) {
  var f, obj = {
    remindersList: jspb.Message.toObjectList(msg.getRemindersList(),
    proto.swift.invoicing.v1.Reminder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.Remind}
 */
proto.swift.invoicing.v1.Remind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.Remind;
  return proto.swift.invoicing.v1.Remind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.Remind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.Remind}
 */
proto.swift.invoicing.v1.Remind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Reminder;
      reader.readMessage(value,proto.swift.invoicing.v1.Reminder.deserializeBinaryFromReader);
      msg.addReminders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.Remind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.Remind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.Remind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Remind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemindersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.invoicing.v1.Reminder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Reminder reminders = 1;
 * @return {!Array<!proto.swift.invoicing.v1.Reminder>}
 */
proto.swift.invoicing.v1.Remind.prototype.getRemindersList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Reminder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Reminder, 1));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Reminder>} value
 * @return {!proto.swift.invoicing.v1.Remind} returns this
*/
proto.swift.invoicing.v1.Remind.prototype.setRemindersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Reminder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Reminder}
 */
proto.swift.invoicing.v1.Remind.prototype.addReminders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.invoicing.v1.Reminder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.Remind} returns this
 */
proto.swift.invoicing.v1.Remind.prototype.clearRemindersList = function() {
  return this.setRemindersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.Reminder.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.Reminder.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.Reminder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.Reminder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Reminder.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.Reminder}
 */
proto.swift.invoicing.v1.Reminder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.Reminder;
  return proto.swift.invoicing.v1.Reminder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.Reminder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.Reminder}
 */
proto.swift.invoicing.v1.Reminder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.Reminder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.Reminder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.Reminder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Reminder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceNo();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int64 invoice_no = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.Reminder.prototype.getInvoiceNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Reminder} returns this
 */
proto.swift.invoicing.v1.Reminder.prototype.setInvoiceNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string emails = 2;
 * @return {!Array<string>}
 */
proto.swift.invoicing.v1.Reminder.prototype.getEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.invoicing.v1.Reminder} returns this
 */
proto.swift.invoicing.v1.Reminder.prototype.setEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Reminder} returns this
 */
proto.swift.invoicing.v1.Reminder.prototype.addEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.Reminder} returns this
 */
proto.swift.invoicing.v1.Reminder.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.InvoiceSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.InvoiceSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.InvoiceSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    branchId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    singleInvoiceSeries: jspb.Message.getFieldWithDefault(msg, 2, 0),
    collectiveInvoiceSeries: jspb.Message.getFieldWithDefault(msg, 3, 0),
    creditInvoiceSeries: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.InvoiceSeries}
 */
proto.swift.invoicing.v1.InvoiceSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.InvoiceSeries;
  return proto.swift.invoicing.v1.InvoiceSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.InvoiceSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.InvoiceSeries}
 */
proto.swift.invoicing.v1.InvoiceSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSingleInvoiceSeries(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCollectiveInvoiceSeries(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreditInvoiceSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.InvoiceSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.InvoiceSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.InvoiceSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSingleInvoiceSeries();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCollectiveInvoiceSeries();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreditInvoiceSeries();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 branch_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.InvoiceSeries} returns this
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 single_invoice_series = 2;
 * @return {number}
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.getSingleInvoiceSeries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.InvoiceSeries} returns this
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.setSingleInvoiceSeries = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 collective_invoice_series = 3;
 * @return {number}
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.getCollectiveInvoiceSeries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.InvoiceSeries} returns this
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.setCollectiveInvoiceSeries = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 credit_invoice_series = 4;
 * @return {number}
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.getCreditInvoiceSeries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.InvoiceSeries} returns this
 */
proto.swift.invoicing.v1.InvoiceSeries.prototype.setCreditInvoiceSeries = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListInvoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListInvoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListInvoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.swift.invoicing.v1.InvoiceFilter.toObject(includeInstance, f),
    sorting: (f = msg.getSorting()) && proto.swift.invoicing.v1.InvoiceSorting.toObject(includeInstance, f),
    skipArticles: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListInvoicesRequest;
  return proto.swift.invoicing.v1.ListInvoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListInvoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.invoicing.v1.InvoiceFilter;
      reader.readMessage(value,proto.swift.invoicing.v1.InvoiceFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.invoicing.v1.InvoiceSorting;
      reader.readMessage(value,proto.swift.invoicing.v1.InvoiceSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipArticles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListInvoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListInvoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListInvoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.invoicing.v1.InvoiceFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.invoicing.v1.InvoiceSorting.serializeBinaryToWriter
    );
  }
  f = message.getSkipArticles();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest} returns this
*/
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest} returns this
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InvoiceFilter filter = 2;
 * @return {?proto.swift.invoicing.v1.InvoiceFilter}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.invoicing.v1.InvoiceFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.InvoiceFilter, 2));
};


/**
 * @param {?proto.swift.invoicing.v1.InvoiceFilter|undefined} value
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest} returns this
*/
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest} returns this
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional InvoiceSorting sorting = 3;
 * @return {?proto.swift.invoicing.v1.InvoiceSorting}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.invoicing.v1.InvoiceSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.InvoiceSorting, 3));
};


/**
 * @param {?proto.swift.invoicing.v1.InvoiceSorting|undefined} value
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest} returns this
*/
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest} returns this
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool skip_articles = 4;
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.getSkipArticles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.ListInvoicesRequest} returns this
 */
proto.swift.invoicing.v1.ListInvoicesRequest.prototype.setSkipArticles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.ListInvoicesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListInvoicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListInvoicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListInvoicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.swift.invoicing.v1.Invoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListInvoicesResponse}
 */
proto.swift.invoicing.v1.ListInvoicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListInvoicesResponse;
  return proto.swift.invoicing.v1.ListInvoicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListInvoicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListInvoicesResponse}
 */
proto.swift.invoicing.v1.ListInvoicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListInvoicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListInvoicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListInvoicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.ListInvoicesResponse} returns this
 */
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Invoice invoices = 2;
 * @return {!Array<!proto.swift.invoicing.v1.Invoice>}
 */
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Invoice, 2));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Invoice>} value
 * @return {!proto.swift.invoicing.v1.ListInvoicesResponse} returns this
*/
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.invoicing.v1.Invoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ListInvoicesResponse} returns this
 */
proto.swift.invoicing.v1.ListInvoicesResponse.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.swift.invoicing.v1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceRequest}
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateInvoiceRequest;
  return proto.swift.invoicing.v1.CreateInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceRequest}
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateInvoiceRequest} returns this
*/
proto.swift.invoicing.v1.CreateInvoiceRequest.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateInvoiceRequest.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.swift.invoicing.v1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceResponse}
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateInvoiceResponse;
  return proto.swift.invoicing.v1.CreateInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceResponse}
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateInvoiceResponse} returns this
*/
proto.swift.invoicing.v1.CreateInvoiceResponse.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceResponse} returns this
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateInvoiceResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.swift.invoicing.v1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceRequest}
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateInvoiceRequest;
  return proto.swift.invoicing.v1.UpdateInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceRequest}
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceRequest} returns this
*/
proto.swift.invoicing.v1.UpdateInvoiceRequest.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateInvoiceRequest.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.swift.invoicing.v1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceResponse}
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateInvoiceResponse;
  return proto.swift.invoicing.v1.UpdateInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceResponse}
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceResponse} returns this
*/
proto.swift.invoicing.v1.UpdateInvoiceResponse.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceResponse} returns this
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateInvoiceResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.IssueInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.IssueInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.IssueInvoiceRequest}
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.IssueInvoiceRequest;
  return proto.swift.invoicing.v1.IssueInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.IssueInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.IssueInvoiceRequest}
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.IssueInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.IssueInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.IssueInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.IssueInvoiceRequest.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.IssueInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.IssueInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.swift.invoicing.v1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.IssueInvoiceResponse}
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.IssueInvoiceResponse;
  return proto.swift.invoicing.v1.IssueInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.IssueInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.IssueInvoiceResponse}
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.IssueInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.IssueInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.invoicing.v1.IssueInvoiceResponse} returns this
*/
proto.swift.invoicing.v1.IssueInvoiceResponse.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.IssueInvoiceResponse} returns this
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.IssueInvoiceResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    invoicingEmailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest;
  return proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInvoiceIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addInvoicingEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getInvoicingEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated int64 invoice_ids = 1;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.getInvoiceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.setInvoiceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.addInvoiceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.clearInvoiceIdsList = function() {
  return this.setInvoiceIdsList([]);
};


/**
 * repeated string invoicing_emails = 2;
 * @return {!Array<string>}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.getInvoicingEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.setInvoicingEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.addInvoicingEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceRequest.prototype.clearInvoicingEmailsList = function() {
  return this.setInvoicingEmailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.swift.invoicing.v1.Invoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse;
  return proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Invoice invoices = 1;
 * @return {!Array<!proto.swift.invoicing.v1.Invoice>}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Invoice>} value
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse} returns this
*/
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.invoicing.v1.Invoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse} returns this
 */
proto.swift.invoicing.v1.IssueCollectiveInvoiceResponse.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invoiceNo: jspb.Message.getFieldWithDefault(msg, 2, 0),
    invoiceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateStatusRequest}
 */
proto.swift.invoicing.v1.UpdateStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateStatusRequest;
  return proto.swift.invoicing.v1.UpdateStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateStatusRequest}
 */
proto.swift.invoicing.v1.UpdateStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.invoicing.v1.Invoice.Status} */ (reader.readEnum());
      msg.setNewStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceNo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getInvoiceNo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional Invoice.Status new_status = 1;
 * @return {!proto.swift.invoicing.v1.Invoice.Status}
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.getNewStatus = function() {
  return /** @type {!proto.swift.invoicing.v1.Invoice.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice.Status} value
 * @return {!proto.swift.invoicing.v1.UpdateStatusRequest} returns this
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.setNewStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 invoice_no = 2;
 * @return {number}
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.getInvoiceNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.UpdateStatusRequest} returns this
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.setInvoiceNo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 invoice_id = 3;
 * @return {number}
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.UpdateStatusRequest} returns this
 */
proto.swift.invoicing.v1.UpdateStatusRequest.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateStatusResponse}
 */
proto.swift.invoicing.v1.UpdateStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateStatusResponse;
  return proto.swift.invoicing.v1.UpdateStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateStatusResponse}
 */
proto.swift.invoicing.v1.UpdateStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SendRemindersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SendRemindersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SendRemindersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SendRemindersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    remind: (f = msg.getRemind()) && proto.swift.invoicing.v1.Remind.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SendRemindersRequest}
 */
proto.swift.invoicing.v1.SendRemindersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SendRemindersRequest;
  return proto.swift.invoicing.v1.SendRemindersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SendRemindersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SendRemindersRequest}
 */
proto.swift.invoicing.v1.SendRemindersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Remind;
      reader.readMessage(value,proto.swift.invoicing.v1.Remind.deserializeBinaryFromReader);
      msg.setRemind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SendRemindersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SendRemindersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SendRemindersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SendRemindersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemind();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Remind.serializeBinaryToWriter
    );
  }
};


/**
 * optional Remind remind = 1;
 * @return {?proto.swift.invoicing.v1.Remind}
 */
proto.swift.invoicing.v1.SendRemindersRequest.prototype.getRemind = function() {
  return /** @type{?proto.swift.invoicing.v1.Remind} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Remind, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Remind|undefined} value
 * @return {!proto.swift.invoicing.v1.SendRemindersRequest} returns this
*/
proto.swift.invoicing.v1.SendRemindersRequest.prototype.setRemind = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.SendRemindersRequest} returns this
 */
proto.swift.invoicing.v1.SendRemindersRequest.prototype.clearRemind = function() {
  return this.setRemind(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.SendRemindersRequest.prototype.hasRemind = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.SendRemindersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SendRemindersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SendRemindersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SendRemindersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SendRemindersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.swift.invoicing.v1.Invoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SendRemindersResponse}
 */
proto.swift.invoicing.v1.SendRemindersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SendRemindersResponse;
  return proto.swift.invoicing.v1.SendRemindersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SendRemindersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SendRemindersResponse}
 */
proto.swift.invoicing.v1.SendRemindersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SendRemindersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SendRemindersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SendRemindersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SendRemindersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Invoice invoices = 1;
 * @return {!Array<!proto.swift.invoicing.v1.Invoice>}
 */
proto.swift.invoicing.v1.SendRemindersResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Invoice>} value
 * @return {!proto.swift.invoicing.v1.SendRemindersResponse} returns this
*/
proto.swift.invoicing.v1.SendRemindersResponse.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.SendRemindersResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.invoicing.v1.Invoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.SendRemindersResponse} returns this
 */
proto.swift.invoicing.v1.SendRemindersResponse.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.PreviewInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.PreviewInvoiceRequest}
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.PreviewInvoiceRequest;
  return proto.swift.invoicing.v1.PreviewInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.PreviewInvoiceRequest}
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.PreviewInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.PreviewInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.PreviewInvoiceRequest.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.PreviewInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.PreviewInvoiceResponse}
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.PreviewInvoiceResponse;
  return proto.swift.invoicing.v1.PreviewInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.PreviewInvoiceResponse}
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.PreviewInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.PreviewInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {string}
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.prototype.getFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.swift.invoicing.v1.PreviewInvoiceResponse} returns this
 */
proto.swift.invoicing.v1.PreviewInvoiceResponse.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest;
  return proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInvoiceIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 invoice_ids = 1;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.prototype.getInvoiceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.prototype.setInvoiceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.prototype.addInvoiceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceRequest.prototype.clearInvoiceIdsList = function() {
  return this.setInvoiceIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse;
  return proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {string}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.prototype.getFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse} returns this
 */
proto.swift.invoicing.v1.PreviewCollectiveInvoiceResponse.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteInvoiceRequest}
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteInvoiceRequest;
  return proto.swift.invoicing.v1.DeleteInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteInvoiceRequest}
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.DeleteInvoiceRequest} returns this
 */
proto.swift.invoicing.v1.DeleteInvoiceRequest.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteInvoiceResponse}
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteInvoiceResponse;
  return proto.swift.invoicing.v1.DeleteInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteInvoiceResponse}
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.DeleteInvoiceResponse} returns this
 */
proto.swift.invoicing.v1.DeleteInvoiceResponse.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceSeries: (f = msg.getInvoiceSeries()) && proto.swift.invoicing.v1.InvoiceSeries.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateInvoiceSeriesRequest;
  return proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.InvoiceSeries;
      reader.readMessage(value,proto.swift.invoicing.v1.InvoiceSeries.deserializeBinaryFromReader);
      msg.setInvoiceSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceSeries();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.InvoiceSeries.serializeBinaryToWriter
    );
  }
};


/**
 * optional InvoiceSeries invoice_series = 1;
 * @return {?proto.swift.invoicing.v1.InvoiceSeries}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.prototype.getInvoiceSeries = function() {
  return /** @type{?proto.swift.invoicing.v1.InvoiceSeries} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.InvoiceSeries, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.InvoiceSeries|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} returns this
*/
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.prototype.setInvoiceSeries = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesRequest} returns this
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.prototype.clearInvoiceSeries = function() {
  return this.setInvoiceSeries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesRequest.prototype.hasInvoiceSeries = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceSeries: (f = msg.getInvoiceSeries()) && proto.swift.invoicing.v1.InvoiceSeries.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateInvoiceSeriesResponse;
  return proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.InvoiceSeries;
      reader.readMessage(value,proto.swift.invoicing.v1.InvoiceSeries.deserializeBinaryFromReader);
      msg.setInvoiceSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceSeries();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.InvoiceSeries.serializeBinaryToWriter
    );
  }
};


/**
 * optional InvoiceSeries invoice_series = 1;
 * @return {?proto.swift.invoicing.v1.InvoiceSeries}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.prototype.getInvoiceSeries = function() {
  return /** @type{?proto.swift.invoicing.v1.InvoiceSeries} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.InvoiceSeries, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.InvoiceSeries|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse} returns this
*/
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.prototype.setInvoiceSeries = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateInvoiceSeriesResponse} returns this
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.prototype.clearInvoiceSeries = function() {
  return this.setInvoiceSeries(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateInvoiceSeriesResponse.prototype.hasInvoiceSeries = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invoiceNote: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateInvoiceNoteRequest;
  return proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNote(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInvoiceNote();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} returns this
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string invoice_note = 2;
 * @return {string}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.getInvoiceNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} returns this
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.setInvoiceNote = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_by = 3;
 * @return {string}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteRequest} returns this
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteRequest.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.swift.invoicing.v1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateInvoiceNoteResponse;
  return proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse} returns this
*/
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.UpdateInvoiceNoteResponse} returns this
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateInvoiceNoteResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toggle: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    pending: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateAutoInvoicingRequest;
  return proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToggle(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getToggle();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPending();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 invoice_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} returns this
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool toggle = 2;
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.getToggle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} returns this
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.setToggle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool pending = 3;
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.getPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingRequest} returns this
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingRequest.prototype.setPending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.swift.invoicing.v1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateAutoInvoicingResponse;
  return proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Invoice;
      reader.readMessage(value,proto.swift.invoicing.v1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.swift.invoicing.v1.Invoice}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.prototype.getInvoice = function() {
  return /** @type{?proto.swift.invoicing.v1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Invoice, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Invoice|undefined} value
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse} returns this
*/
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.prototype.setInvoice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.UpdateAutoInvoicingResponse} returns this
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.prototype.clearInvoice = function() {
  return this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateAutoInvoicingResponse.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.swift.invoicing.v1);
