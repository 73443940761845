import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { LoginOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'

import { Actions } from '../store/auth/actions'

import eliseComputerMobile from '../assets/images/elise-computer-illustration.png'
import eliseComputer from '../assets/images/elise-login.png'
import redIntereastLogo from '../assets/images/logo-intereast-red-full.png'
import './style.css'

export const Login = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'InterEast Elise'
  }, [])

  const handleLogin = () => {
    dispatch(Actions.logout())
    dispatch(Actions.login())
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-image-container">
          <img className="login-desktop-image" src={eliseComputer} alt="computer" />
          <img className="login-mobile-image" src={eliseComputerMobile} alt="computer" />
        </div>
        <div className="login-content">
          <img className="login-logo-intereast" src={redIntereastLogo} alt="intereast" />
          <div className="login-title">
            Welcome to <span className="login-elise-serif">Elise</span>
          </div>
          <p className="login-description">
            InterEast's platform Elise is a customer-first, next-generation platform packed with
            solutions for all your local and global shipping needs. It is a fully operational
            platform, currently being developed by our in-house engineering team, backed by the
            organization's thirty-year industry experience.
          </p>
          <div>
            <Button
              className="login-button"
              icon={<LoginOutlined />}
              disabled={window.env.ENVIRONMENT === 'unknown'}
              onClick={handleLogin}
            >
              Login
            </Button>
          </div>
          <Divider className="login-divider">or</Divider>
          <p className="login-email">
            If you wish to request a demo or an account, please reach out to us at{' '}
            <a className="login-email-link" href={`mailto:elise@intereast.se`}>
              elise@intereast.se
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
