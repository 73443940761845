import * as commonProvider from '../../../proto/common/provider_pb'
import * as articleTypev1 from '../../../proto/invoicing/v1/articletype_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as articleTypes from '../../../types/articleType'

export const LIST_ARTICLE_TYPES_REQ = 'swift2/articleType/LIST_ARTICLE_TYPES_REQ'
export const LIST_ARTICLE_TYPES_RESP = 'swift2/articleType/LIST_ARTICLE_TYPES_RESP'
export const LIST_ARTICLE_TYPES_ERR = 'swift2/articleType/LIST_ARTICLE_TYPES_ERR'
export const LIST_ACCOUNTING_NUMBERS_REQ = 'swift2/articleType/LIST_ACCOUNTING_NUMBERS_REQ'
export const LIST_ACCOUNTING_NUMBERS_RESP = 'swift2/articleType/LIST_ACCOUNTING_NUMBERS_RESP'
export const LIST_ACCOUNTING_NUMBERS_ERR = 'swift2/articleType/LIST_ACCOUNTING_NUMBERS_ERR'

export const CREATE_ARTICLE_TYPE_REQ = 'swift2/articleType/CREATE_ARTICLE_TYPE_REQ'
export const CREATE_ARTICLE_TYPE_RESP = 'swift2/articleType/CREATE_ARTICLE_TYPE_RESP'
export const CREATE_ARTICLE_TYPE_ERR = 'swift2/articleType/CREATE_ARTICLE_TYPE_ERR'
export const SET_ACCOUNTING_NUMBER_REQ = 'swift2/articleType/SET_ACCOUNTING_NUMBER_REQ'
export const SET_ACCOUNTING_NUMBER_RESP = 'swift2/articleType/SET_ACCOUNTING_NUMBER_RESP'
export const SET_ACCOUNTING_NUMBER_ERR = 'swift2/articleType/SET_ACCOUNTING_NUMBER_ERR'

export const UPDATE_ARTICLE_TYPE_REQ = 'swift2/articleType/UPDATE_ARTICLE_TYPE_REQ'
export const UPDATE_ARTICLE_TYPE_RESP = 'swift2/articleType/UPDATE_ARTICLE_TYPE_RESP'
export const UPDATE_ARTICLE_TYPE_ERR = 'swift2/articleType/UPDATE_ARTICLE_TYPE_ERR'

export const DELETE_ARTICLE_TYPE_REQ = 'swift2/articleType/DELETE_ARTICLE_TYPE_REQ'
export const DELETE_ARTICLE_TYPE_RESP = 'swift2/articleType/DELETE_ARTICLE_TYPE_RESP'
export const DELETE_ARTICLE_TYPE_ERR = 'swift2/articleType/DELETE_ARTICLE_TYPE_ERR'
export const DELETE_ACCOUNTING_NUMBER_REQ = 'swift2/articleType/DELETE_ACCOUNTING_NUMBER_REQ'
export const DELETE_ACCOUNTING_NUMBER_RESP = 'swift2/articleType/DELETE_ACCOUNTING_NUMBER_RESP'
export const DELETE_ACCOUNTING_NUMBER_ERR = 'swift2/articleType/DELETE_ACCOUNTING_NUMBER_ERR'

export const Actions = {
  listArticleTypesReq: (
    page: articleTypes.ArticleTypePage,
    branchCountryID?: string,
    customerCountryID?: string,
    fetchAccounting?: boolean,
  ) =>
    createAction(LIST_ARTICLE_TYPES_REQ, {
      page,
      branchCountryID,
      customerCountryID,
      fetchAccounting,
    }),
  listArticleTypesResp: (
    articleTypes: articleTypev1.ArticleType[],
    count: number,
    page: articleTypes.ArticleTypePage,
  ) => createAction(LIST_ARTICLE_TYPES_RESP, { articleTypes, count, page }),
  listArticleTypesErr: (err: Error) => createAction(LIST_ARTICLE_TYPES_ERR, { err }),
  listAccountingNumbersReq: () => createAction(LIST_ACCOUNTING_NUMBERS_REQ),
  listAccountingNumbersResp: (accountingNumbers: commonProvider.AccountingNumber[]) =>
    createAction(LIST_ACCOUNTING_NUMBERS_RESP, { accountingNumbers }),
  listAccountingNumbersErr: (err: Error) => createAction(LIST_ACCOUNTING_NUMBERS_ERR, { err }),

  createArticleTypeReq: (
    accountingArticle: articleTypes.AccountingArticle,
    page: articleTypes.ArticleTypePage,
  ) => createAction(CREATE_ARTICLE_TYPE_REQ, { accountingArticle, page }),
  createArticleTypeResp: (
    articleType: articleTypev1.ArticleType,
    page: articleTypes.ArticleTypePage,
  ) => createAction(CREATE_ARTICLE_TYPE_RESP, { articleType, page }),
  createArticleTypeErr: (err: Error) => createAction(CREATE_ARTICLE_TYPE_ERR, { err }),
  setAccountingNumberReq: (accountingNumber: commonProvider.AccountingNumber) =>
    createAction(SET_ACCOUNTING_NUMBER_REQ, { accountingNumber }),
  setAccountingNumberResp: (accountingNumber: commonProvider.AccountingNumber) =>
    createAction(SET_ACCOUNTING_NUMBER_RESP, { accountingNumber }),
  setAccountingNumberErr: (err: Error) => createAction(SET_ACCOUNTING_NUMBER_ERR, { err }),

  updateArticleTypeReq: (accountingArticle: articleTypes.AccountingArticle) =>
    createAction(UPDATE_ARTICLE_TYPE_REQ, { accountingArticle }),
  updateArticleTypeResp: (articleType: articleTypev1.ArticleType) =>
    createAction(UPDATE_ARTICLE_TYPE_RESP, { articleType }),
  updateArticleTypeErr: (err: Error) => createAction(UPDATE_ARTICLE_TYPE_ERR, { err }),

  deleteArticleTypeReq: (articleNo: number) => createAction(DELETE_ARTICLE_TYPE_REQ, { articleNo }),
  deleteArticleTypeResp: (articleNo: number) =>
    createAction(DELETE_ARTICLE_TYPE_RESP, { articleNo }),
  deleteArticleTypeErr: (err: Error) => createAction(DELETE_ARTICLE_TYPE_ERR, { err }),
  deleteAccountingNumberReq: (articleNo: number) =>
    createAction(DELETE_ACCOUNTING_NUMBER_REQ, { articleNo }),
  deleteAccountingNumberResp: (articleNo: number) =>
    createAction(DELETE_ACCOUNTING_NUMBER_RESP, { articleNo }),
  deleteAccountingNumberErr: (err: Error) => createAction(DELETE_ACCOUNTING_NUMBER_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
