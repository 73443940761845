import * as React from 'react'

import { Tag } from 'antd'
import { TagProps } from 'antd/lib/tag'

import * as orderv1 from '../../proto/order/v1/order_pb'

import { statusColors, statusLabels } from '../../helpers/order'

interface Props extends TagProps {
  status: orderv1.Order.Status
}

export const OrderStatusTag: React.FC<Props> = ({ status, ...rest }) => {
  return (
    <Tag color={statusColors[status]} {...rest}>
      {statusLabels[status]}
    </Tag>
  )
}
