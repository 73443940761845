import * as costv1 from '../../../proto/invoicing/v1/cost_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as costType from '../../../types/cost'

export const LIST_REQ = 'swift2/cost/LIST_REQ'
export const LIST_RESP = 'swift2/cost/LIST_RESP'
export const LIST_ERR = 'swift2/cost/LIST_ERR'
export const SET_BOOKING_COSTS_REQ = 'swift2/cost/SET_BOOKING_COSTS_REQ'
export const SET_BOOKING_COSTS_RESP = 'swift2/cost/SET_BOOKING_COSTS_RESP'
export const SET_BOOKING_COSTS_ERR = 'swift2/cost/SET_BOOKING_COSTS_ERR'
export const SET_COST_STATUS_REQ = 'swift2/cost/SET_COST_STATUS_REQ'
export const SET_COST_STATUS_RESP = 'swift2/cost/SET_COST_STATUS_RESP'
export const SET_COST_STATUS_ERR = 'swift2/cost/SET_COST_STATUS_ERR'

export const Actions = {
  listCostsReq: (page: costType.CostPage) => createAction(LIST_REQ, { page }),
  listCostsResp: (count: number, costs: costv1.Cost[], page: costType.CostPage) =>
    createAction(LIST_RESP, { count, costs, page }),
  listCostsErr: (err: Error) => createAction(LIST_ERR, { err }),
  setBookingCostsReq: (orgCurrency: string, costs: costv1.Cost[]) =>
    createAction(SET_BOOKING_COSTS_REQ, { orgCurrency, costs }),
  setBookingCostsResp: (costs: costv1.Cost[]) => createAction(SET_BOOKING_COSTS_RESP, { costs }),
  setBookingCostsErr: (err: Error) => createAction(SET_BOOKING_COSTS_ERR, { err }),
  setCostStatusReq: (costId: number, status: costv1.Cost.Status, comment: string) =>
    createAction(SET_COST_STATUS_REQ, { costId, status, comment }),
  setCostStatusResp: (cost: costv1.Cost) => createAction(SET_COST_STATUS_RESP, { cost }),
  setCostStatusErr: (err: Error) => createAction(SET_COST_STATUS_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
