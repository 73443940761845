import * as React from 'react'

import Icon from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'

import * as commonBooking from '../../proto/common/booking_pb'

import { TransportModeType, modeEnumToModeType } from '../../helpers/booking'

import * as Icons from '../icons'

interface Props {
  readonly mode?: TransportModeType
  readonly modeEnum?: commonBooking.TransportMode
  readonly forModesList?: Array<commonBooking.TransportMode>
  readonly withText?: boolean
  readonly textOnly?: boolean
  readonly asTag?: boolean
  readonly tagText?: string
  readonly iconStyle?: React.CSSProperties
  readonly verticalCenterIconAndText?: boolean
  readonly labelClassName?: string
}

// Component to display transport modes.
export const TransportMode: React.FC<Props> = ({
  mode,
  modeEnum,
  forModesList,
  withText,
  textOnly,
  asTag,
  tagText,
  iconStyle,
  verticalCenterIconAndText,
  labelClassName,
}) => {
  let icon = Icons.Box
  let label = 'Unspecified'
  let color = ''

  if (!mode && forModesList && forModesList.length === 1) {
    mode = modeEnumToModeType[forModesList[0]]
  } else if (!mode && modeEnum !== undefined) {
    mode = modeEnumToModeType[modeEnum]
  }

  if (mode) {
    switch (mode) {
      case 'Air':
        icon = Icons.Air
        label = 'By Air'
        color = 'cyan'
        break
      case 'Sea':
        icon = Icons.Sea
        label = 'By Sea'
        color = 'blue'
        break
      case 'Rail':
        icon = Icons.Rail
        label = 'By Rail'
        color = 'orange'
        break
      case 'Road':
        icon = Icons.Road
        label = 'By Road'
        color = 'green'
        break
      case 'Courier':
        icon = Icons.Courier
        label = 'By Courier'
        color = 'magenta'
        break
      case 'Unspecified':
        icon = Icons.Box
    }
  } else if (forModesList && forModesList.length > 0) {
    label = 'By ' + forModesList.map((m) => modeEnumToModeType[m]).join(', ')
  }

  return asTag ? (
    <Tooltip title={withText || textOnly ? '' : tagText ? tagText : label}>
      <Tag color={color}>
        {withText && !textOnly ? <span style={{ paddingLeft: '5px' }}>{label}</span> : null}
        {textOnly ? <span>{label}</span> : <Icon component={icon} />}
      </Tag>
    </Tooltip>
  ) : (
    <Tooltip title={withText || textOnly ? '' : tagText ? tagText : label}>
      <span
        style={
          verticalCenterIconAndText ? { display: 'inline-flex', alignItems: 'center' } : undefined
        }
      >
        {textOnly ? (
          <span>{label}</span>
        ) : (
          <Icon component={icon} style={{ fontSize: '1.4em', color: '#44474f', ...iconStyle }} />
        )}
        {withText && !textOnly ? (
          <span style={{ paddingLeft: '5px' }} className={labelClassName}>
            {label}
          </span>
        ) : null}
      </span>
    </Tooltip>
  )
}
