import React from 'react'

import { Button } from 'antd'

interface PrimaryButtonProps {
  label: string | number
  disabled?: boolean
  onClick?: () => void
  htmlType?: 'button' | 'submit' | 'reset' | undefined
  loading?: boolean
}

/**
 * Usage: Primary actions that carries out important actions, such as save or submit.
 */
export const PrimaryButton = ({
  label,
  disabled,
  onClick,
  htmlType,
  loading,
}: PrimaryButtonProps) => {
  return (
    <Button
      type="primary"
      htmlType={htmlType}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
    >
      {label}
    </Button>
  )
}
