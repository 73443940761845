import { TableColumns } from './TableComponent'

const COLUMNTYPES = {
  numberColumn: 'numberColumn',
  textColumn: 'textColumn',
  dateTimeColumn: 'dateTimeColumn',
  customActions: 'customActions',
  actionRight: 'actionRight',
} as const
type ColumnType = (typeof COLUMNTYPES)[keyof typeof COLUMNTYPES]
type ColumnStyling = { align?: 'left' | 'right' | 'center'; width?: string }

const columnStyleMap: Map<ColumnType | 'default', ColumnStyling> = new Map([
  [COLUMNTYPES.numberColumn, { align: 'right', width: '10ch' }],
  [COLUMNTYPES.dateTimeColumn, { width: '20ch' }],
  [COLUMNTYPES.textColumn, { width: 'auto' }],
  [COLUMNTYPES.customActions, { align: 'left', width: '10ch' }],
  [COLUMNTYPES.actionRight, { align: 'right' }],
  ['default', { width: 'auto' }],
])

const getSpecificColumnTypeTextAlignment = <T>(column: TableColumns<T>) => {
  return columnStyleMap.get(column.columnType || 'default')?.align
}

const getColumnWidthDependingOnColumnType = <T>(column: TableColumns<T>) => {
  return columnStyleMap.get(column.columnType || 'default')?.width
}

export type { ColumnType }
export { getSpecificColumnTypeTextAlignment, getColumnWidthDependingOnColumnType, COLUMNTYPES }
