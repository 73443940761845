import * as costv1 from '../../../proto/invoicing/v1/cost_pb'

import { RootState } from '../../../store/reducer'

import * as costTypes from '../../../types/cost'

import * as actions from './actions'

export interface State {
  readonly pages: Map<costTypes.CostPage, Array<number>>
  readonly items: { [key: string]: costv1.Cost }
  readonly count: number
  readonly isFetching: boolean
  readonly err?: Error
}

const initialState: State = {
  pages: new Map(),
  items: {},
  count: 0,
  isFetching: false,
  err: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.invoicing.cost.items).map((id) => state.invoicing.cost.items[id])
}

export const getCount = (state: RootState) => state.invoicing.cost.count

export const getPageItems = (state: RootState, page: costTypes.CostPage) => {
  const items = state.invoicing.cost.items
  const pageItems = state.invoicing.cost.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<costv1.Cost>)
}

export const getItemsById = (state: RootState) => {
  return state.invoicing.cost.items
}

export const getItemsByBookingRef = (state: RootState, bookingRef: string) => {
  return getItems(state).filter((i) => i.getBookingRef() === bookingRef)
}

export const getItemsByShipmentRef = (state: RootState, shipmentRef: string) => {
  return getItems(state).filter((i) => i.getShipmentRef() === shipmentRef)
}
export const getIsFetching = (state: RootState) => state.invoicing.cost.isFetching

export const getErr = (state: RootState) => state.invoicing.cost.err

// Uses some explicit declarations that values cannot be null to simplify logic.
// Relies on insert checks always happening via this function
function mergeItems(
  existingItems: ReadonlyArray<costv1.Cost>,
  newItems: ReadonlyArray<costv1.Cost>,
): ReadonlyArray<costv1.Cost> {
  const existing: { [key: string]: costv1.Cost } = existingItems.reduce((acc, curr) => {
    return { ...acc, [curr.getCostId()]: curr }
  }, {})

  const received: { [key: string]: costv1.Cost } = newItems.reduce((acc, curr) => {
    return { ...acc, [curr.getCostId()]: curr }
  }, {})
  const result: { [key: string]: costv1.Cost } = { ...existing, ...received }
  return Object.values(result)
}

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, costs, page } = action.payload

      const newItems = costs.reduce<{ [key: string]: costv1.Cost }>((map, r) => {
        map[r.getCostId()] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }

      const pageItems = costs.map((i) => i.getCostId())

      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })

      pages.set(page, pageItems)
      return { ...s, items, count, isFetching: false, pages }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_BOOKING_COSTS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_BOOKING_COSTS_RESP: {
      const { costs } = action.payload
      const items = { ...s.items }
      costs.forEach((i) => {
        items[i.getCostId()] = i
      })
      return { ...s, items, isFetching: false }
    }

    case actions.SET_BOOKING_COSTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_COST_STATUS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_COST_STATUS_RESP: {
      const { cost } = action.payload
      const newItems = mergeItems(Object.values(s.items), [cost])
      const items = { ...s.items }
      newItems.forEach((i) => {
        items[i.getCostId()] = i
      })

      return { ...s, items: items, isFetching: false }
    }

    case actions.SET_COST_STATUS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
