import * as orderv1 from '../proto/order/v1/order_pb'

type OrderTypeString = 'Purchase' | 'Return'

export const orderType: {
  [key in orderv1.OrderType]: OrderTypeString
} = {
  [orderv1.OrderType.PURCHASE]: 'Purchase',
  [orderv1.OrderType.RETURN]: 'Return',
}

export const statusColors: { [key in orderv1.Order.Status]: string } = {
  [orderv1.Order.Status.NEW]: 'blue',
  [orderv1.Order.Status.SUBMITTED]: 'blue',
  [orderv1.Order.Status.CHECKED]: 'orange',
  [orderv1.Order.Status.APPROVED]: 'purple',
  [orderv1.Order.Status.COMPLETED]: 'green',
}

export const statusLabels: { [key in orderv1.Order.Status]: string } = {
  [orderv1.Order.Status.NEW]: 'New',
  [orderv1.Order.Status.SUBMITTED]: 'Submitted',
  [orderv1.Order.Status.CHECKED]: 'Checked',
  [orderv1.Order.Status.APPROVED]: 'Approved',
  [orderv1.Order.Status.COMPLETED]: 'Completed',
}

export const statusColorAndLabel: {
  [key in orderv1.Order.Status]: { color: string; text: string }
} = {
  [orderv1.Order.Status.NEW]: {
    color: statusColors[orderv1.Order.Status.NEW],
    text: statusLabels[orderv1.Order.Status.NEW],
  },
  [orderv1.Order.Status.SUBMITTED]: {
    color: statusColors[orderv1.Order.Status.SUBMITTED],
    text: statusLabels[orderv1.Order.Status.SUBMITTED],
  },
  [orderv1.Order.Status.CHECKED]: {
    color: statusColors[orderv1.Order.Status.CHECKED],
    text: statusLabels[orderv1.Order.Status.CHECKED],
  },
  [orderv1.Order.Status.APPROVED]: {
    color: statusColors[orderv1.Order.Status.APPROVED],
    text: statusLabels[orderv1.Order.Status.APPROVED],
  },
  [orderv1.Order.Status.COMPLETED]: {
    color: statusColors[orderv1.Order.Status.COMPLETED],
    text: statusLabels[orderv1.Order.Status.COMPLETED],
  },
}

export function nextPurchaseOrderStatus(
  status: orderv1.Order.Status,
): orderv1.Order.Status | undefined {
  const mappings = {
    [orderv1.Order.Status.NEW]: orderv1.Order.Status.SUBMITTED,
    [orderv1.Order.Status.SUBMITTED]: orderv1.Order.Status.APPROVED,
    [orderv1.Order.Status.CHECKED]: undefined,
    [orderv1.Order.Status.APPROVED]: undefined, //Completed status is performed via booking creation
    [orderv1.Order.Status.COMPLETED]: undefined,
  }
  return mappings[status] || undefined
}

export function latestOfStatus(
  events: ReadonlyArray<orderv1.OrderEvent>,
  status: orderv1.Order.Status,
): orderv1.OrderEvent | undefined {
  return events
    .filter((event) => {
      const poEvent = event.getPurchaseOrderEvent()
      return !!(poEvent && poEvent.getStatus() === status)
    })
    .slice(-1)[0]
}

export function validateOrder(order: orderv1.Order): string[] {
  const invalidFields: string[] = []
  if (order.getOwner() === undefined || order.getOwner()?.getOrganizationId() === 0) {
    invalidFields.push('You must select an organization')
  }

  if (order.getSender() === undefined) {
    invalidFields.push('You must select a sender')
  }
  if (order.getReceiver() === undefined) {
    invalidFields.push('You must select a receiver')
  }

  if (order.getItemsList().length === 0) {
    invalidFields.push('You must add items')
  }
  return invalidFields
}
