import { RootState } from '../../reducer'
import * as actions from './actions'

export interface State {
  readonly err?: Error
  readonly isFetching: boolean
  readonly isJoinCodeValid?: boolean
  readonly shouldCreateUsergroup?: boolean
}

const initialState: State = {
  err: undefined,
  isFetching: false,
}

export const getErr = (state: RootState) => state.iam.signup.err
export const getIsFetching = (state: RootState) => state.iam.signup.isFetching
export const getIsJoinCodevalid = (state: RootState) => state.iam.signup.isJoinCodeValid
export const getShouldCreateUsergroup = (state: RootState) => state.iam.signup.shouldCreateUsergroup

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.VALIDATE_JOIN_CODE_INPUT: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.VALIDATE_JOIN_CODE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.VALIDATE_JOIN_CODE_RESP: {
      const { validation } = action.payload
      return {
        ...s,
        isJoinCodeValid: validation.getIsValid(),
        shouldCreateUsergroup: validation.getOnSignupCreateUsergroup(),
        isFetching: false,
      }
    }

    case actions.VALIDATE_JOIN_CODE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SIGNUP_USER_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SIGNUP_USER_RESP: {
      return { ...s, isFetching: false }
    }

    case actions.SIGNUP_USER_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CLEAN_SIGNUP_USER_ERR: {
      return { ...s, err: undefined, isFetching: false }
    }

    default:
      return s
  }
}
