import { combineReducers } from 'redux'

import { State as BookingState, default as booking } from './booking/reducer'
import {
  State as BookingTemplateState,
  default as bookingTemplate,
} from './bookingtemplate/reducer'
import { State as DeviationState, default as deviation } from './deviation/reducer'
import { State as NominationState, default as nomination } from './nomination/reducer'
import { State as PricingState, default as pricing } from './pricing/reducer'
import { State as SupplierState, default as supplier } from './supplier/reducer'

export interface State {
  pricing: PricingState
  booking: BookingState
  bookingTemplate: BookingTemplateState
  supplier: SupplierState
  nomination: NominationState
  deviation: DeviationState
}

export default combineReducers<State>({
  booking,
  bookingTemplate,
  supplier,
  nomination,
  deviation,
  pricing,
} as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.
