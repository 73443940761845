// source: crm/v1/address.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_geolocation_pb = require('../../common/geolocation_pb.js');
goog.object.extend(proto, common_geolocation_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var crm_v1_contact_pb = require('../../crm/v1/contact_pb.js');
goog.object.extend(proto, crm_v1_contact_pb);
goog.exportSymbol('proto.swift.crm.v1.Address', null, global);
goog.exportSymbol('proto.swift.crm.v1.Address.Usage', null, global);
goog.exportSymbol('proto.swift.crm.v1.AddressFilter', null, global);
goog.exportSymbol('proto.swift.crm.v1.AddressSorting', null, global);
goog.exportSymbol('proto.swift.crm.v1.AddressSorting.Field', null, global);
goog.exportSymbol('proto.swift.crm.v1.AddressSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.crm.v1.CreateAddressRequest', null, global);
goog.exportSymbol('proto.swift.crm.v1.CreateAddressResponse', null, global);
goog.exportSymbol('proto.swift.crm.v1.DeleteAddressRequest', null, global);
goog.exportSymbol('proto.swift.crm.v1.DeleteAddressResponse', null, global);
goog.exportSymbol('proto.swift.crm.v1.GetAddressRequest', null, global);
goog.exportSymbol('proto.swift.crm.v1.GetAddressResponse', null, global);
goog.exportSymbol('proto.swift.crm.v1.ListAddressesRequest', null, global);
goog.exportSymbol('proto.swift.crm.v1.ListAddressesResponse', null, global);
goog.exportSymbol('proto.swift.crm.v1.UpdateAddressRequest', null, global);
goog.exportSymbol('proto.swift.crm.v1.UpdateAddressResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.crm.v1.Address.repeatedFields_, null);
};
goog.inherits(proto.swift.crm.v1.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.Address.displayName = 'proto.swift.crm.v1.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.AddressFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.crm.v1.AddressFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.crm.v1.AddressFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.AddressFilter.displayName = 'proto.swift.crm.v1.AddressFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.AddressSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.AddressSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.AddressSorting.displayName = 'proto.swift.crm.v1.AddressSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.ListAddressesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.ListAddressesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.ListAddressesRequest.displayName = 'proto.swift.crm.v1.ListAddressesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.ListAddressesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.crm.v1.ListAddressesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.crm.v1.ListAddressesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.ListAddressesResponse.displayName = 'proto.swift.crm.v1.ListAddressesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.GetAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.GetAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.GetAddressRequest.displayName = 'proto.swift.crm.v1.GetAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.GetAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.GetAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.GetAddressResponse.displayName = 'proto.swift.crm.v1.GetAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.CreateAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.CreateAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.CreateAddressRequest.displayName = 'proto.swift.crm.v1.CreateAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.CreateAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.CreateAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.CreateAddressResponse.displayName = 'proto.swift.crm.v1.CreateAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.UpdateAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.UpdateAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.UpdateAddressRequest.displayName = 'proto.swift.crm.v1.UpdateAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.UpdateAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.UpdateAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.UpdateAddressResponse.displayName = 'proto.swift.crm.v1.UpdateAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.DeleteAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.DeleteAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.DeleteAddressRequest.displayName = 'proto.swift.crm.v1.DeleteAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.crm.v1.DeleteAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.crm.v1.DeleteAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.crm.v1.DeleteAddressResponse.displayName = 'proto.swift.crm.v1.DeleteAddressResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.crm.v1.Address.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address3: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    county: jspb.Message.getFieldWithDefault(msg, 9, ""),
    countryId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    geolocation: (f = msg.getGeolocation()) && common_geolocation_pb.Geolocation.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    usageList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    contact: (f = msg.getContact()) && crm_v1_contact_pb.Contact.toObject(includeInstance, f),
    internalInstruction: jspb.Message.getFieldWithDefault(msg, 15, ""),
    externalInstruction: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.Address;
  return proto.swift.crm.v1.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddressId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress3(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounty(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryId(value);
      break;
    case 11:
      var value = new common_geolocation_pb.Geolocation;
      reader.readMessage(value,common_geolocation_pb.Geolocation.deserializeBinaryFromReader);
      msg.setGeolocation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 13:
      var values = /** @type {!Array<!proto.swift.crm.v1.Address.Usage>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsage(values[i]);
      }
      break;
    case 14:
      var value = new crm_v1_contact_pb.Contact;
      reader.readMessage(value,crm_v1_contact_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalInstruction(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalInstruction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress3();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCounty();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountryId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getGeolocation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_geolocation_pb.Geolocation.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUsageList();
  if (f.length > 0) {
    writer.writePackedEnum(
      13,
      f
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      crm_v1_contact_pb.Contact.serializeBinaryToWriter
    );
  }
  f = message.getInternalInstruction();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getExternalInstruction();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.crm.v1.Address.Usage = {
  SENDER: 0,
  RECEIVER: 1,
  ORDER_PURCHASE: 2,
  ORDER_RETURN: 3,
  ORGANIZATION: 4,
  INVOICE: 5
};

/**
 * optional int64 address_id = 1;
 * @return {number}
 */
proto.swift.crm.v1.Address.prototype.getAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.crm.v1.Address.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.crm.v1.Address} returns this
*/
proto.swift.crm.v1.Address.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.Address.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address1 = 4;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address2 = 5;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address3 = 6;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getAddress3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setAddress3 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postalcode = 7;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string county = 9;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getCounty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setCounty = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country_id = 10;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getCountryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setCountryId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional swift.common.Geolocation geolocation = 11;
 * @return {?proto.swift.common.Geolocation}
 */
proto.swift.crm.v1.Address.prototype.getGeolocation = function() {
  return /** @type{?proto.swift.common.Geolocation} */ (
    jspb.Message.getWrapperField(this, common_geolocation_pb.Geolocation, 11));
};


/**
 * @param {?proto.swift.common.Geolocation|undefined} value
 * @return {!proto.swift.crm.v1.Address} returns this
*/
proto.swift.crm.v1.Address.prototype.setGeolocation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.clearGeolocation = function() {
  return this.setGeolocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.Address.prototype.hasGeolocation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string timezone = 12;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated Usage usage = 13;
 * @return {!Array<!proto.swift.crm.v1.Address.Usage>}
 */
proto.swift.crm.v1.Address.prototype.getUsageList = function() {
  return /** @type {!Array<!proto.swift.crm.v1.Address.Usage>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<!proto.swift.crm.v1.Address.Usage>} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setUsageList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {!proto.swift.crm.v1.Address.Usage} value
 * @param {number=} opt_index
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.addUsage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.clearUsageList = function() {
  return this.setUsageList([]);
};


/**
 * optional Contact contact = 14;
 * @return {?proto.swift.crm.v1.Contact}
 */
proto.swift.crm.v1.Address.prototype.getContact = function() {
  return /** @type{?proto.swift.crm.v1.Contact} */ (
    jspb.Message.getWrapperField(this, crm_v1_contact_pb.Contact, 14));
};


/**
 * @param {?proto.swift.crm.v1.Contact|undefined} value
 * @return {!proto.swift.crm.v1.Address} returns this
*/
proto.swift.crm.v1.Address.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.Address.prototype.hasContact = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string internal_instruction = 15;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getInternalInstruction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setInternalInstruction = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string external_instruction = 16;
 * @return {string}
 */
proto.swift.crm.v1.Address.prototype.getExternalInstruction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.Address} returns this
 */
proto.swift.crm.v1.Address.prototype.setExternalInstruction = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.crm.v1.AddressFilter.repeatedFields_ = [1,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.AddressFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.AddressFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.AddressFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.AddressFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    usageList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    addressIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.AddressFilter}
 */
proto.swift.crm.v1.AddressFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.AddressFilter;
  return proto.swift.crm.v1.AddressFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.AddressFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.AddressFilter}
 */
proto.swift.crm.v1.AddressFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.swift.crm.v1.Address.Usage>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsage(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAddressId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.AddressFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.AddressFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.AddressFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.AddressFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsageList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getAddressIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
};


/**
 * repeated Address.Usage usage = 1;
 * @return {!Array<!proto.swift.crm.v1.Address.Usage>}
 */
proto.swift.crm.v1.AddressFilter.prototype.getUsageList = function() {
  return /** @type {!Array<!proto.swift.crm.v1.Address.Usage>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.swift.crm.v1.Address.Usage>} value
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.setUsageList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.swift.crm.v1.Address.Usage} value
 * @param {number=} opt_index
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.addUsage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.clearUsageList = function() {
  return this.setUsageList([]);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.swift.crm.v1.AddressFilter.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int64 organization_id = 3;
 * @return {!Array<number>}
 */
proto.swift.crm.v1.AddressFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated int64 address_id = 4;
 * @return {!Array<number>}
 */
proto.swift.crm.v1.AddressFilter.prototype.getAddressIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.setAddressIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.addAddressId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.crm.v1.AddressFilter} returns this
 */
proto.swift.crm.v1.AddressFilter.prototype.clearAddressIdList = function() {
  return this.setAddressIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.AddressSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.AddressSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.AddressSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.AddressSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.AddressSorting}
 */
proto.swift.crm.v1.AddressSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.AddressSorting;
  return proto.swift.crm.v1.AddressSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.AddressSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.AddressSorting}
 */
proto.swift.crm.v1.AddressSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.crm.v1.AddressSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.crm.v1.AddressSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.AddressSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.AddressSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.AddressSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.AddressSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.crm.v1.AddressSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.crm.v1.AddressSorting.Field = {
  ADDRESS_ID: 0,
  NAME: 1,
  ADDRESS: 2,
  POSTAL_CODE: 3,
  CITY: 4,
  COUNTY: 5,
  COUNTRY: 6,
  CONTACT_NAME: 7,
  CONTACT_EMAIL: 8,
  CONTACT_PHONE: 9,
  ORGANIZATION: 10
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.crm.v1.AddressSorting.Field}
 */
proto.swift.crm.v1.AddressSorting.prototype.getField = function() {
  return /** @type {!proto.swift.crm.v1.AddressSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.crm.v1.AddressSorting.Field} value
 * @return {!proto.swift.crm.v1.AddressSorting} returns this
 */
proto.swift.crm.v1.AddressSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.crm.v1.AddressSorting.Ordering}
 */
proto.swift.crm.v1.AddressSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.crm.v1.AddressSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.crm.v1.AddressSorting.Ordering} value
 * @return {!proto.swift.crm.v1.AddressSorting} returns this
 */
proto.swift.crm.v1.AddressSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.ListAddressesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.ListAddressesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.ListAddressesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.swift.crm.v1.AddressFilter.toObject(includeInstance, f),
    sorting: (f = msg.getSorting()) && proto.swift.crm.v1.AddressSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.ListAddressesRequest}
 */
proto.swift.crm.v1.ListAddressesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.ListAddressesRequest;
  return proto.swift.crm.v1.ListAddressesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.ListAddressesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.ListAddressesRequest}
 */
proto.swift.crm.v1.ListAddressesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.crm.v1.AddressFilter;
      reader.readMessage(value,proto.swift.crm.v1.AddressFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.crm.v1.AddressSorting;
      reader.readMessage(value,proto.swift.crm.v1.AddressSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.ListAddressesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.ListAddressesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.ListAddressesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.crm.v1.AddressFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.crm.v1.AddressSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.crm.v1.ListAddressesRequest} returns this
*/
proto.swift.crm.v1.ListAddressesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.ListAddressesRequest} returns this
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddressFilter filter = 2;
 * @return {?proto.swift.crm.v1.AddressFilter}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.crm.v1.AddressFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.crm.v1.AddressFilter, 2));
};


/**
 * @param {?proto.swift.crm.v1.AddressFilter|undefined} value
 * @return {!proto.swift.crm.v1.ListAddressesRequest} returns this
*/
proto.swift.crm.v1.ListAddressesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.ListAddressesRequest} returns this
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AddressSorting sorting = 3;
 * @return {?proto.swift.crm.v1.AddressSorting}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.crm.v1.AddressSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.crm.v1.AddressSorting, 3));
};


/**
 * @param {?proto.swift.crm.v1.AddressSorting|undefined} value
 * @return {!proto.swift.crm.v1.ListAddressesRequest} returns this
*/
proto.swift.crm.v1.ListAddressesRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.ListAddressesRequest} returns this
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.ListAddressesRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.crm.v1.ListAddressesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.ListAddressesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.ListAddressesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.ListAddressesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.ListAddressesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    proto.swift.crm.v1.Address.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.ListAddressesResponse}
 */
proto.swift.crm.v1.ListAddressesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.ListAddressesResponse;
  return proto.swift.crm.v1.ListAddressesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.ListAddressesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.ListAddressesResponse}
 */
proto.swift.crm.v1.ListAddressesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.crm.v1.Address;
      reader.readMessage(value,proto.swift.crm.v1.Address.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.ListAddressesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.ListAddressesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.ListAddressesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.ListAddressesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.crm.v1.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.crm.v1.ListAddressesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.crm.v1.ListAddressesResponse} returns this
 */
proto.swift.crm.v1.ListAddressesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Address addresses = 2;
 * @return {!Array<!proto.swift.crm.v1.Address>}
 */
proto.swift.crm.v1.ListAddressesResponse.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.swift.crm.v1.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.crm.v1.Address, 2));
};


/**
 * @param {!Array<!proto.swift.crm.v1.Address>} value
 * @return {!proto.swift.crm.v1.ListAddressesResponse} returns this
*/
proto.swift.crm.v1.ListAddressesResponse.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.crm.v1.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.ListAddressesResponse.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.crm.v1.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.crm.v1.ListAddressesResponse} returns this
 */
proto.swift.crm.v1.ListAddressesResponse.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.GetAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.GetAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.GetAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.GetAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.GetAddressRequest}
 */
proto.swift.crm.v1.GetAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.GetAddressRequest;
  return proto.swift.crm.v1.GetAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.GetAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.GetAddressRequest}
 */
proto.swift.crm.v1.GetAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddressId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.GetAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.GetAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.GetAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.GetAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 address_id = 1;
 * @return {number}
 */
proto.swift.crm.v1.GetAddressRequest.prototype.getAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.crm.v1.GetAddressRequest} returns this
 */
proto.swift.crm.v1.GetAddressRequest.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.GetAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.GetAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.GetAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.GetAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.swift.crm.v1.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.GetAddressResponse}
 */
proto.swift.crm.v1.GetAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.GetAddressResponse;
  return proto.swift.crm.v1.GetAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.GetAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.GetAddressResponse}
 */
proto.swift.crm.v1.GetAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.crm.v1.Address;
      reader.readMessage(value,proto.swift.crm.v1.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.GetAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.GetAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.GetAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.GetAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.crm.v1.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.GetAddressResponse.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, proto.swift.crm.v1.Address, 1));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.crm.v1.GetAddressResponse} returns this
*/
proto.swift.crm.v1.GetAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.GetAddressResponse} returns this
 */
proto.swift.crm.v1.GetAddressResponse.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.GetAddressResponse.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.CreateAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.CreateAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.CreateAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.CreateAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.swift.crm.v1.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.CreateAddressRequest}
 */
proto.swift.crm.v1.CreateAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.CreateAddressRequest;
  return proto.swift.crm.v1.CreateAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.CreateAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.CreateAddressRequest}
 */
proto.swift.crm.v1.CreateAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.crm.v1.Address;
      reader.readMessage(value,proto.swift.crm.v1.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.CreateAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.CreateAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.CreateAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.CreateAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.crm.v1.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.CreateAddressRequest.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, proto.swift.crm.v1.Address, 1));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.crm.v1.CreateAddressRequest} returns this
*/
proto.swift.crm.v1.CreateAddressRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.CreateAddressRequest} returns this
 */
proto.swift.crm.v1.CreateAddressRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.CreateAddressRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.CreateAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.CreateAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.CreateAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.CreateAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.swift.crm.v1.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.CreateAddressResponse}
 */
proto.swift.crm.v1.CreateAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.CreateAddressResponse;
  return proto.swift.crm.v1.CreateAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.CreateAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.CreateAddressResponse}
 */
proto.swift.crm.v1.CreateAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.crm.v1.Address;
      reader.readMessage(value,proto.swift.crm.v1.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.CreateAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.CreateAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.CreateAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.CreateAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.crm.v1.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.CreateAddressResponse.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, proto.swift.crm.v1.Address, 1));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.crm.v1.CreateAddressResponse} returns this
*/
proto.swift.crm.v1.CreateAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.CreateAddressResponse} returns this
 */
proto.swift.crm.v1.CreateAddressResponse.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.CreateAddressResponse.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.UpdateAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.UpdateAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.UpdateAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.UpdateAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.swift.crm.v1.Address.toObject(includeInstance, f),
    refreshgeolocation: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.UpdateAddressRequest}
 */
proto.swift.crm.v1.UpdateAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.UpdateAddressRequest;
  return proto.swift.crm.v1.UpdateAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.UpdateAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.UpdateAddressRequest}
 */
proto.swift.crm.v1.UpdateAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.crm.v1.Address;
      reader.readMessage(value,proto.swift.crm.v1.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefreshgeolocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.UpdateAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.UpdateAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.UpdateAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.UpdateAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.crm.v1.Address.serializeBinaryToWriter
    );
  }
  f = message.getRefreshgeolocation();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.UpdateAddressRequest.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, proto.swift.crm.v1.Address, 1));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.crm.v1.UpdateAddressRequest} returns this
*/
proto.swift.crm.v1.UpdateAddressRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.UpdateAddressRequest} returns this
 */
proto.swift.crm.v1.UpdateAddressRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.UpdateAddressRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool refreshGeoLocation = 2;
 * @return {boolean}
 */
proto.swift.crm.v1.UpdateAddressRequest.prototype.getRefreshgeolocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.crm.v1.UpdateAddressRequest} returns this
 */
proto.swift.crm.v1.UpdateAddressRequest.prototype.setRefreshgeolocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.UpdateAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.UpdateAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.UpdateAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.UpdateAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.swift.crm.v1.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.UpdateAddressResponse}
 */
proto.swift.crm.v1.UpdateAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.UpdateAddressResponse;
  return proto.swift.crm.v1.UpdateAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.UpdateAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.UpdateAddressResponse}
 */
proto.swift.crm.v1.UpdateAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.crm.v1.Address;
      reader.readMessage(value,proto.swift.crm.v1.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.UpdateAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.UpdateAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.UpdateAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.UpdateAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.crm.v1.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.crm.v1.UpdateAddressResponse.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, proto.swift.crm.v1.Address, 1));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.crm.v1.UpdateAddressResponse} returns this
*/
proto.swift.crm.v1.UpdateAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.crm.v1.UpdateAddressResponse} returns this
 */
proto.swift.crm.v1.UpdateAddressResponse.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.crm.v1.UpdateAddressResponse.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.DeleteAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.DeleteAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.DeleteAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.DeleteAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.DeleteAddressRequest}
 */
proto.swift.crm.v1.DeleteAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.DeleteAddressRequest;
  return proto.swift.crm.v1.DeleteAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.DeleteAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.DeleteAddressRequest}
 */
proto.swift.crm.v1.DeleteAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddressId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.DeleteAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.DeleteAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.DeleteAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.DeleteAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 address_id = 1;
 * @return {number}
 */
proto.swift.crm.v1.DeleteAddressRequest.prototype.getAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.crm.v1.DeleteAddressRequest} returns this
 */
proto.swift.crm.v1.DeleteAddressRequest.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.crm.v1.DeleteAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.crm.v1.DeleteAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.crm.v1.DeleteAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.DeleteAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.crm.v1.DeleteAddressResponse}
 */
proto.swift.crm.v1.DeleteAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.crm.v1.DeleteAddressResponse;
  return proto.swift.crm.v1.DeleteAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.crm.v1.DeleteAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.crm.v1.DeleteAddressResponse}
 */
proto.swift.crm.v1.DeleteAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddressId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.crm.v1.DeleteAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.crm.v1.DeleteAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.crm.v1.DeleteAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.crm.v1.DeleteAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 address_id = 1;
 * @return {number}
 */
proto.swift.crm.v1.DeleteAddressResponse.prototype.getAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.crm.v1.DeleteAddressResponse} returns this
 */
proto.swift.crm.v1.DeleteAddressResponse.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.swift.crm.v1);
