import React, { useEffect, useRef, useState } from 'react'

import { Form, Input, InputRef } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

import { getFormItemValidator, runCustomvalidators } from '../helpers/CustomValidator.helper'
import { getInputClassName } from '../helpers/Input.helper'
import { isValidTimeInputAtDigitIndex } from '../helpers/Time.helper'
import { isValidTime } from 'helpers/timestamp'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

import './Input.css'

interface TimePickerProps extends InputCommonProps {
  value: string
  placeholder?: string
  errorMessage?: string
  formItemName?: string
  formRef: FormInstance<any>
  required?: boolean
  onChange: (times: string) => void
}

/**
 * A writeable time picker which only allows valid times e.g 00:00-23:59.
 */
export const TimePickerComponent = ({
  value,
  disabled,
  placeholder = 'HH:mm',
  label,
  size = INPUTSIZES.small,
  overrideWidth,
  customValidators,
  formItemName,
  formRef,
  onChange,
  required = false,
}: TimePickerProps) => {
  const [time, setTime] = useState('')
  const cursorPos = useRef(time.length)
  const inputRef = useRef<InputRef>(null)

  useEffect(() => setFormFieldsValue(value), [value])

  const setFormFieldsValue = (value: string): void => {
    if (formRef && formItemName) {
      formRef.setFieldsValue({ [formItemName]: value })
    }
    setTime(value)
  }
  /**
   * Checks if the value from props has been changed from outside which means that this component
   * should sync and set the time from props.
   */
  useEffect(() => {
    checkIfValueFromPropDiffers(value, time)
  }, [value])

  /**
   * Manages the cursor for the input. Should put the curser after the ':' when ':00' has been added to a value
   */
  useEffect(() => {
    if (cursorPos?.current >= 0) {
      inputRef?.current?.setSelectionRange(cursorPos.current + 1, cursorPos.current + 3)
      cursorPos.current = -1
    }
  }, [time])

  const checkIfValueFromPropDiffers = (propValue: string, internalTime: string) => {
    if (propValue !== internalTime) {
      setFormFieldsValue(propValue)
    }
  }

  const onInputChange = (ev: React.CompositionEvent<HTMLInputElement>) => {
    const { value } = ev.target as HTMLInputElement

    // decides if ':00' should be appended to the value.
    const appendColon =
      value.length === 2 && Number.isInteger(Number.parseInt(ev.nativeEvent.data) ?? ':')
    const newTime = appendColon ? `${value}:00` : value
    if (appendColon) {
      cursorPos.current = newTime.indexOf(':')
    }

    const timeValidation = isValidTime(newTime)
    setFormFieldsValue(newTime) // Always set the internal variable but only emit the value once its valid.
    // Clearing the time or setting it to an empty string should also emit an event even though its an invalid time.
    if (timeValidation || newTime?.length === 0) {
      onChange(newTime)
    }
  }

  /**
   * Checks if the pasted value is a valid input and prevents the event from happening if any character is invalid.
   */
  const handlePasteEvent = (ev: React.ClipboardEvent): void => {
    const textFromPasteEvent: string = (ev as React.ClipboardEvent).clipboardData.getData('text')
    const potentialOutcome =
      time?.substring(0, 0) + textFromPasteEvent + time?.substring(5, time?.length)
    const allValuesFromPasteAreValid = [...textFromPasteEvent].every((char, idx) => {
      return isValidTimeInputAtDigitIndex(potentialOutcome, idx)
    })
    if (!allValuesFromPasteAreValid) {
      ev.preventDefault()
    }
  }

  /**
   * Checks that the user input is a valid character to add to the time value.
   */
  const handleRegularEvent = (ev: React.CompositionEvent): void => {
    const { selectionStart, selectionEnd } = ev.nativeEvent.target as HTMLInputElement
    if (selectionEnd === null || selectionStart === null) return
    const potentialOutcome =
      time?.substring(0, selectionStart) + ev.data + time?.substring(selectionEnd, time?.length)
    if (!isValidTimeInputAtDigitIndex(potentialOutcome, selectionStart)) {
      ev.preventDefault()
    }
  }

  const handleKeyDown = (ev: React.CompositionEvent | React.ClipboardEvent): void => {
    ev.type?.toLowerCase() === 'paste'
      ? handlePasteEvent(ev as React.ClipboardEvent)
      : handleRegularEvent(ev as React.CompositionEvent)
  }

  const validateTime = (): string | undefined => {
    return customValidators ? runCustomvalidators(customValidators) : undefined
  }

  return (
    <>
      {customValidators ? (
        <Form.Item
          label={label ?? ' '}
          name={formItemName}
          className="inter-time-picker form-item--margin"
          required={required}
          rules={[getFormItemValidator(validateTime)]}
        >
          <Input
            allowClear
            ref={inputRef}
            className={overrideWidth ? '' : getInputClassName(size)}
            placeholder={placeholder}
            disabled={disabled}
            onPaste={(ev: React.SyntheticEvent) => handleKeyDown(ev as React.CompositionEvent)}
            onBeforeInput={(ev: React.SyntheticEvent) =>
              handleKeyDown(ev as React.CompositionEvent)
            }
            onChange={(ev: React.SyntheticEvent) =>
              onInputChange(ev as React.CompositionEvent<HTMLInputElement>)
            }
          />
        </Form.Item>
      ) : (
        <Form.Item
          className="inter-time-picker form-item--margin"
          label={label ?? ' '}
          required={required}
          name={formItemName}
          rules={[
            {
              message: !(required || time.length > 0) ? '' : 'Field is required',
              required: !(required || time.length > 0) ? false : true,
            },
          ]}
        >
          <Input
            value={time}
            allowClear
            ref={inputRef}
            className={overrideWidth ? '' : getInputClassName(size)}
            placeholder={placeholder}
            disabled={disabled}
            onPaste={(ev: React.SyntheticEvent) => handleKeyDown(ev as React.CompositionEvent)}
            onBeforeInput={(ev: React.SyntheticEvent) =>
              handleKeyDown(ev as React.CompositionEvent)
            }
            onChange={(ev: React.SyntheticEvent) =>
              onInputChange(ev as React.CompositionEvent<HTMLInputElement>)
            }
          />
        </Form.Item>
      )}
    </>
  )
}
