import React, { useEffect } from 'react'

import { DatePicker, Form } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { Rule } from 'antd/lib/form'

import { getFormItemValidator, runCustomvalidators } from '../helpers/CustomValidator.helper'
import { getInputClassName } from '../helpers/Input.helper'
import { createDateFromString } from 'helpers/timestamp'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

import './Input.css'

interface DatePickerProps extends InputCommonProps {
  value: string
  formItemName?: string
  allowClear?: boolean
  pickerType?: 'week' | 'month' | 'quarter' | 'year'
  placeholder?: string
  formRef?: FormInstance<any>
  customDateValidators?: Array<() => string | undefined>
  required?: boolean
  rules?: Rule[]
  disabledDate?: (date: moment.Moment) => boolean
  onChange?: ((value: moment.Moment | null, dateString: string) => void) | undefined
}

/**
 * Usage: Picks date.
 */
export const DatePickerComponent = ({
  value,
  disabled,
  label,
  formItemName,
  placeholder = 'Select date',
  allowClear = false,
  pickerType = 'week',
  overrideWidth,
  size = INPUTSIZES.medium,
  formRef,
  customDateValidators,
  rules,
  required = false,
  disabledDate,
  onChange,
}: DatePickerProps) => {
  useEffect(() => {
    if (formRef && formItemName && value) {
      formRef.setFieldsValue({
        [formItemName]: value.length > 0 ? createDateFromString(value) : '',
      })
    }
  }, [value])

  const validateDate = (): string | undefined => {
    return customDateValidators ? runCustomvalidators(customDateValidators) : undefined
  }

  return (
    <Form.Item
      name={formItemName}
      label={label}
      initialValue={value ? createDateFromString(value) : ''}
      rules={rules ?? [getFormItemValidator(validateDate)]}
      required={required}
    >
      <DatePicker
        className={overrideWidth ? '' : getInputClassName(size)}
        disabled={disabled}
        disabledDate={disabledDate}
        placeholder={placeholder}
        format="YYYY-MM-DD"
        allowClear={allowClear}
        picker={pickerType}
        onChange={onChange}
      />
    </Form.Item>
  )
}
