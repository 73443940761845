// source: iam/v1/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var email_v1_email_pb = require('../../email/v1/email_pb.js');
goog.object.extend(proto, email_v1_email_pb);
goog.exportSymbol('proto.swift.iam.v1.CreateUserRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.CreateUserResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditUserRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditUserResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetDefaultImportOwnerRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetDefaultImportOwnerResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetUserRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetUserResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.ImageFormat', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListPublicUsersRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListPublicUsersResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListUsersRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListUsersResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListUsersWithImagesRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListUsersWithImagesResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.TransportOperating', null, global);
goog.exportSymbol('proto.swift.iam.v1.UploadProfilePictureRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.UploadProfilePictureResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.User', null, global);
goog.exportSymbol('proto.swift.iam.v1.User.Role', null, global);
goog.exportSymbol('proto.swift.iam.v1.User.UserStatus', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserConfig', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserFilter', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserImage', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserListFilter', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserListSorting', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserListSorting.Field', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserListSorting.Ordering', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.User.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.User.displayName = 'proto.swift.iam.v1.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UserConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.UserConfig.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.UserConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UserConfig.displayName = 'proto.swift.iam.v1.UserConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.TransportOperating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.TransportOperating.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.TransportOperating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.TransportOperating.displayName = 'proto.swift.iam.v1.TransportOperating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UserFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.UserFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.UserFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UserFilter.displayName = 'proto.swift.iam.v1.UserFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UserListFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.UserListFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.UserListFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UserListFilter.displayName = 'proto.swift.iam.v1.UserListFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UserListSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.UserListSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UserListSorting.displayName = 'proto.swift.iam.v1.UserListSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UserImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.UserImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UserImage.displayName = 'proto.swift.iam.v1.UserImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListUsersRequest.displayName = 'proto.swift.iam.v1.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListUsersResponse.displayName = 'proto.swift.iam.v1.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListUsersWithImagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.ListUsersWithImagesRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.ListUsersWithImagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListUsersWithImagesRequest.displayName = 'proto.swift.iam.v1.ListUsersWithImagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListUsersWithImagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.ListUsersWithImagesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.ListUsersWithImagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListUsersWithImagesResponse.displayName = 'proto.swift.iam.v1.ListUsersWithImagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListPublicUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.ListPublicUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListPublicUsersRequest.displayName = 'proto.swift.iam.v1.ListPublicUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListPublicUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.ListPublicUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.ListPublicUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListPublicUsersResponse.displayName = 'proto.swift.iam.v1.ListPublicUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetUserRequest.displayName = 'proto.swift.iam.v1.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetUserResponse.displayName = 'proto.swift.iam.v1.GetUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateUserRequest.displayName = 'proto.swift.iam.v1.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateUserResponse.displayName = 'proto.swift.iam.v1.CreateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditUserRequest.displayName = 'proto.swift.iam.v1.EditUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditUserResponse.displayName = 'proto.swift.iam.v1.EditUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetDefaultImportOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetDefaultImportOwnerRequest.displayName = 'proto.swift.iam.v1.GetDefaultImportOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetDefaultImportOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetDefaultImportOwnerResponse.displayName = 'proto.swift.iam.v1.GetDefaultImportOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UploadProfilePictureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.UploadProfilePictureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UploadProfilePictureRequest.displayName = 'proto.swift.iam.v1.UploadProfilePictureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UploadProfilePictureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.UploadProfilePictureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UploadProfilePictureResponse.displayName = 'proto.swift.iam.v1.UploadProfilePictureResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.User.repeatedFields_ = [6,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userGroupId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    branchId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    surname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    disabledEmailTypesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.swift.iam.v1.UserFilter.toObject, includeInstance),
    config: (f = msg.getConfig()) && proto.swift.iam.v1.UserConfig.toObject(includeInstance, f),
    uiConfigJson: jspb.Message.getFieldWithDefault(msg, 13, ""),
    img: msg.getImg_asB64(),
    userStatus: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.User;
  return proto.swift.iam.v1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserGroupId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.swift.iam.v1.User.Role>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.swift.email.v1.Email.Type>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDisabledEmailTypes(values[i]);
      }
      break;
    case 11:
      var value = new proto.swift.iam.v1.UserFilter;
      reader.readMessage(value,proto.swift.iam.v1.UserFilter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 12:
      var value = new proto.swift.iam.v1.UserConfig;
      reader.readMessage(value,proto.swift.iam.v1.UserConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUiConfigJson(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImg(value);
      break;
    case 15:
      var value = /** @type {!proto.swift.iam.v1.User.UserStatus} */ (reader.readEnum());
      msg.setUserStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserGroupId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSurname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDisabledEmailTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.swift.iam.v1.UserFilter.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.swift.iam.v1.UserConfig.serializeBinaryToWriter
    );
  }
  f = message.getUiConfigJson();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getImg_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getUserStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.iam.v1.User.Role = {
  ORGANIZATION: 0,
  SHIPPER: 1,
  RECEIVER: 2,
  CONTROLLER: 3,
  TRANSPORTER: 4,
  FINANCE: 5,
  MANAGER: 6,
  ADMIN: 7,
  SALES: 8,
  API: 9
};

/**
 * @enum {number}
 */
proto.swift.iam.v1.User.UserStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
  DISABLED: 2
};

/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_group_id = 2;
 * @return {number}
 */
proto.swift.iam.v1.User.prototype.getUserGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setUserGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 organization_id = 3;
 * @return {number}
 */
proto.swift.iam.v1.User.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 branch_id = 4;
 * @return {number}
 */
proto.swift.iam.v1.User.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Role roles = 6;
 * @return {!Array<!proto.swift.iam.v1.User.Role>}
 */
proto.swift.iam.v1.User.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.swift.iam.v1.User.Role>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.swift.iam.v1.User.Role>} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.swift.iam.v1.User.Role} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string surname = 8;
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getSurname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setSurname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone = 9;
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated swift.email.v1.Email.Type disabled_email_types = 10;
 * @return {!Array<!proto.swift.email.v1.Email.Type>}
 */
proto.swift.iam.v1.User.prototype.getDisabledEmailTypesList = function() {
  return /** @type {!Array<!proto.swift.email.v1.Email.Type>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.swift.email.v1.Email.Type>} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setDisabledEmailTypesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.swift.email.v1.Email.Type} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.addDisabledEmailTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.clearDisabledEmailTypesList = function() {
  return this.setDisabledEmailTypesList([]);
};


/**
 * repeated UserFilter filters = 11;
 * @return {!Array<!proto.swift.iam.v1.UserFilter>}
 */
proto.swift.iam.v1.User.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.UserFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.UserFilter, 11));
};


/**
 * @param {!Array<!proto.swift.iam.v1.UserFilter>} value
 * @return {!proto.swift.iam.v1.User} returns this
*/
proto.swift.iam.v1.User.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.swift.iam.v1.UserFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.UserFilter}
 */
proto.swift.iam.v1.User.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.swift.iam.v1.UserFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional UserConfig config = 12;
 * @return {?proto.swift.iam.v1.UserConfig}
 */
proto.swift.iam.v1.User.prototype.getConfig = function() {
  return /** @type{?proto.swift.iam.v1.UserConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserConfig, 12));
};


/**
 * @param {?proto.swift.iam.v1.UserConfig|undefined} value
 * @return {!proto.swift.iam.v1.User} returns this
*/
proto.swift.iam.v1.User.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.User.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string ui_config_json = 13;
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getUiConfigJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setUiConfigJson = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bytes img = 14;
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes img = 14;
 * This is a type-conversion wrapper around `getImg()`
 * @return {string}
 */
proto.swift.iam.v1.User.prototype.getImg_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImg()));
};


/**
 * optional bytes img = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImg()`
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.User.prototype.getImg_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImg()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setImg = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional UserStatus user_status = 15;
 * @return {!proto.swift.iam.v1.User.UserStatus}
 */
proto.swift.iam.v1.User.prototype.getUserStatus = function() {
  return /** @type {!proto.swift.iam.v1.User.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.swift.iam.v1.User.UserStatus} value
 * @return {!proto.swift.iam.v1.User} returns this
 */
proto.swift.iam.v1.User.prototype.setUserStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.UserConfig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UserConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UserConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UserConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultImportOwner: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    defaultInvoiceIssuer: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    transportOperatingList: jspb.Message.toObjectList(msg.getTransportOperatingList(),
    proto.swift.iam.v1.TransportOperating.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UserConfig}
 */
proto.swift.iam.v1.UserConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UserConfig;
  return proto.swift.iam.v1.UserConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UserConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UserConfig}
 */
proto.swift.iam.v1.UserConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultImportOwner(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultInvoiceIssuer(value);
      break;
    case 3:
      var value = new proto.swift.iam.v1.TransportOperating;
      reader.readMessage(value,proto.swift.iam.v1.TransportOperating.deserializeBinaryFromReader);
      msg.addTransportOperating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UserConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UserConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultImportOwner();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDefaultInvoiceIssuer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTransportOperatingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.swift.iam.v1.TransportOperating.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool default_import_owner = 1;
 * @return {boolean}
 */
proto.swift.iam.v1.UserConfig.prototype.getDefaultImportOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.UserConfig} returns this
 */
proto.swift.iam.v1.UserConfig.prototype.setDefaultImportOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool default_invoice_issuer = 2;
 * @return {boolean}
 */
proto.swift.iam.v1.UserConfig.prototype.getDefaultInvoiceIssuer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.UserConfig} returns this
 */
proto.swift.iam.v1.UserConfig.prototype.setDefaultInvoiceIssuer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated TransportOperating transport_operating = 3;
 * @return {!Array<!proto.swift.iam.v1.TransportOperating>}
 */
proto.swift.iam.v1.UserConfig.prototype.getTransportOperatingList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.TransportOperating>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.TransportOperating, 3));
};


/**
 * @param {!Array<!proto.swift.iam.v1.TransportOperating>} value
 * @return {!proto.swift.iam.v1.UserConfig} returns this
*/
proto.swift.iam.v1.UserConfig.prototype.setTransportOperatingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.swift.iam.v1.TransportOperating=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.TransportOperating}
 */
proto.swift.iam.v1.UserConfig.prototype.addTransportOperating = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.swift.iam.v1.TransportOperating, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.UserConfig} returns this
 */
proto.swift.iam.v1.UserConfig.prototype.clearTransportOperatingList = function() {
  return this.setTransportOperatingList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.TransportOperating.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.TransportOperating.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.TransportOperating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.TransportOperating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.TransportOperating.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    transportModesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.TransportOperating}
 */
proto.swift.iam.v1.TransportOperating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.TransportOperating;
  return proto.swift.iam.v1.TransportOperating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.TransportOperating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.TransportOperating}
 */
proto.swift.iam.v1.TransportOperating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.swift.common.TransportMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTransportModes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.TransportOperating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.TransportOperating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.TransportOperating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.TransportOperating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTransportModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.swift.iam.v1.TransportOperating.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.TransportOperating} returns this
 */
proto.swift.iam.v1.TransportOperating.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 organization_id = 2;
 * @return {number}
 */
proto.swift.iam.v1.TransportOperating.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.TransportOperating} returns this
 */
proto.swift.iam.v1.TransportOperating.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool default = 3;
 * @return {boolean}
 */
proto.swift.iam.v1.TransportOperating.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.TransportOperating} returns this
 */
proto.swift.iam.v1.TransportOperating.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated swift.common.TransportMode transport_modes = 4;
 * @return {!Array<!proto.swift.common.TransportMode>}
 */
proto.swift.iam.v1.TransportOperating.prototype.getTransportModesList = function() {
  return /** @type {!Array<!proto.swift.common.TransportMode>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.swift.common.TransportMode>} value
 * @return {!proto.swift.iam.v1.TransportOperating} returns this
 */
proto.swift.iam.v1.TransportOperating.prototype.setTransportModesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.TransportOperating} returns this
 */
proto.swift.iam.v1.TransportOperating.prototype.addTransportModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.TransportOperating} returns this
 */
proto.swift.iam.v1.TransportOperating.prototype.clearTransportModesList = function() {
  return this.setTransportModesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.UserFilter.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UserFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UserFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UserFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    disabledEmailTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UserFilter}
 */
proto.swift.iam.v1.UserFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UserFilter;
  return proto.swift.iam.v1.UserFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UserFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UserFilter}
 */
proto.swift.iam.v1.UserFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFilterId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.swift.email.v1.Email.Type>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDisabledEmailTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UserFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UserFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDisabledEmailTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 filter_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.UserFilter.prototype.getFilterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.UserFilter} returns this
 */
proto.swift.iam.v1.UserFilter.prototype.setFilterId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool disabled = 2;
 * @return {boolean}
 */
proto.swift.iam.v1.UserFilter.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.UserFilter} returns this
 */
proto.swift.iam.v1.UserFilter.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated swift.email.v1.Email.Type disabled_email_types = 3;
 * @return {!Array<!proto.swift.email.v1.Email.Type>}
 */
proto.swift.iam.v1.UserFilter.prototype.getDisabledEmailTypesList = function() {
  return /** @type {!Array<!proto.swift.email.v1.Email.Type>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.swift.email.v1.Email.Type>} value
 * @return {!proto.swift.iam.v1.UserFilter} returns this
 */
proto.swift.iam.v1.UserFilter.prototype.setDisabledEmailTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.swift.email.v1.Email.Type} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.UserFilter} returns this
 */
proto.swift.iam.v1.UserFilter.prototype.addDisabledEmailTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.UserFilter} returns this
 */
proto.swift.iam.v1.UserFilter.prototype.clearDisabledEmailTypesList = function() {
  return this.setDisabledEmailTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.UserListFilter.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UserListFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UserListFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UserListFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserListFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    organizationIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    userGroupIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    defaultImportOwner: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    defaultInvoiceIssuer: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    excludeDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UserListFilter}
 */
proto.swift.iam.v1.UserListFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UserListFilter;
  return proto.swift.iam.v1.UserListFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UserListFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UserListFilter}
 */
proto.swift.iam.v1.UserListFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.swift.iam.v1.User.Role>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserGroupId(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultImportOwner(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultInvoiceIssuer(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeDisabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserListFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UserListFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UserListFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserListFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getUserIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getUserGroupIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getDefaultImportOwner();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDefaultInvoiceIssuer();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExcludeDisabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * repeated User.Role roles = 1;
 * @return {!Array<!proto.swift.iam.v1.User.Role>}
 */
proto.swift.iam.v1.UserListFilter.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.swift.iam.v1.User.Role>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.swift.iam.v1.User.Role>} value
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.swift.iam.v1.User.Role} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated string user_id = 2;
 * @return {!Array<string>}
 */
proto.swift.iam.v1.UserListFilter.prototype.getUserIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.setUserIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.addUserId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.clearUserIdList = function() {
  return this.setUserIdList([]);
};


/**
 * repeated int64 organization_id = 3;
 * @return {!Array<number>}
 */
proto.swift.iam.v1.UserListFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated int64 user_group_id = 4;
 * @return {!Array<number>}
 */
proto.swift.iam.v1.UserListFilter.prototype.getUserGroupIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.setUserGroupIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.addUserGroupId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.clearUserGroupIdList = function() {
  return this.setUserGroupIdList([]);
};


/**
 * optional bool default_import_owner = 5;
 * @return {boolean}
 */
proto.swift.iam.v1.UserListFilter.prototype.getDefaultImportOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.setDefaultImportOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool default_invoice_issuer = 6;
 * @return {boolean}
 */
proto.swift.iam.v1.UserListFilter.prototype.getDefaultInvoiceIssuer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.setDefaultInvoiceIssuer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool exclude_disabled = 7;
 * @return {boolean}
 */
proto.swift.iam.v1.UserListFilter.prototype.getExcludeDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.UserListFilter} returns this
 */
proto.swift.iam.v1.UserListFilter.prototype.setExcludeDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UserListSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UserListSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UserListSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserListSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UserListSorting}
 */
proto.swift.iam.v1.UserListSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UserListSorting;
  return proto.swift.iam.v1.UserListSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UserListSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UserListSorting}
 */
proto.swift.iam.v1.UserListSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.iam.v1.UserListSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.iam.v1.UserListSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserListSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UserListSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UserListSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserListSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.iam.v1.UserListSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.iam.v1.UserListSorting.Field = {
  NAME: 0,
  ORGANIZATION: 1,
  ROLES: 2,
  EMAIL: 3,
  BRANCH: 4,
  STATUS: 5
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.iam.v1.UserListSorting.Field}
 */
proto.swift.iam.v1.UserListSorting.prototype.getField = function() {
  return /** @type {!proto.swift.iam.v1.UserListSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.iam.v1.UserListSorting.Field} value
 * @return {!proto.swift.iam.v1.UserListSorting} returns this
 */
proto.swift.iam.v1.UserListSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.iam.v1.UserListSorting.Ordering}
 */
proto.swift.iam.v1.UserListSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.iam.v1.UserListSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.iam.v1.UserListSorting.Ordering} value
 * @return {!proto.swift.iam.v1.UserListSorting} returns this
 */
proto.swift.iam.v1.UserListSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UserImage.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UserImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UserImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    img: msg.getImg_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UserImage}
 */
proto.swift.iam.v1.UserImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UserImage;
  return proto.swift.iam.v1.UserImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UserImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UserImage}
 */
proto.swift.iam.v1.UserImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UserImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UserImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImg_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.swift.iam.v1.UserImage.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.UserImage} returns this
 */
proto.swift.iam.v1.UserImage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes img = 2;
 * @return {string}
 */
proto.swift.iam.v1.UserImage.prototype.getImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes img = 2;
 * This is a type-conversion wrapper around `getImg()`
 * @return {string}
 */
proto.swift.iam.v1.UserImage.prototype.getImg_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImg()));
};


/**
 * optional bytes img = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImg()`
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserImage.prototype.getImg_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImg()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.swift.iam.v1.UserImage} returns this
 */
proto.swift.iam.v1.UserImage.prototype.setImg = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.swift.iam.v1.UserListFilter.toObject(includeInstance, f),
    sorting: (f = msg.getSorting()) && proto.swift.iam.v1.UserListSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListUsersRequest}
 */
proto.swift.iam.v1.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListUsersRequest;
  return proto.swift.iam.v1.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListUsersRequest}
 */
proto.swift.iam.v1.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.UserListFilter;
      reader.readMessage(value,proto.swift.iam.v1.UserListFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.iam.v1.UserListSorting;
      reader.readMessage(value,proto.swift.iam.v1.UserListSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.iam.v1.UserListFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.iam.v1.UserListSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.iam.v1.ListUsersRequest} returns this
*/
proto.swift.iam.v1.ListUsersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListUsersRequest} returns this
 */
proto.swift.iam.v1.ListUsersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserListFilter filter = 2;
 * @return {?proto.swift.iam.v1.UserListFilter}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.iam.v1.UserListFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserListFilter, 2));
};


/**
 * @param {?proto.swift.iam.v1.UserListFilter|undefined} value
 * @return {!proto.swift.iam.v1.ListUsersRequest} returns this
*/
proto.swift.iam.v1.ListUsersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListUsersRequest} returns this
 */
proto.swift.iam.v1.ListUsersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserListSorting sorting = 3;
 * @return {?proto.swift.iam.v1.UserListSorting}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.iam.v1.UserListSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserListSorting, 3));
};


/**
 * @param {?proto.swift.iam.v1.UserListSorting|undefined} value
 * @return {!proto.swift.iam.v1.ListUsersRequest} returns this
*/
proto.swift.iam.v1.ListUsersRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListUsersRequest} returns this
 */
proto.swift.iam.v1.ListUsersRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListUsersRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.ListUsersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.swift.iam.v1.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListUsersResponse}
 */
proto.swift.iam.v1.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListUsersResponse;
  return proto.swift.iam.v1.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListUsersResponse}
 */
proto.swift.iam.v1.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.iam.v1.ListUsersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.ListUsersResponse} returns this
 */
proto.swift.iam.v1.ListUsersResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated User users = 2;
 * @return {!Array<!proto.swift.iam.v1.User>}
 */
proto.swift.iam.v1.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.User, 2));
};


/**
 * @param {!Array<!proto.swift.iam.v1.User>} value
 * @return {!proto.swift.iam.v1.ListUsersResponse} returns this
*/
proto.swift.iam.v1.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.iam.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.iam.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.ListUsersResponse} returns this
 */
proto.swift.iam.v1.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListUsersWithImagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListUsersWithImagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListUsersWithImagesRequest}
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListUsersWithImagesRequest;
  return proto.swift.iam.v1.ListUsersWithImagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListUsersWithImagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListUsersWithImagesRequest}
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListUsersWithImagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListUsersWithImagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.iam.v1.ListUsersWithImagesRequest} returns this
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.ListUsersWithImagesRequest} returns this
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.ListUsersWithImagesRequest} returns this
 */
proto.swift.iam.v1.ListUsersWithImagesRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListUsersWithImagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListUsersWithImagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.swift.iam.v1.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListUsersWithImagesResponse}
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListUsersWithImagesResponse;
  return proto.swift.iam.v1.ListUsersWithImagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListUsersWithImagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListUsersWithImagesResponse}
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListUsersWithImagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListUsersWithImagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.swift.iam.v1.User>}
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.User, 1));
};


/**
 * @param {!Array<!proto.swift.iam.v1.User>} value
 * @return {!proto.swift.iam.v1.ListUsersWithImagesResponse} returns this
*/
proto.swift.iam.v1.ListUsersWithImagesResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.iam.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.iam.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.ListUsersWithImagesResponse} returns this
 */
proto.swift.iam.v1.ListUsersWithImagesResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListPublicUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListPublicUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListPublicUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.swift.iam.v1.UserListFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListPublicUsersRequest}
 */
proto.swift.iam.v1.ListPublicUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListPublicUsersRequest;
  return proto.swift.iam.v1.ListPublicUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListPublicUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListPublicUsersRequest}
 */
proto.swift.iam.v1.ListPublicUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.UserListFilter;
      reader.readMessage(value,proto.swift.iam.v1.UserListFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListPublicUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListPublicUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListPublicUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.iam.v1.UserListFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.iam.v1.ListPublicUsersRequest} returns this
*/
proto.swift.iam.v1.ListPublicUsersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListPublicUsersRequest} returns this
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserListFilter filter = 2;
 * @return {?proto.swift.iam.v1.UserListFilter}
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.iam.v1.UserListFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserListFilter, 2));
};


/**
 * @param {?proto.swift.iam.v1.UserListFilter|undefined} value
 * @return {!proto.swift.iam.v1.ListPublicUsersRequest} returns this
*/
proto.swift.iam.v1.ListPublicUsersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListPublicUsersRequest} returns this
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListPublicUsersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.ListPublicUsersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListPublicUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListPublicUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListPublicUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListPublicUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.swift.iam.v1.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListPublicUsersResponse}
 */
proto.swift.iam.v1.ListPublicUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListPublicUsersResponse;
  return proto.swift.iam.v1.ListPublicUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListPublicUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListPublicUsersResponse}
 */
proto.swift.iam.v1.ListPublicUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListPublicUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListPublicUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListPublicUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListPublicUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.iam.v1.ListPublicUsersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.ListPublicUsersResponse} returns this
 */
proto.swift.iam.v1.ListPublicUsersResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated User users = 2;
 * @return {!Array<!proto.swift.iam.v1.User>}
 */
proto.swift.iam.v1.ListPublicUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.User, 2));
};


/**
 * @param {!Array<!proto.swift.iam.v1.User>} value
 * @return {!proto.swift.iam.v1.ListPublicUsersResponse} returns this
*/
proto.swift.iam.v1.ListPublicUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.iam.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.ListPublicUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.iam.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.ListPublicUsersResponse} returns this
 */
proto.swift.iam.v1.ListPublicUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetUserRequest}
 */
proto.swift.iam.v1.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetUserRequest;
  return proto.swift.iam.v1.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetUserRequest}
 */
proto.swift.iam.v1.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.swift.iam.v1.GetUserRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.GetUserRequest} returns this
 */
proto.swift.iam.v1.GetUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.swift.iam.v1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetUserResponse}
 */
proto.swift.iam.v1.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetUserResponse;
  return proto.swift.iam.v1.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetUserResponse}
 */
proto.swift.iam.v1.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.GetUserResponse.prototype.getUser = function() {
  return /** @type{?proto.swift.iam.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.User, 1));
};


/**
 * @param {?proto.swift.iam.v1.User|undefined} value
 * @return {!proto.swift.iam.v1.GetUserResponse} returns this
*/
proto.swift.iam.v1.GetUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.GetUserResponse} returns this
 */
proto.swift.iam.v1.GetUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.GetUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.swift.iam.v1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateUserRequest}
 */
proto.swift.iam.v1.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateUserRequest;
  return proto.swift.iam.v1.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateUserRequest}
 */
proto.swift.iam.v1.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.CreateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.swift.iam.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.User, 1));
};


/**
 * @param {?proto.swift.iam.v1.User|undefined} value
 * @return {!proto.swift.iam.v1.CreateUserRequest} returns this
*/
proto.swift.iam.v1.CreateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateUserRequest} returns this
 */
proto.swift.iam.v1.CreateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.swift.iam.v1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateUserResponse}
 */
proto.swift.iam.v1.CreateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateUserResponse;
  return proto.swift.iam.v1.CreateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateUserResponse}
 */
proto.swift.iam.v1.CreateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.CreateUserResponse.prototype.getUser = function() {
  return /** @type{?proto.swift.iam.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.User, 1));
};


/**
 * @param {?proto.swift.iam.v1.User|undefined} value
 * @return {!proto.swift.iam.v1.CreateUserResponse} returns this
*/
proto.swift.iam.v1.CreateUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateUserResponse} returns this
 */
proto.swift.iam.v1.CreateUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.swift.iam.v1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditUserRequest}
 */
proto.swift.iam.v1.EditUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditUserRequest;
  return proto.swift.iam.v1.EditUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditUserRequest}
 */
proto.swift.iam.v1.EditUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.EditUserRequest.prototype.getUser = function() {
  return /** @type{?proto.swift.iam.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.User, 1));
};


/**
 * @param {?proto.swift.iam.v1.User|undefined} value
 * @return {!proto.swift.iam.v1.EditUserRequest} returns this
*/
proto.swift.iam.v1.EditUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditUserRequest} returns this
 */
proto.swift.iam.v1.EditUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.swift.iam.v1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditUserResponse}
 */
proto.swift.iam.v1.EditUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditUserResponse;
  return proto.swift.iam.v1.EditUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditUserResponse}
 */
proto.swift.iam.v1.EditUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.EditUserResponse.prototype.getUser = function() {
  return /** @type{?proto.swift.iam.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.User, 1));
};


/**
 * @param {?proto.swift.iam.v1.User|undefined} value
 * @return {!proto.swift.iam.v1.EditUserResponse} returns this
*/
proto.swift.iam.v1.EditUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditUserResponse} returns this
 */
proto.swift.iam.v1.EditUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetDefaultImportOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userGroupId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerRequest}
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetDefaultImportOwnerRequest;
  return proto.swift.iam.v1.GetDefaultImportOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerRequest}
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetDefaultImportOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserGroupId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 organization_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} returns this
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_group_id = 2;
 * @return {number}
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.prototype.getUserGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} returns this
 */
proto.swift.iam.v1.GetDefaultImportOwnerRequest.prototype.setUserGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetDefaultImportOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.swift.iam.v1.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerResponse}
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetDefaultImportOwnerResponse;
  return proto.swift.iam.v1.GetDefaultImportOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerResponse}
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.User;
      reader.readMessage(value,proto.swift.iam.v1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetDefaultImportOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.prototype.getUser = function() {
  return /** @type{?proto.swift.iam.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.User, 1));
};


/**
 * @param {?proto.swift.iam.v1.User|undefined} value
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerResponse} returns this
*/
proto.swift.iam.v1.GetDefaultImportOwnerResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.GetDefaultImportOwnerResponse} returns this
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.GetDefaultImportOwnerResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UploadProfilePictureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UploadProfilePictureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UploadProfilePictureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userImg: (f = msg.getUserImg()) && proto.swift.iam.v1.UserImage.toObject(includeInstance, f),
    format: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UploadProfilePictureRequest}
 */
proto.swift.iam.v1.UploadProfilePictureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UploadProfilePictureRequest;
  return proto.swift.iam.v1.UploadProfilePictureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UploadProfilePictureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UploadProfilePictureRequest}
 */
proto.swift.iam.v1.UploadProfilePictureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.UserImage;
      reader.readMessage(value,proto.swift.iam.v1.UserImage.deserializeBinaryFromReader);
      msg.setUserImg(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.iam.v1.ImageFormat} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UploadProfilePictureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UploadProfilePictureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UploadProfilePictureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserImg();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.UserImage.serializeBinaryToWriter
    );
  }
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional UserImage user_img = 1;
 * @return {?proto.swift.iam.v1.UserImage}
 */
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.getUserImg = function() {
  return /** @type{?proto.swift.iam.v1.UserImage} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserImage, 1));
};


/**
 * @param {?proto.swift.iam.v1.UserImage|undefined} value
 * @return {!proto.swift.iam.v1.UploadProfilePictureRequest} returns this
*/
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.setUserImg = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.UploadProfilePictureRequest} returns this
 */
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.clearUserImg = function() {
  return this.setUserImg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.hasUserImg = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ImageFormat format = 2;
 * @return {!proto.swift.iam.v1.ImageFormat}
 */
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.getFormat = function() {
  return /** @type {!proto.swift.iam.v1.ImageFormat} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.iam.v1.ImageFormat} value
 * @return {!proto.swift.iam.v1.UploadProfilePictureRequest} returns this
 */
proto.swift.iam.v1.UploadProfilePictureRequest.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UploadProfilePictureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UploadProfilePictureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UploadProfilePictureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UploadProfilePictureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UploadProfilePictureResponse}
 */
proto.swift.iam.v1.UploadProfilePictureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UploadProfilePictureResponse;
  return proto.swift.iam.v1.UploadProfilePictureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UploadProfilePictureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UploadProfilePictureResponse}
 */
proto.swift.iam.v1.UploadProfilePictureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UploadProfilePictureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UploadProfilePictureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UploadProfilePictureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UploadProfilePictureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.swift.iam.v1.ImageFormat = {
  UNKNOWN: 0,
  JPG: 1,
  PNG: 2
};

goog.object.extend(exports, proto.swift.iam.v1);
