/**
 * @fileoverview gRPC-Web generated client stub for swift.invoicing.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: invoicing/v1/cost.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_query_pb = require('../../common/query_pb.js')
const proto = {};
proto.swift = {};
proto.swift.invoicing = {};
proto.swift.invoicing.v1 = require('./cost_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.invoicing.v1.CostServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.invoicing.v1.CostServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.ListCostsRequest,
 *   !proto.swift.invoicing.v1.ListCostsResponse>}
 */
const methodDescriptor_CostService_ListCosts = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.CostService/ListCosts',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.ListCostsRequest,
  proto.swift.invoicing.v1.ListCostsResponse,
  /**
   * @param {!proto.swift.invoicing.v1.ListCostsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.ListCostsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.ListCostsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.ListCostsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.ListCostsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.CostServiceClient.prototype.listCosts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/ListCosts',
      request,
      metadata || {},
      methodDescriptor_CostService_ListCosts,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.ListCostsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.ListCostsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.CostServicePromiseClient.prototype.listCosts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/ListCosts',
      request,
      metadata || {},
      methodDescriptor_CostService_ListCosts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.CreateCostRequest,
 *   !proto.swift.invoicing.v1.CreateCostResponse>}
 */
const methodDescriptor_CostService_CreateCost = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.CostService/CreateCost',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.CreateCostRequest,
  proto.swift.invoicing.v1.CreateCostResponse,
  /**
   * @param {!proto.swift.invoicing.v1.CreateCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.CreateCostResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.CreateCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.CreateCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.CreateCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.CostServiceClient.prototype.createCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/CreateCost',
      request,
      metadata || {},
      methodDescriptor_CostService_CreateCost,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.CreateCostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.CreateCostResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.CostServicePromiseClient.prototype.createCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/CreateCost',
      request,
      metadata || {},
      methodDescriptor_CostService_CreateCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.SetBookingCostsRequest,
 *   !proto.swift.invoicing.v1.SetBookingCostsResponse>}
 */
const methodDescriptor_CostService_SetBookingCosts = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.CostService/SetBookingCosts',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.SetBookingCostsRequest,
  proto.swift.invoicing.v1.SetBookingCostsResponse,
  /**
   * @param {!proto.swift.invoicing.v1.SetBookingCostsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.SetBookingCostsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.SetBookingCostsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.SetBookingCostsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.SetBookingCostsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.CostServiceClient.prototype.setBookingCosts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/SetBookingCosts',
      request,
      metadata || {},
      methodDescriptor_CostService_SetBookingCosts,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.SetBookingCostsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.SetBookingCostsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.CostServicePromiseClient.prototype.setBookingCosts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/SetBookingCosts',
      request,
      metadata || {},
      methodDescriptor_CostService_SetBookingCosts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.SetCostStatusRequest,
 *   !proto.swift.invoicing.v1.SetCostStatusResponse>}
 */
const methodDescriptor_CostService_SetCostStatus = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.CostService/SetCostStatus',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.SetCostStatusRequest,
  proto.swift.invoicing.v1.SetCostStatusResponse,
  /**
   * @param {!proto.swift.invoicing.v1.SetCostStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.SetCostStatusResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.SetCostStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.SetCostStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.SetCostStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.CostServiceClient.prototype.setCostStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/SetCostStatus',
      request,
      metadata || {},
      methodDescriptor_CostService_SetCostStatus,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.SetCostStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.SetCostStatusResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.CostServicePromiseClient.prototype.setCostStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/SetCostStatus',
      request,
      metadata || {},
      methodDescriptor_CostService_SetCostStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.SetShipmentCostsRequest,
 *   !proto.swift.invoicing.v1.SetShipmentCostsResponse>}
 */
const methodDescriptor_CostService_SetShipmentCosts = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.CostService/SetShipmentCosts',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.SetShipmentCostsRequest,
  proto.swift.invoicing.v1.SetShipmentCostsResponse,
  /**
   * @param {!proto.swift.invoicing.v1.SetShipmentCostsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.SetShipmentCostsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.SetShipmentCostsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.SetShipmentCostsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.CostServiceClient.prototype.setShipmentCosts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/SetShipmentCosts',
      request,
      metadata || {},
      methodDescriptor_CostService_SetShipmentCosts,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.SetShipmentCostsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.CostServicePromiseClient.prototype.setShipmentCosts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.CostService/SetShipmentCosts',
      request,
      metadata || {},
      methodDescriptor_CostService_SetShipmentCosts);
};


module.exports = proto.swift.invoicing.v1;

