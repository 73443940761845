/**
 * @fileoverview gRPC-Web generated client stub for swift.iam.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: iam/v1/branch.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_query_pb = require('../../common/query_pb.js')

var crm_v1_address_pb = require('../../crm/v1/address_pb.js')

var common_provider_pb = require('../../common/provider_pb.js')
const proto = {};
proto.swift = {};
proto.swift.iam = {};
proto.swift.iam.v1 = require('./branch_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.BranchServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.BranchServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.ListBranchesRequest,
 *   !proto.swift.iam.v1.ListBranchesResponse>}
 */
const methodDescriptor_BranchService_ListBranches = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.BranchService/ListBranches',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.ListBranchesRequest,
  proto.swift.iam.v1.ListBranchesResponse,
  /**
   * @param {!proto.swift.iam.v1.ListBranchesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.ListBranchesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.ListBranchesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.ListBranchesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.ListBranchesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.BranchServiceClient.prototype.listBranches =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.BranchService/ListBranches',
      request,
      metadata || {},
      methodDescriptor_BranchService_ListBranches,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.ListBranchesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.ListBranchesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.BranchServicePromiseClient.prototype.listBranches =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.BranchService/ListBranches',
      request,
      metadata || {},
      methodDescriptor_BranchService_ListBranches);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.GetBranchRequest,
 *   !proto.swift.iam.v1.GetBranchResponse>}
 */
const methodDescriptor_BranchService_GetBranch = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.BranchService/GetBranch',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.GetBranchRequest,
  proto.swift.iam.v1.GetBranchResponse,
  /**
   * @param {!proto.swift.iam.v1.GetBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.GetBranchResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.GetBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.GetBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.GetBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.BranchServiceClient.prototype.getBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.BranchService/GetBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_GetBranch,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.GetBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.GetBranchResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.BranchServicePromiseClient.prototype.getBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.BranchService/GetBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_GetBranch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.CreateBranchRequest,
 *   !proto.swift.iam.v1.CreateBranchResponse>}
 */
const methodDescriptor_BranchService_CreateBranch = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.BranchService/CreateBranch',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.CreateBranchRequest,
  proto.swift.iam.v1.CreateBranchResponse,
  /**
   * @param {!proto.swift.iam.v1.CreateBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.CreateBranchResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.CreateBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.CreateBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.CreateBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.BranchServiceClient.prototype.createBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.BranchService/CreateBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_CreateBranch,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.CreateBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.CreateBranchResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.BranchServicePromiseClient.prototype.createBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.BranchService/CreateBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_CreateBranch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.EditBranchRequest,
 *   !proto.swift.iam.v1.EditBranchResponse>}
 */
const methodDescriptor_BranchService_EditBranch = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.BranchService/EditBranch',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.EditBranchRequest,
  proto.swift.iam.v1.EditBranchResponse,
  /**
   * @param {!proto.swift.iam.v1.EditBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.EditBranchResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.EditBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.EditBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.EditBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.BranchServiceClient.prototype.editBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.BranchService/EditBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_EditBranch,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.EditBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.EditBranchResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.BranchServicePromiseClient.prototype.editBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.BranchService/EditBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_EditBranch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.DeleteBranchRequest,
 *   !proto.swift.iam.v1.DeleteBranchResponse>}
 */
const methodDescriptor_BranchService_DeleteBranch = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.BranchService/DeleteBranch',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.DeleteBranchRequest,
  proto.swift.iam.v1.DeleteBranchResponse,
  /**
   * @param {!proto.swift.iam.v1.DeleteBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.DeleteBranchResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.DeleteBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.DeleteBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.DeleteBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.BranchServiceClient.prototype.deleteBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.BranchService/DeleteBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_DeleteBranch,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.DeleteBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.DeleteBranchResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.BranchServicePromiseClient.prototype.deleteBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.BranchService/DeleteBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_DeleteBranch);
};


module.exports = proto.swift.iam.v1;

