import * as organizationv1 from '../../../proto/iam/v1/organization_pb'

import { RootState } from '../../../store/reducer'

import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: organizationv1.Organization }
  readonly err?: Error
  readonly isFetching: boolean
  readonly currentOrg?: organizationv1.Organization
  readonly isFetchingList: boolean
  readonly listErr?: Error
  readonly latestCreatedOrganizationId: number | undefined
}

const initialState: State = {
  items: {},
  err: undefined,
  isFetching: false,
  currentOrg: undefined,
  isFetchingList: false,
  listErr: undefined,
  latestCreatedOrganizationId: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.iam.organization.items).map((id) => state.iam.organization.items[id])
}
export const getItemsByBranchId = (state: RootState, branchId: number) => {
  return Object.values(state.iam.organization.items).filter((o) => o.getBranchId() === branchId)
}

export const getEnabledItems = (state: RootState) => {
  return Object.values(state.iam.organization.items).filter((o) => o.getEnabled())
}

export const getItemsById = (state: RootState) => {
  return state.iam.organization.items
}

export const selectItemById = (state: RootState, orgId: number) => {
  return getItems(state).find((org) => org.getOrganizationId() === orgId)
}

export const getErr = (state: RootState) => state.iam.organization.err
export const getIsFetching = (state: RootState) => state.iam.organization.isFetching
export const getCurrentOrg = (state: RootState) => state.iam.organization.currentOrg

export const getListErr = (state: RootState) => state.iam.organization.listErr
export const getIsFetchingList = (state: RootState) => state.iam.organization.isFetchingList

export const getLatestCreatedOrganizationId = (state: RootState) =>
  state.iam.organization.latestCreatedOrganizationId

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, isFetchingList: true }
    }

    case actions.LIST_RESP: {
      const { organizations } = action.payload
      const newItems = organizations.reduce((obj, org) => {
        return { ...obj, [org.getOrganizationId()]: org }
      }, {})
      const items = { ...s.items, ...newItems }
      return { ...s, items, isFetchingList: false, listErr: undefined }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, isFetchingList: false, listErr: err }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { organization } = action.payload
      if (!organization) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [organization.getOrganizationId()]: organization,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { organization } = action.payload
      if (!organization) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [organization.getOrganizationId()]: organization,
      }
      return {
        ...s,
        items,
        isFetching: false,
        latestCreatedOrganizationId: organization.getOrganizationId(),
      }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { organization } = action.payload
      const items = {
        ...s.items,
        [organization.getOrganizationId()]: organization,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_INV_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_INV_RESP: {
      const { organization } = action.payload
      const items = {
        ...s.items,
        [organization.getOrganizationId()]: organization,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_INV_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_INSTRUCTIONS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_INSTRUCTIONS_RESP: {
      const { organization } = action.payload
      const items = {
        ...s.items,
        [organization.getOrganizationId()]: organization,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.EDIT_INSTRUCTIONS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_CURRENT_ORG: {
      const { organization } = action.payload
      return { ...s, currentOrg: organization }
    }

    default:
      return s
  }
}
