import { call, debounce, put, takeEvery } from 'redux-saga/effects'

import { SignupServicePromiseClient } from '../../proto/iam/v1/signup_grpc_web_pb'
import * as signupv1 from '../../proto/iam/v1/signup_pb'

import {
  Actions,
  SIGNUP_USER_REQ,
  VALIDATE_JOIN_CODE_INPUT,
  VALIDATE_JOIN_CODE_REQ,
} from '../../store/iam/signup/actions'
import { Actions as UserActions } from '../../store/iam/user/actions'

import { authMetadata } from '../../helpers/auth'

export function* validateJoinCodeInput(action: ReturnType<typeof Actions.signupUser>) {
  const { joinCode } = action.payload
  yield put(Actions.validateJoinCodeReq(joinCode))
}

export function* validateJoinCode(
  client: SignupServicePromiseClient,
  action: ReturnType<typeof Actions.signupUser>,
) {
  try {
    const { joinCode } = action.payload

    if (!joinCode) {
      throw new Error('missing join code')
    }

    const req = new signupv1.ValidateJoinCodeRequest()
    req.setJoinCode(joinCode)

    const resp: signupv1.ValidateJoinCodeResponse = yield call(
      [client, client.validateJoinCode],
      req,
      authMetadata(),
    )
    yield put(Actions.validateJoinCodeResp(resp))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.validateJoinCodeErr(err))
  }
}

export function* signupUser(
  client: SignupServicePromiseClient,
  action: ReturnType<typeof Actions.signupUser>,
) {
  try {
    const { userID, email, joinCode, address } = action.payload

    const req = new signupv1.SignupUserRequest()
    const userSignup = new signupv1.UserSignup()
    userSignup.setUserId(userID)
    userSignup.setEmail(email)
    userSignup.setJoinCode(joinCode)
    userSignup.setAddress(address)
    req.setUserSignup(userSignup)
    const resp: signupv1.SignupUserResponse = yield call(
      [client, client.signupUser],
      req,
      authMetadata(),
    )

    const newUser = resp.getUser()
    if (!newUser) {
      throw new Error('missing user')
    }

    yield put(UserActions.createUserResp(newUser))
    yield put(Actions.signupUserResp())
  } catch (err) {
    if (err instanceof Error) yield put(Actions.signupUserErr(err))
  }
}

export default function* sagas() {
  const client = new SignupServicePromiseClient('')

  yield debounce(1000, VALIDATE_JOIN_CODE_INPUT, validateJoinCodeInput)
  yield takeEvery(VALIDATE_JOIN_CODE_REQ, validateJoinCode, client)
  yield takeEvery(SIGNUP_USER_REQ, signupUser, client)
}
