import { RootState } from '../../store/reducer'

import { Notif } from './types'

import * as actions from './actions'

export interface State {
  readonly items: ReadonlyArray<Notif>
}

const initialState: State = {
  items: [],
}

export const getItems = (state: RootState) => state.notification.items

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.SEND: {
      const { notif } = action.payload
      const items = [notif, ...s.items.filter((n) => n.key !== notif.key)]
      return { items }
    }

    case actions.DISMISS: {
      const { key } = action.payload
      const items = s.items.filter((n) => n.key !== key)
      return { items }
    }

    case actions.CLEAR: {
      return { items: [] }
    }

    default:
      return s
  }
}
