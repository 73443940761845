/**
 * @fileoverview gRPC-Web generated client stub for swift.order.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: order/v1/order.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var crm_v1_address_pb = require('../../crm/v1/address_pb.js')

var common_query_pb = require('../../common/query_pb.js')
const proto = {};
proto.swift = {};
proto.swift.order = {};
proto.swift.order.v1 = require('./order_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.order.v1.OrderServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.order.v1.OrderServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.order.v1.ListOrdersRequest,
 *   !proto.swift.order.v1.ListOrdersResponse>}
 */
const methodDescriptor_OrderService_ListOrders = new grpc.web.MethodDescriptor(
  '/swift.order.v1.OrderService/ListOrders',
  grpc.web.MethodType.UNARY,
  proto.swift.order.v1.ListOrdersRequest,
  proto.swift.order.v1.ListOrdersResponse,
  /**
   * @param {!proto.swift.order.v1.ListOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.order.v1.ListOrdersResponse.deserializeBinary
);


/**
 * @param {!proto.swift.order.v1.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.order.v1.ListOrdersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.order.v1.ListOrdersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.order.v1.OrderServiceClient.prototype.listOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.order.v1.OrderService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_OrderService_ListOrders,
      callback);
};


/**
 * @param {!proto.swift.order.v1.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.order.v1.ListOrdersResponse>}
 *     Promise that resolves to the response
 */
proto.swift.order.v1.OrderServicePromiseClient.prototype.listOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.order.v1.OrderService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_OrderService_ListOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.order.v1.GetOrderRequest,
 *   !proto.swift.order.v1.GetOrderResponse>}
 */
const methodDescriptor_OrderService_GetOrder = new grpc.web.MethodDescriptor(
  '/swift.order.v1.OrderService/GetOrder',
  grpc.web.MethodType.UNARY,
  proto.swift.order.v1.GetOrderRequest,
  proto.swift.order.v1.GetOrderResponse,
  /**
   * @param {!proto.swift.order.v1.GetOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.order.v1.GetOrderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.order.v1.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.order.v1.GetOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.order.v1.GetOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.order.v1.OrderServiceClient.prototype.getOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.order.v1.OrderService/GetOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_GetOrder,
      callback);
};


/**
 * @param {!proto.swift.order.v1.GetOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.order.v1.GetOrderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.order.v1.OrderServicePromiseClient.prototype.getOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.order.v1.OrderService/GetOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_GetOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.order.v1.GetOrderEventsRequest,
 *   !proto.swift.order.v1.GetOrderEventsResponse>}
 */
const methodDescriptor_OrderService_GetOrderEvents = new grpc.web.MethodDescriptor(
  '/swift.order.v1.OrderService/GetOrderEvents',
  grpc.web.MethodType.UNARY,
  proto.swift.order.v1.GetOrderEventsRequest,
  proto.swift.order.v1.GetOrderEventsResponse,
  /**
   * @param {!proto.swift.order.v1.GetOrderEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.order.v1.GetOrderEventsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.order.v1.GetOrderEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.order.v1.GetOrderEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.order.v1.GetOrderEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.order.v1.OrderServiceClient.prototype.getOrderEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.order.v1.OrderService/GetOrderEvents',
      request,
      metadata || {},
      methodDescriptor_OrderService_GetOrderEvents,
      callback);
};


/**
 * @param {!proto.swift.order.v1.GetOrderEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.order.v1.GetOrderEventsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.order.v1.OrderServicePromiseClient.prototype.getOrderEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.order.v1.OrderService/GetOrderEvents',
      request,
      metadata || {},
      methodDescriptor_OrderService_GetOrderEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.order.v1.GetOrderStatsRequest,
 *   !proto.swift.order.v1.GetOrderStatsResponse>}
 */
const methodDescriptor_OrderService_GetOrderStats = new grpc.web.MethodDescriptor(
  '/swift.order.v1.OrderService/GetOrderStats',
  grpc.web.MethodType.UNARY,
  proto.swift.order.v1.GetOrderStatsRequest,
  proto.swift.order.v1.GetOrderStatsResponse,
  /**
   * @param {!proto.swift.order.v1.GetOrderStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.order.v1.GetOrderStatsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.order.v1.GetOrderStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.order.v1.GetOrderStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.order.v1.GetOrderStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.order.v1.OrderServiceClient.prototype.getOrderStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.order.v1.OrderService/GetOrderStats',
      request,
      metadata || {},
      methodDescriptor_OrderService_GetOrderStats,
      callback);
};


/**
 * @param {!proto.swift.order.v1.GetOrderStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.order.v1.GetOrderStatsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.order.v1.OrderServicePromiseClient.prototype.getOrderStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.order.v1.OrderService/GetOrderStats',
      request,
      metadata || {},
      methodDescriptor_OrderService_GetOrderStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.order.v1.CreateOrderRequest,
 *   !proto.swift.order.v1.CreateOrderResponse>}
 */
const methodDescriptor_OrderService_CreateOrder = new grpc.web.MethodDescriptor(
  '/swift.order.v1.OrderService/CreateOrder',
  grpc.web.MethodType.UNARY,
  proto.swift.order.v1.CreateOrderRequest,
  proto.swift.order.v1.CreateOrderResponse,
  /**
   * @param {!proto.swift.order.v1.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.order.v1.CreateOrderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.order.v1.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.order.v1.CreateOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.order.v1.CreateOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.order.v1.OrderServiceClient.prototype.createOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.order.v1.OrderService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_CreateOrder,
      callback);
};


/**
 * @param {!proto.swift.order.v1.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.order.v1.CreateOrderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.order.v1.OrderServicePromiseClient.prototype.createOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.order.v1.OrderService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_CreateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.order.v1.UpdateOrderRequest,
 *   !proto.swift.order.v1.UpdateOrderResponse>}
 */
const methodDescriptor_OrderService_UpdateOrder = new grpc.web.MethodDescriptor(
  '/swift.order.v1.OrderService/UpdateOrder',
  grpc.web.MethodType.UNARY,
  proto.swift.order.v1.UpdateOrderRequest,
  proto.swift.order.v1.UpdateOrderResponse,
  /**
   * @param {!proto.swift.order.v1.UpdateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.order.v1.UpdateOrderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.order.v1.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.order.v1.UpdateOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.order.v1.UpdateOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.order.v1.OrderServiceClient.prototype.updateOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.order.v1.OrderService/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_UpdateOrder,
      callback);
};


/**
 * @param {!proto.swift.order.v1.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.order.v1.UpdateOrderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.order.v1.OrderServicePromiseClient.prototype.updateOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.order.v1.OrderService/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_UpdateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.order.v1.ApplyOrderEventRequest,
 *   !proto.swift.order.v1.ApplyOrderEventResponse>}
 */
const methodDescriptor_OrderService_ApplyOrderEvent = new grpc.web.MethodDescriptor(
  '/swift.order.v1.OrderService/ApplyOrderEvent',
  grpc.web.MethodType.UNARY,
  proto.swift.order.v1.ApplyOrderEventRequest,
  proto.swift.order.v1.ApplyOrderEventResponse,
  /**
   * @param {!proto.swift.order.v1.ApplyOrderEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.order.v1.ApplyOrderEventResponse.deserializeBinary
);


/**
 * @param {!proto.swift.order.v1.ApplyOrderEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.order.v1.ApplyOrderEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.order.v1.ApplyOrderEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.order.v1.OrderServiceClient.prototype.applyOrderEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.order.v1.OrderService/ApplyOrderEvent',
      request,
      metadata || {},
      methodDescriptor_OrderService_ApplyOrderEvent,
      callback);
};


/**
 * @param {!proto.swift.order.v1.ApplyOrderEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.order.v1.ApplyOrderEventResponse>}
 *     Promise that resolves to the response
 */
proto.swift.order.v1.OrderServicePromiseClient.prototype.applyOrderEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.order.v1.OrderService/ApplyOrderEvent',
      request,
      metadata || {},
      methodDescriptor_OrderService_ApplyOrderEvent);
};


module.exports = proto.swift.order.v1;

