import * as costv1 from 'proto/invoicing/v1/cost_pb'
import * as shipmentv1 from 'proto/shipment/v1/shipment_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as shipmentTypes from 'types/shipment'

export const LIST_REQ = 'swift2/shipment/LIST_REQ'
export const LIST_RESP = 'swift2/shipment/LIST_RESP'
export const LIST_ERR = 'swift2/shipment/LIST_ERR'
export const GET_REQ = 'swift2/shipment/GET_REQ'
export const GET_RESP = 'swift2/shipment/GET_RESP'
export const GET_ERR = 'swift2/shipment/GET_ERR'
export const GET_STATS_REQ = 'swift2/shipment/GET_STATS_REQ'
export const GET_STATS_RESP = 'swift2/shipment/GET_STATS_RESP'
export const GET_STATS_ERR = 'swift2/shipment/GET_STATS_ERR'
export const UPDATE_INTERNAL_NOTE_REQ = 'swift2/shipment/UPDATE_INTERNAL_NOTE_REQ'
export const UPDATE_INTERNAL_NOTE_RESP = 'swift2/shipment/UPDATE_INTERNAL_NOTE_RESP'
export const UPDATE_INTERNAL_NOTE_ERR = 'swift2/shipment/UPDATE_INTERNAL_NOTE_ERR'

export const REMOVE_BOOKINGS_FROM_SHIPMENTS_REQ =
  'swift2/shipment/REMOVE_BOOKINGS_FROM_SHIPMENTS_REQ'
export const REMOVE_BOOKINGS_FROM_SHIPMENTS_RESP =
  'swift2/shipment/REMOVE_BOOKINGS_FROM_SHIPMENTS_RESP'
export const REMOVE_BOOKINGS_FROM_SHIPMENTS_ERR =
  'swift2/shipment/REMOVE_BOOKINGS_FROM_SHIPMENTS_ERR'

export const CREATE_SHIPMENT_COST_REQ = 'swift2/shipment/CREATE_SHIPMENT_COST_REQ'
export const CREATE_SHIPMENT_COST_RESP = 'swift2/shipment/CREATE_SHIPMENT_COST_RESP'
export const CREATE_SHIPMENT_COST_ERR = 'swift2/shipment/CREATE_SHIPMENT_COST_ERR'

export const LIST_SPLIT_COST_REF_REQ = 'swift2/shipment/LIST_SPLIT_COST_REF_REQ'
export const LIST_SPLIT_COST_REF_RESP = 'swift2/shipment/LIST_SPLIT_COST_REF_RESP'
export const LIST_SPLIT_COST_REF_ERR = 'swift2/shipment/LIST_SPLIT_COST_REF_ERR'

export const ADD_BOOKINGS_TO_SHIPMENTS_REQ = 'swift2/shipment/ADD_BOOKINGS_TO_SHIPMENTS_REQ'
export const ADD_BOOKINGS_TO_SHIPMENTS_RESP = 'swift2/shipment/ADD_BOOKINGS_TO_SHIPMENTS_RESP'
export const ADD_BOOKINGS_TO_SHIPMENTS_ERR = 'swift2/shipment/ADD_BOOKINGS_TO_SHIPMENTS_ERR'

export const SET_BOOKING_ORDER_SHIPMENT_REQ = 'swift2/shipment/SET_BOOKING_ORDER_SHIPMENT_REQ'
export const SET_BOOKING_ORDER_SHIPMENT_RESP = 'swift2/shipment/SET_BOOKING_ORDER_SHIPMENT_RESP'
export const SET_BOOKING_ORDER_SHIPMENT_ERR = 'swift2/shipment/SET_BOOKING_ORDER_SHIPMENT_ERR'

export const CREATE_REQ = 'swift2/shipment/CREATE_REQ'
export const CREATE_RESP = 'swift2/shipment/CREATE_RESP'
export const CREATE_ERR = 'swift2/shipment/CREATE_ERR'

export const UPDATE_REQ = 'swift2/shipment/EDIT_REQ'
export const UPDATE_RESP = 'swift2/shipment/EDIT_RESP'
export const UPDATE_ERR = 'swift2/shipment/EDIT_ERR'

export const UPDATE_SHIPMENT_COST_REQ = 'swift2/shipment/UPDATE_SHIPMENT_COST_REQ'
export const UPDATE_SHIPMENT_COST_RESP = 'swift2/shipment/UPDATE_SHIPMENT_COST_RESP'
export const UPDATE_SHIPMENT_COST_ERR = 'swift2/shipment/UPDATE_SHIPMENT_COST_ERR'

export const UPDATE_SHIPMENT_COST_STATUS_REQ = 'swift2/shipment/SET_SHIPMENT_COST_STATUS_REQ'
export const UPDATE_SHIPMENT_COST_STATUS_RESP = 'swift2/shipment/SET_SHIPMENT_COST_STATUS_RESP'
export const UPDATE_SHIPMENT_COST_STATUS_ERR = 'swift2/shipment/SET_SHIPMENT_COST_STATUS_ERR'

export const Actions = {
  listShipmentsReq: (page: shipmentTypes.ShipmentPage) => createAction(LIST_REQ, { page }),
  listShipmentsResp: (
    count: number,
    shipments: shipmentv1.Shipment[],
    page: shipmentTypes.ShipmentPage,
  ) => createAction(LIST_RESP, { count, shipments, page }),
  listShipmentsErr: (err: Error) => createAction(LIST_ERR, { err }),

  getShipmentReq: (ref: string, fetchLinkedItems: boolean) =>
    createAction(GET_REQ, { ref, fetchLinkedItems }),
  getShipmentResp: (shipment?: shipmentv1.Shipment) => createAction(GET_RESP, { shipment }),
  getShipmentErr: (err: Error) => createAction(GET_ERR, { err }),

  getShipmentStatsReq: (query: shipmentv1.ShipmentStatsQuery) =>
    createAction(GET_STATS_REQ, { query }),
  getShipmentStatsResp: (stats: shipmentv1.ShipmentStats) =>
    createAction(GET_STATS_RESP, { stats }),
  getShipmentStatsErr: (err: Error) => createAction(GET_STATS_ERR, { err }),

  updateInternalNoteReq: (shipment: shipmentv1.Shipment) =>
    createAction(UPDATE_INTERNAL_NOTE_REQ, { shipment }),
  updateInternalNoteResp: (shipment: shipmentv1.Shipment) =>
    createAction(UPDATE_INTERNAL_NOTE_RESP, { shipment }),
  updateInternalNoteErr: (err: Error) => createAction(UPDATE_INTERNAL_NOTE_ERR, { err }),

  removeBookingsFromShipmentReq: (bookingRefs: string[], shipment: shipmentv1.Shipment) =>
    createAction(REMOVE_BOOKINGS_FROM_SHIPMENTS_REQ, { bookingRefs, shipment }),
  removeBookingsFromShipmentResp: (shipment: shipmentv1.Shipment) =>
    createAction(REMOVE_BOOKINGS_FROM_SHIPMENTS_RESP, { shipment }),
  removeBookingsFromShipmentErr: (err: Error) =>
    createAction(REMOVE_BOOKINGS_FROM_SHIPMENTS_ERR, { err }),

  createShipmentCostReq: (
    shipmentRef: string,
    bookingRefs: string[],
    amount: number,
    currency: string,
    costType: costv1.Cost.Type,
    supplierId: number,
    comment: string,
  ) =>
    createAction(CREATE_SHIPMENT_COST_REQ, {
      shipmentRef,
      bookingRefs,
      amount,
      currency,
      costType,
      supplierId,
      comment,
    }),
  createShipmentCostResp: (shipmentCost: shipmentv1.SplitCostReference) =>
    createAction(CREATE_SHIPMENT_COST_RESP, { shipmentCost }),
  createShipmentCostErr: (err: Error) => createAction(CREATE_SHIPMENT_COST_ERR, { err }),

  listSplitCostRefReq: (shipmentId: number) =>
    createAction(LIST_SPLIT_COST_REF_REQ, { shipmentId }),
  listSplitCostRefResp: (costRef: shipmentv1.SplitCostReference[]) =>
    createAction(LIST_SPLIT_COST_REF_RESP, { costRef }),
  listSplitCostRefErr: (err: Error) => createAction(LIST_SPLIT_COST_REF_ERR, { err }),

  addBookingsToShipmentReq: (bookingRefs: string[], shipment: shipmentv1.Shipment) =>
    createAction(ADD_BOOKINGS_TO_SHIPMENTS_REQ, { bookingRefs, shipment }),
  addBookingsToShipmentResp: (shipment: shipmentv1.Shipment) =>
    createAction(ADD_BOOKINGS_TO_SHIPMENTS_RESP, { shipment }),
  addBookingsToShipmentErr: (err: Error) => createAction(ADD_BOOKINGS_TO_SHIPMENTS_ERR, { err }),

  setBookingOrderInShipmentReq: (shipmentRef: string, bookingsRefs: string[]) =>
    createAction(SET_BOOKING_ORDER_SHIPMENT_REQ, { shipmentRef, bookingsRefs }),
  setBookingOrderInShipmentResp: (shipment: shipmentv1.Shipment) =>
    createAction(SET_BOOKING_ORDER_SHIPMENT_RESP, { shipment }),
  setBookingOrderInShipmentErr: (err: Error) =>
    createAction(SET_BOOKING_ORDER_SHIPMENT_ERR, { err }),

  createShipmentReq: (shipment: shipmentv1.Shipment) => createAction(CREATE_REQ, { shipment }),
  createShipmentResp: (shipment: shipmentv1.Shipment) => createAction(CREATE_RESP, { shipment }),
  createShipmentErr: (err: Error) => createAction(CREATE_ERR, { err }),

  updateShipmentReq: (shipment: shipmentv1.Shipment) => createAction(UPDATE_REQ, { shipment }),
  updateShipmentResp: (shipment: shipmentv1.Shipment) => createAction(UPDATE_RESP, { shipment }),
  updateShipmentErr: (err: Error) => createAction(UPDATE_ERR, { err }),

  updateShipmentCostReq: (cost: shipmentv1.UpdateShipmentCostRequest) =>
    createAction(UPDATE_SHIPMENT_COST_REQ, { cost }),
  updateShipmentCostResp: (cost: shipmentv1.UpdateShipmentCostResponse) =>
    createAction(UPDATE_SHIPMENT_COST_RESP, { cost }),
  updateShipmentCostErr: (err: Error) => createAction(UPDATE_SHIPMENT_COST_ERR, { err }),
  updateShipmentCostStatusReq: (
    splitCostRefId: number,
    status: costv1.Cost.Status,
    comment: string,
  ) => createAction(UPDATE_SHIPMENT_COST_STATUS_REQ, { splitCostRefId, status, comment }),
  updateShipmentCostStatusResp: (shipmentCost: shipmentv1.SplitCostReference) =>
    createAction(UPDATE_SHIPMENT_COST_STATUS_RESP, { shipmentCost }),
  updateShipmentCostStatusErr: (err: Error) =>
    createAction(UPDATE_SHIPMENT_COST_STATUS_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
