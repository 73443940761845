import React, { useEffect, useId, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { Rule } from 'antd/lib/form'
import * as lib from 'country-data'
import parsePhoneNumberFromString, {
  CountryCode,
  isValidNumber,
  parseIncompletePhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js'

import * as branchSelectors from 'store/iam/branch/reducer'
import * as organizationSelectors from 'store/iam/organization/reducer'
import { RootState } from 'store/reducer'

import { runRules } from '../helpers/CustomValidator.helper'
import { getInputClassName } from '../helpers/Input.helper'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'
import { CountryWithFlag } from '../types/countryCode.type'

import './Input.css'
import './PhoneNumber.css'

interface Props extends InputCommonProps {
  value: string
  placeholder?: string
  formName: string
  label: string
  rules?: Rule[]
  formRef: FormInstance<any>
  onChange: (val: string) => void
}

export const PhoneInputComponent = ({
  value,
  formName,
  formRef,
  label,
  disabled,
  overrideWidth,
  size = INPUTSIZES.large,
  placeholder = '0707-717171',
  onChange,
}: Props) => {
  const countryCodeId = useId()
  const branchCountryCode = useSelector((state: RootState) =>
    branchSelectors.getCurrentBranchCountryCode(state),
  )
  /**
   * Used when a user manually selects a country code.
   * Should be used as fallback when the country code could not be parsed from value
   */
  const userPrefferedCountryCode = useRef('')
  const currentOrg = useSelector((state: RootState) => organizationSelectors.getCurrentOrg(state))
  const orgCountryCode = currentOrg?.getAddress()?.getCountryId().toUpperCase()
  const branchPhoneCountryCode = branchCountryCode
    ? lib.countries[branchCountryCode]?.countryCallingCodes[0] ?? ''
    : ''

  const organizationCountryCode = orgCountryCode
    ? lib.countries[orgCountryCode]?.countryCallingCodes[0] ?? ''
    : ''

  const findMatchingCountryCode = (country: lib.Country, contryCodeString: string) =>
    country.countryCallingCodes.some((code) => contryCodeString.includes(code))

  const getCountryCode = (contryCodeString: string) =>
    lib.countries.all.find((country) => findMatchingCountryCode(country, contryCodeString))

  const countryCode =
    getCountryCode(parseIncompletePhoneNumber(value))?.countryCallingCodes[0] ||
    userPrefferedCountryCode.current ||
    branchPhoneCountryCode ||
    organizationCountryCode

  const onCountryCodeChange = (v: string) => {
    try {
      userPrefferedCountryCode.current = v
      const country = getCountryCode(v)
      const parsedNumber = parsePhoneNumberFromString(value)?.nationalNumber
      const pp = parsePhoneNumber(parsedNumber || '', country?.alpha2 as CountryCode)
      onChange(pp.number)
    } catch (e) {
      // if the above parsing failed. emit country code so user can continue
      onChange(v)
    }
  }

  const parseNumberAndEmit = (val: string) => {
    try {
      const { number } = parsePhoneNumber(val, getCountryCode(countryCode)?.alpha2 as CountryCode)
      onChange(number)
    } catch {
      // emit the current value so the user can continue to enter the phone number
      onChange(val)
    }
  }

  const sortCodes = (codesWithFlagsA: CountryWithFlag, codesWithFlagsB: CountryWithFlag) => {
    const aNameTrimmed = +codesWithFlagsA.countryCallingCode.replace('+', '').replace(/ /g, '')
    const bNameTrimmed = +codesWithFlagsB.countryCallingCode.replace('+', '').replace(/ /g, '')
    return aNameTrimmed > bNameTrimmed ? 1 : -1
  }

  const getFlagIcon = (c: string) => {
    const country = lib.countries.all.find((country) =>
      country.countryCallingCodes.find((code) => code === c),
    )
    return country?.emoji ?? ''
  }
  const mapCodesTo = (c: string): CountryWithFlag => ({
    countryCallingCode: c,
    flag: getFlagIcon(c),
  })

  const setSortedCodes = () => lib.callingCodes.all.map(mapCodesTo).sort(sortCodes)

  const sortedCodesWithFlags = useMemo(setSortedCodes, [])

  useEffect(() => {
    if (formRef && formName) {
      const parsedPhoneNumber = parsePhoneNumberFromString(value)?.nationalNumber ?? value
      formRef.setFieldsValue({
        [formName]: parsedPhoneNumber || '',
      })
    }
  }, [value])

  useEffect(() => {
    if (formRef && countryCodeId) {
      formRef.setFieldsValue({
        [countryCodeId]: countryCode,
      })
    }
  }, [value])

  const someRule: Rule[] = [
    {
      message: (() => {
        const countryCodeObj = getCountryCode(countryCode)
        if (value && countryCodeObj) {
          return !isValidNumber(value, countryCodeObj.alpha2 as CountryCode)
            ? 'Invalid format on number'
            : undefined
        }
        return 'Field is required'
      })(),
    },
  ]

  return (
    <Form.Item
      name={formName}
      label={label}
      className={overrideWidth ? '' : getInputClassName(size) + ' inter__input--height'}
      rules={[
        {
          validator() {
            return runRules(someRule)
          },
        },
      ]}
    >
      <Input
        disabled={disabled}
        addonBefore={
          <Form.Item
            name={countryCodeId}
            className="inter__input--height"
            noStyle
            initialValue={countryCode}
          >
            <Select
              className="inter__input--height"
              showSearch
              style={{ maxWidth: 130, minWidth: 90 }}
              onChange={onCountryCodeChange}
              value={countryCode}
            >
              {sortedCodesWithFlags.map((r) => (
                <Select.Option key={r.countryCallingCode} value={r.countryCallingCode}>
                  <div className="phone-number__select">
                    <div>{r.countryCallingCode}</div>
                    <div>{r.flag}</div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        className={overrideWidth ? '' : getInputClassName(size)}
        placeholder={placeholder}
        style={{ width: overrideWidth }}
        onChange={(e) => parseNumberAndEmit(e.target.value)}
      ></Input>
    </Form.Item>
  )
}
