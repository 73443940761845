import React from 'react'

import { Divider } from 'antd'

import './dividercomponent.css'

interface DividerComponentProps {
  children?: JSX.Element
  marginBottom?: boolean
  type?: 'horizontal' | 'vertical'
  orientation?: 'left' | 'center'
}
export const DividerComponent = ({
  children,
  marginBottom,
  type = 'horizontal',
  orientation,
}: DividerComponentProps) => {
  return type === 'horizontal' ? (
    <Divider
      orientation={orientation}
      className={marginBottom ? 'divider-component--margin-bottom ' : ' divider-component--margin '}
    >
      {children}
    </Divider>
  ) : (
    <Divider type="vertical" className={'dotted-divider divider-component--vertical'}>
      {children}
    </Divider>
  )
}
