import { combineReducers } from 'redux'

import { State as ShipmentState, default as shipment } from './shipment/reducer'

export interface State {
  shipment: ShipmentState
}

export default combineReducers<State>({
  shipment,
} as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.
