import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingOutlined } from '@ant-design/icons'
import { Col, Layout, Row, Spin } from 'antd'
import { RouteNames } from 'route/routes-map'

import * as contactv1 from '../../proto/crm/v1/contact_pb'
import { Address } from 'proto/crm/v1/address_pb'

import { Actions as AuthActions } from '../../store/auth/actions'
import * as authSelectors from '../../store/auth/reducer'
import { Actions as SignupActions } from '../../store/iam/signup/actions'
import * as signupSelectors from '../../store/iam/signup/reducer'
import { RootState } from '../../store/reducer'

import { ErrorAlert } from '../../components/Alerts/Alerts'
import { ContentBox } from '../../components/ContentBox'

import { UserProfile } from '../../helpers/auth'
import { useRouter } from 'helpers/route.helper'

import { Footer } from '../../App/Footer'
import SignupForm from './SignupForm'

export const SetupUser = () => {
  const dispatch = useDispatch()
  const [navigate] = useRouter()

  const signupFetching = useSelector<RootState, boolean>((state) =>
    signupSelectors.getIsFetching(state),
  )
  const signupErr = useSelector<RootState, Error | undefined>((state) =>
    signupSelectors.getErr(state),
  )
  const isJoinCodeValid = useSelector<RootState, boolean | undefined>((state) =>
    signupSelectors.getIsJoinCodevalid(state),
  )
  const profile = useSelector<RootState, UserProfile | undefined>((state) =>
    authSelectors.getProfile(state),
  )
  const shouldCreateUsergroup = useSelector<RootState, boolean | undefined>((state) =>
    signupSelectors.getShouldCreateUsergroup(state),
  )

  const [joinCode, setJoinCode] = useState<string>('')
  const [address, setAddress] = useState(Address ? new Address() : undefined)
  const [contact, setContact] = useState(contactv1.Contact ? new contactv1.Contact() : undefined)

  useEffect(() => {
    document.title = 'Signup'
  }, [])

  const handleJoinCodeChange = (joinCode: string) => {
    setJoinCode(joinCode)
    dispatch(SignupActions.validateJoinCodeInput(joinCode))
  }

  const handleSubmit = (joinCode: string, address?: Address, contact?: contactv1.Contact) => {
    setAddress(address)
    setContact(contact)
    dispatch(SignupActions.cleanSignupUserErr())
    const userID = profile && profile.sub ? profile.sub : null
    const email = profile && profile.email ? profile.email : null

    if (isJoinCodeValid && userID && email) {
      dispatch(SignupActions.signupUser(userID, email, joinCode, address))
    }
  }

  const handleCancel = () => {
    dispatch(AuthActions.logout())
    navigate(RouteNames.Login)
  }
  if (!profile || !profile.email) {
    return (
      <ContentBox>
        <ErrorAlert
          message={'Missing email'}
          description={
            'Please provide a valid email address when creating your account. ' +
            'Contact info@intereast.se for further assistance.'
          }
        />
      </ContentBox>
    )
  }

  if (signupFetching && isJoinCodeValid) {
    return (
      <ContentBox>
        <div style={{ textAlign: 'center', marginTop: 48 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true} />} />
        </div>
      </ContentBox>
    )
  }

  return (
    <Layout className="SetupUser" style={{ minHeight: '100vh' }}>
      <Layout>
        <ContentBox>
          <Row>
            <Col span={24} md={{ span: 12, offset: 6 }}>
              <h1>Complete your account</h1>
              {signupErr ? (
                <ErrorAlert message={'Could not complete your account.'} closable={true} />
              ) : null}
              <SignupForm
                joinCode={joinCode}
                address={address}
                contact={contact}
                isJoinCodeValid={isJoinCodeValid}
                shouldCreateUsergroup={shouldCreateUsergroup}
                signupError={signupErr}
                isFetching={signupFetching}
                onJoinCodeChange={handleJoinCodeChange}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
              />
            </Col>
          </Row>
        </ContentBox>
      </Layout>

      <Footer />
    </Layout>
  )
}
