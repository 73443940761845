// source: booking/v1/pricing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
goog.exportSymbol('proto.swift.booking.v1.AddonPricing', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreatePricingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreatePricingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeletePricingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeletePricingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditPricingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditPricingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetPricingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetPricingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListPricingsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListPricingsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.Pricing', null, global);
goog.exportSymbol('proto.swift.booking.v1.PricingConfig', null, global);
goog.exportSymbol('proto.swift.booking.v1.PricingConfig.DataCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.PricingConfig.PricingType', null, global);
goog.exportSymbol('proto.swift.booking.v1.RatePricing', null, global);
goog.exportSymbol('proto.swift.booking.v1.TotalPricing', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Pricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.Pricing.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.Pricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Pricing.displayName = 'proto.swift.booking.v1.Pricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.PricingConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.booking.v1.PricingConfig.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.PricingConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.PricingConfig.displayName = 'proto.swift.booking.v1.PricingConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.RatePricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.RatePricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.RatePricing.displayName = 'proto.swift.booking.v1.RatePricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.AddonPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.AddonPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.AddonPricing.displayName = 'proto.swift.booking.v1.AddonPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.TotalPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.TotalPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.TotalPricing.displayName = 'proto.swift.booking.v1.TotalPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListPricingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ListPricingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListPricingsRequest.displayName = 'proto.swift.booking.v1.ListPricingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListPricingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListPricingsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListPricingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListPricingsResponse.displayName = 'proto.swift.booking.v1.ListPricingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetPricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetPricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetPricingRequest.displayName = 'proto.swift.booking.v1.GetPricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetPricingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetPricingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetPricingResponse.displayName = 'proto.swift.booking.v1.GetPricingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreatePricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreatePricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreatePricingRequest.displayName = 'proto.swift.booking.v1.CreatePricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreatePricingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreatePricingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreatePricingResponse.displayName = 'proto.swift.booking.v1.CreatePricingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditPricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditPricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditPricingRequest.displayName = 'proto.swift.booking.v1.EditPricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditPricingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditPricingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditPricingResponse.displayName = 'proto.swift.booking.v1.EditPricingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeletePricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeletePricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeletePricingRequest.displayName = 'proto.swift.booking.v1.DeletePricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeletePricingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeletePricingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeletePricingResponse.displayName = 'proto.swift.booking.v1.DeletePricingResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.Pricing.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Pricing.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Pricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Pricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Pricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    validForServiceIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    config: (f = msg.getConfig()) && proto.swift.booking.v1.PricingConfig.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.Pricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Pricing;
  return proto.swift.booking.v1.Pricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Pricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.Pricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPricingId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValidForServiceIds(values[i]);
      }
      break;
    case 5:
      var value = new proto.swift.booking.v1.PricingConfig;
      reader.readMessage(value,proto.swift.booking.v1.PricingConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Pricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Pricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Pricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Pricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValidForServiceIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.swift.booking.v1.PricingConfig.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 pricing_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.Pricing.prototype.getPricingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.setPricingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.Pricing.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.Pricing} returns this
*/
proto.swift.booking.v1.Pricing.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Pricing.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.swift.booking.v1.Pricing.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int64 valid_for_service_ids = 4;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.Pricing.prototype.getValidForServiceIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.setValidForServiceIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.addValidForServiceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.clearValidForServiceIdsList = function() {
  return this.setValidForServiceIdsList([]);
};


/**
 * optional PricingConfig config = 5;
 * @return {?proto.swift.booking.v1.PricingConfig}
 */
proto.swift.booking.v1.Pricing.prototype.getConfig = function() {
  return /** @type{?proto.swift.booking.v1.PricingConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.PricingConfig, 5));
};


/**
 * @param {?proto.swift.booking.v1.PricingConfig|undefined} value
 * @return {!proto.swift.booking.v1.Pricing} returns this
*/
proto.swift.booking.v1.Pricing.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Pricing.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Pricing.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Pricing} returns this
*/
proto.swift.booking.v1.Pricing.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Pricing.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Pricing.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Pricing} returns this
*/
proto.swift.booking.v1.Pricing.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Pricing} returns this
 */
proto.swift.booking.v1.Pricing.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Pricing.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.PricingConfig.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.PricingConfig.DataCase = {
  DATA_NOT_SET: 0,
  RATE: 2,
  ADDON: 3,
  TOTAL: 4
};

/**
 * @return {proto.swift.booking.v1.PricingConfig.DataCase}
 */
proto.swift.booking.v1.PricingConfig.prototype.getDataCase = function() {
  return /** @type {proto.swift.booking.v1.PricingConfig.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.PricingConfig.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.PricingConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.PricingConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.PricingConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.PricingConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rate: (f = msg.getRate()) && proto.swift.booking.v1.RatePricing.toObject(includeInstance, f),
    addon: (f = msg.getAddon()) && proto.swift.booking.v1.AddonPricing.toObject(includeInstance, f),
    total: (f = msg.getTotal()) && proto.swift.booking.v1.TotalPricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.PricingConfig}
 */
proto.swift.booking.v1.PricingConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.PricingConfig;
  return proto.swift.booking.v1.PricingConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.PricingConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.PricingConfig}
 */
proto.swift.booking.v1.PricingConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.PricingConfig.PricingType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.RatePricing;
      reader.readMessage(value,proto.swift.booking.v1.RatePricing.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 3:
      var value = new proto.swift.booking.v1.AddonPricing;
      reader.readMessage(value,proto.swift.booking.v1.AddonPricing.deserializeBinaryFromReader);
      msg.setAddon(value);
      break;
    case 4:
      var value = new proto.swift.booking.v1.TotalPricing;
      reader.readMessage(value,proto.swift.booking.v1.TotalPricing.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.PricingConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.PricingConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.PricingConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.PricingConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.RatePricing.serializeBinaryToWriter
    );
  }
  f = message.getAddon();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.booking.v1.AddonPricing.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.swift.booking.v1.TotalPricing.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.PricingConfig.PricingType = {
  RATE: 0,
  ADDON: 1,
  TOTAL: 2
};

/**
 * optional PricingType type = 1;
 * @return {!proto.swift.booking.v1.PricingConfig.PricingType}
 */
proto.swift.booking.v1.PricingConfig.prototype.getType = function() {
  return /** @type {!proto.swift.booking.v1.PricingConfig.PricingType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.PricingConfig.PricingType} value
 * @return {!proto.swift.booking.v1.PricingConfig} returns this
 */
proto.swift.booking.v1.PricingConfig.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RatePricing rate = 2;
 * @return {?proto.swift.booking.v1.RatePricing}
 */
proto.swift.booking.v1.PricingConfig.prototype.getRate = function() {
  return /** @type{?proto.swift.booking.v1.RatePricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.RatePricing, 2));
};


/**
 * @param {?proto.swift.booking.v1.RatePricing|undefined} value
 * @return {!proto.swift.booking.v1.PricingConfig} returns this
*/
proto.swift.booking.v1.PricingConfig.prototype.setRate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.swift.booking.v1.PricingConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.PricingConfig} returns this
 */
proto.swift.booking.v1.PricingConfig.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.PricingConfig.prototype.hasRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AddonPricing addon = 3;
 * @return {?proto.swift.booking.v1.AddonPricing}
 */
proto.swift.booking.v1.PricingConfig.prototype.getAddon = function() {
  return /** @type{?proto.swift.booking.v1.AddonPricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.AddonPricing, 3));
};


/**
 * @param {?proto.swift.booking.v1.AddonPricing|undefined} value
 * @return {!proto.swift.booking.v1.PricingConfig} returns this
*/
proto.swift.booking.v1.PricingConfig.prototype.setAddon = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.swift.booking.v1.PricingConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.PricingConfig} returns this
 */
proto.swift.booking.v1.PricingConfig.prototype.clearAddon = function() {
  return this.setAddon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.PricingConfig.prototype.hasAddon = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TotalPricing total = 4;
 * @return {?proto.swift.booking.v1.TotalPricing}
 */
proto.swift.booking.v1.PricingConfig.prototype.getTotal = function() {
  return /** @type{?proto.swift.booking.v1.TotalPricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.TotalPricing, 4));
};


/**
 * @param {?proto.swift.booking.v1.TotalPricing|undefined} value
 * @return {!proto.swift.booking.v1.PricingConfig} returns this
*/
proto.swift.booking.v1.PricingConfig.prototype.setTotal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.swift.booking.v1.PricingConfig.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.PricingConfig} returns this
 */
proto.swift.booking.v1.PricingConfig.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.PricingConfig.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.RatePricing.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.RatePricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.RatePricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.RatePricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    rate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minProfit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.RatePricing}
 */
proto.swift.booking.v1.RatePricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.RatePricing;
  return proto.swift.booking.v1.RatePricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.RatePricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.RatePricing}
 */
proto.swift.booking.v1.RatePricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.RatePricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.RatePricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.RatePricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.RatePricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMinProfit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int32 rate = 1;
 * @return {number}
 */
proto.swift.booking.v1.RatePricing.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.RatePricing} returns this
 */
proto.swift.booking.v1.RatePricing.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 min_profit = 2;
 * @return {number}
 */
proto.swift.booking.v1.RatePricing.prototype.getMinProfit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.RatePricing} returns this
 */
proto.swift.booking.v1.RatePricing.prototype.setMinProfit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.AddonPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.AddonPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.AddonPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AddonPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    profit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.AddonPricing}
 */
proto.swift.booking.v1.AddonPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.AddonPricing;
  return proto.swift.booking.v1.AddonPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.AddonPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.AddonPricing}
 */
proto.swift.booking.v1.AddonPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.AddonPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.AddonPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.AddonPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AddonPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 profit = 1;
 * @return {number}
 */
proto.swift.booking.v1.AddonPricing.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.AddonPricing} returns this
 */
proto.swift.booking.v1.AddonPricing.prototype.setProfit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.TotalPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.TotalPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.TotalPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TotalPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.TotalPricing}
 */
proto.swift.booking.v1.TotalPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.TotalPricing;
  return proto.swift.booking.v1.TotalPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.TotalPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.TotalPricing}
 */
proto.swift.booking.v1.TotalPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.TotalPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.TotalPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.TotalPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TotalPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.swift.booking.v1.TotalPricing.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.TotalPricing} returns this
 */
proto.swift.booking.v1.TotalPricing.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListPricingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListPricingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListPricingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListPricingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListPricingsRequest}
 */
proto.swift.booking.v1.ListPricingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListPricingsRequest;
  return proto.swift.booking.v1.ListPricingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListPricingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListPricingsRequest}
 */
proto.swift.booking.v1.ListPricingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListPricingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListPricingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListPricingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListPricingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListPricingsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListPricingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListPricingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListPricingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListPricingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pricingsList: jspb.Message.toObjectList(msg.getPricingsList(),
    proto.swift.booking.v1.Pricing.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListPricingsResponse}
 */
proto.swift.booking.v1.ListPricingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListPricingsResponse;
  return proto.swift.booking.v1.ListPricingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListPricingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListPricingsResponse}
 */
proto.swift.booking.v1.ListPricingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.Pricing;
      reader.readMessage(value,proto.swift.booking.v1.Pricing.deserializeBinaryFromReader);
      msg.addPricings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListPricingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListPricingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListPricingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListPricingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPricingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.booking.v1.ListPricingsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.ListPricingsResponse} returns this
 */
proto.swift.booking.v1.ListPricingsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Pricing pricings = 2;
 * @return {!Array<!proto.swift.booking.v1.Pricing>}
 */
proto.swift.booking.v1.ListPricingsResponse.prototype.getPricingsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Pricing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Pricing, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Pricing>} value
 * @return {!proto.swift.booking.v1.ListPricingsResponse} returns this
*/
proto.swift.booking.v1.ListPricingsResponse.prototype.setPricingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.Pricing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.ListPricingsResponse.prototype.addPricings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.Pricing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListPricingsResponse} returns this
 */
proto.swift.booking.v1.ListPricingsResponse.prototype.clearPricingsList = function() {
  return this.setPricingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetPricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetPricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetPricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetPricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetPricingRequest}
 */
proto.swift.booking.v1.GetPricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetPricingRequest;
  return proto.swift.booking.v1.GetPricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetPricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetPricingRequest}
 */
proto.swift.booking.v1.GetPricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPricingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetPricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetPricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetPricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetPricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 pricing_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.GetPricingRequest.prototype.getPricingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.GetPricingRequest} returns this
 */
proto.swift.booking.v1.GetPricingRequest.prototype.setPricingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetPricingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetPricingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetPricingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetPricingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.swift.booking.v1.Pricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetPricingResponse}
 */
proto.swift.booking.v1.GetPricingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetPricingResponse;
  return proto.swift.booking.v1.GetPricingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetPricingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetPricingResponse}
 */
proto.swift.booking.v1.GetPricingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Pricing;
      reader.readMessage(value,proto.swift.booking.v1.Pricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetPricingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetPricingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetPricingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetPricingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pricing pricing = 1;
 * @return {?proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.GetPricingResponse.prototype.getPricing = function() {
  return /** @type{?proto.swift.booking.v1.Pricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Pricing, 1));
};


/**
 * @param {?proto.swift.booking.v1.Pricing|undefined} value
 * @return {!proto.swift.booking.v1.GetPricingResponse} returns this
*/
proto.swift.booking.v1.GetPricingResponse.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetPricingResponse} returns this
 */
proto.swift.booking.v1.GetPricingResponse.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetPricingResponse.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreatePricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreatePricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreatePricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.swift.booking.v1.Pricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreatePricingRequest}
 */
proto.swift.booking.v1.CreatePricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreatePricingRequest;
  return proto.swift.booking.v1.CreatePricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreatePricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreatePricingRequest}
 */
proto.swift.booking.v1.CreatePricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Pricing;
      reader.readMessage(value,proto.swift.booking.v1.Pricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreatePricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreatePricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreatePricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pricing pricing = 1;
 * @return {?proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.CreatePricingRequest.prototype.getPricing = function() {
  return /** @type{?proto.swift.booking.v1.Pricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Pricing, 1));
};


/**
 * @param {?proto.swift.booking.v1.Pricing|undefined} value
 * @return {!proto.swift.booking.v1.CreatePricingRequest} returns this
*/
proto.swift.booking.v1.CreatePricingRequest.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreatePricingRequest} returns this
 */
proto.swift.booking.v1.CreatePricingRequest.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreatePricingRequest.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreatePricingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreatePricingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreatePricingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePricingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.swift.booking.v1.Pricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreatePricingResponse}
 */
proto.swift.booking.v1.CreatePricingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreatePricingResponse;
  return proto.swift.booking.v1.CreatePricingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreatePricingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreatePricingResponse}
 */
proto.swift.booking.v1.CreatePricingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Pricing;
      reader.readMessage(value,proto.swift.booking.v1.Pricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreatePricingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreatePricingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreatePricingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePricingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pricing pricing = 1;
 * @return {?proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.CreatePricingResponse.prototype.getPricing = function() {
  return /** @type{?proto.swift.booking.v1.Pricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Pricing, 1));
};


/**
 * @param {?proto.swift.booking.v1.Pricing|undefined} value
 * @return {!proto.swift.booking.v1.CreatePricingResponse} returns this
*/
proto.swift.booking.v1.CreatePricingResponse.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreatePricingResponse} returns this
 */
proto.swift.booking.v1.CreatePricingResponse.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreatePricingResponse.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditPricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditPricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditPricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditPricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.swift.booking.v1.Pricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditPricingRequest}
 */
proto.swift.booking.v1.EditPricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditPricingRequest;
  return proto.swift.booking.v1.EditPricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditPricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditPricingRequest}
 */
proto.swift.booking.v1.EditPricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Pricing;
      reader.readMessage(value,proto.swift.booking.v1.Pricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditPricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditPricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditPricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditPricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pricing pricing = 1;
 * @return {?proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.EditPricingRequest.prototype.getPricing = function() {
  return /** @type{?proto.swift.booking.v1.Pricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Pricing, 1));
};


/**
 * @param {?proto.swift.booking.v1.Pricing|undefined} value
 * @return {!proto.swift.booking.v1.EditPricingRequest} returns this
*/
proto.swift.booking.v1.EditPricingRequest.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditPricingRequest} returns this
 */
proto.swift.booking.v1.EditPricingRequest.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditPricingRequest.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditPricingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditPricingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditPricingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditPricingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricing: (f = msg.getPricing()) && proto.swift.booking.v1.Pricing.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditPricingResponse}
 */
proto.swift.booking.v1.EditPricingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditPricingResponse;
  return proto.swift.booking.v1.EditPricingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditPricingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditPricingResponse}
 */
proto.swift.booking.v1.EditPricingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Pricing;
      reader.readMessage(value,proto.swift.booking.v1.Pricing.deserializeBinaryFromReader);
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditPricingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditPricingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditPricingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditPricingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricing();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Pricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pricing pricing = 1;
 * @return {?proto.swift.booking.v1.Pricing}
 */
proto.swift.booking.v1.EditPricingResponse.prototype.getPricing = function() {
  return /** @type{?proto.swift.booking.v1.Pricing} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Pricing, 1));
};


/**
 * @param {?proto.swift.booking.v1.Pricing|undefined} value
 * @return {!proto.swift.booking.v1.EditPricingResponse} returns this
*/
proto.swift.booking.v1.EditPricingResponse.prototype.setPricing = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditPricingResponse} returns this
 */
proto.swift.booking.v1.EditPricingResponse.prototype.clearPricing = function() {
  return this.setPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditPricingResponse.prototype.hasPricing = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeletePricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeletePricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeletePricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeletePricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeletePricingRequest}
 */
proto.swift.booking.v1.DeletePricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeletePricingRequest;
  return proto.swift.booking.v1.DeletePricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeletePricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeletePricingRequest}
 */
proto.swift.booking.v1.DeletePricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPricingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeletePricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeletePricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeletePricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeletePricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 pricing_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeletePricingRequest.prototype.getPricingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeletePricingRequest} returns this
 */
proto.swift.booking.v1.DeletePricingRequest.prototype.setPricingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeletePricingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeletePricingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeletePricingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeletePricingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeletePricingResponse}
 */
proto.swift.booking.v1.DeletePricingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeletePricingResponse;
  return proto.swift.booking.v1.DeletePricingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeletePricingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeletePricingResponse}
 */
proto.swift.booking.v1.DeletePricingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeletePricingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeletePricingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeletePricingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeletePricingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.swift.booking.v1);
