import * as commonBooking from '../../proto/common/booking_pb'

const colors = {
  red: '#fa5a50', // default circle color
  gray: '#474747', // selected/hover circle color
}

/*
 * The svgs were extracted from svg files where they had viewBox="0 0 640 512"
 * and circle params: cx="327.5" cy="256" r="250"
 * These values are adjusted here but the old path is still used,
 * which is why transform is used to adjust the old paths
 */
const transform = 'scale(0.8), translate(-75, 115)'

const circle = (circleColor: string = colors.red) => {
  return `
    <svg fill="red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <circle fill="${circleColor}" cx="250" cy="250" r="250" />
    </svg>
  `
}

const box = (circleColor: string = colors.red, num?: number) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g>
        <circle fill="${circleColor}" cx="200" cy="300" r="200" />
        <path fill="#ffffff" transform="${transform}" d="M448.8,221.8l-24.2-72.6c-3.1-9.4-11.9-15.7-21.8-15.7h-67.6v91.9h114.2C449.2,224.2,449.2,223,448.8,221.8zM319.8,133.5h-67.6c-9.9,0-18.7,6.3-21.8,15.7l-24.2,72.6c-0.4,1.1-0.4,2.3-0.6,3.5h114.2V133.5z M205,240.7v114.8c0,12.7,10.3,23,23,23h199c12.7,0,23-10.3,23-23V240.7H205z"/>
      </g>
      ${displayNumCircle(num)}
    </svg>
  `
}

const courier = (circleColor: string = colors.red, num?: number) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g>
        <circle fill="${circleColor}" cx="200" cy="300" r="200" />
        <path fill="#ffffff" transform="${transform}" d="M333.8,275.7c9.7,2.7,18.6,7.2,26.6,13.3l86.7-28.9c4.5-1.5,6.9-6.4,5.4-10.8l-32.4-97.3c-1.5-4.5-6.4-6.9-10.8-5.4l-32.8,11l17.8,53.4l-32.6,10.8l-17.8-53.4l-33.1,11c-4.5,1.5-6.9,6.4-5.4,10.8L333.8,275.7L333.8,275.7zM484.7,301.8l-5.5-16.3c-1.5-4.5-6.4-6.9-10.8-5.4l-114.7,38.2c-9.2-11.8-23.4-19.5-39.5-19.9l-53.4-160.1c-2.4-7-8.9-11.8-16.3-11.8h-60.1c-4.7,0-8.6,3.9-8.6,8.6v17.2c0,4.7,3.9,8.6,8.6,8.6h47.7l49.5,148.6c-14,11-22.4,28.8-19.3,48.6c3.3,21.2,20.4,38.8,41.5,42.5c32.3,5.7,60.3-18.7,60.9-49.7l114.5-38.2C483.8,311.2,486.2,306.3,484.7,301.8z M313.3,375.7c-14.2,0-25.8-11.5-25.8-25.8s11.5-25.8,25.8-25.8c14.2,0,25.8,11.5,25.8,25.8S327.5,375.7,313.3,375.7z"/>
      </g>
      ${displayNumCircle(num)}
    </svg>
  `
}

const plane = (circleColor: string = colors.red, num?: number) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g>
        <circle fill="${circleColor}" cx="200" cy="300" r="200" />
        <path fill="#ffffff" transform="${transform}" d="M431.5,224h-57.1l-52.6-91.9c-1.4-2.5-4.1-4.1-6.9-4.1h-32.8c-5.3,0-9.2,5.1-7.7,10.2l24.5,85.8h-51.4l-21.6-28.8c-1.5-2-3.9-3.2-6.4-3.2h-20c-5.2,0-9,4.9-7.8,9.9l15.8,54.1l-15.8,54c-1.2,5.1,2.6,10,7.8,10h20c2.5,0,4.9-1.2,6.4-3.2l21.6-28.8h51.4l-24.5,85.8c-1.4,5.1,2.4,10.2,7.7,10.2h32.8c2.8,0,5.5-1.5,6.9-4l52.5-92h57.1c17.6,0,48-14.3,48-32S449.1,224,431.5,224z"/>
      </g>
      ${displayNumCircle(num)}
    </svg>
  `
}

const ship = (circleColor: string = colors.red, num?: number) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g>
        <circle fill="${circleColor}" cx="200" cy="300" r="200" />
        <path fill="#ffffff" transform="${transform}" d="M405.9,307.8l31.1-31.1c7.5-7.5,4.4-20.3-5.7-23.6l-18.6-6v-62.2c0-7.8-6.4-14.2-14.2-14.2h-28.4V153c0-5.9-4.8-10.7-10.7-10.7h-64c-5.9,0-10.7,4.8-10.7,10.7v17.8h-28.4c-7.8,0-14.2,6.4-14.2,14.2v62.2l-18.6,6c-10.1,3.2-13.2,16.1-5.7,23.6l31.1,31.1c-7.8,19.4-25.7,33.5-53,33.5c-5.9,0-10.7,4.8-10.7,10.7v7.1c0,5.9,4.8,10.7,10.7,10.7c27.1,0,47.7-9.2,63.6-26.4c6.4,15.5,21.7,26.4,39.4,26.4h56.9c17.8,0,33-10.9,39.4-26.4c15.9,17.2,36.5,26.4,63.6,26.4c5.9,0,10.7-4.8,10.7-10.7v-7.1c0-5.9-4.8-10.7-10.7-10.7C432,341.3,413.9,327.5,405.9,307.8z M270.6,199.1h113.7V238l-52.5-16.9c-2.8-0.9-5.9-0.9-8.7,0L270.6,238V199.1z"/>
      </g>
      ${displayNumCircle(num)}
    </svg>
  `
}

const train = (circleColor: string = colors.red, num?: number) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g>
        <circle fill="${circleColor}" cx="200" cy="300" r="200" />
        <path fill="#ffffff" transform="${transform}" d="M434.7,179.5v122.5c0,24.8-29.5,45.9-62.2,45.9l30.1,23.8c2.8,2.2,1.2,6.8-2.4,6.8H254.8c-3.6,0-5.2-4.6-2.4-6.8l30.1-23.8c-32.6,0-62.2-21.1-62.2-45.9V179.5c0-25.4,30.6-45.9,61.2-45.9h91.9C404.5,133.5,434.7,154.1,434.7,179.5z M411.7,244.5v-53.6c0-6.3-5.1-11.5-11.5-11.5H254.8c-6.3,0-11.5,5.1-11.5,11.5v53.6c0,6.3,5.1,11.5,11.5,11.5h145.4C406.6,256,411.7,250.9,411.7,244.5z M327.5,275.1c-14.8,0-26.8,12-26.8,26.8c0,14.8,12,26.8,26.8,26.8s26.8-12,26.8-26.8C354.3,287.1,342.3,275.1,327.5,275.1z"/>
      </g>
      ${displayNumCircle(num)}
    </svg>
  `
}

const truck = (circleColor: string = colors.red, num?: number) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g>
        <circle fill="${circleColor}" cx="200" cy="300" r="200" />
        <path fill="#ffffff" transform="${transform}" d="M485.7,302.4H478v-52.2c0-6.1-2.5-12-6.8-16.4l-48.2-48.2c-4.3-4.3-10.2-6.8-16.4-6.8h-21.3v-23.2c0-12.8-10.4-23.2-23.2-23.2H207.5c-12.8,0-23.2,10.4-23.2,23.2v154.5c0,12.8,10.4,23.2,23.2,23.2h7.7c0,25.6,20.8,46.4,46.4,46.4s46.4-20.8,46.4-46.4h61.8c0,25.6,20.8,46.4,46.4,46.4c25.6,0,46.4-20.8,46.4-46.4h23.2c4.3,0,7.7-3.5,7.7-7.7v-15.5C493.4,305.8,489.9,302.4,485.7,302.4z M261.6,356.5c-12.8,0-23.2-10.4-23.2-23.2s10.4-23.2,23.2-23.2c12.8,0,23.2,10.4,23.2,23.2S274.4,356.5,261.6,356.5z M416.1,356.5c-12.8,0-23.2-10.4-23.2-23.2s10.4-23.2,23.2-23.2c12.8,0,23.2,10.4,23.2,23.2S428.9,356.5,416.1,356.5z M454.8,256h-69.5v-54.1h21.3l48.2,48.2V256z"/>
      </g>
      ${displayNumCircle(num)}
    </svg>
  `
}

const displayNumCircle = (num?: number) => {
  const moreThanNine = num && num >= 10
  const x = moreThanNine ? '292' : '305'
  const y = moreThanNine ? '185' : '200'
  const fontSize = moreThanNine ? '110' : '160'

  return num && num >= 2
    ? `
      <g>
        <circle fill="#474747" cx="350" cy="150" r="100" />
        <text x="${x}" y="${y}" fill="#fff" font-size="${fontSize}px" font-family="TTNorms, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif" font-weight="bold">${
          moreThanNine ? '9+' : num
        }</text>
      </g>
    `
    : ''
}

export const getMarkerIconForTransportModesList = (
  transportModeList: Array<commonBooking.TransportMode>,
  useGrayIcon?: boolean,
  numberOfStackedBookings?: number,
): string | undefined => {
  let svgString

  if (transportModeList.length > 1) {
    svgString = useGrayIcon
      ? box(colors.gray, numberOfStackedBookings)
      : box(colors.red, numberOfStackedBookings)
  } else if (transportModeList.length === 1) {
    switch (transportModeList[0]) {
      case commonBooking.TransportMode.UNKNOWN:
        svgString = useGrayIcon
          ? box(colors.gray, numberOfStackedBookings)
          : box(colors.red, numberOfStackedBookings)
        break
      case commonBooking.TransportMode.AIR:
        svgString = useGrayIcon
          ? plane(colors.gray, numberOfStackedBookings)
          : plane(colors.red, numberOfStackedBookings)
        break
      case commonBooking.TransportMode.COURIER:
        svgString = useGrayIcon
          ? courier(colors.gray, numberOfStackedBookings)
          : courier(colors.red, numberOfStackedBookings)
        break
      case commonBooking.TransportMode.RAIL:
        svgString = useGrayIcon
          ? train(colors.gray, numberOfStackedBookings)
          : train(colors.red, numberOfStackedBookings)
        break
      case commonBooking.TransportMode.ROAD:
        svgString = useGrayIcon
          ? truck(colors.gray, numberOfStackedBookings)
          : truck(colors.red, numberOfStackedBookings)
        break
      case commonBooking.TransportMode.SEA:
        svgString = useGrayIcon
          ? ship(colors.gray, numberOfStackedBookings)
          : ship(colors.red, numberOfStackedBookings)
        break
    }
  }

  return svgString ? `data:image/svg+xml;base64,${window.btoa(svgString)}` : undefined
}

export const getCircleIcon = (useGrayIcon?: boolean): string => {
  const svgString = useGrayIcon ? circle(colors.gray) : circle(colors.red)

  return `data:image/svg+xml;base64,${window.btoa(svgString)}`
}
