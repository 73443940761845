import * as userv1 from '../../../proto/iam/v1/user_pb'

import { ActionsUnion, createAction } from '../../helpers'

import * as userType from '../../../types/user'

export const LIST_USERS_REQ = 'swift2/user/LIST_USERS_REQ'
export const LIST_USERS_RESP = 'swift2/user/LIST_USERS_RESP'
export const LIST_USERS_ERR = 'swift2/user/LIST_USERS_ERR'
export const LIST_PUBLIC_USERS_REQ = 'swift2/user/LIST_PUBLIC_USERS_REQ'
export const LIST_PUBLIC_USERS_RESP = 'swift2/user/LIST_PUBLIC_USERS_RESP'
export const LIST_PUBLIC_USERS_ERR = 'swift2/user/LIST_PUBLIC_USERS_ERR'
export const GET_REQ = 'swift2/user/GET_REQ'
export const GET_RESP = 'swift2/user/GET_RESP'
export const GET_ERR = 'swift2/user/GET_ERR'
export const CREATE_REQ = 'swift2/user/CREATE_REQ'
export const CREATE_RESP = 'swift2/user/CREATE_RESP'
export const CREATE_ERR = 'swift2/user/CREATE_ERR'
export const EDIT_REQ = 'swift2/user/EDIT_REQ'
export const EDIT_RESP = 'swift2/user/EDIT_RESP'
export const EDIT_ERR = 'swift2/user/EDIT_ERR'
export const SET_CURRENT_USER = 'swift2/user/SET_CURRENT_USER'
export const SET_ROLES = 'swift2/user/SET_ROLES'
export const UPLOAD_PROFILE_PICTURE_REQ = 'swift2/user/UPLOAD_PROFILE_PICTURE_REQ'
export const UPLOAD_PROFILE_PICTURE_ERR = 'swift2/user/UPLOAD_PROFILE_PICTURE_ERR'
export const SET_PROFILE_PICTURE_LOADING = 'swift2/user/SET_PROFILE_PICTURE_LOADING'

export const Actions = {
  listUsersReq: (page: userType.UserPage) => createAction(LIST_USERS_REQ, { page }),
  listUsersResp: (count: number, users: userv1.User[], page: userType.UserPage) =>
    createAction(LIST_USERS_RESP, { count, users, page }),
  listUsersErr: (err: Error) => createAction(LIST_USERS_ERR, { err }),
  listPublicUsersReq: (limit: number, skip: number) =>
    createAction(LIST_PUBLIC_USERS_REQ, { limit, skip }),
  listPublicUsersResp: (count: number, users: userv1.User[]) =>
    createAction(LIST_PUBLIC_USERS_RESP, { count, users }),
  listPublicUsersErr: (err: Error) => createAction(LIST_PUBLIC_USERS_ERR, { err }),
  getUserReq: (id: string) => createAction(GET_REQ, { id }),
  getUserResp: (user?: userv1.User) => createAction(GET_RESP, { user }),
  getUserErr: (err: Error) => createAction(GET_ERR, { err }),
  createUserReq: (user: userv1.User) => createAction(CREATE_REQ, { user }),
  createUserResp: (user: userv1.User) => createAction(CREATE_RESP, { user }),
  createUserErr: (err: Error) => createAction(CREATE_ERR, { err }),
  editUserReq: (user: userv1.User) => createAction(EDIT_REQ, { user }),
  editUserResp: (user: userv1.User) => createAction(EDIT_RESP, { user }),
  editUserErr: (err: Error) => createAction(EDIT_ERR, { err }),
  setCurrentUser: (user?: userv1.User) => createAction(SET_CURRENT_USER, { user }),
  setRoles: (roles: Array<userv1.User.Role>) => createAction(SET_ROLES, { roles }),
  uploadProfilePictureReq: (user: userv1.User, file: Uint8Array, format: userv1.ImageFormat) =>
    createAction(UPLOAD_PROFILE_PICTURE_REQ, { user, file, format }),
  uploadProfilePictureErr: (err: Error) => createAction(UPLOAD_PROFILE_PICTURE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
