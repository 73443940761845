import * as React from 'react'

import { Tag, Tooltip } from 'antd'
import { TagProps } from 'antd/lib/tag'

import * as bookingv1 from '../../proto/booking/v1/booking_pb'

import { statusColors, statusLabels } from '../../helpers/booking'
import { formatTimestamp } from '../../helpers/timestamp'

interface Props extends TagProps {
  booking: bookingv1.Booking
  context: 'BOOKING' | 'SHIPMENT'
}

export const BookingStatusTag: React.FC<Props> = ({ booking, context, ...rest }) => {
  if (context === 'BOOKING' && !booking.getLatestDescription()) {
    return (
      <>
        <Tooltip title={'missing latest description'}>
          <Tag color={statusColors[booking.getStatus()]} {...rest}>
            {statusLabels[booking.getStatus()]}
          </Tag>
        </Tooltip>
      </>
    )
  }

  return (
    <>
      {context === 'BOOKING' && (
        <Tooltip
          overlayClassName="custom-ant-tooltip"
          title={
            <div style={{ minWidth: 150 }}>
              <span style={{ whiteSpace: 'pre-wrap' }}>{booking.getLatestDescription()}</span>
              <br />
              <div style={{ fontSize: 10, textAlign: 'right' }}>
                {formatTimestamp(booking.getLatestDescriptionCreatedAt(), 'YYYY-MM-DD HH:mm', '')}
              </div>
            </div>
          }
        >
          <Tag color={statusColors[booking.getStatus()]} {...rest}>
            {statusLabels[booking.getStatus()]}
          </Tag>
        </Tooltip>
      )}

      {context === 'SHIPMENT' && (
        <Tooltip
          overlayClassName="custom-ant-tooltip"
          title={
            <div>
              <span style={{ whiteSpace: 'pre-wrap' }}>{booking.getBookingRef()}</span>
            </div>
          }
        >
          <Tag color={statusColors[booking.getStatus()]} {...rest}>
            {statusLabels[booking.getStatus()]}
          </Tag>
        </Tooltip>
      )}
    </>
  )
}
