import * as costv1 from '../proto/invoicing/v1/cost_pb'

import { Pagination, defaultPageSize } from './query'

export interface CostPage {
  pagination: Pagination
  filter: CostFilter
  sorting: costv1.CostSorting
}

export interface CostFilter {
  bookingRef?: Array<string>
  shipmentRef?: string
  status?: Array<costv1.Cost.Status>
  type?: Array<costv1.Cost.Type>
  search?: string
  organizationId?: Array<number>
  branchId?: Array<number>
  cancelled?: boolean
}

export const getDefaultCostPage = (): CostPage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new costv1.CostSorting(),
})

// A very permissive type so we can convert some indexes to a JSON string in
// address to allow conversion to a query parameter string.
export type ParsingCostPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  search?: string
  sorting?: string
}
