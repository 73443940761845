import * as React from 'react'

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  color?: string
}

const defaultIconColor = '#44474F'
const closeIconColor = '#FFFFFF'

// Transport mode icons
export const Air = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 576 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M480,192H365.7L260.6,8.1c-2.9-5-8.2-8.1-13.9-8.1h-65.5c-10.6,0-18.3,10.2-15.4,20.4l49,171.6H112l-43.2-57.6c-3-4-7.8-6.4-12.8-6.4H16c-10.4,0-18,9.8-15.5,19.9L32,256L0.5,364.1C-2,374.2,5.6,384,16,384h40c5,0,9.8-2.4,12.8-6.4L112,320h102.9l-49,171.6c-2.9,10.2,4.8,20.4,15.4,20.4h65.5c5.7,0,11-3.1,13.9-8.1L365.7,320H480c35.3,0,96-28.6,96-64S515.3,192,480,192z"></path>
  </svg>
)
export const Sea = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 640 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M496.6,372.6l70-70c16.9-16.9,9.9-45.8-12.8-53.1L512,236.1V96c0-17.7-14.3-32-32-32h-64V24c0-13.3-10.7-24-24-24H248c-13.3,0-24,10.7-24,24v40h-64c-17.7,0-32,14.3-32,32v140.1l-41.8,13.4c-22.8,7.3-29.8,36.2-12.8,53.1l70,70C125.8,416.3,85.6,448,24,448c-13.3,0-24,10.7-24,24v16c0,13.3,10.7,24,24,24c61,0,107.5-20.6,143.3-59.4C181.7,487.4,216,512,256,512h128c40,0,74.3-24.6,88.7-59.4C508.5,491.4,555,512,616,512c13.3,0,24-10.7,24-24v-16c0-13.3-10.7-24-24-24C555.2,448,514.5,417,496.6,372.6z M192,128h256v87.5l-118.2-38c-6.4-2-13.2-2-19.6,0l-118.2,38V128z"></path>
  </svg>
)
export const Rail = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M448,96v256c0,51.8-61.6,96-130,96l63,49.7c5.9,4.7,2.6,14.3-5,14.3H72c-7.6,0-10.9-9.6-5-14.3l63-49.7C61.8,448,0,404,0,352V96C0,43,64,0,128,0h192C385,0,448,43,448,96z M400,232V120c0-13.3-10.7-24-24-24H72c-13.3,0-24,10.7-24,24v112c0,13.3,10.7,24,24,24h304C389.3,256,400,245.3,400,232z M224,296c-30.9,0-56,25.1-56,56s25.1,56,56,56s56-25.1,56-56S254.9,296,224,296z" />
  </svg>
)
export const Road = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 640 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M624,352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494,110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5,0,0,21.5,0,48v320c0,26.5,21.5,48,48,48h16c0,53,43,96,96,96s96-43,96-96h128c0,53,43,96,96,96s96-43,96-96h48c8.8,0,16-7.2,16-16v-32C640,359.2,632.8,352,624,352z M160,464c-26.5,0-48-21.5-48-48s21.5-48,48-48s48,21.5,48,48S186.5,464,160,464z M480,464c-26.5,0-48-21.5-48-48s21.5-48,48-48s48,21.5,48,48S506.5,464,480,464z M560,256H416V144h44.1l99.9,99.9V256z" />
  </svg>
)
export const Courier = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 576 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M294.2,277.7c18,5,34.7,13.4,49.5,24.7l161.5-53.8c8.4-2.8,12.9-11.9,10.1-20.2L454.9,47.2c-2.8-8.4-11.9-12.9-20.2-10.1l-61.1,20.4l33.1,99.4L346,177l-33.1-99.4l-61.6,20.5c-8.4,2.8-12.9,11.9-10.1,20.2L294.2,277.7L294.2,277.7z M575.2,326.4L565,296c-2.8-8.4-11.9-12.9-20.2-10.1l-213.5,71.2c-17.2-22-43.6-36.4-73.5-37L158.4,21.9C154,8.8,141.8,0,128,0H16C7.2,0,0,7.2,0,16v32c0,8.8,7.2,16,16,16h88.9l92.2,276.7c-26.1,20.4-41.7,53.6-36,90.5c6.1,39.4,37.9,72.3,77.3,79.2c60.2,10.7,112.3-34.8,113.4-92.6l213.3-71.2C573.4,343.8,578,334.8,575.2,326.4z M256,464c-26.5,0-48-21.5-48-48s21.5-48,48-48s48,21.5,48,48S282.5,464,256,464z" />
  </svg>
)
export const Box = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M509.5,184.6L458.9,32.8C452.4,13.2,434.1,0,413.4,0H272v192h238.7C510.3,189.5,510.3,187,509.5,184.6z M240,0H98.6c-20.7,0-39,13.2-45.5,32.8L2.5,184.6c-0.8,2.4-0.8,4.9-1.2,7.4H240V0z M0,224v240c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V224H0z"></path>
  </svg>
)

// Icons used in menu
export const AddressBook = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M436,160c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5,0,0,21.5,0,48v416c0,26.5,21.5,48,48,48h320c26.5,0,48-21.5,48-48v-48h20c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6,0,12-5.4,12-12v-40c0-6.6-5.4-12-12-12h-20v-64H436z M208,128c35.3,0,64,28.7,64,64s-28.7,64-64,64s-64-28.7-64-64S172.7,128,208,128z M320,364.8c0,10.6-10,19.2-22.4,19.2H118.4C106,384,96,375.4,96,364.8v-19.2c0-31.8,30.1-57.6,67.2-57.6h5c12.3,5.1,25.7,8,39.8,8s27.6-2.9,39.8-8h5c37.1,0,67.2,25.8,67.2,57.6V364.8z" />
  </svg>
)
export const Boxes = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 576 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M560,288h-80v96l-32-21.3L416,384v-96h-80c-8.8,0-16,7.2-16,16v192c0,8.8,7.2,16,16,16h224c8.8,0,16-7.2,16-16V304C576,295.2,568.8,288,560,288z M176,224h224c8.8,0,16-7.2,16-16V16c0-8.8-7.2-16-16-16h-80v96l-32-21.3L256,96V0h-80c-8.8,0-16,7.2-16,16v192C160,216.8,167.2,224,176,224z M240,288h-80v96l-32-21.3L96,384v-96H16c-8.8,0-16,7.2-16,16v192c0,8.8,7.2,16,16,16h224c8.8,0,16-7.2,16-16V304C256,295.2,248.8,288,240,288z" />
  </svg>
)
export const Building = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M436,480h-20V24c0-13.3-10.7-24-24-24H56C42.7,0,32,10.7,32,24v456H12c-6.6,0-12,5.4-12,12v20h448v-20C448,485.4,442.6,480,436,480z M128,76c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12v40c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12V76z M128,172c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12v40c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12V172z M180,320h-40c-6.6,0-12-5.4-12-12v-40c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12v40C192,314.6,186.6,320,180,320z M256,480h-64v-84c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12V480z M320,308c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12v-40c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12V308z M320,212c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12v-40c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12V212zM320,116c0,6.6-5.4,12-12,12h-40c-6.6,0-12-5.4-12-12V76c0-6.6,5.4-12,12-12h40c6.6,0,12,5.4,12,12V116z" />
  </svg>
)
export const ClipboardList = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 384 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M336,64h-80c0-35.3-28.7-64-64-64s-64,28.7-64,64H48C21.5,64,0,85.5,0,112v352c0,26.5,21.5,48,48,48h288c26.5,0,48-21.5,48-48V112C384,85.5,362.5,64,336,64z M96,424c-13.3,0-24-10.7-24-24s10.7-24,24-24s24,10.7,24,24S109.3,424,96,424zM96,328c-13.3,0-24-10.7-24-24s10.7-24,24-24s24,10.7,24,24S109.3,328,96,328z M96,232c-13.3,0-24-10.7-24-24s10.7-24,24-24s24,10.7,24,24S109.3,232,96,232z M192,40c13.3,0,24,10.7,24,24s-10.7,24-24,24s-24-10.7-24-24S178.7,40,192,40z M320,408c0,4.4-3.6,8-8,8H168c-4.4,0-8-3.6-8-8v-16c0-4.4,3.6-8,8-8h144c4.4,0,8,3.6,8,8V408z M320,312c0,4.4-3.6,8-8,8H168c-4.4,0-8-3.6-8-8v-16c0-4.4,3.6-8,8-8h144c4.4,0,8,3.6,8,8V312z M320,216c0,4.4-3.6,8-8,8H168c-4.4,0-8-3.6-8-8v-16c0-4.4,3.6-8,8-8h144c4.4,0,8,3.6,8,8V216z" />
  </svg>
)
export const Coins = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M0,405.3V448c0,35.3,86,64,192,64s192-28.7,192-64v-42.7C342.7,434.4,267.2,448,192,448S41.3,434.4,0,405.3zM320,128c106,0,192-28.7,192-64S426,0,320,0S128,28.7,128,64S214,128,320,128z M0,300.4V352c0,35.3,86,64,192,64s192-28.7,192-64v-51.6c-41.3,34-116.9,51.6-192,51.6S41.3,334.4,0,300.4z M416,311.4c57.3-11.1,96-31.7,96-55.4v-42.7c-23.2,16.4-57.3,27.6-96,34.5V311.4z M192,160C86,160,0,195.8,0,240s86,80,192,80s192-35.8,192-80S298,160,192,160z M411.3,216.3c60-10.8,100.7-32,100.7-56.3v-42.7c-35.5,25.1-96.5,38.6-160.7,41.8C380.8,173.4,402.5,192.6,411.3,216.3z" />
  </svg>
)
export const Pencil = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M497.9,142.1l-46.1,46.1c-4.7,4.7-12.3,4.7-17,0l-111-111c-4.7-4.7-4.7-12.3,0-17l46.1-46.1c18.7-18.7,49.1-18.7,67.9,0l60.1,60.1C516.7,92.9,516.7,123.3,497.9,142.1z M284.2,99.8L21.6,362.4L0.4,483.9c-2.9,16.4,11.4,30.6,27.8,27.8l121.5-21.3l262.6-262.6c4.7-4.7,4.7-12.3,0-17l-111-111C296.5,95.1,288.9,95.1,284.2,99.8L284.2,99.8z M124.1,339.9c-5.5-5.5-5.5-14.3,0-19.8l154-154c5.5-5.5,14.3-5.5,19.8,0s5.5,14.3,0,19.8l-154,154C138.4,345.4,129.6,345.4,124.1,339.9L124.1,339.9z M88,424h48v36.3l-64.5,11.3l-31.1-31.1L51.7,376H88V424z" />
  </svg>
)
export const Poll = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M400,32H48C21.5,32,0,53.5,0,80v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V80C448,53.5,426.5,32,400,32z M160,368c0,8.8-7.2,16-16,16h-32c-8.8,0-16-7.2-16-16V240c0-8.8,7.2-16,16-16h32c8.8,0,16,7.2,16,16V368z M256,368c0,8.8-7.2,16-16,16h-32c-8.8,0-16-7.2-16-16V144c0-8.8,7.2-16,16-16h32c8.8,0,16,7.2,16,16V368z M352,368c0,8.8-7.2,16-16,16h-32c-8.8,0-16-7.2-16-16v-64c0-8.8,7.2-16,16-16h32c8.8,0,16,7.2,16,16V368z" />
  </svg>
)
export const SignOut = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M497,273L329,441c-15,15-41,4.5-41-17v-96H152c-13.3,0-24-10.7-24-24v-96c0-13.3,10.7-24,24-24h136V88c0-21.4,25.9-32,41-17l168,168C506.3,248.4,506.3,263.6,497,273z M192,436v-40c0-6.6-5.4-12-12-12H96c-17.7,0-32-14.3-32-32V160c0-17.7,14.3-32,32-32h84c6.6,0,12-5.4,12-12V76c0-6.6-5.4-12-12-12H96c-53,0-96,43-96,96v192c0,53,43,96,96,96h84C186.6,448,192,442.6,192,436z" />
  </svg>
)
export const StickyNote = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M312,320h136V56c0-13.3-10.7-24-24-24H24C10.7,32,0,42.7,0,56v400c0,13.3,10.7,24,24,24h264V344C288,330.8,298.8,320,312,320z M441,375l-98,98c-4.5,4.5-10.6,7-17,7h-6V352h128v6.1C448,364.4,445.5,370.5,441,375z" />
  </svg>
)
export const Tag = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M0,252.1V48C0,21.5,21.5,0,48,0h204.1c12.7,0,24.9,5.1,33.9,14.1l211.9,211.9c18.7,18.7,18.7,49.1,0,67.9L293.8,497.9c-18.7,18.7-49.1,18.7-67.9,0L14.1,286.1C5.1,277.1,0,264.8,0,252.1z M112,64c-26.5,0-48,21.5-48,48s21.5,48,48,48s48-21.5,48-48S138.5,64,112,64z" />
  </svg>
)
export const UserCog = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 640 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M610.5,373.3c2.6-14.1,2.6-28.5,0-42.6l25.8-14.9c3-1.7,4.3-5.2,3.3-8.5c-6.7-21.6-18.2-41.2-33.2-57.4c-2.3-2.5-6-3.1-9-1.4l-25.8,14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1c-22.3-5-45-4.8-66.2,0c-3.3,0.7-5.7,3.7-5.7,7.1v29.8c-13.5,4.8-26,12-36.9,21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9,1.4c-15,16.2-26.5,35.8-33.2,57.4c-1,3.3,0.4,6.8,3.3,8.5l25.8,14.9c-2.6,14.1-2.6,28.5,0,42.6l-25.8,14.9c-3,1.7-4.3,5.2-3.3,8.5c6.7,21.6,18.2,41.1,33.2,57.4c2.3,2.5,6,3.1,9,1.4l25.8-14.9c10.9,9.3,23.4,16.5,36.9,21.3v29.8c0,3.4,2.4,6.4,5.7,7.1c22.3,5,45,4.8,66.2,0c3.3-0.7,5.7-3.7,5.7-7.1v-29.8c13.5-4.8,26-12,36.9-21.3l25.8,14.9c2.9,1.7,6.7,1.1,9-1.4c15-16.2,26.5-35.8,33.2-57.4c1-3.3-0.4-6.8-3.3-8.5L610.5,373.3z M496,400.5c-26.8,0-48.5-21.8-48.5-48.5s21.8-48.5,48.5-48.5s48.5,21.8,48.5,48.5S522.8,400.5,496,400.5z M224,256c70.7,0,128-57.3,128-128S294.7,0,224,0S96,57.3,96,128S153.3,256,224,256z M425.2,482.5c-2.3-1.2-4.6-2.6-6.8-3.9l-7.9,4.6c-6,3.4-12.8,5.3-19.6,5.3c-10.9,0-21.4-4.6-28.9-12.6c-18.3-19.8-32.3-43.9-40.2-69.6c-5.5-17.7,1.9-36.4,17.9-45.7l7.9-4.6c-0.1-2.6-0.1-5.2,0-7.8l-7.9-4.6c-16-9.2-23.4-28-17.9-45.7c0.9-2.9,2.2-5.8,3.2-8.7c-3.8-0.3-7.5-1.2-11.4-1.2h-16.7c-22.2,10.2-46.9,16-72.9,16s-50.6-5.8-72.9-16h-16.7C60.2,288,0,348.2,0,422.4V464c0,26.5,21.5,48,48,48h352c10.1,0,19.5-3.2,27.2-8.5c-1.2-3.8-2-7.7-2-11.8V482.5z" />
  </svg>
)

// Other icons
export const Comment = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M256,32C114.6,32,0,125.1,0,240c0,49.6,21.4,95,57,130.7C44.5,421.1,2.7,466,2.2,466.5c-2.2,2.3-2.8,5.7-1.5,8.7S4.8,480,8,480c66.3,0,116-31.8,140.6-51.4c32.7,12.3,69,19.4,107.4,19.4c141.4,0,256-93.1,256-208S397.4,32,256,32z M128,272c-17.7,0-32-14.3-32-32s14.3-32,32-32s32,14.3,32,32S145.7,272,128,272z M256,272c-17.7,0-32-14.3-32-32s14.3-32,32-32s32,14.3,32,32S273.7,272,256,272z M384,272c-17.7,0-32-14.3-32-32s14.3-32,32-32s32,14.3,32,32S401.7,272,384,272z" />
  </svg>
)
export const Edit = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 576 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M402.6,83.2l90.2,90.2c3.8,3.8,3.8,10,0,13.8L274.4,405.6l-92.8,10.3c-12.4,1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8,83.2C392.6,79.4,398.8,79.4,402.6,83.2L402.6,83.2z M564.6,60.3l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2,0l-35.4,35.4c-3.8,3.8-3.8,10,0,13.8l90.2,90.2c3.8,3.8,10,3.8,13.8,0l35.4-35.4C579.8,100.2,579.8,75.5,564.6,60.3z M384,346.2V448H64V128h229.8c3.2,0,6.2-1.3,8.5-3.5l40-40c7.6-7.6,2.2-20.5-8.5-20.5H48C21.5,64,0,85.5,0,112v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40,40C385.3,340,384,343,384,346.2z" />
  </svg>
)
export const Print = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M448,192V77.2c0-8.5-3.4-16.6-9.4-22.6L393.4,9.4c-6-6-14.1-9.4-22.6-9.4H96C78.3,0,64,14.3,64,32v160c-35.3,0-64,28.6-64,64v112c0,8.8,7.2,16,16,16h48v96c0,17.7,14.3,32,32,32h320c17.7,0,32-14.3,32-32v-96h48c8.8,0,16-7.2,16-16V256C512,220.6,483.4,192,448,192z M384,448H128v-96h256V448z M384,224H128V64h192v48c0,8.8,7.2,16,16,16h48V224z M432,296c-13.2,0-24-10.8-24-24c0-13.3,10.8-24,24-24s24,10.7,24,24C456,285.2,445.2,296,432,296z" />
  </svg>
)
export const Trash = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M432,32H312l-9.4-18.7C298.5,5.1,290.2,0,281.1,0H166.8c-9.1,0-17.4,5.1-21.4,13.3L136,32H16C7.2,32,0,39.2,0,48v32c0,8.8,7.2,16,16,16h416c8.8,0,16-7.2,16-16V48C448,39.2,440.8,32,432,32z M53.2,467c1.6,25.3,22.6,45,47.9,45h245.8c25.3,0,46.3-19.7,47.9-45L416,128H32L53.2,467z" />
  </svg>
)
export const Forward = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M224.3,273l-136,136c-9.4,9.4-24.6,9.4-33.9,0l-22.6-22.6c-9.4-9.4-9.4-24.6,0-33.9l96.4-96.4l-96.4-96.4c-9.4-9.4-9.4-24.6,0-33.9L54.3,103c9.4-9.4,24.6-9.4,33.9,0l136,136C233.7,248.4,233.7,263.6,224.3,273z M416.3,239l-136-136c-9.4-9.4-24.6-9.4-33.9,0l-22.6,22.6c-9.4,9.4-9.4,24.6,0,33.9l96.4,96.4l-96.4,96.4c-9.4,9.4-9.4,24.6,0,33.9l22.6,22.6c9.4,9.4,24.6,9.4,33.9,0l136-136C425.7,263.6,425.7,248.4,416.3,239L416.3,239z" />
  </svg>
)
export const InfoCircle = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M256,8C119,8,8,119.1,8,256c0,137,111,248,248,248s248-111,248-248C504,119.1,393,8,256,8z M256,118c23.2,0,42,18.8,42,42s-18.8,42-42,42s-42-18.8-42-42S232.8,118,256,118z M312,372c0,6.6-5.4,12-12,12h-88c-6.6,0-12-5.4-12-12v-24c0-6.6,5.4-12,12-12h12v-64h-12c-6.6,0-12-5.4-12-12v-24c0-6.6,5.4-12,12-12h64c6.6,0,12,5.4,12,12v100h12c6.6,0,12,5.4,12,12V372z" />
  </svg>
)

export const ProfileUser = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 45.532 45.532"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765 S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53 c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012 c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592 c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"></path>{' '}
      </g>
    </g>
  </svg>
)

export const Like = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M104,224H24c-13.3,0-24,10.7-24,24v240c0,13.3,10.7,24,24,24h80c13.3,0,24-10.7,24-24V248C128,234.7,117.3,224,104,224z M64,472c-13.3,0-24-10.7-24-24s10.7-24,24-24s24,10.7,24,24S77.3,472,64,472z M384,81.5c0,42.4-26,66.2-33.3,94.5h101.7c33.4,0,59.4,27.7,59.6,58.1c0.1,17.9-7.5,37.2-19.4,49.2l-0.1,0.1c9.8,23.3,8.2,56-9.3,79.5c8.7,25.9-0.1,57.7-16.4,74.8c4.3,17.6,2.2,32.6-6.1,44.6c-20.4,29.3-71,29.7-113.8,29.7l-2.8,0c-48.3,0-87.8-17.6-119.6-31.7c-16-7.1-36.8-15.9-52.7-16.2c-6.5-0.1-11.8-5.5-11.8-12V238.3c0-3.2,1.3-6.3,3.6-8.5c39.6-39.1,56.6-80.6,89.1-113.1c14.8-14.8,20.2-37.2,25.4-58.9C282.5,39.3,291.8,0,312,0C336,0,384,8,384,81.5z" />
  </svg>
)
export const TimesCircleRegular = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 512 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M256,8C119,8,8,119,8,256s111,248,248,248s248-111,248-248S393,8,256,8z M256,456c-110.5,0-200-89.5-200-200S145.5,56,256,56s200,89.5,200,200S366.5,456,256,456z M357.8,193.8L295.6,256l62.2,62.2c4.7,4.7,4.7,12.3,0,17l-22.6,22.6c-4.7,4.7-12.3,4.7-17,0L256,295.6l-62.2,62.2c-4.7,4.7-12.3,4.7-17,0l-22.6-22.6c-4.7-4.7-4.7-12.3,0-17l62.2-62.2l-62.2-62.2c-4.7-4.7-4.7-12.3,0-17l22.6-22.6c4.7-4.7,12.3-4.7,17,0l62.2,62.2l62.2-62.2c4.7-4.7,12.3-4.7,17,0l22.6,22.6C362.5,181.5,362.5,189.1,357.8,193.8L357.8,193.8z" />
  </svg>
)
export const AngleUp = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 320 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
  </svg>
)
export const AngleDown = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 320 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
  </svg>
)
export const AngleLeft = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 256 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
  </svg>
)
export const AngleRight = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 256 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
  </svg>
)
export const PenSquare = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 448 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zM238.1 177.9L102.4 313.6l-6.3 57.1c-.8 7.6 5.6 14.1 13.3 13.3l57.1-6.3L302.2 242c2.3-2.3 2.3-6.1 0-8.5L246.7 178c-2.5-2.4-6.3-2.4-8.6-.1zM345 165.1L314.9 135c-9.4-9.4-24.6-9.4-33.9 0l-23.1 23.1c-2.3 2.3-2.3 6.1 0 8.5l55.5 55.5c2.3 2.3 6.1 2.3 8.5 0L345 199c9.3-9.3 9.3-24.5 0-33.9z" />
  </svg>
)
export const InvoiceDollar = ({ color = defaultIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 384 512"
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <path d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 80v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8zm144 263.88V440c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V232c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v24.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07z" />
  </svg>
)
export const closeIcon = ({ color = closeIconColor, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 12 12"
    {...props}
    style={{ width: '100%', height: '100%' }}
  >
    <line x1="1" y1="11" x2="11" y2="1" stroke="white" strokeWidth="2" />
    <line x1="1" y1="1" x2="11" y2="11" stroke="white" strokeWidth="2" />
  </svg>
)
