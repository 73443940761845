import * as filteringv1 from '../../proto/filtering/v1/filter_pb'
import * as userv1 from '../../proto/iam/v1/user_pb'

import { ActionsUnion, createAction } from '../helpers'

export const LIST_REQ = 'swift2/filter/LIST_REQ'
export const LIST_RESP = 'swift2/filter/LIST_RESP'
export const LIST_ERR = 'swift2/filter/LIST_ERR'

export const GET_REQ = 'swift2/filter/GET_REQ'
export const GET_RESP = 'swift2/filter/GET_RESP'
export const GET_ERR = 'swift2/filter/GET_ERR'

export const CREATE_REQ = 'swift2/filter/CREATE_REQ'
export const CREATE_RESP = 'swift2/filter/CREATE_RESP'
export const CREATE_ERR = 'swift2/filter/CREATE_ERR'

export const EDIT_REQ = 'swift2/filter/EDIT_REQ'
export const EDIT_RESP = 'swift2/filter/EDIT_REQ_RESP'
export const EDIT_ERR = 'swift2/filter/EDIT_REQ_ERR'

export const DELETE_REQ = 'swift2/filter/DELETE_REQ'
export const DELETE_RESP = 'swift2/filter/DELETE_RESP'
export const DELETE_ERR = 'swift2/filter/DELETE_ERR'

export const Actions = {
  listFiltersReq: (limit: number, skip: number, includeUserDisabled: boolean) =>
    createAction(LIST_REQ, { limit, skip, includeUserDisabled }),
  listFiltersResp: (count: number, filters: filteringv1.Filter[]) =>
    createAction(LIST_RESP, { count, filters }),
  listFiltersErr: (err: Error) => createAction(LIST_ERR, { err }),

  getFilterReq: (id: number) => createAction(GET_REQ, { id }),
  getFilterResp: (filter: filteringv1.Filter) => createAction(GET_RESP, { filter }),
  getFilterErr: (err: Error) => createAction(GET_ERR, { err }),

  createFilterReq: (filter: filteringv1.Filter, user?: userv1.User) =>
    createAction(CREATE_REQ, { filter, user }),
  createFilterResp: (filter: filteringv1.Filter) => createAction(CREATE_RESP, { filter }),
  createFilterErr: (err: Error) => createAction(CREATE_ERR, { err }),

  editFilterReq: (filter: filteringv1.Filter) => createAction(EDIT_REQ, { filter }),
  editFilterResp: (filter: filteringv1.Filter) => createAction(EDIT_RESP, { filter }),
  editFilterErr: (err: Error) => createAction(EDIT_ERR, { err }),

  deleteFilterReq: (id: number, user?: userv1.User) => createAction(DELETE_REQ, { id, user }),
  deleteFilterResp: (id: number) => createAction(DELETE_RESP, { id }),
  deleteFilterErr: (err: Error) => createAction(DELETE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
