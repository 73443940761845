export interface DefaultAddress {
  organizationID?: number
  userGroupID?: number
  senderID?: number
  receiverID?: number
}

interface UserConfigSettings {
  defaultAddresses: DefaultAddress[]
  filterOrder: number[]
  configToJSON(): string
  configFromJSON(s: string): void
  editDefaultAddress(v: DefaultAddress): boolean
  setDefaultAddresses(v: DefaultAddress[]): DefaultAddress[]
  getDefaultAddress(orgID: number, userID: number): DefaultAddress
  listDefaultAddresses(): DefaultAddress[]

  setFilterOrder(v: number[]): number[]
  listFilterOrder(): number[]
}

export class UserConfig implements UserConfigSettings {
  defaultAddresses: DefaultAddress[]
  filterOrder: number[]
  constructor() {
    this.defaultAddresses = []
    this.filterOrder = []
  }

  // configToJSON returns a JSON string of the config.
  configToJSON(): string {
    return JSON.stringify(this)
  }

  // configFromJSON parses a config from JSON.
  configFromJSON(s: string) {
    const userConfig = this.validateJSONStructure(s) && (JSON.parse(s) as UserConfig)
    if (userConfig && userConfig.defaultAddresses) {
      this.setDefaultAddresses(userConfig.defaultAddresses)
    }
    if (userConfig && userConfig.filterOrder) {
      this.setFilterOrder(userConfig.filterOrder)
    }
  }

  private validateJSONStructure(s: string): boolean {
    try {
      const result = JSON.parse(s) as UserConfig
      const type = Object.prototype.toString.call(result)
      return type === '[object Object]' || type === '[object Array]'
    } catch (err) {
      return false
    }
  }

  // editDefaultAddress edit a default address.
  editDefaultAddress(v: DefaultAddress): boolean {
    const defAddress = this.listDefaultAddresses()
    const alreadyExist = defAddress.some(
      (a) => a.organizationID === v.organizationID && a.userGroupID === v.userGroupID,
    )
    if (alreadyExist) {
      const editedDefAddresses = defAddress.map((d) => {
        if (d.organizationID === v.organizationID && d.userGroupID === v.userGroupID) {
          d.senderID = this.validateAddressInput(v.senderID)
          d.receiverID = this.validateAddressInput(v.receiverID)
          return d
        }
        return d
      })
      // remove addresses that doesn't contain valid addresses.
      const validateAddresses = editedDefAddresses.filter(
        (f) => f.senderID !== undefined || f.receiverID !== undefined,
      )
      this.setDefaultAddresses(validateAddresses)
      return true
    } else if (!v.senderID && !v.receiverID) {
      return false
    } else {
      defAddress.push(v)
      this.setDefaultAddresses(defAddress)
      return true
    }
  }

  private validateAddressInput(newValue: number | undefined) {
    switch (newValue) {
      case undefined:
        return undefined
      case 0:
        return undefined
      default:
        return newValue
    }
  }

  setDefaultAddresses(v: DefaultAddress[]): DefaultAddress[] {
    this.defaultAddresses = v
    return this.listDefaultAddresses()
  }

  getDefaultAddress(orgID: number, userGroupID: number): DefaultAddress {
    return (
      this.listDefaultAddresses().find(
        (d) => d.organizationID === orgID && d.userGroupID === userGroupID,
      ) || ({} as DefaultAddress)
    )
  }

  listDefaultAddresses() {
    return this.defaultAddresses
  }

  setFilterOrder(v: number[]): number[] {
    this.filterOrder = v
    return this.listFilterOrder()
  }

  listFilterOrder() {
    return this.filterOrder
  }
}

interface UiConfigSettings {
  userConfig: UserConfigSettings
}

export default class UiConfig implements UiConfigSettings {
  constructor(public userConfig = new UserConfig()) {}
  setUserConfig(u: UserConfig) {
    this.userConfig = u
  }
  getUserConfig(): UserConfig {
    return this.userConfig
  }
}
