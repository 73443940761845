import React from 'react'

import { Layout } from 'antd'

import { Spinner } from './Spinner'

export const FullSizeSpinner = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <div style={{ textAlign: 'center', marginTop: 48 }}>
        <Spinner size="lg" loading />
      </div>
    </Layout>
  )
}
