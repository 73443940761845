import React from 'react'
import { createRoot } from 'react-dom/client'

import App from 'App/App'

import * as serviceWorker from './serviceWorker'

const rootNode = document.getElementById('root')
const root = createRoot(rootNode!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
