import * as userv1 from '../../proto/iam/v1/user_pb'
import * as invoicev1 from '../../proto/invoicing/v1/invoice_pb'

import { StoryItem } from '../../components/Story/types'

import { ActionsUnion, createAction } from '../helpers'

export const GET_ALL_ITEMS = 'swift2/story/GET_ALL_ITEMS'
export const SET_REFS = 'swift2/story/SET_REFS'
export const UPDATE_MESSAGES = 'swift2/story/UPDATE_MESSAGES'
export const UPDATE_DOCUMENTS = 'swift2/story/UPDATE_DOCUMENTS'
export const UPDATE_INVOICES = 'swift2/story/UPDATE_INVOICES'
export const SET_STORY_ITEMS = 'swift2/story/SET_STORY_ITEMS'
export const SET_STORY_ITEM_ERR = 'swift2/story/SET_STORY_ITEM_ERR'
export const GET_STORY_USERS_REQ = 'swift2/story/GET_STORY_USERS_REQ'
export const GET_STORY_USERS_RESP = 'swift2/story/GET_STORY_USERS_RESP'
export const GET_STORY_USERS_ERR = 'swift2/story/GET_STORY_USERS_ERR'
export const SET_IS_FETCHING = 'swift2/story/SET_IS_FETCHING'

export const Actions = {
  getAllStoryItems: (bookingRef?: string, orderRef?: string, shipmentRef?: string) =>
    createAction(GET_ALL_ITEMS, { bookingRef, orderRef, shipmentRef }),
  getStoryUsersReq: (usersIds: string[]) => createAction(GET_STORY_USERS_REQ, { usersIds }),
  getStoryUsersResp: (users: { [key: string]: userv1.User }) =>
    createAction(GET_STORY_USERS_RESP, { users }),
  getStoryUsersErr: () => createAction(GET_STORY_USERS_ERR),
  setIsFetching: (isFetching: boolean) => createAction(SET_IS_FETCHING, { isFetching }),
  setReferences: (bookingRef: string, orderRef: string, shipmentRef: string) =>
    createAction(SET_REFS, { bookingRef, orderRef, shipmentRef }),
  setStoryItems: (storyItems: StoryItem[]) => createAction(SET_STORY_ITEMS, { storyItems }),
  setStoryItemError: () => createAction(SET_STORY_ITEM_ERR),
  updateStoryMessages: () => createAction(UPDATE_MESSAGES),
  updateStoryDocuments: (newDocuments?: number) => createAction(UPDATE_DOCUMENTS, { newDocuments }),
  updateStoryInvoices: (invoice: invoicev1.Invoice) => createAction(UPDATE_INVOICES, { invoice }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
