// source: invoicing/v1/cost.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
goog.exportSymbol('proto.swift.invoicing.v1.Cost', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Cost.Status', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.Cost.Type', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CostFilter', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CostSorting', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CostSorting.Field', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CostSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateCostRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateCostResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListCostsRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListCostsResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetBookingCostsRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetBookingCostsResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetCostStatusRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetCostStatusResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetShipmentCostsRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.SetShipmentCostsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.Cost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.Cost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.Cost.displayName = 'proto.swift.invoicing.v1.Cost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CostFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.CostFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.CostFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CostFilter.displayName = 'proto.swift.invoicing.v1.CostFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CostSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CostSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CostSorting.displayName = 'proto.swift.invoicing.v1.CostSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListCostsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.ListCostsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListCostsRequest.displayName = 'proto.swift.invoicing.v1.ListCostsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListCostsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.ListCostsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.ListCostsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListCostsResponse.displayName = 'proto.swift.invoicing.v1.ListCostsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateCostRequest.displayName = 'proto.swift.invoicing.v1.CreateCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateCostResponse.displayName = 'proto.swift.invoicing.v1.CreateCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetBookingCostsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.SetBookingCostsRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.SetBookingCostsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetBookingCostsRequest.displayName = 'proto.swift.invoicing.v1.SetBookingCostsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetBookingCostsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.SetBookingCostsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.SetBookingCostsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetBookingCostsResponse.displayName = 'proto.swift.invoicing.v1.SetBookingCostsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetCostStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.SetCostStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetCostStatusRequest.displayName = 'proto.swift.invoicing.v1.SetCostStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetCostStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.SetCostStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetCostStatusResponse.displayName = 'proto.swift.invoicing.v1.SetCostStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.SetShipmentCostsRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.SetShipmentCostsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetShipmentCostsRequest.displayName = 'proto.swift.invoicing.v1.SetShipmentCostsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.SetShipmentCostsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.SetShipmentCostsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.SetShipmentCostsResponse.displayName = 'proto.swift.invoicing.v1.SetShipmentCostsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.Cost.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.Cost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.Cost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Cost.toObject = function(includeInstance, msg) {
  var f, obj = {
    costId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    branchId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bookingRef: jspb.Message.getFieldWithDefault(msg, 6, ""),
    shipmentRef: jspb.Message.getFieldWithDefault(msg, 7, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 9, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    estimatedAmount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    actualAmount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 13, ""),
    currencyDate: (f = msg.getCurrencyDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    convertedCurrency: jspb.Message.getFieldWithDefault(msg, 15, ""),
    convertedCurrencyRate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    convertedEstimatedAmount: jspb.Message.getFieldWithDefault(msg, 17, 0),
    convertedActualAmount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.Cost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.Cost;
  return proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.Cost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 4:
      var value = /** @type {!proto.swift.invoicing.v1.Cost.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.swift.invoicing.v1.Cost.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualAmount(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrencyDate(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setConvertedCurrency(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConvertedCurrencyRate(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConvertedEstimatedAmount(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConvertedActualAmount(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.Cost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.Cost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.Cost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.Cost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEstimatedAmount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getActualAmount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCurrencyDate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConvertedCurrency();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getConvertedCurrencyRate();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getConvertedEstimatedAmount();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getConvertedActualAmount();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.Cost.Status = {
  NEW: 0,
  IN_REVIEW: 1,
  APPROVED: 2,
  CANCELLED: 3
};

/**
 * @enum {number}
 */
proto.swift.invoicing.v1.Cost.Type = {
  OTHER: 0,
  DOMESTIC: 1,
  TERMINAL: 2,
  CUSTOMS_ADMINISTRATION: 3,
  ARRIVING_FREIGHT: 4,
  DEPARTING_FREIGHT: 5,
  PROFIT_SHARE: 6,
  CUSTOMS_VAT_EXPENSE: 7,
  EXPENSE: 8,
  FERRY: 9,
  TRAILER_COST: 10,
  CLAIM: 11
};

/**
 * optional int64 cost_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getCostId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setCostId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 organization_id = 2;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 branch_id = 3;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.swift.invoicing.v1.Cost.Status}
 */
proto.swift.invoicing.v1.Cost.prototype.getStatus = function() {
  return /** @type {!proto.swift.invoicing.v1.Cost.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Status} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Type type = 5;
 * @return {!proto.swift.invoicing.v1.Cost.Type}
 */
proto.swift.invoicing.v1.Cost.prototype.getType = function() {
  return /** @type {!proto.swift.invoicing.v1.Cost.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Type} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string booking_ref = 6;
 * @return {string}
 */
proto.swift.invoicing.v1.Cost.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string shipment_ref = 7;
 * @return {string}
 */
proto.swift.invoicing.v1.Cost.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 supplier_id = 8;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.swift.invoicing.v1.Cost.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string serial_number = 10;
 * @return {string}
 */
proto.swift.invoicing.v1.Cost.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 estimated_amount = 11;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getEstimatedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setEstimatedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 actual_amount = 12;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getActualAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setActualAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string currency = 13;
 * @return {string}
 */
proto.swift.invoicing.v1.Cost.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp currency_date = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Cost.prototype.getCurrencyDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
*/
proto.swift.invoicing.v1.Cost.prototype.setCurrencyDate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.clearCurrencyDate = function() {
  return this.setCurrencyDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Cost.prototype.hasCurrencyDate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string converted_currency = 15;
 * @return {string}
 */
proto.swift.invoicing.v1.Cost.prototype.getConvertedCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setConvertedCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 converted_currency_rate = 16;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getConvertedCurrencyRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setConvertedCurrencyRate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 converted_estimated_amount = 17;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getConvertedEstimatedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setConvertedEstimatedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 converted_actual_amount = 18;
 * @return {number}
 */
proto.swift.invoicing.v1.Cost.prototype.getConvertedActualAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.setConvertedActualAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Cost.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
*/
proto.swift.invoicing.v1.Cost.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Cost.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.Cost.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.Cost} returns this
*/
proto.swift.invoicing.v1.Cost.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.Cost} returns this
 */
proto.swift.invoicing.v1.Cost.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.Cost.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.CostFilter.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CostFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CostFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CostFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CostFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    costIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    bookingRefList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    statusList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    typeList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    organizationIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    branchIdList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    shipmentRef: jspb.Message.getFieldWithDefault(msg, 7, ""),
    search: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CostFilter}
 */
proto.swift.invoicing.v1.CostFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CostFilter;
  return proto.swift.invoicing.v1.CostFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CostFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CostFilter}
 */
proto.swift.invoicing.v1.CostFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCostId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRef(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.swift.invoicing.v1.Cost.Status>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.swift.invoicing.v1.Cost.Type>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addType(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBranchId(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CostFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CostFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CostFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CostFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getBookingRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getBranchIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated int64 cost_id = 1;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getCostIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setCostIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.addCostId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.clearCostIdList = function() {
  return this.setCostIdList([]);
};


/**
 * repeated string booking_ref = 2;
 * @return {!Array<string>}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getBookingRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setBookingRefList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.addBookingRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.clearBookingRefList = function() {
  return this.setBookingRefList([]);
};


/**
 * repeated Cost.Status status = 3;
 * @return {!Array<!proto.swift.invoicing.v1.Cost.Status>}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.swift.invoicing.v1.Cost.Status>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Cost.Status>} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Status} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated Cost.Type type = 4;
 * @return {!Array<!proto.swift.invoicing.v1.Cost.Type>}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getTypeList = function() {
  return /** @type {!Array<!proto.swift.invoicing.v1.Cost.Type>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Cost.Type>} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setTypeList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Type} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.addType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.clearTypeList = function() {
  return this.setTypeList([]);
};


/**
 * repeated int64 organization_id = 5;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated int64 branch_id = 6;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getBranchIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setBranchIdList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.addBranchId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.clearBranchIdList = function() {
  return this.setBranchIdList([]);
};


/**
 * optional string shipment_ref = 7;
 * @return {string}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string search = 8;
 * @return {string}
 */
proto.swift.invoicing.v1.CostFilter.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.CostFilter} returns this
 */
proto.swift.invoicing.v1.CostFilter.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CostSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CostSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CostSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CostSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CostSorting}
 */
proto.swift.invoicing.v1.CostSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CostSorting;
  return proto.swift.invoicing.v1.CostSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CostSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CostSorting}
 */
proto.swift.invoicing.v1.CostSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.invoicing.v1.CostSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.invoicing.v1.CostSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CostSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CostSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CostSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CostSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.CostSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.invoicing.v1.CostSorting.Field = {
  COST_ID: 0,
  BOOKING_REF: 1,
  STATUS: 2,
  TYPE: 3,
  ESTIMATED_AMOUNT: 4,
  ACTUAL_AMOUNT: 5,
  CREATED_AT: 6
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.invoicing.v1.CostSorting.Field}
 */
proto.swift.invoicing.v1.CostSorting.prototype.getField = function() {
  return /** @type {!proto.swift.invoicing.v1.CostSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.CostSorting.Field} value
 * @return {!proto.swift.invoicing.v1.CostSorting} returns this
 */
proto.swift.invoicing.v1.CostSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.invoicing.v1.CostSorting.Ordering}
 */
proto.swift.invoicing.v1.CostSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.invoicing.v1.CostSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.CostSorting.Ordering} value
 * @return {!proto.swift.invoicing.v1.CostSorting} returns this
 */
proto.swift.invoicing.v1.CostSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListCostsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListCostsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListCostsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.swift.invoicing.v1.CostFilter.toObject(includeInstance, f),
    sorting: (f = msg.getSorting()) && proto.swift.invoicing.v1.CostSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListCostsRequest}
 */
proto.swift.invoicing.v1.ListCostsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListCostsRequest;
  return proto.swift.invoicing.v1.ListCostsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListCostsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListCostsRequest}
 */
proto.swift.invoicing.v1.ListCostsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.invoicing.v1.CostFilter;
      reader.readMessage(value,proto.swift.invoicing.v1.CostFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.invoicing.v1.CostSorting;
      reader.readMessage(value,proto.swift.invoicing.v1.CostSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListCostsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListCostsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListCostsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.invoicing.v1.CostFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.invoicing.v1.CostSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.invoicing.v1.ListCostsRequest} returns this
*/
proto.swift.invoicing.v1.ListCostsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListCostsRequest} returns this
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CostFilter filter = 2;
 * @return {?proto.swift.invoicing.v1.CostFilter}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.invoicing.v1.CostFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.CostFilter, 2));
};


/**
 * @param {?proto.swift.invoicing.v1.CostFilter|undefined} value
 * @return {!proto.swift.invoicing.v1.ListCostsRequest} returns this
*/
proto.swift.invoicing.v1.ListCostsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListCostsRequest} returns this
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CostSorting sorting = 3;
 * @return {?proto.swift.invoicing.v1.CostSorting}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.invoicing.v1.CostSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.CostSorting, 3));
};


/**
 * @param {?proto.swift.invoicing.v1.CostSorting|undefined} value
 * @return {!proto.swift.invoicing.v1.ListCostsRequest} returns this
*/
proto.swift.invoicing.v1.ListCostsRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListCostsRequest} returns this
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListCostsRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.ListCostsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListCostsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListCostsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListCostsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListCostsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.swift.invoicing.v1.Cost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListCostsResponse}
 */
proto.swift.invoicing.v1.ListCostsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListCostsResponse;
  return proto.swift.invoicing.v1.ListCostsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListCostsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListCostsResponse}
 */
proto.swift.invoicing.v1.ListCostsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListCostsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListCostsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListCostsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListCostsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.ListCostsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.ListCostsResponse} returns this
 */
proto.swift.invoicing.v1.ListCostsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Cost costs = 2;
 * @return {!Array<!proto.swift.invoicing.v1.Cost>}
 */
proto.swift.invoicing.v1.ListCostsResponse.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Cost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Cost, 2));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Cost>} value
 * @return {!proto.swift.invoicing.v1.ListCostsResponse} returns this
*/
proto.swift.invoicing.v1.ListCostsResponse.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Cost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.ListCostsResponse.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.invoicing.v1.Cost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ListCostsResponse} returns this
 */
proto.swift.invoicing.v1.ListCostsResponse.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cost: (f = msg.getCost()) && proto.swift.invoicing.v1.Cost.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateCostRequest}
 */
proto.swift.invoicing.v1.CreateCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateCostRequest;
  return proto.swift.invoicing.v1.CreateCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateCostRequest}
 */
proto.swift.invoicing.v1.CreateCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * optional Cost cost = 1;
 * @return {?proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.CreateCostRequest.prototype.getCost = function() {
  return /** @type{?proto.swift.invoicing.v1.Cost} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Cost, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Cost|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateCostRequest} returns this
*/
proto.swift.invoicing.v1.CreateCostRequest.prototype.setCost = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateCostRequest} returns this
 */
proto.swift.invoicing.v1.CreateCostRequest.prototype.clearCost = function() {
  return this.setCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateCostRequest.prototype.hasCost = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cost: (f = msg.getCost()) && proto.swift.invoicing.v1.Cost.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateCostResponse}
 */
proto.swift.invoicing.v1.CreateCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateCostResponse;
  return proto.swift.invoicing.v1.CreateCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateCostResponse}
 */
proto.swift.invoicing.v1.CreateCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * optional Cost cost = 1;
 * @return {?proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.CreateCostResponse.prototype.getCost = function() {
  return /** @type{?proto.swift.invoicing.v1.Cost} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Cost, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Cost|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateCostResponse} returns this
*/
proto.swift.invoicing.v1.CreateCostResponse.prototype.setCost = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateCostResponse} returns this
 */
proto.swift.invoicing.v1.CreateCostResponse.prototype.clearCost = function() {
  return this.setCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateCostResponse.prototype.hasCost = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetBookingCostsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetBookingCostsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.swift.invoicing.v1.Cost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetBookingCostsRequest}
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetBookingCostsRequest;
  return proto.swift.invoicing.v1.SetBookingCostsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetBookingCostsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetBookingCostsRequest}
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetBookingCostsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetBookingCostsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cost costs = 1;
 * @return {!Array<!proto.swift.invoicing.v1.Cost>}
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Cost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Cost, 1));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Cost>} value
 * @return {!proto.swift.invoicing.v1.SetBookingCostsRequest} returns this
*/
proto.swift.invoicing.v1.SetBookingCostsRequest.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Cost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.invoicing.v1.Cost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.SetBookingCostsRequest} returns this
 */
proto.swift.invoicing.v1.SetBookingCostsRequest.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetBookingCostsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetBookingCostsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.swift.invoicing.v1.Cost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetBookingCostsResponse}
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetBookingCostsResponse;
  return proto.swift.invoicing.v1.SetBookingCostsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetBookingCostsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetBookingCostsResponse}
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetBookingCostsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetBookingCostsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cost costs = 1;
 * @return {!Array<!proto.swift.invoicing.v1.Cost>}
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Cost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Cost, 1));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Cost>} value
 * @return {!proto.swift.invoicing.v1.SetBookingCostsResponse} returns this
*/
proto.swift.invoicing.v1.SetBookingCostsResponse.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Cost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.invoicing.v1.Cost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.SetBookingCostsResponse} returns this
 */
proto.swift.invoicing.v1.SetBookingCostsResponse.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetCostStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetCostStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetCostStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    costId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetCostStatusRequest}
 */
proto.swift.invoicing.v1.SetCostStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetCostStatusRequest;
  return proto.swift.invoicing.v1.SetCostStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetCostStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetCostStatusRequest}
 */
proto.swift.invoicing.v1.SetCostStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostId(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.invoicing.v1.Cost.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetCostStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetCostStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetCostStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 cost_id = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.getCostId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.SetCostStatusRequest} returns this
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.setCostId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Cost.Status status = 2;
 * @return {!proto.swift.invoicing.v1.Cost.Status}
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.swift.invoicing.v1.Cost.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Status} value
 * @return {!proto.swift.invoicing.v1.SetCostStatusRequest} returns this
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.SetCostStatusRequest} returns this
 */
proto.swift.invoicing.v1.SetCostStatusRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetCostStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetCostStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetCostStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetCostStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cost: (f = msg.getCost()) && proto.swift.invoicing.v1.Cost.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetCostStatusResponse}
 */
proto.swift.invoicing.v1.SetCostStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetCostStatusResponse;
  return proto.swift.invoicing.v1.SetCostStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetCostStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetCostStatusResponse}
 */
proto.swift.invoicing.v1.SetCostStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetCostStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetCostStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetCostStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetCostStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * optional Cost cost = 1;
 * @return {?proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.SetCostStatusResponse.prototype.getCost = function() {
  return /** @type{?proto.swift.invoicing.v1.Cost} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.Cost, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.Cost|undefined} value
 * @return {!proto.swift.invoicing.v1.SetCostStatusResponse} returns this
*/
proto.swift.invoicing.v1.SetCostStatusResponse.prototype.setCost = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.SetCostStatusResponse} returns this
 */
proto.swift.invoicing.v1.SetCostStatusResponse.prototype.clearCost = function() {
  return this.setCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.SetCostStatusResponse.prototype.hasCost = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetShipmentCostsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.swift.invoicing.v1.Cost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsRequest}
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetShipmentCostsRequest;
  return proto.swift.invoicing.v1.SetShipmentCostsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsRequest}
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetShipmentCostsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cost costs = 1;
 * @return {!Array<!proto.swift.invoicing.v1.Cost>}
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Cost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Cost, 1));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Cost>} value
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsRequest} returns this
*/
proto.swift.invoicing.v1.SetShipmentCostsRequest.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Cost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.invoicing.v1.Cost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsRequest} returns this
 */
proto.swift.invoicing.v1.SetShipmentCostsRequest.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.SetShipmentCostsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.swift.invoicing.v1.Cost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsResponse}
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.SetShipmentCostsResponse;
  return proto.swift.invoicing.v1.SetShipmentCostsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsResponse}
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.Cost;
      reader.readMessage(value,proto.swift.invoicing.v1.Cost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.SetShipmentCostsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.SetShipmentCostsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.invoicing.v1.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cost costs = 1;
 * @return {!Array<!proto.swift.invoicing.v1.Cost>}
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.Cost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.Cost, 1));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.Cost>} value
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsResponse} returns this
*/
proto.swift.invoicing.v1.SetShipmentCostsResponse.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.invoicing.v1.Cost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.Cost}
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.invoicing.v1.Cost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.SetShipmentCostsResponse} returns this
 */
proto.swift.invoicing.v1.SetShipmentCostsResponse.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};


goog.object.extend(exports, proto.swift.invoicing.v1);
