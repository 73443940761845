import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as rrd from 'react-router-dom'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { availableRoutes } from 'route/routes'
import {
  RouteName,
  RouteNames,
  getDefaultRoutePerRole,
  requiredRolesRouteMap,
} from 'route/routes-map'

import { User } from '../proto/iam/v1/user_pb'

import { Actions } from 'store/auth/actions'
import { RootState } from 'store/reducer'

import { useRouter } from 'helpers/route.helper'

interface NavigationGuardProps {
  children: JSX.Element
  redirectTo?: RouteName
}

export const NavigationGuard = ({ children, redirectTo }: NavigationGuardProps) => {
  try {
    const { user } = useSelector((state: RootState) => state.iam)
    const dispatch = useDispatch()
    const flags = useFlags()
    const currentUser = user?.currentUser
    const isFetching = user?.isFetching
    const defaultFallback = getDefaultRoutePerRole(currentUser?.getRolesList() ?? [])
    const [, location] = useRouter()
    const { search } = location

    useEffect(() => {
      if (currentUser) {
        dispatch(Actions.verifyToken())
      }
    }, [currentUser, dispatch])

    if (isFetching) {
      const navigationParameter = `${search}`
      return <rrd.Navigate to={`/loading?` + new URLSearchParams({ to: navigationParameter })} />
    }

    if (!currentUser) {
      return <rrd.Navigate to={RouteNames.Login} />
    }

    const route = rrd.matchRoutes(availableRoutes as any[], location)
    const targetRoute = route ? route[1] : null

    if (!targetRoute || !targetRoute.route) {
      console.error('Target route not found or does not have route property:', targetRoute)
      return <rrd.Navigate to={defaultFallback} />
    }

    const { requiredRoles } = requiredRolesRouteMap[targetRoute.route.path as RouteName]
    const { userHasRights } = checkForUserRightsAndRoles(currentUser, requiredRoles)

    const routesToCheck = ['contracts', 'quotes', 'reports']

    const preventNavigation = routesToCheck.some((routeToCheck) => {
      const navigatingToRoute = route?.some((val) => val.pathname.includes(routeToCheck))
      return navigatingToRoute && !flags[`${routeToCheck}Fe`]
    })

    if (preventNavigation) {
      return <rrd.Navigate to={defaultFallback} />
    }

    return userHasRights ? (
      children
    ) : (
      <rrd.Navigate to={redirectTo ? redirectTo : (defaultFallback as string)} />
    )
  } catch (error) {
    console.error('Error in NavigationGuard:', error)
    return <rrd.Navigate to={RouteNames.Login} />
  }
}

const checkForUserRightsAndRoles = (currentUser?: User, requiredRoles?: User.Role[]) => {
  // default to true since if the route does not require a role everyone should
  // be able to navigate to that route.
  const userRights = requiredRoles?.filter((role) => currentUser?.getRolesList()?.includes(role))
  const routeDoesNotRequireRoles = !requiredRoles || requiredRoles.length === 0
  const userHasRights = routeDoesNotRequireRoles || (userRights && userRights.length > 0)
  return { userHasRights }
}
