/**
 * @fileoverview gRPC-Web generated client stub for swift.booking.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: booking/v1/deviation.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')
const proto = {};
proto.swift = {};
proto.swift.booking = {};
proto.swift.booking.v1 = require('./deviation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.DeviationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.DeviationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.ListDeviationsRequest,
 *   !proto.swift.booking.v1.ListDeviationsResponse>}
 */
const methodDescriptor_DeviationService_ListDeviations = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.DeviationService/ListDeviations',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.ListDeviationsRequest,
  proto.swift.booking.v1.ListDeviationsResponse,
  /**
   * @param {!proto.swift.booking.v1.ListDeviationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.ListDeviationsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.ListDeviationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.ListDeviationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.ListDeviationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.DeviationServiceClient.prototype.listDeviations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/ListDeviations',
      request,
      metadata || {},
      methodDescriptor_DeviationService_ListDeviations,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.ListDeviationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.ListDeviationsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.DeviationServicePromiseClient.prototype.listDeviations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/ListDeviations',
      request,
      metadata || {},
      methodDescriptor_DeviationService_ListDeviations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.CreateDeviationRequest,
 *   !proto.swift.booking.v1.CreateDeviationResponse>}
 */
const methodDescriptor_DeviationService_CreateDeviation = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.DeviationService/CreateDeviation',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.CreateDeviationRequest,
  proto.swift.booking.v1.CreateDeviationResponse,
  /**
   * @param {!proto.swift.booking.v1.CreateDeviationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.CreateDeviationResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.CreateDeviationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.CreateDeviationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.CreateDeviationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.DeviationServiceClient.prototype.createDeviation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/CreateDeviation',
      request,
      metadata || {},
      methodDescriptor_DeviationService_CreateDeviation,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.CreateDeviationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.CreateDeviationResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.DeviationServicePromiseClient.prototype.createDeviation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/CreateDeviation',
      request,
      metadata || {},
      methodDescriptor_DeviationService_CreateDeviation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.EditDeviationRequest,
 *   !proto.swift.booking.v1.EditDeviationResponse>}
 */
const methodDescriptor_DeviationService_EditDeviation = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.DeviationService/EditDeviation',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.EditDeviationRequest,
  proto.swift.booking.v1.EditDeviationResponse,
  /**
   * @param {!proto.swift.booking.v1.EditDeviationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.EditDeviationResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.EditDeviationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.EditDeviationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.EditDeviationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.DeviationServiceClient.prototype.editDeviation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/EditDeviation',
      request,
      metadata || {},
      methodDescriptor_DeviationService_EditDeviation,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.EditDeviationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.EditDeviationResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.DeviationServicePromiseClient.prototype.editDeviation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/EditDeviation',
      request,
      metadata || {},
      methodDescriptor_DeviationService_EditDeviation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.DeleteDeviationRequest,
 *   !proto.swift.booking.v1.DeleteDeviationResponse>}
 */
const methodDescriptor_DeviationService_DeleteDeviation = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.DeviationService/DeleteDeviation',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.DeleteDeviationRequest,
  proto.swift.booking.v1.DeleteDeviationResponse,
  /**
   * @param {!proto.swift.booking.v1.DeleteDeviationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.DeleteDeviationResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.DeleteDeviationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.DeleteDeviationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.DeleteDeviationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.DeviationServiceClient.prototype.deleteDeviation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/DeleteDeviation',
      request,
      metadata || {},
      methodDescriptor_DeviationService_DeleteDeviation,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.DeleteDeviationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.DeleteDeviationResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.DeviationServicePromiseClient.prototype.deleteDeviation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.DeviationService/DeleteDeviation',
      request,
      metadata || {},
      methodDescriptor_DeviationService_DeleteDeviation);
};


module.exports = proto.swift.booking.v1;

