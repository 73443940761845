import { IContract } from 'shared/interface/contract.interface'
import { INominatedContract } from 'shared/interface/nominated-contract.interface'

import * as contractTypes from 'types/contract'

import {
  ActionTypes,
  CREATE_CONTRACT_ERR,
  CREATE_CONTRACT_REQ,
  CREATE_CONTRACT_RESP,
  GET_CONTRACT_ERR,
  GET_CONTRACT_REQ,
  GET_CONTRACT_RESP,
  LIST_CONTRACTS_ERR,
  LIST_CONTRACTS_REQ,
  LIST_CONTRACTS_RESP,
  LIST_NOMINATED_CONTRACT_RESP,
  UPDATE_CONTRACT_ERR,
  UPDATE_CONTRACT_REQ,
  UPDATE_CONTRACT_RESP,
} from './actions'

export interface State {
  readonly pages: Map<contractTypes.ContractPage, number[]>
  readonly items: Map<string, IContract>
  readonly nominatedItem?: INominatedContract
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly updatingContract: boolean
}

const initialState: State = {
  pages: new Map(),
  items: new Map(),
  nominatedItem: undefined,
  count: 0,
  err: undefined,
  isFetching: false,
  updatingContract: false,
}

const reducer = (s: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case LIST_CONTRACTS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case LIST_CONTRACTS_RESP: {
      const { count, contracts, page } = action.payload
      const newItems = contracts.reduce<{ [key: string]: IContract }>((map, r) => {
        map[r.contractId] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      const pageItems = contracts.map((s) => s.contractId)
      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })
      pages.set(page, pageItems)
      return { ...s, items, count, isFetching: false, pages }
    }

    case LIST_CONTRACTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case LIST_NOMINATED_CONTRACT_RESP: {
      const { nominatedContract } = action.payload
      return { ...s, nominatedItem: nominatedContract, isFetching: false }
    }

    case GET_CONTRACT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case GET_CONTRACT_RESP: {
      const { contract } = action.payload
      const items = s.items
      items.set(contract.contractId.toString(), contract)
      return { ...s, items, isFetching: false }
    }

    case GET_CONTRACT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case CREATE_CONTRACT_REQ: {
      return { ...s, err: undefined, updatingContract: true }
    }
    case CREATE_CONTRACT_RESP: {
      const { contract } = action.payload
      const items = s.items
      items.set(contract.contractId.toString(), contract)
      return { ...s, items, updatingContract: false }
    }
    case CREATE_CONTRACT_ERR: {
      const { err } = action.payload
      return { ...s, err, updatingContract: false }
    }

    case UPDATE_CONTRACT_REQ: {
      return { ...s, err: undefined, updatingContract: true }
    }
    case UPDATE_CONTRACT_RESP: {
      const { contract } = action.payload
      const items = s.items
      items.set(contract.contractId.toString(), contract)
      return { ...s, items, updatingContract: false }
    }
    case UPDATE_CONTRACT_ERR: {
      const { err } = action.payload
      return { ...s, err, updatingContract: false }
    }

    default:
      return s
  }
}

export default reducer
