import * as orderv1 from '../proto/order/v1/order_pb'

import { Pagination, defaultPageSize } from './query'

export interface OrderFilter {
  type?: Array<orderv1.OrderType>
  status?: Array<orderv1.Order.Status>
  search?: string
}

export type OrderPage = {
  pagination: Pagination
  filter: OrderFilter
  sorting: orderv1.OrderSorting
}

export const getDefaultOrderPage = (): OrderPage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new orderv1.OrderSorting(),
})

// A very permissive type so we can convert some indexes to a JSON string in
// order to allow conversion to a query parameter string.
export type ParsingOrderPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  search?: string
  sorting?: string
}
