import React from 'react'

import { Alert } from 'antd'

import './style.css'

interface Props {
  description?: React.ReactNode
  action?: React.ReactNode
  showIcon?: boolean
  closable?: boolean
  message?: string
}

export const ErrorAlert: React.FC<Props> = ({
  description,
  showIcon = true,
  message,
  closable = false,
  action,
}) => {
  return (
    <Alert
      message={message}
      description={description}
      type="error"
      showIcon={showIcon}
      closable={closable}
      className="intereast-alert"
      action={action}
    />
  )
}

export const WarningAlert: React.FC<Props> = ({
  description,
  showIcon = true,
  message,
  closable = false,
  action,
}) => {
  return (
    <Alert
      message={message}
      description={description}
      type="warning"
      showIcon={showIcon}
      closable={closable}
      className="intereast-alert"
      action={action}
    />
  )
}

export const InfoAlert: React.FC<Props> = ({
  description,
  showIcon = true,
  message,
  closable = false,
  action,
}) => {
  return (
    <Alert
      message={message}
      description={description}
      type="info"
      showIcon={showIcon}
      closable={closable}
      className="intereast-alert"
      action={action}
    />
  )
}
