import React from 'react'

import './typography.css'

interface TypographyProps<T extends string | number> {
  text: T
  type?: 'label' | 'description'
  emphasis?: 'high' | 'mid' | 'low'
  size?: 'large' | 'normal' | 'small'
  fontWeight?: 'normal' | 'bold'
  inline?: boolean
}
export const TypographyComponent = <T extends string | number>({
  text,
  inline,
  type = 'label',
  emphasis,
  fontWeight,
  size,
}: TypographyProps<T>) => {
  const getClasNames = (): string => {
    const emphasisMap = {
      ['high']: 'inter-typography-emphasis--high',
      ['mid']: 'inter-typography-emphasis--mid',
      ['low']: 'inter-typography-emphasis--low',
    }
    const fontWeightMap = {
      ['normal']: 'inter-typography-weight--normal',
      ['bold']: 'inter-typography-weight--bold',
    }

    const sizeMap = {
      ['large']: 'inter-typography-size--large',
      ['normal']: 'inter-typography-size--normal',
      ['small']: 'inter-typography-size--small',
    }

    const fw = type === 'label' ? 'bold' : 'normal'
    const fontEmphasis = type === 'label' ? 'low' : 'high'
    const fontSize = size ?? 'normal'
    return `
      ${emphasisMap[emphasis ?? fontEmphasis]}
      ${fontWeightMap[fontWeight ?? fw]}
      ${sizeMap[fontSize]}`
  }
  return inline ? (
    <span className={getClasNames()}>{text}</span>
  ) : (
    <div className={getClasNames()}>{text}</div>
  )
}
