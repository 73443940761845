import * as React from 'react'

import { Button, Divider, Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import * as lib from 'country-data'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import * as contactv1 from '../../proto/crm/v1/contact_pb'
import { Address } from 'proto/crm/v1/address_pb'

import { PhoneInput } from '../../components/PhoneInput'

interface Props {
  joinCode?: string
  address?: Address
  contact?: contactv1.Contact
  isJoinCodeValid?: boolean
  shouldCreateUsergroup?: boolean
  isFetching: boolean
  signupError?: Error
  onJoinCodeChange: (joinCode: string) => void
  onSubmit: (joinCode: string, address?: Address, contact?: contactv1.Contact) => void
  onCancel?: () => void
}

export default class SignupForm extends React.Component<Props, {}> {
  private formRef: React.RefObject<FormInstance>
  public static defaultProps: Partial<Props> = {}
  constructor(props: Props) {
    super(props)
    this.formRef = React.createRef()
  }

  validatePhone = async (rule: any, value: any) => {
    const countryCode = this.formRef.current?.getFieldValue('cpcn')

    const phoneNumber = parsePhoneNumberFromString(countryCode + value)
    if (!phoneNumber) {
      throw new Error('Enter a valid phone number')
    }
  }

  onFinish = () => {
    const { shouldCreateUsergroup } = this.props
    const address = new Address()
    const contact = new contactv1.Contact()
    if (!this.formRef.current) {
      return
    }

    this.formRef.current.validateFields().then((values) => {
      const joinCode = values.ic

      if (address && contact) {
        if (shouldCreateUsergroup) {
          address.setName(values.n)
          address.setAddress1(values.sa1)
          address.setAddress2(values.sa2)
          address.setAddress3(values.sa3)
          address.setPostalcode(values.pc)
          address.setCity(values.ci)
          address.setCounty(values.cty)
          address.setCountryId(values.cr)
          address.setUsageList([
            Address.Usage.SENDER,
            Address.Usage.ORDER_PURCHASE,
            Address.Usage.ORDER_RETURN,
          ])

          contact.setEmail(values.ce)
        }

        contact.setName(values.cn)
        contact.setSurname(values.cs)

        const phoneNumber = parsePhoneNumberFromString(values.cpcn + values.cp)
        if (!phoneNumber) {
          return
        }

        contact.setPhone(phoneNumber.format('E.164'))
        address.setContact(contact)
      }

      this.props.onSubmit(joinCode, address, contact)
    })
  }

  handleJoinCodeChange = () => {
    if (!this.formRef.current) {
      return
    }
    this.props.onJoinCodeChange(this.formRef.current.getFieldValue('ic'))
  }

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  }

  render() {
    const { onCancel } = this.props

    /* These country codes are invalid/duplicated and should not be an option when selecting*/
    const countries = lib.countries.all.filter((c) => c.status === 'assigned')
    const { isJoinCodeValid, shouldCreateUsergroup, isFetching, signupError } = this.props

    type Validation = {
      status: 'success' | 'warning' | 'error' | 'validating' | ''
      message: string
    }

    const validation: Validation = { status: 'success', message: '' }

    if (isFetching) {
      validation.status = 'validating'
      validation.message = ''
    } else if (signupError || isJoinCodeValid === false) {
      validation.status = 'error'
      validation.message = 'Invalid invitation code'
    } else if (isJoinCodeValid) {
      validation.status = 'success'
      validation.message = 'We just need a few more account details 😄'
    }

    return (
      <Form
        ref={this.formRef}
        layout="horizontal"
        className="AddressModal"
        onFinish={this.onFinish}
      >
        <Divider orientation="left">Invitation Code</Divider>
        <Form.Item
          name="ic"
          hasFeedback={isJoinCodeValid !== undefined || isFetching}
          validateStatus={validation.status}
          help={validation.message}
          label="Invitation Code"
          {...this.formItemLayout}
          rules={[{ required: true, message: 'Enter a valid invitation code' }]}
        >
          <Input
            onKeyUp={this.handleJoinCodeChange}
            disabled={isJoinCodeValid}
            placeholder="123456"
          />
        </Form.Item>
        {shouldCreateUsergroup ? (
          <>
            <Divider orientation="left">Address</Divider>
            <Form.Item
              name="n"
              label="Company"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Enter full company name' }]}
            >
              <Input placeholder="Company AB" />
            </Form.Item>
            <Form.Item
              name="sa1"
              label="Address"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Enter full address' }]}
            >
              <Input placeholder="Storgatan 1" />
            </Form.Item>
            <Form.Item name="sa2" label=" " colon={false} {...this.formItemLayout}>
              <Input placeholder="optional box, c/o, building etc" />
            </Form.Item>
            <Form.Item name="sa3" label=" " colon={false} {...this.formItemLayout}>
              <Input placeholder="optional box, c/o, building etc" />
            </Form.Item>
            <Form.Item
              name="pc"
              label="Postal Code"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'SE12345' }]}
            >
              <Input style={{ width: 100 }} />
            </Form.Item>
            <Form.Item
              name="ci"
              label="City"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Gothenburg' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="cty" label="State" {...this.formItemLayout}>
              <Input placeholder="optional state, province, county etc" />
            </Form.Item>
            <Form.Item
              name="cr"
              label="Country"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Select country' }]}
            >
              <Select
                showSearch={true}
                placeholder="Please select a country"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option && option.props && option.props.children) {
                    const child = option.props.children as string
                    return child.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {countries.map((r) => (
                  <Select.Option key={r.alpha2} value={r.alpha2.toLowerCase()}>
                    {r.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Divider orientation="left">Contact</Divider>
            <Form.Item
              name="cn"
              label="Name"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Enter contact name' }]}
            >
              <Input placeholder="Mark" />
            </Form.Item>
            <Form.Item
              name="cs"
              label="Surname"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Enter contact surname' }]}
            >
              <Input placeholder="Anderson" />
            </Form.Item>
            <Form.Item
              name="ce"
              label="Email"
              {...this.formItemLayout}
              rules={[
                { required: true, message: 'Enter contact email' },
                { type: 'email', message: 'Enter valid contact email' },
              ]}
            >
              <Input placeholder="mark.anderson@company.com" />
            </Form.Item>
            <PhoneInput defaultCode="+46" layout={this.formItemLayout} />
          </>
        ) : isJoinCodeValid ? (
          <>
            <Divider orientation="left">Contact</Divider>
            <Form.Item
              name="cn"
              label="Name"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Enter contact name' }]}
            >
              <Input placeholder="Mark" />
            </Form.Item>
            <Form.Item
              name="cs"
              label="Surname"
              {...this.formItemLayout}
              rules={[{ required: true, message: 'Enter contact surname' }]}
            >
              <Input placeholder="Anderson" />
            </Form.Item>
            <PhoneInput defaultCode="+46" layout={this.formItemLayout} />
          </>
        ) : (
          ''
        )}
        <br />

        <Button
          type="primary"
          disabled={!isJoinCodeValid}
          htmlType="submit"
          style={{ marginRight: 8 }}
        >
          Finish
        </Button>

        {onCancel && <Button onClick={() => onCancel()}>Cancel</Button>}
      </Form>
    )
  }
}
