import { Invoice } from 'proto/invoicing/v1/invoice_pb'

import { ActionsUnion, createAction } from 'store/helpers'

import * as invoiceType from 'types/invoice'

export const LIST_REQ = 'swift2/invoice/LIST_REQ'
export const LIST_RESP = 'swift2/invoice/LIST_RESP'
export const LIST_ERR = 'swift2/invoice/LIST_ERR'
export const UPDATE_INVOICE_REQ = 'swift2/invoice/UPDATE_INVOICE_REQ'
export const UPDATE_INVOICE_RESP = 'swift2/invoice/UPDATE_INVOICE_RESP'
export const UPDATE_INVOICE_ERR = 'swift2/invoice/UPDATE_INVOICE_ERR'
export const ISSUE_INVOICE_REQ = 'swift2/invoice/ISSUE_INVOICE_REQ'
export const ISSUE_INVOICE_RESP = 'swift2/invoice/ISSUE_INVOICE_RESP'
export const ISSUE_INVOICE_ERR = 'swift2/invoice/ISSUE_INVOICE_ERR'
export const CREATE_INVOICE_REQ = 'swift2/invoice/CREATE_INVOICE_REQ'
export const CREATE_INVOICE_RESP = 'swift2/invoice/CREATE_INVOICE_RESP'
export const CREATE_INVOICE_ERR = 'swift2/invoice/CREATE_INVOICE_ERR'
export const ISSUE_COLLECTIVE_INVOICE_REQ = 'swift2/invoice/ISSUE_COLLECTIVE_INVOICE_REQ'
export const ISSUE_COLLECTIVE_INVOICE_RESP = 'swift2/invoice/ISSUE_COLLECTIVE_INVOICE_RESP'
export const ISSUE_COLLECTIVE_INVOICE_ERR = 'swift2/invoice/ISSUE_COLLECTIVE_INVOICE_ERR'
export const SEND_INVOICE_REMINDERS_REQ = 'swift2/invoice/SEND_INVOICE_REMINDER_REQ'
export const SEND_INVOICE_REMINDERS_RESP = 'swift2/invoice/SEND_INVOICE_REMINDER_RESP'
export const SEND_INVOICE_REMINDERS_ERR = 'swift2/invoice/SEND_INVOICE_REMINDER_ERR'
export const PREVIEW_INVOICE_REQ = 'swift2/invoice/PREVIEW_INVOICE_REQ'
export const PREVIEW_INVOICE_RESP = 'swift2/invoice/PREVIEW_INVOICE_RESP'
export const PREVIEW_INVOICE_ERR = 'swift2/invoice/PREVIEW_INVOICE_ERR'
export const PREVIEW_COLLECTIVE_INVOICE_REQ = 'swift2/invoice/PREVIEW_COLLECTIVE_INVOICE_REQ'
export const PREVIEW_COLLECTIVE_INVOICE_RESP = 'swift2/invoice/PREVIEW_COLLECTIVE_INVOICE_RESP'
export const PREVIEW_COLLECTIVE_INVOICE_ERR = 'swift2/invoice/PREVIEW_COLLECTIVE_INVOICE_ERR'
export const DELETE_INVOICE_REQ = 'swift2/invoice/DELETE_INVOICE_REQ'
export const DELETE_INVOICE_RESP = 'swift2/invoice/DELETE_INVOICE_RESP'
export const DELETE_INVOICE_ERR = 'swift2/invoice/DELETE_INVOICE_ERR'
export const UPDATE_INVOICE_NOTE_REQ = 'swift2/invoice/UPDATE_INVOICE_NOTE_REQ'
export const UPDATE_INVOICE_NOTE_RESP = 'swift2/invoice/UPDATE_INVOICE_NOTE_RESP'
export const UPDATE_INVOICE_NOTE_ERR = 'swift2/invoice/UPDATE_INVOICE_NOTE_ERR'
export const UPDATE_AUTO_INVOCING_REQ = 'swift2/invoice/UPDATE_AUTO_INVOCING_REQ'
export const UPDATE_AUTO_INVOCING_RESP = 'swift2/invoice/UPDATE_AUTO_INVOCING_RESP'
export const UPDATE_AUTO_INVOCING_ERR = 'swift2/invoice/UPDATE_AUTO_INVOCING_ERR'

export const Actions = {
  listInvoicesReq: (
    page: invoiceType.InvoicePage,
    fetchInvoiceDocuments?: boolean,
    skipArticles?: boolean,
  ) => createAction(LIST_REQ, { page, fetchInvoiceDocuments, skipArticles }),
  listInvoicesResp: (count: number, invoices: Invoice[], page: invoiceType.InvoicePage) =>
    createAction(LIST_RESP, { count, invoices, page }),
  listInvoicesErr: (err: Error) => createAction(LIST_ERR, { err }),
  updateInvoiceReq: (invoice: Invoice) => createAction(UPDATE_INVOICE_REQ, { invoice }),
  updateInvoiceResp: (invoice: Invoice) => createAction(UPDATE_INVOICE_RESP, { invoice }),
  updateInvoiceErr: (err: Error) => createAction(UPDATE_INVOICE_ERR, { err }),
  issueInvoiceReq: (invoice: Invoice) => createAction(ISSUE_INVOICE_REQ, { invoice }),
  issueInvoiceResp: (invoice: Invoice) => createAction(ISSUE_INVOICE_RESP, { invoice }),
  issueInvoiceErr: (err: Error) => createAction(ISSUE_INVOICE_ERR, { err }),
  createInvoiceReq: (invoice: Invoice) => createAction(CREATE_INVOICE_REQ, { invoice }),
  createInvoiceResp: (invoice: Invoice) => createAction(CREATE_INVOICE_RESP, { invoice }),
  createInvoiceErr: (err: Error) => createAction(CREATE_INVOICE_ERR, { err }),
  issueCollectiveInvoiceReq: (
    invoiceIds: number[],
    invoicingEmails: string[],
    page: invoiceType.InvoicePage,
  ) => createAction(ISSUE_COLLECTIVE_INVOICE_REQ, { invoiceIds, invoicingEmails, page }),
  issueCollectiveInvoiceResp: (invoices: Invoice[]) =>
    createAction(ISSUE_COLLECTIVE_INVOICE_RESP, { invoices }),
  issueCollectiveInvoiceErr: (err: Error) => createAction(ISSUE_COLLECTIVE_INVOICE_ERR, { err }),
  sendInvoiceRemindersReq: (reminders: { [key: number]: string[] }) =>
    createAction(SEND_INVOICE_REMINDERS_REQ, { reminders }),
  sendInvoiceRemindersResp: (invoices: Invoice[]) =>
    createAction(SEND_INVOICE_REMINDERS_RESP, { invoices }),
  sendInvoiceRemindersErr: (err: Error) => createAction(SEND_INVOICE_REMINDERS_ERR, { err }),
  previewInvoiceReq: (invoice: Invoice) => createAction(PREVIEW_INVOICE_REQ, { invoice }),
  previewInvoiceResp: () => createAction(PREVIEW_INVOICE_RESP, {}),
  previewInvoiceErr: (err: Error) => createAction(PREVIEW_INVOICE_ERR, { err }),
  previewCollectiveInvoiceReq: (invoiceIds: number[]) =>
    createAction(PREVIEW_COLLECTIVE_INVOICE_REQ, { invoiceIds }),
  previewCollectiveInvoiceResp: () => createAction(PREVIEW_COLLECTIVE_INVOICE_RESP, {}),
  previewCollectiveInvoiceErr: (err: Error) =>
    createAction(PREVIEW_COLLECTIVE_INVOICE_ERR, { err }),
  deleteInvoiceReq: (invoiceId: number) => createAction(DELETE_INVOICE_REQ, { invoiceId }),
  deleteInvoiceResp: (invoiceId: number) => createAction(DELETE_INVOICE_RESP, { invoiceId }),
  deleteInvoiceErr: (err: Error) => createAction(DELETE_INVOICE_ERR, { err }),
  updateInvoiceNoteErr: (err: Error) => createAction(UPDATE_INVOICE_NOTE_ERR, { err }),
  updateInvoiceNoteReq: (invoice: Invoice) => createAction(UPDATE_INVOICE_NOTE_REQ, { invoice }),
  updateInvoiceNoteResp: (invoice: Invoice) => createAction(UPDATE_INVOICE_NOTE_RESP, { invoice }),
  updateAutoInvoicingReq: (invoiceID: number, toggle: boolean, pending: boolean) =>
    createAction(UPDATE_AUTO_INVOCING_REQ, { invoiceID, toggle, pending }),
  updateAutoInvoicingResp: (invoice: Invoice) =>
    createAction(UPDATE_AUTO_INVOCING_RESP, { invoice }),
  updateAutoInvoicingErr: (err: Error) => createAction(UPDATE_AUTO_INVOCING_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
