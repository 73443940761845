import { put, race, select, take, takeEvery } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import * as organizationv1 from '../../proto/iam/v1/organization_pb'
import * as userv1 from '../../proto/iam/v1/user_pb'

import {
  Actions as BookingActions,
  EDIT_BOOKING_ERR,
  EDIT_BOOKING_RESP,
} from '../../store/booking/booking/actions'
import {
  EDIT_ERR as EDIT_ORGANIZATION_ERR,
  EDIT_RESP as EDIT_ORGANIZATION_RESP,
  LIST_RESP as LIST_ORGANIZATION_RESP,
  Actions as OrganizationActions,
} from '../../store/iam/organization/actions'
import {
  EDIT_ERR as EDIT_USER_ERR,
  EDIT_RESP as EDIT_USER_RESP,
  Actions as UserActions,
} from '../../store/iam/user/actions'
import { getCurrentUser } from '../../store/iam/user/reducer'
import { Actions as NotificationActions } from '../../store/notification/actions'
import {
  Actions,
  CREATE_TRANSPORT_OPERATOR_REQ,
  DELETE_TRANSPORT_OPERATOR_REQ,
  EDIT_TRANSPORT_OPERATOR_REQ,
  EDIT_USER_DEFAULT_ADDRESS,
  SET_BOOKING_CONFIG,
  SET_USER_DEFAULT_ADDRESSES,
  SET_USER_FILTER_ORDER,
} from '../../store/ui/config/actions'
import { getTransportOperators } from '../../store/ui/config/reducer'

import { bookingUiTypeToJsonString } from '../../types/bookingui'
import { TransportOperatorUiType } from '../../types/transportOperator'
import { UserConfig } from '../../types/userui'

//  Set user DefaultAddresses following User Edition
export function* setUserDefaultAddresses(
  action: ReturnType<typeof Actions.setUserDefaultAddresses>,
) {
  const { user, defaultAddresses } = action.payload
  // validate user.
  let validUser: userv1.User
  if (!user) {
    validUser = yield select(getCurrentUser)
  } else {
    validUser = user
  }

  const userConfig = new UserConfig()
  userConfig.configFromJSON(validUser.getUiConfigJson())
  userConfig.setDefaultAddresses(defaultAddresses)
  validUser.setUiConfigJson(userConfig.configToJSON())

  yield put(UserActions.editUserReq(validUser))
  const { editUserResp, editUserErr } = yield race({
    editUserErr: take(EDIT_USER_ERR),
    editUserResp: take(EDIT_USER_RESP),
  })

  if (editUserErr) {
    yield put(UserActions.editUserErr(editUserErr.payload.err))
    return
  }

  const respUser: userv1.User = editUserResp.payload.user
  // only update ui store if current user was updated.
  if (validUser && respUser && validUser.getUserId() === respUser.getUserId()) {
    const newUserConfig = new UserConfig()
    newUserConfig.configFromJSON(respUser.getUiConfigJson())
    yield put(Actions.setUserConfig(newUserConfig))
  }
}

//  Edit user DefaultAddress following User Edition.
export function* editUserDefaultAddress(action: ReturnType<typeof Actions.editUserDefaultAddress>) {
  const { user, defaultAddress } = action.payload
  // validate user.
  let validUser: userv1.User
  if (!user) {
    validUser = yield select(getCurrentUser)
  } else {
    validUser = user
  }
  const userConfig = new UserConfig()
  userConfig.configFromJSON(validUser.getUiConfigJson())
  const didntUpdate = !userConfig.editDefaultAddress(defaultAddress)
  if (didntUpdate) {
    return
  }
  validUser.setUiConfigJson(userConfig.configToJSON())

  yield put(UserActions.editUserReq(validUser))
  const { editUserResp, editUserErr } = yield race({
    editUserErr: take(EDIT_USER_ERR),
    editUserResp: take(EDIT_USER_RESP),
  })

  if (editUserErr) {
    yield put(UserActions.editUserErr(editUserErr.payload.err))
    return
  }

  const respUser: userv1.User = editUserResp.payload.user
  // only update ui store if current user was updated.
  if (validUser && respUser && validUser.getUserId() === respUser.getUserId()) {
    const newUserConfig = new UserConfig()
    newUserConfig.configFromJSON(respUser.getUiConfigJson())
    yield put(Actions.setUserConfig(newUserConfig))
  }
}

//  Set user setUserFilterOrder following User Edition
export function* setUserFilterOrder(action: ReturnType<typeof Actions.setUserFilterOrder>) {
  const { user, order } = action.payload
  // validate user.
  let validUser: userv1.User
  if (!user) {
    validUser = yield select(getCurrentUser)
  } else {
    validUser = user
  }

  const userConfig = new UserConfig()
  userConfig.configFromJSON(validUser.getUiConfigJson())
  userConfig.setFilterOrder(order)
  validUser.setUiConfigJson(userConfig.configToJSON())

  yield put(UserActions.editUserReq(validUser))
  const { editUserResp, editUserErr } = yield race({
    editUserErr: take(EDIT_USER_ERR),
    editUserResp: take(EDIT_USER_RESP),
  })

  if (editUserErr) {
    yield put(UserActions.editUserErr(editUserErr.payload.err))
    return
  }

  yield put(
    NotificationActions.send({
      key: `user-${user?.getUserId()}`,
      kind: 'success',
      message: 'Filter Order Edited',
      description: 'The filter order was edited successfully.',
      dismissAfter: 4500,
    }),
  )

  const respUser: userv1.User = editUserResp.payload.user
  // only update ui store if current user was updated.
  if (validUser && respUser && validUser.getUserId() === respUser.getUserId()) {
    const newUserConfig = new UserConfig()
    newUserConfig.configFromJSON(respUser.getUiConfigJson())
    yield put(Actions.setUserConfig(newUserConfig))
  }
}

export function* setBookingUiConfig(action: ReturnType<typeof Actions.setBookingUiConfig>) {
  const { booking, uiConfig } = action.payload
  booking.setUiConfigJson(bookingUiTypeToJsonString(uiConfig))
  yield put(BookingActions.editBookingReq(booking))
  const { editBookingErr } = yield race({
    editBookingErr: take(EDIT_BOOKING_ERR),
    editBookingResp: take(EDIT_BOOKING_RESP),
  })
  if (editBookingErr) {
    yield put(BookingActions.editBookingErr(editBookingErr.payload.err))
    return
  }
}

// Return TransportOperators every time the orgs are listed.
export function* listTransportOperators(
  action: ReturnType<typeof OrganizationActions.listOrganizationsResp>,
) {
  const { organizations } = action.payload
  const transportOperators: TransportOperatorUiType[] = []
  organizations.forEach((o) => {
    const list = o.getConfig()?.getTransportOperatorList()
    if (list) {
      list.forEach((l) =>
        transportOperators.push({
          ID: l.getId(),
          userID: l.getUserId(),
          organizationID: o.getOrganizationId(),
          default: l.getDefault(),
          transportModes: l.getTransportModesList(),
        }),
      )
    }
  })
  yield put(Actions.listTransportOperatorsResp(transportOperators))
}

export function* createTransportOperator(
  action: ReturnType<typeof Actions.createTransportOperatorReq>,
) {
  try {
    const { user, organization, transportOperator } = action.payload

    // check if user isn't already a transportOperator for this org.
    const transportOperators: TransportOperatorUiType[] = yield select(getTransportOperators)
    const alreadyExist = transportOperators.some(
      (s) => s.userID === user.getUserId() && s.organizationID === organization.getOrganizationId(),
    )
    if (alreadyExist) {
      throw new Error(
        'error while creating transport operator, user is already a transport operator for this organization',
      )
    }

    // generate unique id for pair.
    transportOperator.ID = uuidv4()
    // create user transportOperator object.
    const userTransportOperator = new userv1.TransportOperating()
    userTransportOperator.setId(transportOperator.ID)
    userTransportOperator.setOrganizationId(transportOperator.organizationID)
    userTransportOperator.setDefault(transportOperator.default)
    userTransportOperator.setTransportModesList(transportOperator.transportModes)
    // update user config.
    const updatedUserConfig = user.getConfig()
    let userConfig = new userv1.UserConfig()
    if (updatedUserConfig) {
      userConfig = userv1.UserConfig.deserializeBinary(updatedUserConfig.serializeBinary())
    }
    userConfig.setTransportOperatingList([
      ...userConfig.getTransportOperatingList(),
      userTransportOperator,
    ])
    user.setConfig(userConfig)
    yield put(UserActions.editUserReq(user))
    const { editUserErr } = yield race({
      editUserErr: take(EDIT_USER_ERR),
      editUserResp: take(EDIT_USER_RESP),
    })
    if (editUserErr) {
      throw new Error('error while updating user, transport operator configuration was interrupted')
    }
    // create organization transportOperator object.
    const orgTransportOperator = new organizationv1.TransportOperator()
    orgTransportOperator.setId(transportOperator.ID)
    orgTransportOperator.setUserId(transportOperator.userID)
    orgTransportOperator.setDefault(transportOperator.default)
    orgTransportOperator.setTransportModesList(transportOperator.transportModes)
    // update organization config.
    const organizationConfig = organization.getConfig()
    let updatedOrganizationConfig = new organizationv1.Config()
    if (organizationConfig) {
      updatedOrganizationConfig = organizationv1.Config.deserializeBinary(
        organizationConfig.serializeBinary(),
      )
    }
    updatedOrganizationConfig.setTransportOperatorList([
      ...updatedOrganizationConfig.getTransportOperatorList(),
      orgTransportOperator,
    ])
    organization.setConfig(updatedOrganizationConfig)
    yield put(OrganizationActions.editOrganizationReq(organization))
    const { editOrganizationErr } = yield race({
      editOrganizationErr: take(EDIT_ORGANIZATION_ERR),
      editOrganizationResp: take(EDIT_ORGANIZATION_RESP),
    })
    if (editOrganizationErr) {
      throw new Error(
        'error while updating organization transport operator, please check the transport operator section',
      )
    }
    yield put(Actions.createTransportOperatorResp(transportOperator))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createTransportOperatorErr(err))
  }
}

export function* editTransportOperator(
  action: ReturnType<typeof Actions.editTransportOperatorReq>,
) {
  try {
    const { user, organization, transportOperator } = action.payload

    // check if user isn't already a transportOperator for selected org.
    const transportOperators: TransportOperatorUiType[] = yield select(getTransportOperators)
    const alreadyExist = transportOperators.some(
      (s) =>
        s.ID !== transportOperator.ID &&
        s.userID === transportOperator.userID &&
        s.organizationID === transportOperator.organizationID,
    )
    if (alreadyExist) {
      throw new Error(
        'error while editing transport operator, user is already a transport operator for this organization',
      )
    }

    // create user transportOperator object.
    const userTransportOperator = new userv1.TransportOperating()
    userTransportOperator.setId(transportOperator.ID)
    userTransportOperator.setOrganizationId(transportOperator.organizationID)
    userTransportOperator.setDefault(transportOperator.default)
    userTransportOperator.setTransportModesList(transportOperator.transportModes)
    // update user config.
    const updatedUserConfig = user.getConfig()
    let userConfig = new userv1.UserConfig()
    if (updatedUserConfig) {
      userConfig = userv1.UserConfig.deserializeBinary(updatedUserConfig.serializeBinary())
    }

    userConfig.setTransportOperatingList([
      ...userConfig.getTransportOperatingList().filter((t) => t.getId() != transportOperator.ID),
      userTransportOperator,
    ])

    user.setConfig(userConfig)
    yield put(UserActions.editUserReq(user))
    const { editUserErr } = yield race({
      editUserErr: take(EDIT_USER_ERR),
      editUserResp: take(EDIT_USER_RESP),
    })
    if (editUserErr) {
      throw new Error('error while updating user, transport operator configuration was interrupted')
    }
    // create organization transportOperator object.
    const orgTransportOperator = new organizationv1.TransportOperator()
    orgTransportOperator.setId(transportOperator.ID)
    orgTransportOperator.setUserId(transportOperator.userID)
    orgTransportOperator.setDefault(transportOperator.default)
    orgTransportOperator.setTransportModesList(transportOperator.transportModes)
    // update organization config.
    const organizationConfig = organization.getConfig()
    let updatedOrganizationConfig = new organizationv1.Config()
    if (organizationConfig) {
      updatedOrganizationConfig = organizationv1.Config.deserializeBinary(
        organizationConfig.serializeBinary(),
      )
    }

    updatedOrganizationConfig.setTransportOperatorList([
      ...updatedOrganizationConfig
        .getTransportOperatorList()
        .filter((t) => t.getId() != transportOperator.ID),
      orgTransportOperator,
    ])

    organization.setConfig(updatedOrganizationConfig)
    yield put(OrganizationActions.editOrganizationReq(organization))
    const { editOrganizationErr } = yield race({
      editOrganizationErr: take(EDIT_ORGANIZATION_ERR),
      editOrganizationResp: take(EDIT_ORGANIZATION_RESP),
    })
    if (editOrganizationErr) {
      throw new Error(
        'error while updating organization transport operator, please check the transport operator section',
      )
    }
    yield put(Actions.editTransportOperatorResp(transportOperator))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editTransportOperatorErr(err))
  }
}

export function* deleteTransportOperator(
  action: ReturnType<typeof Actions.deleteTransportOperatorReq>,
) {
  try {
    const { user, organization, id } = action.payload

    // update user config.
    const updatedUserConfig = user.getConfig()
    let userConfig = new userv1.UserConfig()
    if (updatedUserConfig) {
      userConfig = userv1.UserConfig.deserializeBinary(updatedUserConfig.serializeBinary())
    }

    userConfig.setTransportOperatingList([
      ...userConfig.getTransportOperatingList().filter((t) => t.getId() != id),
    ])

    user.setConfig(userConfig)
    yield put(UserActions.editUserReq(user))
    const { editUserErr } = yield race({
      editUserErr: take(EDIT_USER_ERR),
      editUserResp: take(EDIT_USER_RESP),
    })
    if (editUserErr) {
      throw new Error('error while updating user, transport operator configuration was interrupted')
    }

    // update organization config.
    const organizationConfig = organization.getConfig()
    let updatedOrganizationConfig = new organizationv1.Config()
    if (organizationConfig) {
      updatedOrganizationConfig = organizationv1.Config.deserializeBinary(
        organizationConfig.serializeBinary(),
      )
    }

    updatedOrganizationConfig.setTransportOperatorList([
      ...updatedOrganizationConfig.getTransportOperatorList().filter((t) => t.getId() != id),
    ])

    organization.setConfig(updatedOrganizationConfig)
    yield put(OrganizationActions.editOrganizationReq(organization))
    const { editOrganizationErr } = yield race({
      editOrganizationErr: take(EDIT_ORGANIZATION_ERR),
      editOrganizationResp: take(EDIT_ORGANIZATION_RESP),
    })
    if (editOrganizationErr) {
      throw new Error(
        'error while updating organization transport operator, please check the transport operator section',
      )
    }
    yield put(Actions.deleteTransportOperatorResp(id))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteTransportOperatorErr(err))
  }
}

export default function* sagas() {
  yield takeEvery(SET_USER_DEFAULT_ADDRESSES, setUserDefaultAddresses)
  yield takeEvery(EDIT_USER_DEFAULT_ADDRESS, editUserDefaultAddress)
  yield takeEvery(SET_USER_FILTER_ORDER, setUserFilterOrder)
  yield takeEvery(SET_BOOKING_CONFIG, setBookingUiConfig)
  // List transportOperators listen to list organization resp and pulls data from that.
  yield takeEvery(LIST_ORGANIZATION_RESP, listTransportOperators)
  yield takeEvery(CREATE_TRANSPORT_OPERATOR_REQ, createTransportOperator)
  yield takeEvery(EDIT_TRANSPORT_OPERATOR_REQ, editTransportOperator)
  yield takeEvery(DELETE_TRANSPORT_OPERATOR_REQ, deleteTransportOperator)
}
