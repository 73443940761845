import React from 'react'

import './BottomContentLayout.css'

interface LayoutProps {
  children: JSX.Element
  shadow?: boolean
}
export const BottomContentLayout = ({ children, shadow = false }: LayoutProps) => {
  return (
    <div className={(shadow ? 'bottom-content--shadow' : '') + ' bottom-content--padding'}>
      {children}
    </div>
  )
}
