import * as pricingv1 from '../../../proto/booking/v1/pricing_pb'

import { ActionsUnion, createAction } from '../../helpers'

export const GET_REQ = 'swift2/pricing/GET_REQ'
export const GET_RESP = 'swift2/pricing/GET_RESP'
export const GET_ERR = 'swift2/pricing/GET_ERR'

export const LIST_REQ = 'swift2/pricing/LIST_REQ'
export const LIST_RESP = 'swift2/pricing/LIST_RESP'
export const LIST_ERR = 'swift2/pricing/LIST_ERR'

export const CREATE_REQ = 'swift2/pricing/CREATE_REQ'
export const CREATE_RESP = 'swift2/pricing/CREATE_RESP'
export const CREATE_ERR = 'swift2/pricing/CREATE_ERR'

export const EDIT_REQ = 'swift2/pricing/EDIT_REQ'
export const EDIT_RESP = 'swift2/pricing/EDIT_RESP'
export const EDIT_ERR = 'swift2/pricing/EDIT_ERR'

export const DELETE_REQ = 'swift2/pricing/DELETE_REQ'
export const DELETE_RESP = 'swift2/pricing/DELETE_RESP'
export const DELETE_ERR = 'swift2/pricing/DELETE_ERR'

export const Actions = {
  listReq: () => createAction(LIST_REQ, {}),
  listResp: (count: number, pricing: pricingv1.Pricing[]) =>
    createAction(LIST_RESP, { count, pricing }),
  listErr: (err: Error) => createAction(LIST_ERR, { err }),
  getReq: (id: number) => createAction(GET_REQ, { id }),
  getResp: (pricing?: pricingv1.Pricing) => createAction(GET_RESP, { pricing }),
  getErr: (err: Error) => createAction(GET_ERR, { err }),
  createReq: (pricing: pricingv1.Pricing) => createAction(CREATE_REQ, { pricing }),
  createResp: (pricing: pricingv1.Pricing) => createAction(CREATE_RESP, { pricing }),
  createErr: (err: Error) => createAction(CREATE_ERR, { err }),
  editReq: (pricing: pricingv1.Pricing) => createAction(EDIT_REQ, { pricing }),
  editResp: (pricing: pricingv1.Pricing) => createAction(EDIT_RESP, { pricing }),
  editErr: (err: Error) => createAction(EDIT_ERR, { err }),
  deleteReq: (pricingID: number) => createAction(DELETE_REQ, { pricingID }),
  deleteResp: (pricingID: number) => createAction(DELETE_RESP, { pricingID }),
  deleteErr: (err: Error) => createAction(DELETE_ERR, { err }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
