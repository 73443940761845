// source: iam/v1/usergroup.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
goog.exportSymbol('proto.swift.iam.v1.CreateUserGroupRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.CreateUserGroupResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetUserGroupRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetUserGroupResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListUserGroupsRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListUserGroupsResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserGroup', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UserGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.UserGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UserGroup.displayName = 'proto.swift.iam.v1.UserGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetUserGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetUserGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetUserGroupRequest.displayName = 'proto.swift.iam.v1.GetUserGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetUserGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetUserGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetUserGroupResponse.displayName = 'proto.swift.iam.v1.GetUserGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListUserGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.ListUserGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListUserGroupsRequest.displayName = 'proto.swift.iam.v1.ListUserGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListUserGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.ListUserGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.ListUserGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListUserGroupsResponse.displayName = 'proto.swift.iam.v1.ListUserGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateUserGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateUserGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateUserGroupRequest.displayName = 'proto.swift.iam.v1.CreateUserGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateUserGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateUserGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateUserGroupResponse.displayName = 'proto.swift.iam.v1.CreateUserGroupResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UserGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UserGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UserGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGroupId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userGroup: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UserGroup}
 */
proto.swift.iam.v1.UserGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UserGroup;
  return proto.swift.iam.v1.UserGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UserGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UserGroup}
 */
proto.swift.iam.v1.UserGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserGroupId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UserGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UserGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGroupId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserGroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 user_group_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.UserGroup.prototype.getUserGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.UserGroup} returns this
 */
proto.swift.iam.v1.UserGroup.prototype.setUserGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 organization_id = 2;
 * @return {number}
 */
proto.swift.iam.v1.UserGroup.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.UserGroup} returns this
 */
proto.swift.iam.v1.UserGroup.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_group = 3;
 * @return {string}
 */
proto.swift.iam.v1.UserGroup.prototype.getUserGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.UserGroup} returns this
 */
proto.swift.iam.v1.UserGroup.prototype.setUserGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetUserGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetUserGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetUserGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGroupId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetUserGroupRequest}
 */
proto.swift.iam.v1.GetUserGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetUserGroupRequest;
  return proto.swift.iam.v1.GetUserGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetUserGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetUserGroupRequest}
 */
proto.swift.iam.v1.GetUserGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetUserGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetUserGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetUserGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGroupId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 user_group_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.GetUserGroupRequest.prototype.getUserGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.GetUserGroupRequest} returns this
 */
proto.swift.iam.v1.GetUserGroupRequest.prototype.setUserGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetUserGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetUserGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetUserGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGroup: (f = msg.getUserGroup()) && proto.swift.iam.v1.UserGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetUserGroupResponse}
 */
proto.swift.iam.v1.GetUserGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetUserGroupResponse;
  return proto.swift.iam.v1.GetUserGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetUserGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetUserGroupResponse}
 */
proto.swift.iam.v1.GetUserGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.UserGroup;
      reader.readMessage(value,proto.swift.iam.v1.UserGroup.deserializeBinaryFromReader);
      msg.setUserGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetUserGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetUserGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetUserGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetUserGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.UserGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserGroup user_group = 1;
 * @return {?proto.swift.iam.v1.UserGroup}
 */
proto.swift.iam.v1.GetUserGroupResponse.prototype.getUserGroup = function() {
  return /** @type{?proto.swift.iam.v1.UserGroup} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserGroup, 1));
};


/**
 * @param {?proto.swift.iam.v1.UserGroup|undefined} value
 * @return {!proto.swift.iam.v1.GetUserGroupResponse} returns this
*/
proto.swift.iam.v1.GetUserGroupResponse.prototype.setUserGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.GetUserGroupResponse} returns this
 */
proto.swift.iam.v1.GetUserGroupResponse.prototype.clearUserGroup = function() {
  return this.setUserGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.GetUserGroupResponse.prototype.hasUserGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListUserGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListUserGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListUserGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUserGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListUserGroupsRequest}
 */
proto.swift.iam.v1.ListUserGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListUserGroupsRequest;
  return proto.swift.iam.v1.ListUserGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListUserGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListUserGroupsRequest}
 */
proto.swift.iam.v1.ListUserGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListUserGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListUserGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListUserGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUserGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.iam.v1.ListUserGroupsRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.iam.v1.ListUserGroupsRequest} returns this
*/
proto.swift.iam.v1.ListUserGroupsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListUserGroupsRequest} returns this
 */
proto.swift.iam.v1.ListUserGroupsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListUserGroupsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.ListUserGroupsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListUserGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListUserGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListUserGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUserGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userGroupsList: jspb.Message.toObjectList(msg.getUserGroupsList(),
    proto.swift.iam.v1.UserGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListUserGroupsResponse}
 */
proto.swift.iam.v1.ListUserGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListUserGroupsResponse;
  return proto.swift.iam.v1.ListUserGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListUserGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListUserGroupsResponse}
 */
proto.swift.iam.v1.ListUserGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.UserGroup;
      reader.readMessage(value,proto.swift.iam.v1.UserGroup.deserializeBinaryFromReader);
      msg.addUserGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListUserGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListUserGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListUserGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListUserGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.iam.v1.UserGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.iam.v1.ListUserGroupsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.ListUserGroupsResponse} returns this
 */
proto.swift.iam.v1.ListUserGroupsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated UserGroup user_groups = 2;
 * @return {!Array<!proto.swift.iam.v1.UserGroup>}
 */
proto.swift.iam.v1.ListUserGroupsResponse.prototype.getUserGroupsList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.UserGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.UserGroup, 2));
};


/**
 * @param {!Array<!proto.swift.iam.v1.UserGroup>} value
 * @return {!proto.swift.iam.v1.ListUserGroupsResponse} returns this
*/
proto.swift.iam.v1.ListUserGroupsResponse.prototype.setUserGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.iam.v1.UserGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.UserGroup}
 */
proto.swift.iam.v1.ListUserGroupsResponse.prototype.addUserGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.iam.v1.UserGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.ListUserGroupsResponse} returns this
 */
proto.swift.iam.v1.ListUserGroupsResponse.prototype.clearUserGroupsList = function() {
  return this.setUserGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateUserGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateUserGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateUserGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGroup: (f = msg.getUserGroup()) && proto.swift.iam.v1.UserGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateUserGroupRequest}
 */
proto.swift.iam.v1.CreateUserGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateUserGroupRequest;
  return proto.swift.iam.v1.CreateUserGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateUserGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateUserGroupRequest}
 */
proto.swift.iam.v1.CreateUserGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.UserGroup;
      reader.readMessage(value,proto.swift.iam.v1.UserGroup.deserializeBinaryFromReader);
      msg.setUserGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateUserGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateUserGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateUserGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.UserGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserGroup user_group = 1;
 * @return {?proto.swift.iam.v1.UserGroup}
 */
proto.swift.iam.v1.CreateUserGroupRequest.prototype.getUserGroup = function() {
  return /** @type{?proto.swift.iam.v1.UserGroup} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserGroup, 1));
};


/**
 * @param {?proto.swift.iam.v1.UserGroup|undefined} value
 * @return {!proto.swift.iam.v1.CreateUserGroupRequest} returns this
*/
proto.swift.iam.v1.CreateUserGroupRequest.prototype.setUserGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateUserGroupRequest} returns this
 */
proto.swift.iam.v1.CreateUserGroupRequest.prototype.clearUserGroup = function() {
  return this.setUserGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateUserGroupRequest.prototype.hasUserGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateUserGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateUserGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateUserGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGroup: (f = msg.getUserGroup()) && proto.swift.iam.v1.UserGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateUserGroupResponse}
 */
proto.swift.iam.v1.CreateUserGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateUserGroupResponse;
  return proto.swift.iam.v1.CreateUserGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateUserGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateUserGroupResponse}
 */
proto.swift.iam.v1.CreateUserGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.UserGroup;
      reader.readMessage(value,proto.swift.iam.v1.UserGroup.deserializeBinaryFromReader);
      msg.setUserGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateUserGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateUserGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateUserGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateUserGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.UserGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserGroup user_group = 1;
 * @return {?proto.swift.iam.v1.UserGroup}
 */
proto.swift.iam.v1.CreateUserGroupResponse.prototype.getUserGroup = function() {
  return /** @type{?proto.swift.iam.v1.UserGroup} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserGroup, 1));
};


/**
 * @param {?proto.swift.iam.v1.UserGroup|undefined} value
 * @return {!proto.swift.iam.v1.CreateUserGroupResponse} returns this
*/
proto.swift.iam.v1.CreateUserGroupResponse.prototype.setUserGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateUserGroupResponse} returns this
 */
proto.swift.iam.v1.CreateUserGroupResponse.prototype.clearUserGroup = function() {
  return this.setUserGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateUserGroupResponse.prototype.hasUserGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.swift.iam.v1);
