import * as React from 'react'
import { Link } from 'react-router-dom'

import { Col, Row, Tag, Tooltip, Typography } from 'antd'

import * as bookingv1 from '../../../proto/booking/v1/booking_pb'
import * as orderv1 from '../../../proto/order/v1/order_pb'
import * as address from 'proto/crm/v1/address_pb'

import { BookingStatusTag } from '../../../components/Booking/BookingStatusTag'
import { DashboardCard } from '../../../components/Card/DashboardCard'
import { OrderStatusTag } from '../../../components/Order/OrderStatusTag'
import { ClipboardList, StickyNote } from '../../../components/icons'
import { TransportMode } from 'components/TransportModes/TransportMode'

import { getBookingTotalWeight, transportSubmodeTypeLabel } from '../../../helpers/booking'
import { orderType } from '../../../helpers/order'
import { formatTimestamp } from '../../../helpers/timestamp'

import './style.css'

const { Text } = Typography

const getTotalColli = (booking: bookingv1.Booking): number => {
  return booking
    .getTransportSubmodeItemsList()
    .flatMap((s) => s.getColliList())
    .reduce((a, c) => (a += c.getQuantity()), 0)
}

interface CardRowProps {
  readonly itemRef: string
  readonly baseUrl: string
  readonly refTooltipText: string
  readonly senderAddress: address.Address
  readonly receiverAddress: address.Address
  readonly eta?: string
  readonly highlightRef?: string
  readonly onClick?: (bookingRef: string) => void
  readonly scrollTo?: (offsetTop: number) => void
  readonly children: React.ReactNode
}

const CardRow: React.FC<CardRowProps> = ({
  itemRef,
  baseUrl,
  refTooltipText,
  senderAddress,
  receiverAddress,
  eta,
  highlightRef,
  onClick,
  scrollTo,
  children,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const handleRowClick = () => {
    if (!!onClick) {
      onClick(itemRef)
    }
  }

  React.useEffect(() => {
    if (itemRef === highlightRef && !!scrollTo && ref.current) {
      scrollTo(ref.current.offsetTop)
    }
  }, [itemRef, highlightRef, scrollTo])

  return (
    <Row
      key={itemRef}
      ref={ref}
      className={`card-row ${!!onClick ? 'clickable' : ''}`}
      onClick={handleRowClick}
    >
      <div className={`highlighter ${itemRef === highlightRef ? 'highlight' : ''}`}></div>
      <Col span={24}>
        <Row className="address-info-row">
          <Tooltip title={`${refTooltipText} ${itemRef}`}>
            <Link to={`${baseUrl}${itemRef}`}>
              <Text strong className="ref-text">
                {itemRef}
              </Text>
            </Link>
          </Tooltip>
          <Text className="text-separator">|</Text>
          <Text style={{ marginRight: '3px' }}>
            <Text className="label">From</Text>
            {`${senderAddress.getName()}, ${senderAddress.getCity()}`}
          </Text>
          <Text>
            <Text className="label">to</Text>
            {`${receiverAddress.getName()}, ${receiverAddress.getCity()}`}
          </Text>
          {eta && (
            <>
              <Text className="text-separator">|</Text>
              <Text className="label" style={{ marginRight: '3px' }}>
                ETA
              </Text>
              <Text strong style={{ textTransform: 'capitalize' }}>
                {eta}
              </Text>
            </>
          )}
        </Row>
        <Row style={{ padding: '5px 0' }}>{children}</Row>
      </Col>
    </Row>
  )
}

interface OrdersCardProps {
  readonly orders: ReadonlyArray<orderv1.Order>
  readonly showCreateButton: boolean
  readonly fetching: boolean
  readonly error?: Error
}

export const OrdersCard: React.FC<OrdersCardProps> = ({ orders, fetching, error }) => {
  return (
    <DashboardCard
      header={{
        title: 'Orders',
        total: orders.length,
        link: '/orders/create',
        buttonLabel: 'Create Order',
        icon: StickyNote,
      }}
      fetching={fetching}
      error={error}
      defaultErrorMessage="Could not get orders"
    >
      <div>
        {orders.map((o) => (
          <CardRow
            key={o.getOrderRef()}
            itemRef={o.getOrderRef()}
            baseUrl="/orders/"
            refTooltipText="View Order"
            senderAddress={o.getSender()!}
            receiverAddress={o.getReceiver()!}
          >
            <OrderStatusTag status={o.getStatus()} className="tag" />
            <Tag color="default" className="tag text-upper">
              {orderType[o.getOrderType()]}
            </Tag>
          </CardRow>
        ))}
      </div>
    </DashboardCard>
  )
}

interface BookingsCardProps {
  readonly bookings: ReadonlyArray<bookingv1.Booking>
  readonly showCreateButton: boolean
  readonly fetching: boolean
  readonly error?: Error
  readonly highlightBooking?: bookingv1.Booking
  readonly onClick?: (ref: string) => void
}

export const BookingsCard: React.FC<BookingsCardProps> = ({
  bookings,
  fetching,
  error,
  highlightBooking,
  onClick,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)

  const scrollTo = (offsetTop: number) => {
    if (ref.current) {
      ref.current!.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    }
  }

  return (
    <DashboardCard
      header={{
        title: 'Bookings',
        total: bookings.length,
        link: 'bookings/create',
        buttonLabel: 'Create Booking',
        icon: ClipboardList,
      }}
      fetching={fetching}
      error={error}
      defaultErrorMessage="Could not get bookings"
    >
      <div ref={ref}>
        {bookings.map((b) => (
          <CardRow
            key={b.getBookingRef()}
            itemRef={b.getBookingRef()}
            baseUrl="/bookings/"
            refTooltipText="View Booking"
            senderAddress={b.getSender()!}
            receiverAddress={b.getReceiver()!}
            eta={formatTimestamp(b.getEstimatedTimeArrival(), 'MMM D', 'N/A')}
            highlightRef={highlightBooking?.getBookingRef()}
            onClick={onClick}
            scrollTo={scrollTo}
          >
            <TransportMode
              modeEnum={b.getNominatedTransportMode()}
              iconStyle={{ marginRight: '15px' }}
            />
            <BookingStatusTag context="BOOKING" booking={b} className="tag" />
            <Tag color="default" className="tag text-upper">
              {transportSubmodeTypeLabel[b.getTransportSubmodeType()]}
            </Tag>
            <Tag color="default" className="tag text-upper">
              {getTotalColli(b)} Colli
            </Tag>
            <Tag color="default" className="tag text-upper">
              {getBookingTotalWeight(b)} kg
            </Tag>
          </CardRow>
        ))}
      </div>
    </DashboardCard>
  )
}
