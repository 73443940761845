import * as iamv1 from 'proto/iam/v1/user_pb'
import { User, UserListSorting } from 'proto/iam/v1/user_pb'

import { Pagination } from './query'

export interface UserPage {
  pagination: Pagination
  filter: UserFilter
  sorting: UserListSorting
}

export interface UserFilter {
  roles?: Array<User.Role>
  organizationID?: Array<number>
  usergroupID?: Array<number>
  excludeDisabled?: boolean
  search?: string
}

// A very permissive type so we can convert some indexes to a JSON string in
// booking to allow conversion to a query parameter string.
export type ParsingUsersPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  sorting?: string
  filterID?: number
  search?: string
}

export const getDefaultUserPage = (): UserPage => ({
  pagination: { skip: 0, limit: 0 },
  filter: {},
  sorting: new iamv1.UserListSorting(),
})
