export const INPUTSIZES = {
  max: 'max',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  extraSmall: 'xs',
  extraLarge: 'xl',
  extraExtraLarge: 'xxl',
} as const

export type InputSize = (typeof INPUTSIZES)[keyof typeof INPUTSIZES]
