import * as currencyv1 from '../../proto/currency/v1/currency_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { ActionsUnion, createAction } from '../helpers'

import * as currencyTypes from '../../types/currency'

export const CREATE_RATE_REQ = 'swift2/currency/CREATE_RATE_REQ'
export const CREATE_RATE_RESP = 'swift2/currency/CREATE_RATE_RESP'
export const CREATE_RATE_ERR = 'swift2/currency/CREATE_RATE_ERR'

export const LIST_RATES_REQ = 'swift2/currency/LIST_RATES_REQ'
export const LIST_RATES_RESP = 'swift2/currency/LIST_RATES_RESP'
export const LIST_RATES_ERR = 'swift2/currency/LIST_RATES_ERR'

export const GET_CURRENCY_RATE_REQ = 'swift2/currency/GET_CURRENCY_RATE_REQ'
export const GET_CURRENCY_RATE_RESP = 'swift2/currency/GET_CURRENCY_RATE_RESP'
export const GET_CURRENCY_RATE_ERR = 'swift2/currency/GET_CURRENCY_RATE_ERR'

export const CONVERT_CURRENCY_REQ = 'swift2/currency/CONVERT_REQ'
export const CONVERT_CURRENCY_RESP = 'swift2/currency/CONVERT_RESP'
export const CONVERT_CURRENCY_ERR = 'swift2/currency/CONVERT_ERR'

export const RESET_CONVERT_CURRENCY_ERR = 'swift2/currency/RESET_CONVERT_CURRENCY_ERR'

export const Actions = {
  createRateReq: (rate: currencyv1.CurrencyRate, dailyInternalRate: boolean) =>
    createAction(CREATE_RATE_REQ, { rate, dailyInternalRate }),
  createRateResp: (rate: currencyv1.CurrencyRate) => createAction(CREATE_RATE_RESP, { rate }),
  createRateErr: (err: Error) => createAction(CREATE_RATE_ERR, { err }),
  listCurrencyRatesReq: (page: currencyTypes.CurrencyPage) =>
    createAction(LIST_RATES_REQ, { page }),
  ListCurrencyRatesResp: (
    count: number,
    rates: currencyv1.CurrencyRate[],
    page: currencyTypes.CurrencyPage,
  ) => createAction(LIST_RATES_RESP, { count, rates, page }),
  listCurrencyRatesErr: (err: Error) => createAction(LIST_RATES_ERR, { err }),

  getCurrencyRateReq: (
    currencyFrom: string,
    currencyTo: string,
    sourceType: currencyv1.CurrencyRate.SourceType,
    lookupDate?: Timestamp,
  ) => createAction(GET_CURRENCY_RATE_REQ, { currencyFrom, currencyTo, sourceType, lookupDate }),
  getCurrencyRateResp: (currencyRate: currencyv1.CurrencyRate) =>
    createAction(GET_CURRENCY_RATE_RESP, { currencyRate }),
  getCurrencyRateErr: (err: Error) => createAction(GET_CURRENCY_RATE_ERR, { err }),

  convertCurrencyReq: (conv: currencyv1.ConvertCurrency[], type: currencyv1.ConvertCurrency.Type) =>
    createAction(CONVERT_CURRENCY_REQ, { conv, type }),
  convertCurrencyResp: (conv: currencyv1.ConvertCurrency[]) =>
    createAction(CONVERT_CURRENCY_RESP, { conv }),
  convertCurrencyErr: (err: Error, type: currencyv1.ConvertCurrency.Type) =>
    createAction(CONVERT_CURRENCY_ERR, { err, type }),
  resetConvertCurrencyErr: (type: currencyv1.ConvertCurrency.Type) =>
    createAction(RESET_CONVERT_CURRENCY_ERR, { type }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
