import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { notification } from 'antd'

import * as notificationSelectors from '../store/notification/reducer'
import { Notif } from '../store/notification/types'
import { RootState } from '../store/reducer'

import { logError } from 'helpers/logger'

export const Notifications = () => {
  const notifications = useSelector((state: RootState) => notificationSelectors.getItems(state))

  const [open, setOpen] = useState<Notif[]>([])
  const [closed, setClosed] = useState<Notif[]>([])

  useEffect(() => {
    try {
      const closed = open.filter((n) => {
        return !notifications.find((c) => c.key === n.key)
      })

      setOpen([...notifications])
      setClosed(closed)
    } catch (e: any) {
      logError(e)
    }
  }, [notifications.length])

  useEffect(() => {
    try {
      open.forEach((n) => {
        notification[n.kind]({
          key: n.key,
          className: 'no-print',
          message: n.message,
          description: n.description,
          duration: 0,
        })
      })

      closed.forEach((n) => {
        notification.close(n.key)
      })
    } catch (e: any) {
      logError(e)
    }
  }, [open.length, closed.length])

  return <></>
}
