/**
 * @fileoverview gRPC-Web generated client stub for swift.iam.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: iam/v1/user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_query_pb = require('../../common/query_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var email_v1_email_pb = require('../../email/v1/email_pb.js')
const proto = {};
proto.swift = {};
proto.swift.iam = {};
proto.swift.iam.v1 = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.ListUsersRequest,
 *   !proto.swift.iam.v1.ListUsersResponse>}
 */
const methodDescriptor_UserService_ListUsers = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/ListUsers',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.ListUsersRequest,
  proto.swift.iam.v1.ListUsersResponse,
  /**
   * @param {!proto.swift.iam.v1.ListUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.ListUsersResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.ListUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.ListUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.listUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUsers,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.ListUsersResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.listUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.ListUsersWithImagesRequest,
 *   !proto.swift.iam.v1.ListUsersWithImagesResponse>}
 */
const methodDescriptor_UserService_ListUsersWithImages = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/ListUsersWithImages',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.ListUsersWithImagesRequest,
  proto.swift.iam.v1.ListUsersWithImagesResponse,
  /**
   * @param {!proto.swift.iam.v1.ListUsersWithImagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.ListUsersWithImagesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.ListUsersWithImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.ListUsersWithImagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.ListUsersWithImagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.listUsersWithImages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/ListUsersWithImages',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUsersWithImages,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.ListUsersWithImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.ListUsersWithImagesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.listUsersWithImages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/ListUsersWithImages',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUsersWithImages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.ListPublicUsersRequest,
 *   !proto.swift.iam.v1.ListPublicUsersResponse>}
 */
const methodDescriptor_UserService_ListPublicUsers = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/ListPublicUsers',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.ListPublicUsersRequest,
  proto.swift.iam.v1.ListPublicUsersResponse,
  /**
   * @param {!proto.swift.iam.v1.ListPublicUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.ListPublicUsersResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.ListPublicUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.ListPublicUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.ListPublicUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.listPublicUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/ListPublicUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ListPublicUsers,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.ListPublicUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.ListPublicUsersResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.listPublicUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/ListPublicUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ListPublicUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.GetUserRequest,
 *   !proto.swift.iam.v1.GetUserResponse>}
 */
const methodDescriptor_UserService_GetUser = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.GetUserRequest,
  proto.swift.iam.v1.GetUserResponse,
  /**
   * @param {!proto.swift.iam.v1.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.GetUserResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.GetUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.GetUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUser,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.GetUserResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.CreateUserRequest,
 *   !proto.swift.iam.v1.CreateUserResponse>}
 */
const methodDescriptor_UserService_CreateUser = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.CreateUserRequest,
  proto.swift.iam.v1.CreateUserResponse,
  /**
   * @param {!proto.swift.iam.v1.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.CreateUserResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.CreateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.CreateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.CreateUserResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.EditUserRequest,
 *   !proto.swift.iam.v1.EditUserResponse>}
 */
const methodDescriptor_UserService_EditUser = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/EditUser',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.EditUserRequest,
  proto.swift.iam.v1.EditUserResponse,
  /**
   * @param {!proto.swift.iam.v1.EditUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.EditUserResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.EditUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.EditUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.EditUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.editUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/EditUser',
      request,
      metadata || {},
      methodDescriptor_UserService_EditUser,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.EditUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.EditUserResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.editUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/EditUser',
      request,
      metadata || {},
      methodDescriptor_UserService_EditUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.GetDefaultImportOwnerRequest,
 *   !proto.swift.iam.v1.GetDefaultImportOwnerResponse>}
 */
const methodDescriptor_UserService_GetDefaultImportOwner = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/GetDefaultImportOwner',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.GetDefaultImportOwnerRequest,
  proto.swift.iam.v1.GetDefaultImportOwnerResponse,
  /**
   * @param {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.GetDefaultImportOwnerResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.GetDefaultImportOwnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.GetDefaultImportOwnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.getDefaultImportOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/GetDefaultImportOwner',
      request,
      metadata || {},
      methodDescriptor_UserService_GetDefaultImportOwner,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.GetDefaultImportOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.GetDefaultImportOwnerResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.getDefaultImportOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/GetDefaultImportOwner',
      request,
      metadata || {},
      methodDescriptor_UserService_GetDefaultImportOwner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.UploadProfilePictureRequest,
 *   !proto.swift.iam.v1.UploadProfilePictureResponse>}
 */
const methodDescriptor_UserService_UploadProfilePicture = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.UserService/UploadProfilePicture',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.UploadProfilePictureRequest,
  proto.swift.iam.v1.UploadProfilePictureResponse,
  /**
   * @param {!proto.swift.iam.v1.UploadProfilePictureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.UploadProfilePictureResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.UploadProfilePictureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.UploadProfilePictureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.UploadProfilePictureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.UserServiceClient.prototype.uploadProfilePicture =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.UserService/UploadProfilePicture',
      request,
      metadata || {},
      methodDescriptor_UserService_UploadProfilePicture,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.UploadProfilePictureRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.UploadProfilePictureResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.UserServicePromiseClient.prototype.uploadProfilePicture =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.UserService/UploadProfilePicture',
      request,
      metadata || {},
      methodDescriptor_UserService_UploadProfilePicture);
};


module.exports = proto.swift.iam.v1;

