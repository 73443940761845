// source: document/v1/document.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var booking_v1_booking_pb = require('../../booking/v1/booking_pb.js');
goog.object.extend(proto, booking_v1_booking_pb);
var shipment_v1_shipment_pb = require('../../shipment/v1/shipment_pb.js');
goog.object.extend(proto, shipment_v1_shipment_pb);
goog.exportSymbol('proto.swift.document.v1.CreateDocumentRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.CreateDocumentResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.CreateDocumentUploadLinkRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.CreateDocumentUploadLinkResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.CustomData', null, global);
goog.exportSymbol('proto.swift.document.v1.DeleteDocumentRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.DeleteDocumentResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.Document', null, global);
goog.exportSymbol('proto.swift.document.v1.DocumentDownloadLink', null, global);
goog.exportSymbol('proto.swift.document.v1.DocumentFilter', null, global);
goog.exportSymbol('proto.swift.document.v1.DocumentMetaData', null, global);
goog.exportSymbol('proto.swift.document.v1.DocumentMetaData.DocumentLocationCase', null, global);
goog.exportSymbol('proto.swift.document.v1.DocumentMetaData.LocationType', null, global);
goog.exportSymbol('proto.swift.document.v1.DocumentUploadLink', null, global);
goog.exportSymbol('proto.swift.document.v1.DocumentUploaded', null, global);
goog.exportSymbol('proto.swift.document.v1.GCloudObjectMeta', null, global);
goog.exportSymbol('proto.swift.document.v1.GenerateBookingDocumentRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.GenerateBookingDocumentResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.GenerateShipmentDocumentRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.GenerateShipmentDocumentResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.GetDocumentRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.GetDocumentResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.Instruction', null, global);
goog.exportSymbol('proto.swift.document.v1.ListDocumentsRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.ListDocumentsResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.SetDocumentUsageRequest', null, global);
goog.exportSymbol('proto.swift.document.v1.SetDocumentUsageResponse', null, global);
goog.exportSymbol('proto.swift.document.v1.Usage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.DocumentMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.document.v1.DocumentMetaData.oneofGroups_);
};
goog.inherits(proto.swift.document.v1.DocumentMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.DocumentMetaData.displayName = 'proto.swift.document.v1.DocumentMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.DocumentUploadLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.DocumentUploadLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.DocumentUploadLink.displayName = 'proto.swift.document.v1.DocumentUploadLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.DocumentDownloadLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.DocumentDownloadLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.DocumentDownloadLink.displayName = 'proto.swift.document.v1.DocumentDownloadLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.GCloudObjectMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.GCloudObjectMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.GCloudObjectMeta.displayName = 'proto.swift.document.v1.GCloudObjectMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.Document.displayName = 'proto.swift.document.v1.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.CustomData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.CustomData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.CustomData.displayName = 'proto.swift.document.v1.CustomData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.Instruction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.Instruction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.Instruction.displayName = 'proto.swift.document.v1.Instruction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.DocumentFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.document.v1.DocumentFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.document.v1.DocumentFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.DocumentFilter.displayName = 'proto.swift.document.v1.DocumentFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.CreateDocumentUploadLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.CreateDocumentUploadLinkRequest.displayName = 'proto.swift.document.v1.CreateDocumentUploadLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.CreateDocumentUploadLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.CreateDocumentUploadLinkResponse.displayName = 'proto.swift.document.v1.CreateDocumentUploadLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.ListDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.ListDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.ListDocumentsRequest.displayName = 'proto.swift.document.v1.ListDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.ListDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.document.v1.ListDocumentsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.document.v1.ListDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.ListDocumentsResponse.displayName = 'proto.swift.document.v1.ListDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.GetDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.GetDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.GetDocumentRequest.displayName = 'proto.swift.document.v1.GetDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.GetDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.GetDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.GetDocumentResponse.displayName = 'proto.swift.document.v1.GetDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.CreateDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.CreateDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.CreateDocumentRequest.displayName = 'proto.swift.document.v1.CreateDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.CreateDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.CreateDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.CreateDocumentResponse.displayName = 'proto.swift.document.v1.CreateDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.SetDocumentUsageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.SetDocumentUsageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.SetDocumentUsageRequest.displayName = 'proto.swift.document.v1.SetDocumentUsageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.SetDocumentUsageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.SetDocumentUsageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.SetDocumentUsageResponse.displayName = 'proto.swift.document.v1.SetDocumentUsageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.DeleteDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.DeleteDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.DeleteDocumentRequest.displayName = 'proto.swift.document.v1.DeleteDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.DeleteDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.DeleteDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.DeleteDocumentResponse.displayName = 'proto.swift.document.v1.DeleteDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.DocumentUploaded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.DocumentUploaded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.DocumentUploaded.displayName = 'proto.swift.document.v1.DocumentUploaded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.GenerateBookingDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.GenerateBookingDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.GenerateBookingDocumentRequest.displayName = 'proto.swift.document.v1.GenerateBookingDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.GenerateBookingDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.GenerateBookingDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.GenerateBookingDocumentResponse.displayName = 'proto.swift.document.v1.GenerateBookingDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.document.v1.GenerateShipmentDocumentRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.document.v1.GenerateShipmentDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.GenerateShipmentDocumentRequest.displayName = 'proto.swift.document.v1.GenerateShipmentDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.document.v1.GenerateShipmentDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.document.v1.GenerateShipmentDocumentResponse.displayName = 'proto.swift.document.v1.GenerateShipmentDocumentResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.document.v1.DocumentMetaData.oneofGroups_ = [[13,14]];

/**
 * @enum {number}
 */
proto.swift.document.v1.DocumentMetaData.DocumentLocationCase = {
  DOCUMENT_LOCATION_NOT_SET: 0,
  DOCUMENT_DOWNLOAD_LINK: 13,
  GCLOUD_OBJECT_META: 14
};

/**
 * @return {proto.swift.document.v1.DocumentMetaData.DocumentLocationCase}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getDocumentLocationCase = function() {
  return /** @type {proto.swift.document.v1.DocumentMetaData.DocumentLocationCase} */(jspb.Message.computeOneofCase(this, proto.swift.document.v1.DocumentMetaData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.DocumentMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.DocumentMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.DocumentMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
    orderRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bookingRef: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shipmentRef: jspb.Message.getFieldWithDefault(msg, 5, ""),
    organizationRef: jspb.Message.getFieldWithDefault(msg, 6, ""),
    invoiceNo: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deviationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    note: jspb.Message.getFieldWithDefault(msg, 9, ""),
    title: jspb.Message.getFieldWithDefault(msg, 10, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    locationType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    documentDownloadLink: (f = msg.getDocumentDownloadLink()) && proto.swift.document.v1.DocumentDownloadLink.toObject(includeInstance, f),
    gcloudObjectMeta: (f = msg.getGcloudObjectMeta()) && proto.swift.document.v1.GCloudObjectMeta.toObject(includeInstance, f),
    usage: jspb.Message.getFieldWithDefault(msg, 15, 0),
    userUploaded: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.DocumentMetaData}
 */
proto.swift.document.v1.DocumentMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.DocumentMetaData;
  return proto.swift.document.v1.DocumentMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.DocumentMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.DocumentMetaData}
 */
proto.swift.document.v1.DocumentMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderRef(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationRef(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvoiceNo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviationId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {!proto.swift.document.v1.DocumentMetaData.LocationType} */ (reader.readEnum());
      msg.setLocationType(value);
      break;
    case 13:
      var value = new proto.swift.document.v1.DocumentDownloadLink;
      reader.readMessage(value,proto.swift.document.v1.DocumentDownloadLink.deserializeBinaryFromReader);
      msg.setDocumentDownloadLink(value);
      break;
    case 14:
      var value = new proto.swift.document.v1.GCloudObjectMeta;
      reader.readMessage(value,proto.swift.document.v1.GCloudObjectMeta.deserializeBinaryFromReader);
      msg.setGcloudObjectMeta(value);
      break;
    case 15:
      var value = /** @type {!proto.swift.document.v1.Usage} */ (reader.readEnum());
      msg.setUsage(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserUploaded(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.DocumentMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.DocumentMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.DocumentMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getOrderRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrganizationRef();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInvoiceNo();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDeviationId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocationType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getDocumentDownloadLink();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.swift.document.v1.DocumentDownloadLink.serializeBinaryToWriter
    );
  }
  f = message.getGcloudObjectMeta();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.swift.document.v1.GCloudObjectMeta.serializeBinaryToWriter
    );
  }
  f = message.getUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getUserUploaded();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.document.v1.DocumentMetaData.LocationType = {
  EXTERNAL: 0,
  GCLOUD: 1
};

/**
 * optional int64 document_id = 1;
 * @return {number}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
*/
proto.swift.document.v1.DocumentMetaData.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.DocumentMetaData.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string order_ref = 3;
 * @return {string}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getOrderRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setOrderRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string booking_ref = 4;
 * @return {string}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string shipment_ref = 5;
 * @return {string}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string organization_ref = 6;
 * @return {string}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getOrganizationRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setOrganizationRef = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 invoice_no = 7;
 * @return {number}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getInvoiceNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setInvoiceNo = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 deviation_id = 8;
 * @return {number}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getDeviationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setDeviationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string note = 9;
 * @return {string}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string title = 10;
 * @return {string}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
*/
proto.swift.document.v1.DocumentMetaData.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.DocumentMetaData.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional LocationType location_type = 12;
 * @return {!proto.swift.document.v1.DocumentMetaData.LocationType}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getLocationType = function() {
  return /** @type {!proto.swift.document.v1.DocumentMetaData.LocationType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.swift.document.v1.DocumentMetaData.LocationType} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setLocationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional DocumentDownloadLink document_download_link = 13;
 * @return {?proto.swift.document.v1.DocumentDownloadLink}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getDocumentDownloadLink = function() {
  return /** @type{?proto.swift.document.v1.DocumentDownloadLink} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.DocumentDownloadLink, 13));
};


/**
 * @param {?proto.swift.document.v1.DocumentDownloadLink|undefined} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
*/
proto.swift.document.v1.DocumentMetaData.prototype.setDocumentDownloadLink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.swift.document.v1.DocumentMetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.clearDocumentDownloadLink = function() {
  return this.setDocumentDownloadLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.DocumentMetaData.prototype.hasDocumentDownloadLink = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional GCloudObjectMeta gcloud_object_meta = 14;
 * @return {?proto.swift.document.v1.GCloudObjectMeta}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getGcloudObjectMeta = function() {
  return /** @type{?proto.swift.document.v1.GCloudObjectMeta} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.GCloudObjectMeta, 14));
};


/**
 * @param {?proto.swift.document.v1.GCloudObjectMeta|undefined} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
*/
proto.swift.document.v1.DocumentMetaData.prototype.setGcloudObjectMeta = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.swift.document.v1.DocumentMetaData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.clearGcloudObjectMeta = function() {
  return this.setGcloudObjectMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.DocumentMetaData.prototype.hasGcloudObjectMeta = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Usage usage = 15;
 * @return {!proto.swift.document.v1.Usage}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getUsage = function() {
  return /** @type {!proto.swift.document.v1.Usage} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.swift.document.v1.Usage} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool user_uploaded = 16;
 * @return {boolean}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getUserUploaded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setUserUploaded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool custom = 17;
 * @return {boolean}
 */
proto.swift.document.v1.DocumentMetaData.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.document.v1.DocumentMetaData} returns this
 */
proto.swift.document.v1.DocumentMetaData.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.DocumentUploadLink.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.DocumentUploadLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.DocumentUploadLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentUploadLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadLocation: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.DocumentUploadLink}
 */
proto.swift.document.v1.DocumentUploadLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.DocumentUploadLink;
  return proto.swift.document.v1.DocumentUploadLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.DocumentUploadLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.DocumentUploadLink}
 */
proto.swift.document.v1.DocumentUploadLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadLocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.DocumentUploadLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.DocumentUploadLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.DocumentUploadLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentUploadLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadLocation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string upload_location = 1;
 * @return {string}
 */
proto.swift.document.v1.DocumentUploadLink.prototype.getUploadLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentUploadLink} returns this
 */
proto.swift.document.v1.DocumentUploadLink.prototype.setUploadLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_name = 2;
 * @return {string}
 */
proto.swift.document.v1.DocumentUploadLink.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentUploadLink} returns this
 */
proto.swift.document.v1.DocumentUploadLink.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.DocumentDownloadLink.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.DocumentDownloadLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.DocumentDownloadLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentDownloadLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    downloadLocation: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.DocumentDownloadLink}
 */
proto.swift.document.v1.DocumentDownloadLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.DocumentDownloadLink;
  return proto.swift.document.v1.DocumentDownloadLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.DocumentDownloadLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.DocumentDownloadLink}
 */
proto.swift.document.v1.DocumentDownloadLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.DocumentDownloadLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.DocumentDownloadLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.DocumentDownloadLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentDownloadLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDownloadLocation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string download_location = 1;
 * @return {string}
 */
proto.swift.document.v1.DocumentDownloadLink.prototype.getDownloadLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.DocumentDownloadLink} returns this
 */
proto.swift.document.v1.DocumentDownloadLink.prototype.setDownloadLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.GCloudObjectMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.GCloudObjectMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.GCloudObjectMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GCloudObjectMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.GCloudObjectMeta}
 */
proto.swift.document.v1.GCloudObjectMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.GCloudObjectMeta;
  return proto.swift.document.v1.GCloudObjectMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.GCloudObjectMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.GCloudObjectMeta}
 */
proto.swift.document.v1.GCloudObjectMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.GCloudObjectMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.GCloudObjectMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.GCloudObjectMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GCloudObjectMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.swift.document.v1.GCloudObjectMeta.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.GCloudObjectMeta} returns this
 */
proto.swift.document.v1.GCloudObjectMeta.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    metaData: (f = msg.getMetaData()) && proto.swift.document.v1.DocumentMetaData.toObject(includeInstance, f),
    link: (f = msg.getLink()) && proto.swift.document.v1.DocumentDownloadLink.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.Document}
 */
proto.swift.document.v1.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.Document;
  return proto.swift.document.v1.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.Document}
 */
proto.swift.document.v1.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.DocumentMetaData;
      reader.readMessage(value,proto.swift.document.v1.DocumentMetaData.deserializeBinaryFromReader);
      msg.setMetaData(value);
      break;
    case 2:
      var value = new proto.swift.document.v1.DocumentDownloadLink;
      reader.readMessage(value,proto.swift.document.v1.DocumentDownloadLink.deserializeBinaryFromReader);
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetaData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.DocumentMetaData.serializeBinaryToWriter
    );
  }
  f = message.getLink();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.document.v1.DocumentDownloadLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional DocumentMetaData meta_data = 1;
 * @return {?proto.swift.document.v1.DocumentMetaData}
 */
proto.swift.document.v1.Document.prototype.getMetaData = function() {
  return /** @type{?proto.swift.document.v1.DocumentMetaData} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.DocumentMetaData, 1));
};


/**
 * @param {?proto.swift.document.v1.DocumentMetaData|undefined} value
 * @return {!proto.swift.document.v1.Document} returns this
*/
proto.swift.document.v1.Document.prototype.setMetaData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.Document} returns this
 */
proto.swift.document.v1.Document.prototype.clearMetaData = function() {
  return this.setMetaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.Document.prototype.hasMetaData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DocumentDownloadLink link = 2;
 * @return {?proto.swift.document.v1.DocumentDownloadLink}
 */
proto.swift.document.v1.Document.prototype.getLink = function() {
  return /** @type{?proto.swift.document.v1.DocumentDownloadLink} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.DocumentDownloadLink, 2));
};


/**
 * @param {?proto.swift.document.v1.DocumentDownloadLink|undefined} value
 * @return {!proto.swift.document.v1.Document} returns this
*/
proto.swift.document.v1.Document.prototype.setLink = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.Document} returns this
 */
proto.swift.document.v1.Document.prototype.clearLink = function() {
  return this.setLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.Document.prototype.hasLink = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.CustomData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.CustomData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.CustomData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CustomData.toObject = function(includeInstance, msg) {
  var f, obj = {
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    supplier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    revisedNumber: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.CustomData}
 */
proto.swift.document.v1.CustomData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.CustomData;
  return proto.swift.document.v1.CustomData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.CustomData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.CustomData}
 */
proto.swift.document.v1.CustomData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevisedNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.CustomData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.CustomData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.CustomData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CustomData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRevisedNumber();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool custom = 1;
 * @return {boolean}
 */
proto.swift.document.v1.CustomData.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.document.v1.CustomData} returns this
 */
proto.swift.document.v1.CustomData.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string supplier = 2;
 * @return {string}
 */
proto.swift.document.v1.CustomData.prototype.getSupplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.CustomData} returns this
 */
proto.swift.document.v1.CustomData.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price = 3;
 * @return {number}
 */
proto.swift.document.v1.CustomData.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.CustomData} returns this
 */
proto.swift.document.v1.CustomData.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.swift.document.v1.CustomData.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.CustomData} returns this
 */
proto.swift.document.v1.CustomData.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 revised_number = 5;
 * @return {number}
 */
proto.swift.document.v1.CustomData.prototype.getRevisedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.CustomData} returns this
 */
proto.swift.document.v1.CustomData.prototype.setRevisedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.Instruction.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.Instruction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.Instruction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.Instruction.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instruction: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.Instruction}
 */
proto.swift.document.v1.Instruction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.Instruction;
  return proto.swift.document.v1.Instruction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.Instruction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.Instruction}
 */
proto.swift.document.v1.Instruction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstruction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.Instruction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.Instruction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.Instruction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.Instruction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstruction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string booking_ref = 1;
 * @return {string}
 */
proto.swift.document.v1.Instruction.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.Instruction} returns this
 */
proto.swift.document.v1.Instruction.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string instruction = 2;
 * @return {string}
 */
proto.swift.document.v1.Instruction.prototype.getInstruction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.Instruction} returns this
 */
proto.swift.document.v1.Instruction.prototype.setInstruction = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.document.v1.DocumentFilter.repeatedFields_ = [1,2,3,4,5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.DocumentFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.DocumentFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.DocumentFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    organizationIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    userGroupIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    orderRefList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    bookingRefList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    shipmentRefList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    organizationRefList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    invoiceNoList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    deviationIdList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    usagesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    custom: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.DocumentFilter}
 */
proto.swift.document.v1.DocumentFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.DocumentFilter;
  return proto.swift.document.v1.DocumentFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.DocumentFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.DocumentFilter}
 */
proto.swift.document.v1.DocumentFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserGroupId(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrderRef(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRef(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addShipmentRef(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationRef(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInvoiceNo(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviationId(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<!proto.swift.document.v1.Usage>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsages(values[i]);
      }
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.DocumentFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.DocumentFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.DocumentFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getUserGroupIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getOrderRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBookingRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getShipmentRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getOrganizationRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getInvoiceNoList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getDeviationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getUsagesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getCustom();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * repeated string user_id = 1;
 * @return {!Array<string>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getUserIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setUserIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addUserId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearUserIdList = function() {
  return this.setUserIdList([]);
};


/**
 * repeated int64 organization_id = 2;
 * @return {!Array<number>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated int64 user_group_id = 3;
 * @return {!Array<number>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getUserGroupIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setUserGroupIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addUserGroupId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearUserGroupIdList = function() {
  return this.setUserGroupIdList([]);
};


/**
 * repeated string order_ref = 4;
 * @return {!Array<string>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getOrderRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setOrderRefList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addOrderRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearOrderRefList = function() {
  return this.setOrderRefList([]);
};


/**
 * repeated string booking_ref = 5;
 * @return {!Array<string>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getBookingRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setBookingRefList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addBookingRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearBookingRefList = function() {
  return this.setBookingRefList([]);
};


/**
 * repeated string shipment_ref = 6;
 * @return {!Array<string>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getShipmentRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setShipmentRefList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addShipmentRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearShipmentRefList = function() {
  return this.setShipmentRefList([]);
};


/**
 * repeated string organization_ref = 7;
 * @return {!Array<string>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getOrganizationRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setOrganizationRefList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addOrganizationRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearOrganizationRefList = function() {
  return this.setOrganizationRefList([]);
};


/**
 * repeated int64 invoice_no = 8;
 * @return {!Array<number>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getInvoiceNoList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setInvoiceNoList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addInvoiceNo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearInvoiceNoList = function() {
  return this.setInvoiceNoList([]);
};


/**
 * repeated int64 deviation_id = 9;
 * @return {!Array<number>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getDeviationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setDeviationIdList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addDeviationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearDeviationIdList = function() {
  return this.setDeviationIdList([]);
};


/**
 * repeated Usage usages = 10;
 * @return {!Array<!proto.swift.document.v1.Usage>}
 */
proto.swift.document.v1.DocumentFilter.prototype.getUsagesList = function() {
  return /** @type {!Array<!proto.swift.document.v1.Usage>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.swift.document.v1.Usage>} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setUsagesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.swift.document.v1.Usage} value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.addUsages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.clearUsagesList = function() {
  return this.setUsagesList([]);
};


/**
 * optional bool custom = 11;
 * @return {boolean}
 */
proto.swift.document.v1.DocumentFilter.prototype.getCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.document.v1.DocumentFilter} returns this
 */
proto.swift.document.v1.DocumentFilter.prototype.setCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.CreateDocumentUploadLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mimeType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationNamespace: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkRequest}
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.CreateDocumentUploadLinkRequest;
  return proto.swift.document.v1.CreateDocumentUploadLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkRequest}
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationNamespace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.CreateDocumentUploadLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMimeType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationNamespace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string mime_type = 1;
 * @return {string}
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} returns this
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} returns this
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization_namespace = 3;
 * @return {string}
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.getOrganizationNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} returns this
 */
proto.swift.document.v1.CreateDocumentUploadLinkRequest.prototype.setOrganizationNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.CreateDocumentUploadLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadLocation: (f = msg.getUploadLocation()) && proto.swift.document.v1.DocumentUploadLink.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkResponse}
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.CreateDocumentUploadLinkResponse;
  return proto.swift.document.v1.CreateDocumentUploadLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkResponse}
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.DocumentUploadLink;
      reader.readMessage(value,proto.swift.document.v1.DocumentUploadLink.deserializeBinaryFromReader);
      msg.setUploadLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.CreateDocumentUploadLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadLocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.DocumentUploadLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional DocumentUploadLink upload_location = 1;
 * @return {?proto.swift.document.v1.DocumentUploadLink}
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.prototype.getUploadLocation = function() {
  return /** @type{?proto.swift.document.v1.DocumentUploadLink} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.DocumentUploadLink, 1));
};


/**
 * @param {?proto.swift.document.v1.DocumentUploadLink|undefined} value
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkResponse} returns this
*/
proto.swift.document.v1.CreateDocumentUploadLinkResponse.prototype.setUploadLocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.CreateDocumentUploadLinkResponse} returns this
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.prototype.clearUploadLocation = function() {
  return this.setUploadLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.CreateDocumentUploadLinkResponse.prototype.hasUploadLocation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.ListDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.ListDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.ListDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.ListDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.swift.document.v1.DocumentFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.ListDocumentsRequest}
 */
proto.swift.document.v1.ListDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.ListDocumentsRequest;
  return proto.swift.document.v1.ListDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.ListDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.ListDocumentsRequest}
 */
proto.swift.document.v1.ListDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.DocumentFilter;
      reader.readMessage(value,proto.swift.document.v1.DocumentFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.ListDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.ListDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.ListDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.ListDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.DocumentFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional DocumentFilter filter = 1;
 * @return {?proto.swift.document.v1.DocumentFilter}
 */
proto.swift.document.v1.ListDocumentsRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.document.v1.DocumentFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.DocumentFilter, 1));
};


/**
 * @param {?proto.swift.document.v1.DocumentFilter|undefined} value
 * @return {!proto.swift.document.v1.ListDocumentsRequest} returns this
*/
proto.swift.document.v1.ListDocumentsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.ListDocumentsRequest} returns this
 */
proto.swift.document.v1.ListDocumentsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.ListDocumentsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.document.v1.ListDocumentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.ListDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.ListDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.ListDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.ListDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.swift.document.v1.Document.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.ListDocumentsResponse}
 */
proto.swift.document.v1.ListDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.ListDocumentsResponse;
  return proto.swift.document.v1.ListDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.ListDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.ListDocumentsResponse}
 */
proto.swift.document.v1.ListDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.Document;
      reader.readMessage(value,proto.swift.document.v1.Document.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.ListDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.ListDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.ListDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.ListDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.document.v1.Document.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Document documents = 1;
 * @return {!Array<!proto.swift.document.v1.Document>}
 */
proto.swift.document.v1.ListDocumentsResponse.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.swift.document.v1.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.document.v1.Document, 1));
};


/**
 * @param {!Array<!proto.swift.document.v1.Document>} value
 * @return {!proto.swift.document.v1.ListDocumentsResponse} returns this
*/
proto.swift.document.v1.ListDocumentsResponse.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.document.v1.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.Document}
 */
proto.swift.document.v1.ListDocumentsResponse.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.document.v1.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.ListDocumentsResponse} returns this
 */
proto.swift.document.v1.ListDocumentsResponse.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.swift.document.v1.ListDocumentsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.ListDocumentsResponse} returns this
 */
proto.swift.document.v1.ListDocumentsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.GetDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.GetDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.GetDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GetDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.GetDocumentRequest}
 */
proto.swift.document.v1.GetDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.GetDocumentRequest;
  return proto.swift.document.v1.GetDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.GetDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.GetDocumentRequest}
 */
proto.swift.document.v1.GetDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.GetDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.GetDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.GetDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GetDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 document_id = 1;
 * @return {number}
 */
proto.swift.document.v1.GetDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.GetDocumentRequest} returns this
 */
proto.swift.document.v1.GetDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.GetDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.GetDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.GetDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GetDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.swift.document.v1.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.GetDocumentResponse}
 */
proto.swift.document.v1.GetDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.GetDocumentResponse;
  return proto.swift.document.v1.GetDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.GetDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.GetDocumentResponse}
 */
proto.swift.document.v1.GetDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.Document;
      reader.readMessage(value,proto.swift.document.v1.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.GetDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.GetDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.GetDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GetDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.swift.document.v1.Document}
 */
proto.swift.document.v1.GetDocumentResponse.prototype.getDocument = function() {
  return /** @type{?proto.swift.document.v1.Document} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.Document, 1));
};


/**
 * @param {?proto.swift.document.v1.Document|undefined} value
 * @return {!proto.swift.document.v1.GetDocumentResponse} returns this
*/
proto.swift.document.v1.GetDocumentResponse.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.GetDocumentResponse} returns this
 */
proto.swift.document.v1.GetDocumentResponse.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.GetDocumentResponse.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.CreateDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.CreateDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.CreateDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    metaData: (f = msg.getMetaData()) && proto.swift.document.v1.DocumentMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.CreateDocumentRequest}
 */
proto.swift.document.v1.CreateDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.CreateDocumentRequest;
  return proto.swift.document.v1.CreateDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.CreateDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.CreateDocumentRequest}
 */
proto.swift.document.v1.CreateDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.DocumentMetaData;
      reader.readMessage(value,proto.swift.document.v1.DocumentMetaData.deserializeBinaryFromReader);
      msg.setMetaData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.CreateDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.CreateDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.CreateDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetaData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.DocumentMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional DocumentMetaData meta_data = 1;
 * @return {?proto.swift.document.v1.DocumentMetaData}
 */
proto.swift.document.v1.CreateDocumentRequest.prototype.getMetaData = function() {
  return /** @type{?proto.swift.document.v1.DocumentMetaData} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.DocumentMetaData, 1));
};


/**
 * @param {?proto.swift.document.v1.DocumentMetaData|undefined} value
 * @return {!proto.swift.document.v1.CreateDocumentRequest} returns this
*/
proto.swift.document.v1.CreateDocumentRequest.prototype.setMetaData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.CreateDocumentRequest} returns this
 */
proto.swift.document.v1.CreateDocumentRequest.prototype.clearMetaData = function() {
  return this.setMetaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.CreateDocumentRequest.prototype.hasMetaData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.CreateDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.CreateDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.CreateDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.swift.document.v1.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.CreateDocumentResponse}
 */
proto.swift.document.v1.CreateDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.CreateDocumentResponse;
  return proto.swift.document.v1.CreateDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.CreateDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.CreateDocumentResponse}
 */
proto.swift.document.v1.CreateDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.Document;
      reader.readMessage(value,proto.swift.document.v1.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.CreateDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.CreateDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.CreateDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.CreateDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.swift.document.v1.Document}
 */
proto.swift.document.v1.CreateDocumentResponse.prototype.getDocument = function() {
  return /** @type{?proto.swift.document.v1.Document} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.Document, 1));
};


/**
 * @param {?proto.swift.document.v1.Document|undefined} value
 * @return {!proto.swift.document.v1.CreateDocumentResponse} returns this
*/
proto.swift.document.v1.CreateDocumentResponse.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.CreateDocumentResponse} returns this
 */
proto.swift.document.v1.CreateDocumentResponse.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.CreateDocumentResponse.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.SetDocumentUsageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.SetDocumentUsageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.SetDocumentUsageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.SetDocumentUsageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.SetDocumentUsageRequest}
 */
proto.swift.document.v1.SetDocumentUsageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.SetDocumentUsageRequest;
  return proto.swift.document.v1.SetDocumentUsageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.SetDocumentUsageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.SetDocumentUsageRequest}
 */
proto.swift.document.v1.SetDocumentUsageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.document.v1.Usage} */ (reader.readEnum());
      msg.setUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.SetDocumentUsageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.SetDocumentUsageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.SetDocumentUsageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.SetDocumentUsageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 document_id = 1;
 * @return {number}
 */
proto.swift.document.v1.SetDocumentUsageRequest.prototype.getDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.SetDocumentUsageRequest} returns this
 */
proto.swift.document.v1.SetDocumentUsageRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Usage usage = 2;
 * @return {!proto.swift.document.v1.Usage}
 */
proto.swift.document.v1.SetDocumentUsageRequest.prototype.getUsage = function() {
  return /** @type {!proto.swift.document.v1.Usage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.document.v1.Usage} value
 * @return {!proto.swift.document.v1.SetDocumentUsageRequest} returns this
 */
proto.swift.document.v1.SetDocumentUsageRequest.prototype.setUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.SetDocumentUsageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.SetDocumentUsageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.SetDocumentUsageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.SetDocumentUsageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.SetDocumentUsageResponse}
 */
proto.swift.document.v1.SetDocumentUsageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.SetDocumentUsageResponse;
  return proto.swift.document.v1.SetDocumentUsageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.SetDocumentUsageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.SetDocumentUsageResponse}
 */
proto.swift.document.v1.SetDocumentUsageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.SetDocumentUsageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.SetDocumentUsageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.SetDocumentUsageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.SetDocumentUsageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.DeleteDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.DeleteDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.DeleteDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DeleteDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.DeleteDocumentRequest}
 */
proto.swift.document.v1.DeleteDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.DeleteDocumentRequest;
  return proto.swift.document.v1.DeleteDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.DeleteDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.DeleteDocumentRequest}
 */
proto.swift.document.v1.DeleteDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.DeleteDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.DeleteDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.DeleteDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DeleteDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 document_id = 1;
 * @return {number}
 */
proto.swift.document.v1.DeleteDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.DeleteDocumentRequest} returns this
 */
proto.swift.document.v1.DeleteDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.DeleteDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.DeleteDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.DeleteDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DeleteDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.DeleteDocumentResponse}
 */
proto.swift.document.v1.DeleteDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.DeleteDocumentResponse;
  return proto.swift.document.v1.DeleteDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.DeleteDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.DeleteDocumentResponse}
 */
proto.swift.document.v1.DeleteDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.DeleteDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.DeleteDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.DeleteDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DeleteDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 document_id = 1;
 * @return {number}
 */
proto.swift.document.v1.DeleteDocumentResponse.prototype.getDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.document.v1.DeleteDocumentResponse} returns this
 */
proto.swift.document.v1.DeleteDocumentResponse.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.DocumentUploaded.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.DocumentUploaded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.DocumentUploaded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentUploaded.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.swift.document.v1.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.DocumentUploaded}
 */
proto.swift.document.v1.DocumentUploaded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.DocumentUploaded;
  return proto.swift.document.v1.DocumentUploaded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.DocumentUploaded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.DocumentUploaded}
 */
proto.swift.document.v1.DocumentUploaded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.Document;
      reader.readMessage(value,proto.swift.document.v1.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.DocumentUploaded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.DocumentUploaded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.DocumentUploaded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.DocumentUploaded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.swift.document.v1.Document}
 */
proto.swift.document.v1.DocumentUploaded.prototype.getDocument = function() {
  return /** @type{?proto.swift.document.v1.Document} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.Document, 1));
};


/**
 * @param {?proto.swift.document.v1.Document|undefined} value
 * @return {!proto.swift.document.v1.DocumentUploaded} returns this
*/
proto.swift.document.v1.DocumentUploaded.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.DocumentUploaded} returns this
 */
proto.swift.document.v1.DocumentUploaded.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.DocumentUploaded.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.GenerateBookingDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.GenerateBookingDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && booking_v1_booking_pb.Booking.toObject(includeInstance, f),
    usage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customData: (f = msg.getCustomData()) && proto.swift.document.v1.CustomData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.GenerateBookingDocumentRequest}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.GenerateBookingDocumentRequest;
  return proto.swift.document.v1.GenerateBookingDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.GenerateBookingDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.GenerateBookingDocumentRequest}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_booking_pb.Booking;
      reader.readMessage(value,booking_v1_booking_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.document.v1.Usage} */ (reader.readEnum());
      msg.setUsage(value);
      break;
    case 3:
      var value = new proto.swift.document.v1.CustomData;
      reader.readMessage(value,proto.swift.document.v1.CustomData.deserializeBinaryFromReader);
      msg.setCustomData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.GenerateBookingDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.GenerateBookingDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      booking_v1_booking_pb.Booking.serializeBinaryToWriter
    );
  }
  f = message.getUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCustomData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.document.v1.CustomData.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.booking.v1.Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, booking_v1_booking_pb.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.document.v1.GenerateBookingDocumentRequest} returns this
*/
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.GenerateBookingDocumentRequest} returns this
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Usage usage = 2;
 * @return {!proto.swift.document.v1.Usage}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.getUsage = function() {
  return /** @type {!proto.swift.document.v1.Usage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.document.v1.Usage} value
 * @return {!proto.swift.document.v1.GenerateBookingDocumentRequest} returns this
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.setUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional CustomData custom_data = 3;
 * @return {?proto.swift.document.v1.CustomData}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.getCustomData = function() {
  return /** @type{?proto.swift.document.v1.CustomData} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.CustomData, 3));
};


/**
 * @param {?proto.swift.document.v1.CustomData|undefined} value
 * @return {!proto.swift.document.v1.GenerateBookingDocumentRequest} returns this
*/
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.setCustomData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.GenerateBookingDocumentRequest} returns this
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.clearCustomData = function() {
  return this.setCustomData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.GenerateBookingDocumentRequest.prototype.hasCustomData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.GenerateBookingDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.GenerateBookingDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.swift.document.v1.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.GenerateBookingDocumentResponse}
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.GenerateBookingDocumentResponse;
  return proto.swift.document.v1.GenerateBookingDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.GenerateBookingDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.GenerateBookingDocumentResponse}
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.Document;
      reader.readMessage(value,proto.swift.document.v1.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.GenerateBookingDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.GenerateBookingDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.swift.document.v1.Document}
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.prototype.getDocument = function() {
  return /** @type{?proto.swift.document.v1.Document} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.Document, 1));
};


/**
 * @param {?proto.swift.document.v1.Document|undefined} value
 * @return {!proto.swift.document.v1.GenerateBookingDocumentResponse} returns this
*/
proto.swift.document.v1.GenerateBookingDocumentResponse.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.GenerateBookingDocumentResponse} returns this
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.GenerateBookingDocumentResponse.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.GenerateShipmentDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && shipment_v1_shipment_pb.Shipment.toObject(includeInstance, f),
    usage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    instructionList: jspb.Message.toObjectList(msg.getInstructionList(),
    proto.swift.document.v1.Instruction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentRequest}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.GenerateShipmentDocumentRequest;
  return proto.swift.document.v1.GenerateShipmentDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentRequest}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shipment_v1_shipment_pb.Shipment;
      reader.readMessage(value,shipment_v1_shipment_pb.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.document.v1.Usage} */ (reader.readEnum());
      msg.setUsage(value);
      break;
    case 3:
      var value = new proto.swift.document.v1.Instruction;
      reader.readMessage(value,proto.swift.document.v1.Instruction.deserializeBinaryFromReader);
      msg.addInstruction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.GenerateShipmentDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shipment_v1_shipment_pb.Shipment.serializeBinaryToWriter
    );
  }
  f = message.getUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInstructionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.swift.document.v1.Instruction.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.shipment.v1.Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, shipment_v1_shipment_pb.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentRequest} returns this
*/
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentRequest} returns this
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Usage usage = 2;
 * @return {!proto.swift.document.v1.Usage}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.getUsage = function() {
  return /** @type {!proto.swift.document.v1.Usage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.document.v1.Usage} value
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentRequest} returns this
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.setUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated Instruction instruction = 3;
 * @return {!Array<!proto.swift.document.v1.Instruction>}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.getInstructionList = function() {
  return /** @type{!Array<!proto.swift.document.v1.Instruction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.document.v1.Instruction, 3));
};


/**
 * @param {!Array<!proto.swift.document.v1.Instruction>} value
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentRequest} returns this
*/
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.setInstructionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.swift.document.v1.Instruction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.document.v1.Instruction}
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.addInstruction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.swift.document.v1.Instruction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentRequest} returns this
 */
proto.swift.document.v1.GenerateShipmentDocumentRequest.prototype.clearInstructionList = function() {
  return this.setInstructionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.document.v1.GenerateShipmentDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.swift.document.v1.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentResponse}
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.document.v1.GenerateShipmentDocumentResponse;
  return proto.swift.document.v1.GenerateShipmentDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentResponse}
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.document.v1.Document;
      reader.readMessage(value,proto.swift.document.v1.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.document.v1.GenerateShipmentDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.document.v1.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.swift.document.v1.Document}
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.prototype.getDocument = function() {
  return /** @type{?proto.swift.document.v1.Document} */ (
    jspb.Message.getWrapperField(this, proto.swift.document.v1.Document, 1));
};


/**
 * @param {?proto.swift.document.v1.Document|undefined} value
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentResponse} returns this
*/
proto.swift.document.v1.GenerateShipmentDocumentResponse.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.document.v1.GenerateShipmentDocumentResponse} returns this
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.document.v1.GenerateShipmentDocumentResponse.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.swift.document.v1.Usage = {
  NONE: 0,
  GOODSINVOICE: 1,
  FREIGHTBILL: 2,
  CMR: 3,
  TRANSPORTORDER: 4,
  INVOICE: 5,
  DEVIATION: 6,
  BOOKINGCONFIRMATION: 7,
  SHIPMENTADVICE: 8,
  LOADINGLIST: 9,
  UNLOADINGLIST: 10,
  ADMINISTRATIVE: 11,
  CONTRACT: 12,
  SOP: 13
};

goog.object.extend(exports, proto.swift.document.v1);
