import { Document, Usage } from 'proto/document/v1/document_pb'

import { RootState } from 'store/reducer'

import { DocumentFilter } from 'types/documents'

import * as actions from './actions'

export interface State {
  readonly items: ReadonlyArray<Document>
  readonly isUploading: boolean
  readonly isFetching: boolean
  readonly error?: Error
}

const initialState: State = {
  items: [],
  isFetching: false,
  isUploading: false,
}

// Uses some explicit declarations that values cannot be null to simplify logic.
// Relies on insert checks always happening via this function
function mergeItems(
  existingItems: ReadonlyArray<Document>,
  newItems: ReadonlyArray<Document>,
): ReadonlyArray<Document> | Error {
  if (!newItems.every((item) => !!item.getMetaData())) {
    return new Error('Missing metadata in document')
  }
  const existing: { [key: string]: Document } = existingItems.reduce((acc, curr) => {
    return { ...acc, [curr.getMetaData()!.getDocumentId()]: curr }
  }, {})
  if (!newItems.every((item) => !!item.getMetaData())) {
    return new Error('Missing metadata in document')
  }
  const received: { [key: string]: Document } = newItems.reduce((acc, curr) => {
    return { ...acc, [curr.getMetaData()!.getDocumentId()]: curr }
  }, {})
  const result: { [key: string]: Document } = { ...existing, ...received }
  return Object.values(result)
}

export const getItems = (state: RootState) => state.document.items
export const getItemsByFilter = (state: RootState, filter: DocumentFilter) =>
  state.document.items.filter((document) => {
    const meta = document.getMetaData()
    if (!meta) return false
    const owner = meta.getOwner()
    return (
      (filter.userID === undefined ||
        filter.userID.length === 0 ||
        (!!owner && filter.userID.some((id) => id === owner.getUserId()))) &&
      (filter.organizationID === undefined ||
        filter.organizationID.length === 0 ||
        (!!owner && filter.organizationID.some((org) => org === owner.getOrganizationId()))) &&
      (filter.userGroupID === undefined ||
        filter.userGroupID.length === 0 ||
        (!!owner && filter.userGroupID.some((ug) => ug === owner.getUserGroupId()))) &&
      (filter.orderRef === undefined ||
        filter.orderRef.length === 0 ||
        filter.orderRef.some((oRef) => oRef === meta.getOrderRef())) &&
      (filter.bookingRef === undefined ||
        filter.bookingRef.length === 0 ||
        filter.bookingRef.some((bRef) => bRef === meta.getBookingRef())) &&
      (filter.organizationRef === undefined ||
        filter.organizationRef.length === 0 ||
        filter.organizationRef.some((bRef) => bRef === meta.getOrganizationRef())) &&
      (filter.shipmentRef === undefined ||
        filter.shipmentRef.length === 0 ||
        filter.shipmentRef.some((sRef) => sRef === meta.getShipmentRef())) &&
      (filter.invoiceNo === undefined ||
        filter.invoiceNo.length === 0 ||
        filter.invoiceNo.some((iNo) => iNo === meta.getInvoiceNo())) &&
      (filter.deviationId === undefined ||
        filter.deviationId.length === 0 ||
        filter.deviationId.some((dId) => dId === meta.getDeviationId()))
    )
  })
export const getItemsByUsage = (state: RootState, usage: Usage) =>
  state.document.items.filter((d) => d.getMetaData()?.getUsage() === usage)

export const getIsFetching = (state: RootState) => state.document.isFetching
export const getIsUploading = (state: RootState) => state.document.isUploading
export const getError = (state: RootState) => state.document.error

export default function reducer(state: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQUEST:
      return { ...state, error: undefined, isFetching: true }
    case actions.LIST_ERROR:
      return { ...state, error: action.payload.error, isFetching: false }
    case actions.LIST_RESPONSE: {
      const newItems = mergeItems(state.items, action.payload.documents)
      return newItems instanceof Error
        ? { ...state, error: newItems, isFetching: false }
        : {
            ...state,
            error: undefined,
            isFetching: false,
            items: newItems,
          }
    }
    case actions.UPLOAD_FILE:
      return { ...state, error: undefined, isUploading: true }
    case actions.UPLOAD_FILE_ERROR:
      return { ...state, error: action.payload.error, isUploading: false }
    case actions.UPLOAD_FILE_SUCCESS: {
      const newItems = mergeItems(state.items, [action.payload.document])
      return newItems instanceof Error
        ? { ...state, error: newItems, isUploading: false }
        : {
            ...state,
            error: undefined,
            isUploading: false,
            items: newItems,
          }
    }
    case actions.DELETE_REQUEST:
      return { ...state, error: undefined, isFetching: true }
    case actions.DELETE_RESPONSE:
      const { documentID } = action.payload
      const items = state.items.filter((doc) => {
        const md = doc.getMetaData()
        return !!md && md.getDocumentId() !== documentID
      })
      return { ...state, items, isFetching: false }
    case actions.DELETE_ERROR:
      const { error } = action.payload
      return { ...state, error, isFetching: false }
    case actions.GENERATE_BOOKING_DOCUMENT_REQUEST:
      return { ...state, error: undefined, isFetching: true }
    case actions.GENERATE_BOOKING_DOCUMENT_ERROR:
      return { ...state, error: action.payload.error, isFetching: false }
    case actions.GENERATE_BOOKING_DOCUMENT_RESPONSE: {
      const newItems = mergeItems(state.items, [action.payload.document])
      return newItems instanceof Error
        ? { ...state, error: newItems, isFetching: false }
        : {
            ...state,
            error: undefined,
            isFetching: false,
            items: newItems,
          }
    }
    case actions.GENERATE_SHIPMENT_DOCUMENT_REQUEST:
      return { ...state, error: undefined, isFetching: true }
    case actions.GENERATE_SHIPMENT_DOCUMENT_ERROR:
      return { ...state, error: action.payload.error, isFetching: false }
    case actions.GENERATE_SHIPMENT_DOCUMENT_RESPONSE: {
      const newItems = mergeItems(state.items, [action.payload.document])
      return newItems instanceof Error
        ? { ...state, error: newItems, isFetching: false }
        : {
            ...state,
            error: undefined,
            isFetching: false,
            items: newItems,
          }
    }
    default:
      return state
  }
}
