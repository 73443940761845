import * as providerv1 from '../../../proto/invoicing/v1/provider_pb'

import { RootState } from '../../../store/reducer'

import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: providerv1.Provider }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.invoicing.provider.items).map((id) => state.invoicing.provider.items[id])
}
export const getCount = (state: RootState) => state.invoicing.provider.count
export const getErr = (state: RootState) => state.invoicing.provider.err
export const getIsFetching = (state: RootState) => state.invoicing.provider.isFetching

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_PROVIDERS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_PROVIDERS_RESP: {
      const { count, providers } = action.payload
      const newItems = providers.reduce<{ [key: string]: providerv1.Provider }>((map, p) => {
        map[p.getProviderId()] = p
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      return { ...s, items: items, count, isFetching: false }
    }

    case actions.LIST_PROVIDERS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { provider } = action.payload
      if (!provider) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [provider.getProviderId()]: provider,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { provider } = action.payload
      if (!provider) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [provider.getProviderId()]: provider,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { provider } = action.payload
      if (!provider) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [provider.getProviderId()]: provider,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { id } = action.payload

      const newItems = Object.keys(s.items).reduce<{ [key: string]: providerv1.Provider }>(
        (map, i) => {
          if (i !== String(id)) {
            map[String(i)] = s.items[String(i)]
          }
          return map
        },
        {},
      )

      return { ...s, items: newItems, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
