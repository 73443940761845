import * as branchv1 from '../../../proto/iam/v1/branch_pb'

import { RootState } from '../../../store/reducer'

import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: branchv1.Branch }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly currentBranch?: branchv1.Branch
}

const initialState: State = {
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
  currentBranch: undefined,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.iam.branch.items).map((id) => state.iam.branch.items[id])
}
export const getCount = (state: RootState) => state.iam.branch.count
export const getErr = (state: RootState) => state.iam.branch.err
export const getIsFetching = (state: RootState) => state.iam.branch.isFetching
export const getCurrentBranch = (state: RootState) => state.iam.branch.currentBranch
export const getConversionAddon = (state: RootState) =>
  state.iam.branch.currentBranch?.getConversionAddon() ?? 0
export const getBranchDefaultCurrency = (state: RootState) => {
  return state.iam.branch.currentBranch?.getDefaultCurrency() ?? ''
}

export const getCurrentBranchCountryCode = (state: RootState) =>
  state.iam.branch.currentBranch?.getBranchConfig()?.getAddress()?.getCountryId()
export const getBranchAcceptedCurrencies = (state: RootState) => {
  return state.iam.branch.currentBranch?.getAcceptedCurrenciesList() || []
}

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_BRANCHES_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_BRANCHES_RESP: {
      const { count, branches } = action.payload
      const newItems = branches.reduce<{ [key: string]: branchv1.Branch }>((map, b) => {
        map[b.getBranchId()] = b
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      return { ...s, items: items, count, isFetching: false }
    }

    case actions.LIST_BRANCHES_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { branch } = action.payload
      if (!branch) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [branch.getBranchId()]: branch,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { branch } = action.payload
      if (!branch) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [branch.getBranchId()]: branch,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.EDIT_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.EDIT_RESP: {
      const { branch } = action.payload
      if (!branch) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [branch.getBranchId()]: branch,
      }
      return { ...s, items: items, isFetching: false }
    }

    case actions.EDIT_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.DELETE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.DELETE_RESP: {
      const { id } = action.payload

      const newItems = Object.keys(s.items).reduce<{ [key: string]: branchv1.Branch }>((map, i) => {
        if (i !== String(id)) {
          map[String(i)] = s.items[String(i)]
        }
        return map
      }, {})

      return { ...s, items: newItems, isFetching: false }
    }

    case actions.DELETE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_CURRENT_BRANCH: {
      const { branch } = action.payload
      return { ...s, currentBranch: branch }
    }

    default:
      return s
  }
}
