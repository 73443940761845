import React from 'react'

import { Button } from 'antd'

import './contentbutton.css'

interface ContentButtonProps {
  children?: JSX.Element
  onClick?: () => void
}

export const ContentButton = ({ children, onClick }: ContentButtonProps) => {
  return (
    <Button onClick={onClick} className="content-button content-button__container">
      {children}
    </Button>
  )
}
