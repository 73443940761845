import React from 'react'

import { Card } from 'antd'

import './cardcomponent.css'
import { ContentButton } from 'components/Buttons'
import { DividerComponent } from 'components/Dividers'

interface CardProps {
  title?: JSX.Element | string
  content?: JSX.Element
  avatar?: JSX.Element | React.ReactNode
  footer?: JSX.Element | null
  focused?: boolean
  onClick?: () => void
}

export const CardComponent = ({ title, content, avatar, footer, focused, onClick }: CardProps) => {
  const styles = {
    boxShadow: '0px 0px 0px 0px rgba(208, 216, 243, 0.6)',
    border: '1px solid #f0f0f0',
  }

  return (
    <Card
      hoverable={false}
      style={focused ? { borderWidth: 1, borderColor: '#fa5a50', ...styles } : { ...styles }}
    >
      <ContentButton onClick={onClick}>
        <div className="card-component__container">
          {avatar}
          <div className="card-component__content">
            {title}
            {content}
          </div>
        </div>
      </ContentButton>
      {footer ? (
        <>
          <DividerComponent marginBottom={true} />
          {footer}
        </>
      ) : (
        <></>
      )}
    </Card>
  )
}
