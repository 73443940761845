import { UserProfile } from '../../helpers/auth'
import { ActionsUnion, createAction } from '../helpers'

import { AuthSignup } from '../../types/signup'

export const LOGIN = 'swift2/auth/LOGIN'
export const AUTH = 'swift2/auth/AUTH'
export const AUTH_RESP = 'swift2/auth/AUTH_RESP'
export const AUTH_ERR = 'swift2/auth/AUTH_ERR'
export const LOGOUT = 'swift2/auth/LOGOUT'
export const SIGNUP_REQ = 'swift2/auth/SIGNUP_REQ'
export const SIGNUP_RESP = 'swift2/auth/SIGNUP_RESP'
export const SIGNUP_ERR = 'swift2/auth/SIGNUP_ERR'
export const VERIFY_TOKEN = 'swift2/auth/VERIFY_TOKEN'

export const Actions = {
  login: () => createAction(LOGIN),
  authenticate: () => createAction(AUTH),
  authenticateResp: (profile: UserProfile) => createAction(AUTH_RESP, { profile }),
  authenticateErr: (err: any) => createAction(AUTH_ERR, { err }),
  logout: () => createAction(LOGOUT),
  signupReq: (email: string) => createAction(SIGNUP_REQ, { email }),
  signupResp: (credential: AuthSignup) => createAction(SIGNUP_RESP, { credential }),
  signupErr: (err: Error) => createAction(SIGNUP_ERR, { err }),
  verifyToken: () => createAction(VERIFY_TOKEN),
}

export type ActionTypes = ActionsUnion<typeof Actions>
