// source: common/booking.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.swift.common.SpaceUnit', null, global);
goog.exportSymbol('proto.swift.common.TransportMode', null, global);
goog.exportSymbol('proto.swift.common.TransportSubmode', null, global);
/**
 * @enum {number}
 */
proto.swift.common.TransportMode = {
  UNKNOWN: 0,
  AIR: 1,
  SEA: 2,
  RAIL: 3,
  ROAD: 4,
  COURIER: 5
};

/**
 * @enum {number}
 */
proto.swift.common.TransportSubmode = {
  UNSPECIFIED: 0,
  PART_LOAD: 1,
  FULL_TRUCK_LOAD: 2,
  SPRINTER: 3,
  LESS_CONTAINER_LOAD: 4,
  FULL_CONTAINER_LOAD: 5
};

/**
 * @enum {number}
 */
proto.swift.common.SpaceUnit = {
  SPACE_UNIT_UNKNOWN: 0,
  OTHER: 1,
  CBM: 2,
  CW: 3,
  LDM: 4,
  PPL: 5
};

goog.object.extend(exports, proto.swift.common);
