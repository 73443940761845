import { INominatedContract } from 'shared/interface/nominated-contract.interface'
import { ISurcharge } from 'shared/interface/surcharge.interface'

import { NominatedContract, Surcharge } from 'proto/contract/v1/contract_pb'

const fromNominatedContractProto = (contract: NominatedContract): INominatedContract => {
  return {
    contractName: contract.getContractName(),
    contractRef: contract.getContractRef(),
    currency: contract.getCurrency(),
    laneId: contract.getLaneId(),
    surchargesList: contract.getSurchargesList().map((s) => s.toObject()),
    freightPrice: contract.getFreightPrice(),
    totalPrice: contract.getTotalPrice(),
    acceptedBy: contract.getAcceptedBy(),
  }
}

const toNominatedContractProto = (contract: INominatedContract): NominatedContract => {
  const proto = new NominatedContract()
  proto.setContractName(contract.contractName)
  proto.setContractRef(contract.contractRef)
  proto.setCurrency(contract.currency)
  proto.setLaneId(contract.laneId)
  proto.setSurchargesList(contract.surchargesList.map(toSurchargeProto))
  proto.setFreightPrice(contract.freightPrice)
  proto.setTotalPrice(contract.totalPrice)
  return proto
}

const toSurchargeProto = (surcharge: ISurcharge): Surcharge => {
  const proto = new Surcharge()
  proto.setSurchargeId(surcharge.surchargeId)
  proto.setSurchargeType(surcharge.surchargeType)
  proto.setRate(surcharge.rate)
  proto.setRateType(surcharge.rateType)
  return proto
}

export { fromNominatedContractProto, toNominatedContractProto }
