// source: iam/v1/signup.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
var iam_v1_user_pb = require('../../iam/v1/user_pb.js');
goog.object.extend(proto, iam_v1_user_pb);
var iam_v1_usergroup_pb = require('../../iam/v1/usergroup_pb.js');
goog.object.extend(proto, iam_v1_usergroup_pb);
goog.exportSymbol('proto.swift.iam.v1.SignupUserRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.SignupUserResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.UserSignup', null, global);
goog.exportSymbol('proto.swift.iam.v1.ValidateJoinCodeRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.ValidateJoinCodeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.UserSignup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.UserSignup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.UserSignup.displayName = 'proto.swift.iam.v1.UserSignup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.SignupUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.SignupUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.SignupUserRequest.displayName = 'proto.swift.iam.v1.SignupUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ValidateJoinCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.ValidateJoinCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ValidateJoinCodeRequest.displayName = 'proto.swift.iam.v1.ValidateJoinCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ValidateJoinCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.ValidateJoinCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ValidateJoinCodeResponse.displayName = 'proto.swift.iam.v1.ValidateJoinCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.SignupUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.SignupUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.SignupUserResponse.displayName = 'proto.swift.iam.v1.SignupUserResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.UserSignup.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.UserSignup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.UserSignup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserSignup.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
    joinCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.UserSignup}
 */
proto.swift.iam.v1.UserSignup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.UserSignup;
  return proto.swift.iam.v1.UserSignup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.UserSignup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.UserSignup}
 */
proto.swift.iam.v1.UserSignup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoinCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.UserSignup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.UserSignup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.UserSignup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.UserSignup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getJoinCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.swift.iam.v1.UserSignup.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.UserSignup} returns this
 */
proto.swift.iam.v1.UserSignup.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.swift.iam.v1.UserSignup.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.UserSignup} returns this
 */
proto.swift.iam.v1.UserSignup.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional swift.crm.v1.Address address = 3;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.iam.v1.UserSignup.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 3));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.iam.v1.UserSignup} returns this
*/
proto.swift.iam.v1.UserSignup.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.UserSignup} returns this
 */
proto.swift.iam.v1.UserSignup.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.UserSignup.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string join_code = 4;
 * @return {string}
 */
proto.swift.iam.v1.UserSignup.prototype.getJoinCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.UserSignup} returns this
 */
proto.swift.iam.v1.UserSignup.prototype.setJoinCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.SignupUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.SignupUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.SignupUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.SignupUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userSignup: (f = msg.getUserSignup()) && proto.swift.iam.v1.UserSignup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.SignupUserRequest}
 */
proto.swift.iam.v1.SignupUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.SignupUserRequest;
  return proto.swift.iam.v1.SignupUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.SignupUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.SignupUserRequest}
 */
proto.swift.iam.v1.SignupUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.UserSignup;
      reader.readMessage(value,proto.swift.iam.v1.UserSignup.deserializeBinaryFromReader);
      msg.setUserSignup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.SignupUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.SignupUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.SignupUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.SignupUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserSignup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.UserSignup.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserSignup user_signup = 1;
 * @return {?proto.swift.iam.v1.UserSignup}
 */
proto.swift.iam.v1.SignupUserRequest.prototype.getUserSignup = function() {
  return /** @type{?proto.swift.iam.v1.UserSignup} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.UserSignup, 1));
};


/**
 * @param {?proto.swift.iam.v1.UserSignup|undefined} value
 * @return {!proto.swift.iam.v1.SignupUserRequest} returns this
*/
proto.swift.iam.v1.SignupUserRequest.prototype.setUserSignup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.SignupUserRequest} returns this
 */
proto.swift.iam.v1.SignupUserRequest.prototype.clearUserSignup = function() {
  return this.setUserSignup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.SignupUserRequest.prototype.hasUserSignup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ValidateJoinCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ValidateJoinCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    joinCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ValidateJoinCodeRequest}
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ValidateJoinCodeRequest;
  return proto.swift.iam.v1.ValidateJoinCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ValidateJoinCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ValidateJoinCodeRequest}
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoinCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ValidateJoinCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ValidateJoinCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJoinCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string join_code = 1;
 * @return {string}
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.prototype.getJoinCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.ValidateJoinCodeRequest} returns this
 */
proto.swift.iam.v1.ValidateJoinCodeRequest.prototype.setJoinCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ValidateJoinCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ValidateJoinCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isValid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    onSignupCreateUsergroup: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ValidateJoinCodeResponse}
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ValidateJoinCodeResponse;
  return proto.swift.iam.v1.ValidateJoinCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ValidateJoinCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ValidateJoinCodeResponse}
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsValid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnSignupCreateUsergroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ValidateJoinCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ValidateJoinCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOnSignupCreateUsergroup();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool is_valid = 1;
 * @return {boolean}
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.prototype.getIsValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.ValidateJoinCodeResponse} returns this
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.prototype.setIsValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool on_signup_create_usergroup = 2;
 * @return {boolean}
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.prototype.getOnSignupCreateUsergroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.ValidateJoinCodeResponse} returns this
 */
proto.swift.iam.v1.ValidateJoinCodeResponse.prototype.setOnSignupCreateUsergroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.SignupUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.SignupUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.SignupUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && iam_v1_user_pb.User.toObject(includeInstance, f),
    userGroup: (f = msg.getUserGroup()) && iam_v1_usergroup_pb.UserGroup.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.SignupUserResponse}
 */
proto.swift.iam.v1.SignupUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.SignupUserResponse;
  return proto.swift.iam.v1.SignupUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.SignupUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.SignupUserResponse}
 */
proto.swift.iam.v1.SignupUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new iam_v1_user_pb.User;
      reader.readMessage(value,iam_v1_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new iam_v1_usergroup_pb.UserGroup;
      reader.readMessage(value,iam_v1_usergroup_pb.UserGroup.deserializeBinaryFromReader);
      msg.setUserGroup(value);
      break;
    case 3:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.SignupUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.SignupUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.SignupUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      iam_v1_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUserGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      iam_v1_usergroup_pb.UserGroup.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.swift.iam.v1.User}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.getUser = function() {
  return /** @type{?proto.swift.iam.v1.User} */ (
    jspb.Message.getWrapperField(this, iam_v1_user_pb.User, 1));
};


/**
 * @param {?proto.swift.iam.v1.User|undefined} value
 * @return {!proto.swift.iam.v1.SignupUserResponse} returns this
*/
proto.swift.iam.v1.SignupUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.SignupUserResponse} returns this
 */
proto.swift.iam.v1.SignupUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserGroup user_group = 2;
 * @return {?proto.swift.iam.v1.UserGroup}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.getUserGroup = function() {
  return /** @type{?proto.swift.iam.v1.UserGroup} */ (
    jspb.Message.getWrapperField(this, iam_v1_usergroup_pb.UserGroup, 2));
};


/**
 * @param {?proto.swift.iam.v1.UserGroup|undefined} value
 * @return {!proto.swift.iam.v1.SignupUserResponse} returns this
*/
proto.swift.iam.v1.SignupUserResponse.prototype.setUserGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.SignupUserResponse} returns this
 */
proto.swift.iam.v1.SignupUserResponse.prototype.clearUserGroup = function() {
  return this.setUserGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.hasUserGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional swift.crm.v1.Address address = 3;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 3));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.iam.v1.SignupUserResponse} returns this
*/
proto.swift.iam.v1.SignupUserResponse.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.SignupUserResponse} returns this
 */
proto.swift.iam.v1.SignupUserResponse.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.SignupUserResponse.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.swift.iam.v1);
