// source: iam/v1/organization.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
goog.exportSymbol('proto.swift.iam.v1.Config', null, global);
goog.exportSymbol('proto.swift.iam.v1.CreateOrganizationRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.CreateOrganizationResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditInstructionsRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditInstructionsResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditInvoicingRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditInvoicingResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditOrganizationRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditOrganizationResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetOrganizationRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetOrganizationResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.InvoiceConfig', null, global);
goog.exportSymbol('proto.swift.iam.v1.InvoiceConfig.CollectiveInvoiceTimeInterval', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListOrganizationsRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListOrganizationsResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.Organization', null, global);
goog.exportSymbol('proto.swift.iam.v1.OrganizationListFilter', null, global);
goog.exportSymbol('proto.swift.iam.v1.TransportOperator', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.Organization.displayName = 'proto.swift.iam.v1.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.Config = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.Config.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.Config, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.Config.displayName = 'proto.swift.iam.v1.Config';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.TransportOperator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.TransportOperator.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.TransportOperator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.TransportOperator.displayName = 'proto.swift.iam.v1.TransportOperator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.InvoiceConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.InvoiceConfig.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.InvoiceConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.InvoiceConfig.displayName = 'proto.swift.iam.v1.InvoiceConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.OrganizationListFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.OrganizationListFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.OrganizationListFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.OrganizationListFilter.displayName = 'proto.swift.iam.v1.OrganizationListFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetOrganizationRequest.displayName = 'proto.swift.iam.v1.GetOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetOrganizationResponse.displayName = 'proto.swift.iam.v1.GetOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListOrganizationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.ListOrganizationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListOrganizationsRequest.displayName = 'proto.swift.iam.v1.ListOrganizationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListOrganizationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.ListOrganizationsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.ListOrganizationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListOrganizationsResponse.displayName = 'proto.swift.iam.v1.ListOrganizationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateOrganizationRequest.displayName = 'proto.swift.iam.v1.CreateOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateOrganizationResponse.displayName = 'proto.swift.iam.v1.CreateOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditOrganizationRequest.displayName = 'proto.swift.iam.v1.EditOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditOrganizationResponse.displayName = 'proto.swift.iam.v1.EditOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditInvoicingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditInvoicingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditInvoicingRequest.displayName = 'proto.swift.iam.v1.EditInvoicingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditInvoicingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditInvoicingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditInvoicingResponse.displayName = 'proto.swift.iam.v1.EditInvoicingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditInstructionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditInstructionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditInstructionsRequest.displayName = 'proto.swift.iam.v1.EditInstructionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditInstructionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditInstructionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditInstructionsResponse.displayName = 'proto.swift.iam.v1.EditInstructionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    branchId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customerNo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    organization: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    organizationNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    config: (f = msg.getConfig()) && proto.swift.iam.v1.Config.toObject(includeInstance, f),
    joinCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    externalInstructions: jspb.Message.getFieldWithDefault(msg, 9, ""),
    internalOperationalInstructions: jspb.Message.getFieldWithDefault(msg, 10, ""),
    internalFinancialInstructions: jspb.Message.getFieldWithDefault(msg, 11, ""),
    address: (f = msg.getAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
    invoiceAddress: (f = msg.getInvoiceAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
    salesRepresentative: jspb.Message.getFieldWithDefault(msg, 14, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.Organization;
  return proto.swift.iam.v1.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationNumber(value);
      break;
    case 7:
      var value = new proto.swift.iam.v1.Config;
      reader.readMessage(value,proto.swift.iam.v1.Config.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoinCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalInstructions(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalOperationalInstructions(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalFinancialInstructions(value);
      break;
    case 12:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 13:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setInvoiceAddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesRepresentative(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCustomerNo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrganizationNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.swift.iam.v1.Config.serializeBinaryToWriter
    );
  }
  f = message.getJoinCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExternalInstructions();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInternalOperationalInstructions();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInternalFinancialInstructions();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceAddress();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getSalesRepresentative();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional int64 organization_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.Organization.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 branch_id = 2;
 * @return {number}
 */
proto.swift.iam.v1.Organization.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 customer_no = 3;
 * @return {number}
 */
proto.swift.iam.v1.Organization.prototype.getCustomerNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setCustomerNo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string organization = 4;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string organization_number = 6;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getOrganizationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setOrganizationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Config config = 7;
 * @return {?proto.swift.iam.v1.Config}
 */
proto.swift.iam.v1.Organization.prototype.getConfig = function() {
  return /** @type{?proto.swift.iam.v1.Config} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Config, 7));
};


/**
 * @param {?proto.swift.iam.v1.Config|undefined} value
 * @return {!proto.swift.iam.v1.Organization} returns this
*/
proto.swift.iam.v1.Organization.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.Organization.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string join_code = 8;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getJoinCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setJoinCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string external_instructions = 9;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getExternalInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setExternalInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string internal_operational_instructions = 10;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getInternalOperationalInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setInternalOperationalInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string internal_financial_instructions = 11;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getInternalFinancialInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setInternalFinancialInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional swift.crm.v1.Address address = 12;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.iam.v1.Organization.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 12));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.iam.v1.Organization} returns this
*/
proto.swift.iam.v1.Organization.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.Organization.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional swift.crm.v1.Address invoice_address = 13;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.iam.v1.Organization.prototype.getInvoiceAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 13));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.iam.v1.Organization} returns this
*/
proto.swift.iam.v1.Organization.prototype.setInvoiceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.clearInvoiceAddress = function() {
  return this.setInvoiceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.Organization.prototype.hasInvoiceAddress = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string sales_representative = 14;
 * @return {string}
 */
proto.swift.iam.v1.Organization.prototype.getSalesRepresentative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setSalesRepresentative = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool enabled = 15;
 * @return {boolean}
 */
proto.swift.iam.v1.Organization.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Organization} returns this
 */
proto.swift.iam.v1.Organization.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.Config.repeatedFields_ = [6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.Config.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.Config.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.Config} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.Config.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultCurrency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    onSignupCreateUsergroup: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    returnHandlingRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pickupDateOffset: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dhlStatusSync: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    customerUpsAccountNumbersList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    invoiceConfig: (f = msg.getInvoiceConfig()) && proto.swift.iam.v1.InvoiceConfig.toObject(includeInstance, f),
    transportOperatorList: jspb.Message.toObjectList(msg.getTransportOperatorList(),
    proto.swift.iam.v1.TransportOperator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.Config}
 */
proto.swift.iam.v1.Config.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.Config;
  return proto.swift.iam.v1.Config.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.Config} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.Config}
 */
proto.swift.iam.v1.Config.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultCurrency(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnSignupCreateUsergroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReturnHandlingRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupDateOffset(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDhlStatusSync(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addCustomerUpsAccountNumbers(value);
      break;
    case 7:
      var value = new proto.swift.iam.v1.InvoiceConfig;
      reader.readMessage(value,proto.swift.iam.v1.InvoiceConfig.deserializeBinaryFromReader);
      msg.setInvoiceConfig(value);
      break;
    case 9:
      var value = new proto.swift.iam.v1.TransportOperator;
      reader.readMessage(value,proto.swift.iam.v1.TransportOperator.deserializeBinaryFromReader);
      msg.addTransportOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.Config.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.Config.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.Config} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.Config.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOnSignupCreateUsergroup();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReturnHandlingRate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPickupDateOffset();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDhlStatusSync();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCustomerUpsAccountNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getInvoiceConfig();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.swift.iam.v1.InvoiceConfig.serializeBinaryToWriter
    );
  }
  f = message.getTransportOperatorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.swift.iam.v1.TransportOperator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string default_currency = 1;
 * @return {string}
 */
proto.swift.iam.v1.Config.prototype.getDefaultCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.setDefaultCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool on_signup_create_usergroup = 2;
 * @return {boolean}
 */
proto.swift.iam.v1.Config.prototype.getOnSignupCreateUsergroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.setOnSignupCreateUsergroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 return_handling_rate = 3;
 * @return {number}
 */
proto.swift.iam.v1.Config.prototype.getReturnHandlingRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.setReturnHandlingRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 pickup_date_offset = 4;
 * @return {number}
 */
proto.swift.iam.v1.Config.prototype.getPickupDateOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.setPickupDateOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool dhl_status_sync = 5;
 * @return {boolean}
 */
proto.swift.iam.v1.Config.prototype.getDhlStatusSync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.setDhlStatusSync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string customer_ups_account_numbers = 6;
 * @return {!Array<string>}
 */
proto.swift.iam.v1.Config.prototype.getCustomerUpsAccountNumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.setCustomerUpsAccountNumbersList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.addCustomerUpsAccountNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.clearCustomerUpsAccountNumbersList = function() {
  return this.setCustomerUpsAccountNumbersList([]);
};


/**
 * optional InvoiceConfig invoice_config = 7;
 * @return {?proto.swift.iam.v1.InvoiceConfig}
 */
proto.swift.iam.v1.Config.prototype.getInvoiceConfig = function() {
  return /** @type{?proto.swift.iam.v1.InvoiceConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.InvoiceConfig, 7));
};


/**
 * @param {?proto.swift.iam.v1.InvoiceConfig|undefined} value
 * @return {!proto.swift.iam.v1.Config} returns this
*/
proto.swift.iam.v1.Config.prototype.setInvoiceConfig = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.clearInvoiceConfig = function() {
  return this.setInvoiceConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.Config.prototype.hasInvoiceConfig = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated TransportOperator transport_operator = 9;
 * @return {!Array<!proto.swift.iam.v1.TransportOperator>}
 */
proto.swift.iam.v1.Config.prototype.getTransportOperatorList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.TransportOperator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.TransportOperator, 9));
};


/**
 * @param {!Array<!proto.swift.iam.v1.TransportOperator>} value
 * @return {!proto.swift.iam.v1.Config} returns this
*/
proto.swift.iam.v1.Config.prototype.setTransportOperatorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.swift.iam.v1.TransportOperator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.TransportOperator}
 */
proto.swift.iam.v1.Config.prototype.addTransportOperator = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.swift.iam.v1.TransportOperator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.Config} returns this
 */
proto.swift.iam.v1.Config.prototype.clearTransportOperatorList = function() {
  return this.setTransportOperatorList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.TransportOperator.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.TransportOperator.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.TransportOperator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.TransportOperator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.TransportOperator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    transportModesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.TransportOperator}
 */
proto.swift.iam.v1.TransportOperator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.TransportOperator;
  return proto.swift.iam.v1.TransportOperator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.TransportOperator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.TransportOperator}
 */
proto.swift.iam.v1.TransportOperator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.swift.common.TransportMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTransportModes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.TransportOperator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.TransportOperator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.TransportOperator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.TransportOperator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTransportModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.swift.iam.v1.TransportOperator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.TransportOperator} returns this
 */
proto.swift.iam.v1.TransportOperator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.swift.iam.v1.TransportOperator.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.TransportOperator} returns this
 */
proto.swift.iam.v1.TransportOperator.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool default = 3;
 * @return {boolean}
 */
proto.swift.iam.v1.TransportOperator.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.TransportOperator} returns this
 */
proto.swift.iam.v1.TransportOperator.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated swift.common.TransportMode transport_modes = 4;
 * @return {!Array<!proto.swift.common.TransportMode>}
 */
proto.swift.iam.v1.TransportOperator.prototype.getTransportModesList = function() {
  return /** @type {!Array<!proto.swift.common.TransportMode>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.swift.common.TransportMode>} value
 * @return {!proto.swift.iam.v1.TransportOperator} returns this
 */
proto.swift.iam.v1.TransportOperator.prototype.setTransportModesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.TransportOperator} returns this
 */
proto.swift.iam.v1.TransportOperator.prototype.addTransportModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.TransportOperator} returns this
 */
proto.swift.iam.v1.TransportOperator.prototype.clearTransportModesList = function() {
  return this.setTransportModesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.InvoiceConfig.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.InvoiceConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.InvoiceConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.InvoiceConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    autoInvoicing: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    autoReminder: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    euVatCustomer: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    vatNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    invoicingEmailsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    creditDays: jspb.Message.getFieldWithDefault(msg, 7, 0),
    creditAmount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    collectiveInvoiceEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    collectiveInvoiceTimeInterval: jspb.Message.getFieldWithDefault(msg, 10, 0),
    partialInvoiceEmail: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.InvoiceConfig}
 */
proto.swift.iam.v1.InvoiceConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.InvoiceConfig;
  return proto.swift.iam.v1.InvoiceConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.InvoiceConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.InvoiceConfig}
 */
proto.swift.iam.v1.InvoiceConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoInvoicing(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoReminder(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEuVatCustomer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVatNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addInvoicingEmails(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreditDays(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreditAmount(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCollectiveInvoiceEnabled(value);
      break;
    case 10:
      var value = /** @type {!proto.swift.iam.v1.InvoiceConfig.CollectiveInvoiceTimeInterval} */ (reader.readEnum());
      msg.setCollectiveInvoiceTimeInterval(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPartialInvoiceEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.InvoiceConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.InvoiceConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.InvoiceConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAutoInvoicing();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAutoReminder();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEuVatCustomer();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getVatNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInvoicingEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getCreditDays();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCreditAmount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCollectiveInvoiceEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCollectiveInvoiceTimeInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPartialInvoiceEmail();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.iam.v1.InvoiceConfig.CollectiveInvoiceTimeInterval = {
  END_OF_WEEK: 0,
  END_OF_MONTH: 1
};

/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool auto_invoicing = 2;
 * @return {boolean}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getAutoInvoicing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setAutoInvoicing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool auto_reminder = 3;
 * @return {boolean}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getAutoReminder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setAutoReminder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool eu_vat_customer = 4;
 * @return {boolean}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getEuVatCustomer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setEuVatCustomer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string vat_number = 5;
 * @return {string}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getVatNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setVatNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string invoicing_emails = 6;
 * @return {!Array<string>}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getInvoicingEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setInvoicingEmailsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.addInvoicingEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.clearInvoicingEmailsList = function() {
  return this.setInvoicingEmailsList([]);
};


/**
 * optional int64 credit_days = 7;
 * @return {number}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getCreditDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setCreditDays = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 credit_amount = 8;
 * @return {number}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getCreditAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setCreditAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool collective_invoice_enabled = 9;
 * @return {boolean}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getCollectiveInvoiceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setCollectiveInvoiceEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional CollectiveInvoiceTimeInterval collective_invoice_time_interval = 10;
 * @return {!proto.swift.iam.v1.InvoiceConfig.CollectiveInvoiceTimeInterval}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getCollectiveInvoiceTimeInterval = function() {
  return /** @type {!proto.swift.iam.v1.InvoiceConfig.CollectiveInvoiceTimeInterval} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.swift.iam.v1.InvoiceConfig.CollectiveInvoiceTimeInterval} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setCollectiveInvoiceTimeInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool partial_invoice_email = 11;
 * @return {boolean}
 */
proto.swift.iam.v1.InvoiceConfig.prototype.getPartialInvoiceEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.InvoiceConfig} returns this
 */
proto.swift.iam.v1.InvoiceConfig.prototype.setPartialInvoiceEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.OrganizationListFilter.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.OrganizationListFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.OrganizationListFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.OrganizationListFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    salesRepresentativeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    organizationIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.OrganizationListFilter}
 */
proto.swift.iam.v1.OrganizationListFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.OrganizationListFilter;
  return proto.swift.iam.v1.OrganizationListFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.OrganizationListFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.OrganizationListFilter}
 */
proto.swift.iam.v1.OrganizationListFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSalesRepresentative(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.OrganizationListFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.OrganizationListFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.OrganizationListFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSalesRepresentativeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated string sales_representative = 1;
 * @return {!Array<string>}
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.getSalesRepresentativeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.iam.v1.OrganizationListFilter} returns this
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.setSalesRepresentativeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.OrganizationListFilter} returns this
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.addSalesRepresentative = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.OrganizationListFilter} returns this
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.clearSalesRepresentativeList = function() {
  return this.setSalesRepresentativeList([]);
};


/**
 * repeated int64 organization_id = 2;
 * @return {!Array<number>}
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.iam.v1.OrganizationListFilter} returns this
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.OrganizationListFilter} returns this
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.OrganizationListFilter} returns this
 */
proto.swift.iam.v1.OrganizationListFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organization: jspb.Message.getFieldWithDefault(msg, 2, ""),
    joinCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetOrganizationRequest}
 */
proto.swift.iam.v1.GetOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetOrganizationRequest;
  return proto.swift.iam.v1.GetOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetOrganizationRequest}
 */
proto.swift.iam.v1.GetOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoinCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJoinCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 organization_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.GetOrganizationRequest} returns this
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string organization = 2;
 * @return {string}
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.GetOrganizationRequest} returns this
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string join_code = 3;
 * @return {string}
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.getJoinCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.GetOrganizationRequest} returns this
 */
proto.swift.iam.v1.GetOrganizationRequest.prototype.setJoinCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.swift.iam.v1.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetOrganizationResponse}
 */
proto.swift.iam.v1.GetOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetOrganizationResponse;
  return proto.swift.iam.v1.GetOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetOrganizationResponse}
 */
proto.swift.iam.v1.GetOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.GetOrganizationResponse.prototype.getOrganization = function() {
  return /** @type{?proto.swift.iam.v1.Organization} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Organization, 1));
};


/**
 * @param {?proto.swift.iam.v1.Organization|undefined} value
 * @return {!proto.swift.iam.v1.GetOrganizationResponse} returns this
*/
proto.swift.iam.v1.GetOrganizationResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.GetOrganizationResponse} returns this
 */
proto.swift.iam.v1.GetOrganizationResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.GetOrganizationResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListOrganizationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListOrganizationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListOrganizationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.swift.iam.v1.OrganizationListFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListOrganizationsRequest}
 */
proto.swift.iam.v1.ListOrganizationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListOrganizationsRequest;
  return proto.swift.iam.v1.ListOrganizationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListOrganizationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListOrganizationsRequest}
 */
proto.swift.iam.v1.ListOrganizationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.OrganizationListFilter;
      reader.readMessage(value,proto.swift.iam.v1.OrganizationListFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListOrganizationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListOrganizationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListOrganizationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.iam.v1.OrganizationListFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.iam.v1.ListOrganizationsRequest} returns this
*/
proto.swift.iam.v1.ListOrganizationsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListOrganizationsRequest} returns this
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OrganizationListFilter filter = 2;
 * @return {?proto.swift.iam.v1.OrganizationListFilter}
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.iam.v1.OrganizationListFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.OrganizationListFilter, 2));
};


/**
 * @param {?proto.swift.iam.v1.OrganizationListFilter|undefined} value
 * @return {!proto.swift.iam.v1.ListOrganizationsRequest} returns this
*/
proto.swift.iam.v1.ListOrganizationsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListOrganizationsRequest} returns this
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListOrganizationsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.ListOrganizationsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListOrganizationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListOrganizationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListOrganizationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListOrganizationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.swift.iam.v1.Organization.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListOrganizationsResponse}
 */
proto.swift.iam.v1.ListOrganizationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListOrganizationsResponse;
  return proto.swift.iam.v1.ListOrganizationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListOrganizationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListOrganizationsResponse}
 */
proto.swift.iam.v1.ListOrganizationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListOrganizationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListOrganizationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListOrganizationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListOrganizationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.iam.v1.ListOrganizationsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.ListOrganizationsResponse} returns this
 */
proto.swift.iam.v1.ListOrganizationsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Organization organizations = 2;
 * @return {!Array<!proto.swift.iam.v1.Organization>}
 */
proto.swift.iam.v1.ListOrganizationsResponse.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.Organization, 2));
};


/**
 * @param {!Array<!proto.swift.iam.v1.Organization>} value
 * @return {!proto.swift.iam.v1.ListOrganizationsResponse} returns this
*/
proto.swift.iam.v1.ListOrganizationsResponse.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.iam.v1.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.ListOrganizationsResponse.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.iam.v1.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.ListOrganizationsResponse} returns this
 */
proto.swift.iam.v1.ListOrganizationsResponse.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.swift.iam.v1.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateOrganizationRequest}
 */
proto.swift.iam.v1.CreateOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateOrganizationRequest;
  return proto.swift.iam.v1.CreateOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateOrganizationRequest}
 */
proto.swift.iam.v1.CreateOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.CreateOrganizationRequest.prototype.getOrganization = function() {
  return /** @type{?proto.swift.iam.v1.Organization} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Organization, 1));
};


/**
 * @param {?proto.swift.iam.v1.Organization|undefined} value
 * @return {!proto.swift.iam.v1.CreateOrganizationRequest} returns this
*/
proto.swift.iam.v1.CreateOrganizationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateOrganizationRequest} returns this
 */
proto.swift.iam.v1.CreateOrganizationRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateOrganizationRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.swift.iam.v1.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateOrganizationResponse}
 */
proto.swift.iam.v1.CreateOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateOrganizationResponse;
  return proto.swift.iam.v1.CreateOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateOrganizationResponse}
 */
proto.swift.iam.v1.CreateOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.CreateOrganizationResponse.prototype.getOrganization = function() {
  return /** @type{?proto.swift.iam.v1.Organization} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Organization, 1));
};


/**
 * @param {?proto.swift.iam.v1.Organization|undefined} value
 * @return {!proto.swift.iam.v1.CreateOrganizationResponse} returns this
*/
proto.swift.iam.v1.CreateOrganizationResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateOrganizationResponse} returns this
 */
proto.swift.iam.v1.CreateOrganizationResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateOrganizationResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.swift.iam.v1.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditOrganizationRequest}
 */
proto.swift.iam.v1.EditOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditOrganizationRequest;
  return proto.swift.iam.v1.EditOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditOrganizationRequest}
 */
proto.swift.iam.v1.EditOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.EditOrganizationRequest.prototype.getOrganization = function() {
  return /** @type{?proto.swift.iam.v1.Organization} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Organization, 1));
};


/**
 * @param {?proto.swift.iam.v1.Organization|undefined} value
 * @return {!proto.swift.iam.v1.EditOrganizationRequest} returns this
*/
proto.swift.iam.v1.EditOrganizationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditOrganizationRequest} returns this
 */
proto.swift.iam.v1.EditOrganizationRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditOrganizationRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.swift.iam.v1.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditOrganizationResponse}
 */
proto.swift.iam.v1.EditOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditOrganizationResponse;
  return proto.swift.iam.v1.EditOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditOrganizationResponse}
 */
proto.swift.iam.v1.EditOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.EditOrganizationResponse.prototype.getOrganization = function() {
  return /** @type{?proto.swift.iam.v1.Organization} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Organization, 1));
};


/**
 * @param {?proto.swift.iam.v1.Organization|undefined} value
 * @return {!proto.swift.iam.v1.EditOrganizationResponse} returns this
*/
proto.swift.iam.v1.EditOrganizationResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditOrganizationResponse} returns this
 */
proto.swift.iam.v1.EditOrganizationResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditOrganizationResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditInvoicingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditInvoicingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInvoicingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invoiceConfig: (f = msg.getInvoiceConfig()) && proto.swift.iam.v1.InvoiceConfig.toObject(includeInstance, f),
    invoiceAddress: (f = msg.getInvoiceAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditInvoicingRequest}
 */
proto.swift.iam.v1.EditInvoicingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditInvoicingRequest;
  return proto.swift.iam.v1.EditInvoicingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditInvoicingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditInvoicingRequest}
 */
proto.swift.iam.v1.EditInvoicingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.InvoiceConfig;
      reader.readMessage(value,proto.swift.iam.v1.InvoiceConfig.deserializeBinaryFromReader);
      msg.setInvoiceConfig(value);
      break;
    case 3:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setInvoiceAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditInvoicingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditInvoicingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInvoicingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInvoiceConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.iam.v1.InvoiceConfig.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 organization_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.EditInvoicingRequest} returns this
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional InvoiceConfig invoice_config = 2;
 * @return {?proto.swift.iam.v1.InvoiceConfig}
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.getInvoiceConfig = function() {
  return /** @type{?proto.swift.iam.v1.InvoiceConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.InvoiceConfig, 2));
};


/**
 * @param {?proto.swift.iam.v1.InvoiceConfig|undefined} value
 * @return {!proto.swift.iam.v1.EditInvoicingRequest} returns this
*/
proto.swift.iam.v1.EditInvoicingRequest.prototype.setInvoiceConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditInvoicingRequest} returns this
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.clearInvoiceConfig = function() {
  return this.setInvoiceConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.hasInvoiceConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional swift.crm.v1.Address invoice_address = 3;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.getInvoiceAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 3));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.iam.v1.EditInvoicingRequest} returns this
*/
proto.swift.iam.v1.EditInvoicingRequest.prototype.setInvoiceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditInvoicingRequest} returns this
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.clearInvoiceAddress = function() {
  return this.setInvoiceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditInvoicingRequest.prototype.hasInvoiceAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditInvoicingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditInvoicingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditInvoicingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInvoicingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.swift.iam.v1.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditInvoicingResponse}
 */
proto.swift.iam.v1.EditInvoicingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditInvoicingResponse;
  return proto.swift.iam.v1.EditInvoicingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditInvoicingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditInvoicingResponse}
 */
proto.swift.iam.v1.EditInvoicingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditInvoicingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditInvoicingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditInvoicingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInvoicingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.EditInvoicingResponse.prototype.getOrganization = function() {
  return /** @type{?proto.swift.iam.v1.Organization} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Organization, 1));
};


/**
 * @param {?proto.swift.iam.v1.Organization|undefined} value
 * @return {!proto.swift.iam.v1.EditInvoicingResponse} returns this
*/
proto.swift.iam.v1.EditInvoicingResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditInvoicingResponse} returns this
 */
proto.swift.iam.v1.EditInvoicingResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditInvoicingResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditInstructionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditInstructionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInstructionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalInstructions: jspb.Message.getFieldWithDefault(msg, 2, ""),
    internalOperationalInstructions: jspb.Message.getFieldWithDefault(msg, 3, ""),
    internalFinancialInstructions: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditInstructionsRequest}
 */
proto.swift.iam.v1.EditInstructionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditInstructionsRequest;
  return proto.swift.iam.v1.EditInstructionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditInstructionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditInstructionsRequest}
 */
proto.swift.iam.v1.EditInstructionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalInstructions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalOperationalInstructions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalFinancialInstructions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditInstructionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditInstructionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInstructionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getExternalInstructions();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInternalOperationalInstructions();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInternalFinancialInstructions();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 organization_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.EditInstructionsRequest} returns this
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string external_instructions = 2;
 * @return {string}
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.getExternalInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.EditInstructionsRequest} returns this
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.setExternalInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string internal_operational_instructions = 3;
 * @return {string}
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.getInternalOperationalInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.EditInstructionsRequest} returns this
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.setInternalOperationalInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string internal_financial_instructions = 4;
 * @return {string}
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.getInternalFinancialInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.EditInstructionsRequest} returns this
 */
proto.swift.iam.v1.EditInstructionsRequest.prototype.setInternalFinancialInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditInstructionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditInstructionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditInstructionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInstructionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.swift.iam.v1.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditInstructionsResponse}
 */
proto.swift.iam.v1.EditInstructionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditInstructionsResponse;
  return proto.swift.iam.v1.EditInstructionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditInstructionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditInstructionsResponse}
 */
proto.swift.iam.v1.EditInstructionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Organization;
      reader.readMessage(value,proto.swift.iam.v1.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditInstructionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditInstructionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditInstructionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditInstructionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.swift.iam.v1.Organization}
 */
proto.swift.iam.v1.EditInstructionsResponse.prototype.getOrganization = function() {
  return /** @type{?proto.swift.iam.v1.Organization} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Organization, 1));
};


/**
 * @param {?proto.swift.iam.v1.Organization|undefined} value
 * @return {!proto.swift.iam.v1.EditInstructionsResponse} returns this
*/
proto.swift.iam.v1.EditInstructionsResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditInstructionsResponse} returns this
 */
proto.swift.iam.v1.EditInstructionsResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditInstructionsResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.swift.iam.v1);
