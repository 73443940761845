import * as usergroupv1 from '../../../proto/iam/v1/usergroup_pb'

import { RootState } from '../../../store/reducer'

import * as actions from './actions'

export interface State {
  readonly items: { [key: string]: usergroupv1.UserGroup }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
}

const initialState: State = {
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.iam.usergroup.items).map((id) => state.iam.usergroup.items[id])
}
export const getItemsById = (state: RootState) => {
  return state.iam.usergroup.items
}
export const getCount = (state: RootState) => state.iam.usergroup.count
export const getErr = (state: RootState) => state.iam.usergroup.err
export const getIsFetching = (state: RootState) => state.iam.usergroup.isFetching

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, userGroups } = action.payload
      const newItems = userGroups.reduce<{ [key: string]: usergroupv1.UserGroup }>((map, u) => {
        map[u.getUserGroupId()] = u
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      return { ...s, items, count, isFetching: false }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { userGroup } = action.payload
      if (!userGroup) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [userGroup.getUserGroupId()]: userGroup,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { userGroup } = action.payload
      if (!userGroup) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [userGroup.getUserGroupId()]: userGroup,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
