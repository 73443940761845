import * as shipmentv1 from '../../../proto/shipment/v1/shipment_pb'

import { RootState } from '../../../store/reducer'

import * as shipmentTypes from '../../../types/shipment'

import * as actions from './actions'

export interface State {
  readonly pages: Map<shipmentTypes.ShipmentPage, Array<number>>
  readonly items: { [key: string]: shipmentv1.Shipment }
  readonly count: number
  readonly err?: Error
  readonly isFetching: boolean
  readonly stats?: shipmentv1.ShipmentStats
  readonly splitCostRef: { [key: string]: shipmentv1.SplitCostReference }
  readonly splitCostItem: { [key: string]: shipmentv1.SplitCostItem }
  readonly isFetchingSplitCost: boolean
}

const initialState: State = {
  pages: new Map(),
  items: {},
  count: 0,
  err: undefined,
  isFetching: false,
  stats: undefined,
  splitCostRef: {},
  splitCostItem: {},
  isFetchingSplitCost: false,
}

export const getItems = (state: RootState) => {
  return Object.keys(state.shipment.shipment.items).map((id) => state.shipment.shipment.items[id])
}
export const getItemsById = (state: RootState) => {
  return state.shipment.shipment.items
}
export const getItemByRef = (state: RootState, ref: string) => {
  return Object.keys(state.shipment.shipment.items)
    .map((id) => state.shipment.shipment.items[id])
    .find((shipment) => (shipment ? shipment.getShipmentRef() === ref : false))
}

export const getCount = (state: RootState) => state.shipment.shipment.count
export const getPageItems = (state: RootState, page: shipmentTypes.ShipmentPage) => {
  const items = state.shipment.shipment.items
  const pageItems = state.shipment.shipment.pages.get(page) || []
  return pageItems.map((id) => items[id], [] as Array<shipmentv1.Shipment>)
}

export const getSplitCostRef = (state: RootState) => {
  return Object.values(state.shipment.shipment.splitCostRef)
}
export const getSplitCostRefByShipId = (state: RootState, shipId: number) => {
  return Object.values(state.shipment.shipment.splitCostRef).filter(
    (split) => !!shipId && split.getShipmentId() === shipId,
  )
}

export const getSplitCostItem = (state: RootState) => {
  return Object.values(state.shipment.shipment.splitCostItem)
}

export const getErr = (state: RootState) => state.shipment.shipment.err
export const getIsFetching = (state: RootState) => state.shipment.shipment.isFetching
export const getIsFetchingSplitCost = (state: RootState) =>
  state.shipment.shipment.isFetchingSplitCost

export const getStats = (state: RootState) => {
  return state.shipment.shipment.stats
}

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, shipments, page } = action.payload
      const newItems = shipments.reduce<{ [key: string]: shipmentv1.Shipment }>((map, r) => {
        map[r.getShipmentId()] = r
        return map
      }, {})
      const items = { ...s.items, ...newItems }
      const pageItems = shipments.map((s) => s.getShipmentId())
      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })
      pages.set(page, pageItems)
      return { ...s, items, count, isFetching: false, pages }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_RESP: {
      const { shipment } = action.payload
      if (!shipment) {
        return { ...s, isFetching: false }
      }
      const items = {
        ...s.items,
        [shipment.getShipmentId()]: shipment,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.GET_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.GET_STATS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.GET_STATS_RESP: {
      const { stats } = action.payload
      // NOTE: Replace all stats for now.
      return { ...s, stats, isFetching: false }
    }

    case actions.GET_STATS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_INTERNAL_NOTE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }
    case actions.UPDATE_INTERNAL_NOTE_RESP: {
      const { shipment } = action.payload
      const items = {
        ...s.items,
        [shipment.getShipmentId()]: shipment,
      }
      return { ...s, items, isFetching: false }
    }
    case actions.UPDATE_INTERNAL_NOTE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_RESP: {
      const { shipment } = action.payload
      const items = {
        ...s.items,
        [shipment.getShipmentId()]: shipment,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.CREATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_RESP: {
      const { shipment } = action.payload
      const items = {
        ...s.items,
        [shipment.getShipmentId()]: shipment,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.UPDATE_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_BOOKING_ORDER_SHIPMENT_RESP: {
      const { shipment } = action.payload
      const items = {
        ...s.items,
        [shipment.getShipmentId()]: shipment,
      }
      return { ...s, items }
    }

    case actions.SET_BOOKING_ORDER_SHIPMENT_ERR: {
      const { err } = action.payload
      return { ...s, err }
    }

    case actions.ADD_BOOKINGS_TO_SHIPMENTS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.ADD_BOOKINGS_TO_SHIPMENTS_RESP: {
      const { shipment } = action.payload
      const items = {
        ...s.items,
        [shipment.getShipmentId()]: shipment,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.ADD_BOOKINGS_TO_SHIPMENTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.REMOVE_BOOKINGS_FROM_SHIPMENTS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.REMOVE_BOOKINGS_FROM_SHIPMENTS_RESP: {
      const { shipment } = action.payload
      const items = {
        ...s.items,
        [shipment.getShipmentId()]: shipment,
      }
      return { ...s, items, isFetching: false }
    }

    case actions.REMOVE_BOOKINGS_FROM_SHIPMENTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.CREATE_SHIPMENT_COST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.CREATE_SHIPMENT_COST_RESP: {
      const { shipmentCost } = action.payload
      const splitCostRef = {
        ...s.splitCostRef,
        [shipmentCost.getSplitCostReferenceId()]: shipmentCost,
      }

      return { ...s, splitCostRef, isFetching: false }
    }

    case actions.CREATE_SHIPMENT_COST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.LIST_SPLIT_COST_REF_REQ: {
      return { ...s, err: undefined, isFetchingSplitCost: true }
    }

    case actions.LIST_SPLIT_COST_REF_RESP: {
      const { costRef } = action.payload
      const newItems = costRef.reduce((items, s) => {
        return { ...items, [s.getSplitCostReferenceId()]: s }
      }, {})
      const splitCostRef = { ...s.splitCostRef, ...newItems }
      return { ...s, splitCostRef, isFetchingSplitCost: false, err: undefined }
    }

    case actions.LIST_SPLIT_COST_REF_ERR: {
      const { err } = action.payload
      return { ...s, err: err, isFetchingSplitCost: false }
    }

    case actions.UPDATE_SHIPMENT_COST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_SHIPMENT_COST_RESP: {
      const { cost } = action.payload

      const shipmentCost = cost.getShipmentCost()
      if (shipmentCost) {
        const splitCostRef = {
          ...s.splitCostRef,
          [shipmentCost.getSplitCostReferenceId()]: shipmentCost,
        }
        return { ...s, splitCostRef, isFetching: false }
      } else {
        return { ...s, isFetching: false }
      }
    }

    case actions.UPDATE_SHIPMENT_COST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.UPDATE_SHIPMENT_COST_STATUS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.UPDATE_SHIPMENT_COST_STATUS_RESP: {
      const { shipmentCost } = action.payload
      const splitCostRef = {
        ...s.splitCostRef,
        [shipmentCost.getSplitCostReferenceId()]: shipmentCost,
      }

      return { ...s, splitCostRef, isFetching: false }
    }

    case actions.UPDATE_SHIPMENT_COST_STATUS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
