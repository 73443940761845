/**
 * @fileoverview gRPC-Web generated client stub for swift.booking.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.4
// source: booking/v1/supplier.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var crm_v1_address_pb = require('../../crm/v1/address_pb.js')
const proto = {};
proto.swift = {};
proto.swift.booking = {};
proto.swift.booking.v1 = require('./supplier_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.SupplierSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.SupplierSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.ListSuppliersRequest,
 *   !proto.swift.booking.v1.ListSuppliersResponse>}
 */
const methodDescriptor_SupplierSvc_ListSuppliers = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/ListSuppliers',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.ListSuppliersRequest,
  proto.swift.booking.v1.ListSuppliersResponse,
  /**
   * @param {!proto.swift.booking.v1.ListSuppliersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.ListSuppliersResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.ListSuppliersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.ListSuppliersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.ListSuppliersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.listSuppliers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/ListSuppliers',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_ListSuppliers,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.ListSuppliersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.ListSuppliersResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.listSuppliers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/ListSuppliers',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_ListSuppliers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.ListConfigsRequest,
 *   !proto.swift.booking.v1.ListConfigsResponse>}
 */
const methodDescriptor_SupplierSvc_ListConfigs = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/ListConfigs',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.ListConfigsRequest,
  proto.swift.booking.v1.ListConfigsResponse,
  /**
   * @param {!proto.swift.booking.v1.ListConfigsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.ListConfigsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.ListConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.ListConfigsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.ListConfigsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.listConfigs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/ListConfigs',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_ListConfigs,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.ListConfigsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.ListConfigsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.listConfigs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/ListConfigs',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_ListConfigs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetSupplierRequest,
 *   !proto.swift.booking.v1.GetSupplierResponse>}
 */
const methodDescriptor_SupplierSvc_GetSupplier = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/GetSupplier',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetSupplierRequest,
  proto.swift.booking.v1.GetSupplierResponse,
  /**
   * @param {!proto.swift.booking.v1.GetSupplierRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetSupplierResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetSupplierResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetSupplierResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.getSupplier =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/GetSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_GetSupplier,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetSupplierResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.getSupplier =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/GetSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_GetSupplier);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetSupplierConfigRequest,
 *   !proto.swift.booking.v1.GetSupplierConfigResponse>}
 */
const methodDescriptor_SupplierSvc_GetConfig = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/GetConfig',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetSupplierConfigRequest,
  proto.swift.booking.v1.GetSupplierConfigResponse,
  /**
   * @param {!proto.swift.booking.v1.GetSupplierConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetSupplierConfigResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetSupplierConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetSupplierConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetSupplierConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.getConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/GetConfig',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_GetConfig,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetSupplierConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetSupplierConfigResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.getConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/GetConfig',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_GetConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.CreateSupplierRequest,
 *   !proto.swift.booking.v1.CreateSupplierResponse>}
 */
const methodDescriptor_SupplierSvc_CreateSupplier = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/CreateSupplier',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.CreateSupplierRequest,
  proto.swift.booking.v1.CreateSupplierResponse,
  /**
   * @param {!proto.swift.booking.v1.CreateSupplierRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.CreateSupplierResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.CreateSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.CreateSupplierResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.CreateSupplierResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.createSupplier =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/CreateSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_CreateSupplier,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.CreateSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.CreateSupplierResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.createSupplier =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/CreateSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_CreateSupplier);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.CreateSupplierServiceRequest,
 *   !proto.swift.booking.v1.CreateSupplierServiceResponse>}
 */
const methodDescriptor_SupplierSvc_CreateSupplierService = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/CreateSupplierService',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.CreateSupplierServiceRequest,
  proto.swift.booking.v1.CreateSupplierServiceResponse,
  /**
   * @param {!proto.swift.booking.v1.CreateSupplierServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.CreateSupplierServiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.CreateSupplierServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.CreateSupplierServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.CreateSupplierServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.createSupplierService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/CreateSupplierService',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_CreateSupplierService,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.CreateSupplierServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.CreateSupplierServiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.createSupplierService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/CreateSupplierService',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_CreateSupplierService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.EditSupplierRequest,
 *   !proto.swift.booking.v1.EditSupplierResponse>}
 */
const methodDescriptor_SupplierSvc_EditSupplier = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/EditSupplier',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.EditSupplierRequest,
  proto.swift.booking.v1.EditSupplierResponse,
  /**
   * @param {!proto.swift.booking.v1.EditSupplierRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.EditSupplierResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.EditSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.EditSupplierResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.EditSupplierResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.editSupplier =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/EditSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_EditSupplier,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.EditSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.EditSupplierResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.editSupplier =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/EditSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_EditSupplier);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.EditSupplierServiceRequest,
 *   !proto.swift.booking.v1.EditSupplierServiceResponse>}
 */
const methodDescriptor_SupplierSvc_EditSupplierService = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/EditSupplierService',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.EditSupplierServiceRequest,
  proto.swift.booking.v1.EditSupplierServiceResponse,
  /**
   * @param {!proto.swift.booking.v1.EditSupplierServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.EditSupplierServiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.EditSupplierServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.EditSupplierServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.EditSupplierServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.editSupplierService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/EditSupplierService',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_EditSupplierService,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.EditSupplierServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.EditSupplierServiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.editSupplierService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/EditSupplierService',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_EditSupplierService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.SetSupplierConfigRequest,
 *   !proto.swift.booking.v1.SetSupplierConfigResponse>}
 */
const methodDescriptor_SupplierSvc_SetConfig = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/SetConfig',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.SetSupplierConfigRequest,
  proto.swift.booking.v1.SetSupplierConfigResponse,
  /**
   * @param {!proto.swift.booking.v1.SetSupplierConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.SetSupplierConfigResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.SetSupplierConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.SetSupplierConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.SetSupplierConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.setConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/SetConfig',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_SetConfig,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.SetSupplierConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.SetSupplierConfigResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.setConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/SetConfig',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_SetConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.DeleteSupplierRequest,
 *   !proto.swift.booking.v1.DeleteSupplierResponse>}
 */
const methodDescriptor_SupplierSvc_DeleteSupplier = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/DeleteSupplier',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.DeleteSupplierRequest,
  proto.swift.booking.v1.DeleteSupplierResponse,
  /**
   * @param {!proto.swift.booking.v1.DeleteSupplierRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.DeleteSupplierResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.DeleteSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.DeleteSupplierResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.DeleteSupplierResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.deleteSupplier =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/DeleteSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_DeleteSupplier,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.DeleteSupplierRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.DeleteSupplierResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.deleteSupplier =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/DeleteSupplier',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_DeleteSupplier);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.DeleteSupplierServiceRequest,
 *   !proto.swift.booking.v1.DeleteSupplierServiceResponse>}
 */
const methodDescriptor_SupplierSvc_DeleteSupplierService = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.SupplierSvc/DeleteSupplierService',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.DeleteSupplierServiceRequest,
  proto.swift.booking.v1.DeleteSupplierServiceResponse,
  /**
   * @param {!proto.swift.booking.v1.DeleteSupplierServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.DeleteSupplierServiceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.DeleteSupplierServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.DeleteSupplierServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.SupplierSvcClient.prototype.deleteSupplierService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/DeleteSupplierService',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_DeleteSupplierService,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.DeleteSupplierServiceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.SupplierSvcPromiseClient.prototype.deleteSupplierService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.SupplierSvc/DeleteSupplierService',
      request,
      metadata || {},
      methodDescriptor_SupplierSvc_DeleteSupplierService);
};


module.exports = proto.swift.booking.v1;

