import { combineReducers } from 'redux'

import { State as ProductState, default as product } from '../order/product/reducer'
import { State as OrderState, default as order } from './order/reducer'

export interface State {
  order: OrderState
  product: ProductState
}

export default combineReducers<State>({
  order,
  product,
} as any)
// NOTE: "as any" is a temp fix for https://github.com/reduxjs/redux/issues/2709.
