import { RootState } from '../../store/reducer'

import { UserProfile } from '../../helpers/auth'

import { AuthSignup } from '../../types/signup'

import * as actions from './actions'

export interface State {
  readonly isAuthenticated: boolean
  readonly profile?: UserProfile
  readonly err?: Error
  readonly isFetching: boolean
  readonly credential?: AuthSignup
}

export const initialState: State = {
  isAuthenticated: false,
  profile: undefined,
  err: undefined,
  isFetching: true,
}

export const getIsAuthenticated = (state: RootState) => state.auth.isAuthenticated
export const getProfile = (state: RootState) => state.auth.profile
export const getErr = (state: RootState) => state.auth.err
export const getIsFetching = (state: RootState) => state.auth.isFetching
export const getSignedUpCredential = (state: RootState) => state.auth.credential

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.AUTH: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.AUTH_RESP: {
      const { profile } = action.payload
      return { ...s, isAuthenticated: true, profile, isFetching: false }
    }

    case actions.AUTH_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.LOGOUT: {
      return { ...s, isAuthenticated: false, profile: undefined }
    }

    case actions.SIGNUP_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SIGNUP_RESP: {
      const { credential } = action.payload
      return { ...s, credential, isFetching: false }
    }

    case actions.SIGNUP_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
